<section class="form-container">
  <div class="form-container__title">
    Avaliar o atendimento
  </div>
  <div class="form-container__separator"></div>
  <div class="form-container__subtitle">
    Gostaríamos de saber como foi sua experiência com nosso atendimento?
  </div>
  <ul class="stars-list" (mouseleave)="clearStarsFill()">
    <li
      class="star star-{{ i }}"
      *ngFor="let star of stars; let i = index"
      (click)="selectStar(i)"
      (mouseenter)="fillStar(i)"
    >
      <img
        class="show"
        id="star-{{ i }}"
        src="../../../assets/images/star.svg"
        alt="estrela desselecionada"
      />
      <img
        class="hide"
        id="star-{{ i }}-selected"
        src="../../../assets/images/star-{{ attendanceStar }}.svg"
        alt="estrela selecionada"
      />
    </li>
  </ul>
  <div class="form-container__reason" *ngIf="selected">
    <app-select
      id="rating-reasons"
      #sellersSelect
      [props]="ratingField"
      [selectItems]="ratingReasons"
      (valuesSelected)="setReason($event)"
    ></app-select>

    <label for="description" class="filter-label">Descrição</label>
    <textarea
      #ratingDescription
      name="Descrição"
      class="bla-input--textarea"
      maxlength="301"
    ></textarea>
    <app-character-count
      [characters]="ratingDescription.value"
      [charactersLimit]="300"
      maxlength="301"
    ></app-character-count>

    <div class="rating-actions">
      <button
        id="btn-send-attendance-rating"
        class="bla-btn bla-btn--primary"
        (click)="send()"
      >
        Confirmar
      </button>
    </div>
  </div>
</section>
