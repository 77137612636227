import { Component, Input, OnInit } from '@angular/core'
import { InfoCardType, TitleType } from '@app/components/info-card/info-card'
import * as moment from 'moment'
import { QueueInfoCardType } from '../../monitoring-queues/monitoring-queues'

@Component({
  selector: 'app-monitoring-queues-info',
  templateUrl: './monitoring-queues-info.component.html',
  styleUrls: ['./monitoring-queues-info.component.scss'],
})
export class MonitoringQueuesInfoComponent implements OnInit {
  @Input() queue: {
    name: string
    activeAttendances: number
    availableAttendancesWindows: number
    lastChatDate: Date
    queuedAttendances: number
  }

  @Input() id: number

  timeDiff: string

  QueueInfoCardType = QueueInfoCardType
  InfoCardType = InfoCardType
  TitleType = TitleType

  ngOnInit() {
    this.timeDiff = `${this.buildWaitingTime(
      this.queue.lastChatDate,
    )?.toString()} min`
  }

  buildWaitingTime(time: Date) {
    const utcNow = moment.utc()

    const lastEnqueuedChatDate = moment.utc(time).local()

    return time !== null ? utcNow.diff(lastEnqueuedChatDate, 'minutes') : 0
  }
}
