import { UserAccessComponent } from './forms/user-access/user-access.component'
import { UserAndWorkplaceComponent } from './forms/user-and-workplace/user-and-workplace.component'

export const userRoutes = [
  {
    path: '',
    component: UserAccessComponent,
  },
  {
    path: 'chats-e-times',
    component: UserAndWorkplaceComponent,
  },
]
