<div
  class="row"
  [formGroup]="controlContainer.control"
  *ngIf="parametersControls?.length > 0"
>
  <table>
    <caption class="table-title">
      Configurar Variável
    </caption>
    <thead>
      <th scope="col" class="column-item">Item</th>
      <th scope="col" class="column-variable">Variável</th>
      <th scope="col" class="column-edit">Habilitar Edição?</th>
      <th scope="col" class="column-action" *ngIf="isCreateMode"></th>
    </thead>
    <tbody>
      <tr
        *ngFor="let parameter of parametersControls; index as i"
        [formGroupName]="i"
        id="variable-info-{{ i }}"
      >
        <td>{{ i + 1 }}</td>
        <td id="message-variable-{{ i }}">
          <ng-select
            *ngIf="isCreateMode"
            formControlName="parameterType"
            (change)="patchValueControl()"
            class="custom-dropdown"
            placeholder="Variável"
            [searchable]="true"
            [clearable]="true"
            [closeOnSelect]="true"
          >
            <ng-option
              [value]="type.value"
              *ngFor="let type of parameterTypeOptions"
            >
              {{ type.text }}
            </ng-option>
          </ng-select>
          <div *ngIf="!isCreateMode">
            <span *ngFor="let type of parameterTypeOptions">
              {{ type.text }}
            </span>
          </div>
        </td>

        <td *ngIf="!isCreateMode">
          <span *ngFor="let type of parameterTypeOptions">
            {{ parameter.value.fixed ? 'Sim' : 'Não' }}
          </span>
        </td>

        <td class="column-checkbox" *ngIf="isCreateMode">
          <div class="checkbox-container">
            <input
              formControlName="fixed"
              (change)="patchValueControl()"
              type="checkbox"
              class="checkbox"
              id="message-status-{{ i }}"
            />
            <label
              for="message-status-{{ i }}"
              class="switch"
              id="message-status-{{ i }}-switch"
            >
              <div class="switch-on">Sim</div>
              <div class="switch-off">Não</div>
            </label>
          </div>
        </td>
        <td *ngIf="isCreateMode">
          <button
            type="button"
            (click)="removeMessageVariable(i)"
            id="message-{{ i }}-remove"
          >
            Remover
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<span class="observation-text"
  >Editar: Se a opção for não, o vendedor não poderá alterar o valor da variável
  no momento de encaminhar a mensagem.</span
>
