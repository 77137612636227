<div class="row" [formGroup]="parentForm">
  <div formGroupName="{{ controlName }}Group" class="col-lg-12">
    <div class="form-group col-lg-12">
      <div class="checkbox-container form-group">
        <label class="form-check-label" for="{{ controlName }}">{{
          weekDay
        }}</label>
        <input
          formControlName="{{ controlName }}"
          type="checkbox"
          id="{{ controlName }}"
          class="checkbox col-lg-6"
        />
        <label
          for="{{ controlName }}"
          class="switch"
          id="{{ controlName }}switch"
        >
          <div class="switch-on">Sim</div>
          <div class="switch-off">Não</div>
        </label>
      </div>
    </div>
    <div
      class="col-lg-12 time-input-fields"
      *ngIf="parentForm.get(controlName + 'Group').get(controlName).value"
    >
      <div class="row">
        <div class="form-group form-item col-lg-6">
          <label for="start"
            >Início da atividade
            <small class="info">(obrigatório)</small></label
          >
          <input
            formControlName="{{ controlName }}StartTime"
            autofocus
            class="form-control"
            id="{{ controlName }}StartTime"
            placeholder="hh:mm"
            mask="00:00"
          />
        </div>
        <div class="form-group form-item col-lg-6">
          <label for="end"
            >Final da atividade <small class="info">(obrigatório)</small></label
          >
          <input
            formControlName="{{ controlName }}EndTime"
            autofocus
            class="form-control"
            id="{{ controlName }}EndTime"
            placeholder="hh:mm"
            mask="00:00"
          />
        </div>
      </div>
      <div class="row">
        <div class="input-info col-lg-12">
          <ul
            class="input-errors"
            *ngIf="
              formControlValidator.isInvalid(parentForm, controlName + 'Group')
            "
          >
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasAnyError(
                  parentForm,
                  controlName + 'Group',
                  controlName + 'StartTimeEmpty',
                  controlName + 'EndTimeEmpty'
                )
              "
            >
              É necessário informar o horário inicial e final
            </li>
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasAnyError(
                  parentForm,
                  controlName + 'Group',
                  controlName + 'StartTimePattern',
                  controlName + 'EndTimePattern'
                )
              "
            >
              O horário deve estar no formato 24 horas
            </li>
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasAnyError(
                  parentForm,
                  controlName + 'Group',
                  controlName + 'TimeError'
                )
              "
            >
              O horário inicial deve ser menor do que o horário final
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
