<div class="bla-container">
  <app-title title="Monitoramento de Atendimento"></app-title>

  <app-monitoring-attendances-filter
    [class.hide]="attendanceDetail"
    (search)="searchAttendancesByFilters($event)"
    (resetSorts)="resetSorts($event)"
  ></app-monitoring-attendances-filter>

  <app-attendance-search-result
    [class.hide]="attendanceDetail"
    [attendances]="attendances"
    [resetTable]="resetTable"
    (eventDetailClicked)="fillAttendanceDetail($event)"
  ></app-attendance-search-result>

  <app-monitoring-attendance-details
    *ngIf="attendanceDetail"
    [attendance]="attendanceDetail"
    (eventHideDetail)="resetAttendanceDetail()"
  ></app-monitoring-attendance-details>
</div>

<app-modal type="default" class="hide" id="limit-attendance-modal">
  <div class="limit-attendance-modal__title">Limite de atendimentos</div>
  <hr />

  <p class="message" id="limit-attendance-message">
    O sistema por questões de performance limitou a busca para você. <br />
    É necessário que você aplique mais filtros e refine sua busca para poder
    visualizar os dados.
  </p>

  <div class="limit-attendance-modal__action-btns">
    <button
      type="reset"
      (click)="closeModal()"
      class="bla-btn bla-btn--primary"
    >
      Confirmar
    </button>
  </div>
</app-modal>
