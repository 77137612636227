import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core'
import { AttendanceService } from '@app/chat/attendance/attendance.service'
import { InfoBarService } from '@app/info-bar/info-bar.service'
import { HtmlTools } from '@app/tools/html-tools'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-close-inactive-attendance-modal',
  templateUrl: './close-inactive-attendance-modal.component.html',
  styleUrls: ['./close-inactive-attendance-modal.component.scss'],
})
export class CloseInactiveAttendanceModalComponent
  implements AfterViewInit, OnDestroy {
  confirmationModal: HTMLElement
  subscription: Subscription

  endClosingAttendances = new EventEmitter()

  @Input() attendanceIds: string[]
  @Output() closeInactiveAttendanceSuccess = new EventEmitter<string>()

  constructor(
    private htmlTools: HtmlTools,
    private attendanceService: AttendanceService,
    private infoBarService: InfoBarService,
  ) {}

  ngAfterViewInit() {
    this.subscription = this.endClosingAttendances.subscribe(() => {
      this.closeModal()
    })

    this.confirmationModal = document.getElementById(
      'close-inactive-attendance-modal',
    )
  }

  submit = () => {
    this.subscription = this.attendanceService
      .bachCloseAttendances(this.attendanceIds)
      .subscribe(
        response => {
          if (response.success) {
            this.showSucessMessage()

            this.closeInactiveAttendanceSuccess.emit()
          } else
            response.successfullyClosedAttendanceIds.length !== 0
              ? this.showPartialSuccessMessage()
              : this.showErrorMessage()
        },
        () => this.showErrorMessage(),
      )
      .add(() => {
        this.endClosingAttendances.emit()
      })
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe()
  }

  closeModal = () => this.htmlTools.hideElement(this.confirmationModal)

  openModal = () => this.htmlTools.showElement(this.confirmationModal)

  showSucessMessage = () =>
    this.infoBarService.show(
      'Os atendimentos selecionados foram encerrados com sucesso.',
      'success',
    )

  showPartialSuccessMessage = () =>
    this.infoBarService.show(
      'Alguns atendimentos não foram encerrados com sucesso. Consulte o suporte se o problema persistir',
      'danger',
    )

  showErrorMessage = () =>
    this.infoBarService.show(
      'Falha ao encerrar os atendimentos. Consulte o suporte se o problema persistir',
      'danger',
    )
}
