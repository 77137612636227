<div class="image-loader" *ngIf="loading">
  <div class="loader loader--chat-thumb">
    <div class="animation-reflex"></div>
  </div>
</div>

<section
  *ngIf="imageStatus === ImageStatus.Available"
  class="image image--available"
>
  <button
    class="bla-btn-remove-image bla-btn-remove-image--monitoring"
    (click)="openDeleteImageModal(message.messageId)"
  >
    remover imagem
  </button>

  <picture *ngIf="!loading" (click)="openImageModal(url)">
    <source [srcset]="url" />
    <img (load)="hideLoader()" [src]="url" [alt]="imageAlt" />
  </picture>
</section>

<section
  *ngIf="imageStatus === ImageStatus.Removed"
  class="image image--removed"
>
  <picture>
    <source srcset="../../../assets/images/noimage_available_gray.svg" />
    <img
      src="../../../assets/images/noimage_available_gray.svg"
      (load)="hideLoader()"
      alt="Imagem removida"
    />
  </picture>
  <p class="image-description">Imagem removida</p>
</section>

<section
  *ngIf="imageStatus === ImageStatus.Unavailable"
  class="image image--unavailable"
>
  <picture>
    <source srcset="../../../assets/images/noimage_available_gray.svg" />
    <img
      src="../../../assets/images/noimage_available_gray.svg"
      (load)="hideLoader()"
      alt="Imagem indisponível"
    />
  </picture>
  <p class="image-description">Imagem indisponível</p>
</section>
