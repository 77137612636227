<h4>Atendimentos</h4>

<ngx-datatable
  #table
  [columnMode]="ColumnMode.force"
  [messages]="messages"
  [rowHeight]="40"
  [footerHeight]="hiddenMenu ? 0 : 30"
  [rows]="rows"
  [columns]="tableColumns"
  [limit]="pageSize"
  [sortType]="SortType.multi"
  id="attendances-result"
>
  <ngx-datatable-footer>
    <ng-template
      ngx-datatable-footer-template
      let-rowCount="rowCount"
      let-pageSize="pageSize"
      let-selectedCount="selectedCount"
      let-curPage="curPage"
      let-offset="offset"
    >
      <datatable-pager
        [pagerLeftArrowIcon]="'datatable-icon-left'"
        [pagerRightArrowIcon]="'datatable-icon-right'"
        [page]="curPage"
        [size]="pageSize"
        [count]="rowCount"
        [hidden]="!(rowCount / pageSize > 1)"
        (change)="table.onFooterPage($event)"
      >
      </datatable-pager>
    </ng-template>
  </ngx-datatable-footer>
</ngx-datatable>

<ng-template #details let-row="row" let-value="value">
  <app-details-icon
    [visited]="row.visited"
    (click)="onDetailAttendance(row)"
  ></app-details-icon>
</ng-template>
