import {
  Component,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { Attendance } from '@app/chat/attendance/attendance'
import { TweenMax, Expo } from 'gsap'
import { QueueInfo } from '@app/register/queue/queue.model'
import { interval, Subscription } from 'rxjs'
import { QueueService } from '@app/register/queue/queue.service'
import * as moment from 'moment'
import { CustomerStatus } from '../chat-panel/chat-panel'

@Component({
  selector: 'app-chat-user-detail-mobile',
  templateUrl: './chat-user-detail-mobile.component.html',
  styleUrls: ['./chat-user-detail-mobile.component.scss'],
})
export class ChatUserDetailMobileComponent
  implements OnInit, OnChanges, OnDestroy {
  @ViewChild('chatDetails') private chatDetails: ElementRef
  @Output() showParentModal = new EventEmitter()
  @Output() showCustomerReviewModal = new EventEmitter()
  @Output() showTransferParentModal = new EventEmitter()
  @Input() conversation: Attendance
  @Input() buttonsDisabled: boolean
  @Input() hasReview: boolean

  CustomerStatus = CustomerStatus

  detailsArrow: HTMLElement
  isDetailOpen = false

  queuedAttendances: number
  attendanceStartTime: number
  currentSessionTime: string
  sourceInterval = interval(1000)
  subscription: Subscription
  attendanceInteractionTime: number
  sla: string

  constructor(private queueService: QueueService) {}

  ngOnInit() {
    this.getAttedanceTimesDiff()
    this.getSlaTime()
  }

  ngOnChanges() {
    this.getQueuedAttendances()
    this.subscription = this.sourceInterval.subscribe(_ =>
      this.getAttedanceTimesDiff(),
    )
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe()
  }

  animateDetails = () => {
    this.isDetailOpen ? this.closeDetails() : this.openDetails()
  }

  getSlaTime = () => {
    const time = this.conversation?.sla.split(':')

    const hour: number = Number(time[0])
    const minute: number = Number(time[1])
    const seconds: number = Number(time[2])

    this.sla = `${hour <= 0 ? '' : hour + 'h'}${
      minute < 10 ? '0' + minute + 'min' : minute + 'min'
    }${seconds <= 0 ? '' : seconds < 10 ? '0' + seconds + 's' : seconds + 's'}`
  }

  openDetails = () => {
    this.isDetailOpen = true
    this.detailsArrow = this.chatDetails.nativeElement.children[0].children[1]

    TweenMax.fromTo(
      this.chatDetails.nativeElement.children[2],
      0.5,
      { opacity: 0 },
      { opacity: 1 },
    )

    TweenMax.fromTo(
      this.chatDetails.nativeElement,
      0.25,
      { height: 52 },
      {
        height: 'auto',
        autoRound: false,
        ease: Expo.easeOut,
      },
    )

    TweenMax.fromTo(this.detailsArrow, 0.3, { rotation: 0 }, { rotation: 180 })
  }

  closeDetails = () => {
    this.isDetailOpen = false

    TweenMax.fromTo(
      this.chatDetails.nativeElement.children[2],
      0.25,
      { opacity: 1 },
      { opacity: 0 },
    )

    TweenMax.fromTo(
      this.chatDetails.nativeElement,
      0.5,
      { height: 'auto' },
      { height: 52, autoRound: false, ease: Expo.easeOut },
    )

    TweenMax.fromTo(this.detailsArrow, 0.5, { rotation: 180 }, { rotation: 0 })
  }

  getQueuedAttendances = () =>
    this.queueService
      .getQueued(this.conversation?.queueId)
      .subscribe(
        queuedAttendances => (this.queuedAttendances = queuedAttendances),
      )

  getAttedanceTimesDiff = () => {
    const attendanceCreationTime = moment(
      this.conversation?.startDate,
    ).valueOf()

    this.attendanceInteractionTime = moment().valueOf()

    const timeDiff = this.attendanceInteractionTime - attendanceCreationTime

    const convertedTime = this.convertDateMillesecondsToTime(timeDiff)

    const { hour, minute, seconds } = convertedTime

    this.currentSessionTime = `${hour <= 0 ? '' : hour + 'h'}${
      minute < 10 ? '0' + minute : minute
    }m${seconds < 10 ? '0' + seconds : seconds}s`
  }

  convertDateMillesecondsToTime = (milliseconds: number) => {
    let hour: number
    let minute: number
    let seconds: number

    seconds = Math.floor(milliseconds / 1000)
    minute = Math.floor(seconds / 60)
    seconds = seconds % 60
    hour = Math.floor(minute / 60)
    minute = minute % 60
    hour = hour % 24

    return {
      hour,
      minute,
      seconds,
    }
  }

  openCloseAttendanceModal = () => this.showParentModal.emit('')

  openChatTransferModal = () => this.showTransferParentModal.emit('')

  openCustomerReviewModal = () => this.showCustomerReviewModal.emit('')
}
