import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AuthenticationService } from '@app/chat/authentication.service'
import { AttendantStatus } from '@app/register/user/user.model'
import { LocalStorageService } from '@app/tools/local-storage.service'
import { PassportUserService } from '@app/tools/passport-user.service'

import { Authentication } from '../authentication'
import { Passport } from '../logged-user.model'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  showErrorMessage = false

  constructor(
    private authenticationService: AuthenticationService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private passportUserService: PassportUserService,
  ) {}

  ngOnInit() {
    const passport = this.passportUserService.get()
    passport ? this.login(passport) : this.redirectToSSOLoginPage()
  }

  redirectToSSOLoginPage = () => (window.location.href = '/api/login')

  private login = (passport: Passport) => {
    this.authenticationService
      .loginOrLogout(passport.user.userName, Authentication.Login)
      .subscribe(
        userInfo => {
          this.localStorageService.saveLoggedUser({
            userInfo,
            attendantStatus: AttendantStatus.Unavailable,
          })
          this.router.navigate(['/'])
        },
        _ => (this.showErrorMessage = true),
      )
  }
}
