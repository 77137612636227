<div class="new-customer-container">
  <img
    src="../../../assets/images/begin-attendance-blue-dark.svg"
    alt="Novos Clientes"
  />
  <p class="new-customer-text">
    Novos clientes
  </p>
  <button
    id="new-customer-btn"
    type="button"
    class="bla-btn btn-btn--new-customer"
    (click)="openNewCustomerModal()"
  >
    Adicionar
  </button>
</div>
