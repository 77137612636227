<div class="bla-modal customer-review-modal">
  <div class="bla-modal__body">
    <h1 class="bla-modal-title">Observação sobre o cliente</h1>
    <div class="bla-modal-separator"></div>

    <div class="bla-row customer-review-text">
      <div class="bla-column">
        <div class="bla-modal-label no-margin-bottom">
          Descrição
        </div>
        <button
          class="trash-bin"
          [ngClass]="
            reviewTextContent === '' ? 'bla-btn--disabled' : 'trash-bin'
          "
          (click)="openExcludeReviewModal()"
          *ngIf="reviewTextContent !== ''"
        >
          <img
            src="../../../assets/images/trash_bin.svg"
            alt="excluir comentário"
          />
          <div class="trash-bin__close">excluir</div>
        </button>
        <textarea
          #reviewContent
          maxlength="501"
          name="Customer Review"
          class="bla-input--textarea"
          [value]="reviewTextContent"
          id="customer-review-textarea"
          >{{ reviewTextContent }}</textarea
        >
        <app-character-count
          [characters]="reviewContent.value"
          [charactersLimit]="500"
        ></app-character-count>
      </div>
    </div>

    <div class="bla-modal-actions">
      <button class="bla-btn bla-btn--secondary" (click)="close()">
        Cancelar
      </button>
      <button
        type="button"
        class="bla-btn"
        (click)="save()"
        [ngClass]="invalid ? 'bla-btn--disabled' : 'bla-btn--primary'"
        [disabled]="invalid"
        id="save-customer-review-button"
      >
        Confirmar
      </button>
    </div>
  </div>
</div>
<div class="bla-modal__background"></div>
