<div class="bla-modal">
  <div class="bla-modal__body">
    <h1 class="bla-modal-title">Remover imagem</h1>
    <div class="bla-modal-separator"></div>

    <div class="bla-row">
      <div class="bla-modal-text-info">
        <p>Você tem certeza que deseja remover esta imagem?</p>
        <p>
          IMPORTANTE: <br />
          Está ação não poderá ser desfeita.
        </p>
      </div>
    </div>

    <div class="bla-modal-actions">
      <button
        id="close-delete-image-modal"
        type="reset"
        (click)="closeModal()"
        class="bla-btn"
      >
        Cancelar
      </button>
      <button
        id="confirm-delete-image-modal"
        type="button"
        class="bla-btn bla-btn--danger"
        (click)="removeImage()"
      >
        Remover
      </button>
    </div>
  </div>
</div>
<div class="bla-modal__background"></div>
