import { QueueAutomaticMessagesComponent } from './forms/queue-automatic-messages/queue-automatic-messages.component'
import { QueueNameComponent } from '@app/register/queue/forms/queue-name/queue-name.component'
import { QueueWaitingComponent } from './forms/queue-waiting/queue-waiting.component'
import { QueueAttachmentsComponent } from './forms/queue-attachments/queue-attachments.component'

export const queueRoutes = [
  {
    path: '',
    component: QueueNameComponent,
  },
  {
    path: 'mensagens',
    component: QueueAutomaticMessagesComponent,
  },
  {
    path: 'espera',
    component: QueueWaitingComponent,
  },
  {
    path: 'anexos',
    component: QueueAttachmentsComponent,
  },
]
