import { Injectable } from '@angular/core'
import { QueueInfo } from '@app/register/queue/queue.model'
import { QueueService } from '@app/register/queue/queue.service'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class MonitoringQueuesService {
  constructor(private queueService: QueueService) {}

  getQueuesInfo = (): Observable<QueueInfo[]> =>
    this.queueService.getQueueInfos()
}
