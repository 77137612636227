<div class="bla-modal image-modal">
  <div class="bla-modal__body bla-modal__body--image">
    <button class="close-modal" (click)="close()">
      <img src="../../../assets/images/close.svg" alt="fechar modal" />
    </button>
    <div
      class="loader"
      [ngClass]="orientation ? 'loader--' + orientation : 'loader--portrait'"
      *ngIf="!imageReady"
    >
      <div class="animation-reflex"></div>
    </div>
    <img
      id="modal-image"
      (load)="setImageOrientation()"
      [src]="blobURL"
      class="image"
      #image
      alt="Imagem enviada pelo cliente ampliada"
    />
  </div>
</div>
<div class="bla-modal__background"></div>
