import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '@env/environment'
import { Observable } from 'rxjs'
import { CustomerReview } from './customer-review'

@Injectable({
  providedIn: 'root',
})
export class CustomerReviewService {
  private url: string

  constructor(private http: HttpClient) {
    this.url = `${environment.urlBlaMessageManagementApi}/CustomerReview`
  }

  public post = (review: CustomerReview): Observable<any> =>
    this.http.post<CustomerReview>(this.url, review)

  public get = (customerId: string): Observable<any> =>
    this.http.get<string>(`${this.url}/${customerId}`)

  public delete = (customerId: string): Observable<any> =>
    this.http.delete(`${this.url}/${customerId}`)
}
