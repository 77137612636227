<div class="container">
  <div class="row">
    <app-title title="Usuários"></app-title>
  </div>
  <div class="row">
    <app-search
      [props]="{
        placeholder: 'Digite o nome do usuário'
      }"
      (searchEvent)="searchUsers($event)"
      id="search-username"
    ></app-search>
  </div>
  <div class="row">
    <button
      routerLink="/cadastros/usuario"
      class="bla-btn bla-btn--primary new-user"
    >
      Novo Usuário
    </button>
  </div>

  <ngx-datatable
    [rows]="searchResult"
    [columnMode]="ColumnMode.flex"
    [messages]="messages"
    [limit]="10"
    [footerHeight]="hiddenFooter ? 0 : 40"
    #table
  >
    <ngx-datatable-column prop="name" name="Nome" [flexGrow]="2">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column prop="username" name="Username" [flexGrow]="2">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column prop="active" name="Status" [flexGrow]="1">
      <ng-template let-value="value" ngx-datatable-cell-template>
        <app-status-tag
          [value]="value ? 'Ativo' : 'Inativo'"
          [color]="value ? 'success' : 'danger'"
        ></app-status-tag>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [sortable]="false" name="Ações" [flexGrow]="1">
      <ng-template let-value="value" ngx-datatable-cell-template let-row="row">
        <a
          *ngIf="loggedUserHasSufficientPrivileges(row)"
          [routerLink]=""
          (click)="editUser(row['id'])"
          id="edit-user-{{ row['id'] }}"
          >Editar</a
        >
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-footer>
      <ng-template
        ngx-datatable-footer-template
        let-rowCount="rowCount"
        let-pageSize="pageSize"
        let-selectedCount="selectedCount"
        let-curPage="curPage"
        let-offset="offset"
      >
        <datatable-pager
          [pagerLeftArrowIcon]="'datatable-icon-left'"
          [pagerRightArrowIcon]="'datatable-icon-right'"
          [page]="curPage"
          [size]="pageSize"
          [count]="rowCount"
          [hidden]="!(rowCount / pageSize > 1)"
          (change)="table.onFooterPage($event)"
        >
        </datatable-pager>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>
</div>
