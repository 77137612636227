import { MediaService } from './../../../../Media/media.service'
import { DeleteImageMessageContext } from './../../../../components/delete-image-message-modal/delete-image-message'
import { DatePipe } from '@angular/common'
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core'
import { ColumnMode, SortType } from '@swimlane/ngx-datatable'
import { Subscription } from 'rxjs'
import { Type } from '@app/chat/type'
import { AttendanceDetailsOrder } from './attendance-details-order'
import {
  AttendanceDetailsDelivery,
  AttendanceDetailsDeliveryAddress,
  AttendanceDetailsMessageContent,
  AttendanceDetailsPayment,
} from './attendance-details-message-content'
import { HtmlTools } from '@app/tools/html-tools'
import { AccessProfile } from '@app/register/user/user.model'
import { environment } from '@env/environment'
import { ImageStatus } from '@app/components/image-message/image-message'
import { MessageTranformed } from './attendance-details.model'

@Component({
  selector: 'app-attendance-details',
  templateUrl: './attendance-details.component.html',
  styleUrls: ['./attendance-details.component.scss'],
})
export class AttendanceDetailsComponent implements OnInit, AfterViewInit {
  @ViewChild('messageTemplate', { static: true }) messageTemplate: TemplateRef<
    any
  >
  imageStatus: ImageStatus
  deletedMessageId: any
  constructor(private htmlTools: HtmlTools) {}

  @Input() attendanceMessages: any
  @Input() attendanceId: string
  @Output() updateAttendanceMessages = new EventEmitter()
  @Output() openImageModalEvent = new EventEmitter()

  defaultOrderMessage = 'Resumo do Pedido'
  messages = { emptyMessage: 'Nenhum conversa foi encontrada' }
  tableColumns = []
  subscription: Subscription
  ColumnMode = ColumnMode
  SortType = SortType
  hiddenMenu = true
  attendanceDetails: any
  pipe = new DatePipe('pt')
  imageMessageContext: DeleteImageMessageContext
  imageDeleteModal: HTMLElement
  url = `${environment.urlBlaMessageManagementApi}/messages/media`

  AccessProfile = AccessProfile

  createTableColumns() {
    this.tableColumns = [
      {
        name: 'Usuário',
        prop: 'displayName',
        draggable: false,
        resizeable: false,
        flexGrow: 1.3,
        cellClass: 'ellipsis',
      },
      {
        name: 'Data',
        prop: 'date',
        draggable: false,
        resizeable: false,
        flexGrow: 1,
      },
      {
        name: 'Hora',
        prop: 'hour',
        draggable: false,
        resizeable: false,
        flexGrow: 1,
      },
      {
        name: 'Mensagem',
        prop: 'messageTemplate',
        cellTemplate: this.messageTemplate,
        draggable: false,
        resizeable: true,
        flexGrow: 5,
      },
    ]
  }

  ngAfterViewInit(): void {
    this.imageDeleteModal = document.querySelector(
      '.delete-image-message-modal',
    )
  }

  ngOnInit(): void {
    this.createTableColumns()
  }

  updateAttendance = () => this.updateAttendanceMessages.emit()

  openImageModal = (url: string) => this.openImageModalEvent.emit(url)

  formatOrderMessage = (message: MessageTranformed): AttendanceDetailsOrder => {
    const {
      delivery,
      payment,
      products,
      total,
      orderId,
    } = this.convertStringToMessageContent(message.text)

    const { address, description, value } = delivery
    const withoutDelivery = !address

    return {
      orderId,
      products: products?.map(product => ({
        name: product.name,
        value: product.value,
      })),
      deliveryTime: description,
      deliveryAddress: this.formatDelivery(withoutDelivery, delivery),
      withoutDelivery,
      deliveryPrice: value,
      paymentMethod: this.formatPaymentMethod(payment),
      paymentLink: payment.paymentLink,
      amountProduct: total - value,
      amount: total,
    }
  }

  messageIsOrder = (typeMessage: Type) => typeMessage === Type.Order

  messageIsImage = (typeMessage: Type) => typeMessage === Type.Image

  messageIsText = (typeMessage: Type) => typeMessage === Type.Text

  openDeleteImageModal = messageId => {
    this.imageMessageContext = {
      messageId,
      attendanceId: this.attendanceId,
      accessProfile: AccessProfile.Supervisor,
    }

    this.htmlTools.showElement(this.imageDeleteModal)
  }

  handleImageRemoved = message => (this.deletedMessageId = message.messageId)

  printPage = () => window.print()

  private convertStringToMessageContent = (content: string) =>
    JSON.parse(content)?.order as AttendanceDetailsMessageContent

  private formatDelivery = (
    withoutDelivery,
    delivery: AttendanceDetailsDelivery,
  ) =>
    withoutDelivery
      ? `${delivery.name}: \n ${delivery.description}`
      : this.formatAddress(delivery.address)

  private formatAddress = (address: AttendanceDetailsDeliveryAddress) =>
    `${address.street} - ${address.district} - ${address.city} - ${address.state} - CEP: ${address.zipcode}`

  private formatPaymentMethod = (payment: AttendanceDetailsPayment) =>
    `${this.formatPaymentType(payment.type)} ${payment.installmentsFormatted ||
      ''}`

  private formatPaymentType = type => {
    switch (type) {
      case 'credit_card':
        return 'Cartão de crédito'
      case 'billet':
        return 'Boleto'
      default:
        return type
    }
  }
}
