<div
  [ngClass]="{
    'message-bubble': message.senderType === Sender.Customer,
    'message-bubble--sender':
      message.senderType === Sender.OperationalUser ||
      message.senderType === Sender.Automatic ||
      message.senderType === Sender.SupervisorUser,
    'inactive-message': message.type === Type.InactiveAttendance,
    'active-message': message.type === Type.ActiveContact,
    'message-bubble--image': message.type === Type.Image
  }"
  *ngIf="
    message &&
    message.type !== Type.StartAttendance &&
    message.type !== Type.EndAttendance
  "
>
  <div
    class="message-bubble__message"
    *ngIf="
      message.type === Type.Text ||
      message.type === Type.ActiveContact ||
      message.type === Type.InactiveAttendance
    "
    [innerHTML]="message.text | anchorme"
  ></div>

  <div
    *ngIf="message.type === Type.Image && loading"
    class="message-bubble__message message-bubble__message--image"
  >
    <div class="loader loader--chat-thumb">
      <div class="animation-reflex"></div>
    </div>
  </div>

  <div *ngIf="message.type === Type.Image">
    <app-image-message
      [message]="message"
      [baseURL]="url"
      [deletedMessageId]="messageId"
      (deleteImage)="openCustomerDeleteImageMessageModal($event)"
      (zoom)="openCustomerImageMessageModal($event)"
    ></app-image-message>
  </div>
  <div class="message-bubble__time">
    enviada às {{ message.date | date: 'shortTime' }}
  </div>
</div>

<div class="message-milestones" *ngIf="message?.operationalUserName">
  <div class="message-milestones__content">
    <img
      [src]="
        message.type === Type.StartAttendance
          ? milestoneIcon.start
          : milestoneIcon.end
      "
      [alt]="message.text"
    />
    <p>
      {{ message.text }}: <strong>{{ message.operationalUserName }}</strong>
    </p>
  </div>
  <div class="message-milestones__line"></div>
</div>
