import { Injectable } from '@angular/core'
import { InfoBarComponent } from './info-bar.component'

@Injectable({ providedIn: 'root' })
export class InfoBarService {
  infoBarComponent: InfoBarComponent

  setComponent = (component: InfoBarComponent) =>
    (this.infoBarComponent = component)

  show = (message: string, type: string) => {
    if (this.infoBarComponent) {
      this.infoBarComponent.open(message, type)
    }
  }

  hide = () => this.infoBarComponent.close()
}
