import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { AttendanceService } from '@app/chat/attendance/attendance.service'
import { DirectContact } from '@app/chat/attendance/direct-contact'
import { DirectContactSellerStatus } from '@app/register/user/direct-contact'
import { UserService } from '@app/register/user/user.service'
import { environment } from '@env/environment'

@Component({
  selector: 'app-direct-contact-page',
  templateUrl: './direct-contact-page.component.html',
  styleUrls: ['./direct-contact-page.component.scss'],
})
export class DirectContactPageComponent implements OnInit {
  sellerCode: string
  sellerStatus: DirectContactSellerStatus
  subtitle: string
  showContactInput: boolean
  private url: string

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private attendanceService: AttendanceService,
  ) {
    this.url = `${environment.whatsApp}`
  }

  ngOnInit(): void {
    this.getSellerCode()
    this.validateSellerCode()
  }

  getSellerCode = () => {
    this.route.params.subscribe(params => {
      this.sellerCode = params.sellerCode
    })
  }

  validateSellerCode = () => {
    this.userService
      .validateSellerCode(this.sellerCode)
      .subscribe(
        this.handleValidateSellerCodeSuccess,
        this.handlerValidadeSellerCodeFailed,
      )
  }

  sendDirectContact = (phoneNumber: string) => {
    if (this.sellerStatus === DirectContactSellerStatus.Active) {
      const directContact: DirectContact = {
        sellerCode: this.sellerCode,
        phoneNumber,
      }

      this.attendanceService.createDirectedAttendance(directContact).subscribe({
        complete: () => {
          this.redirectToWhatsAppApi()
        },
      })
    }
    this.redirectToWhatsAppApi()
  }

  redirectToWhatsAppApi = () => {
    window.location.href = this.url
  }

  private handleValidateSellerCodeSuccess = response => {
    if (response.active) {
      this.sellerStatus = DirectContactSellerStatus.Active
      this.subtitle = `Olá, bem vindo! <br/> Gostaria de falar com o vendedor(a) ${response.name} <br /> Para garantir a sua segurança precisamos que você <br /> nos informe o seu número de telefone no campo abaixo.`
      this.showContactInput = true

      return
    }
    this.sellerStatus = DirectContactSellerStatus.Inactive
    this.subtitle = `Olá, bem vindo! <br/> Infelizmente, o vendedor ${response.name} que <br/> você deseja entrar em contato não faz mais parte do time <br/> da FAST. Você deseja continuar e conversar com outro <br/> vendedor?`
    this.showContactInput = false
  }

  private handlerValidadeSellerCodeFailed = () => {
    this.sellerStatus = DirectContactSellerStatus.Invalid
    this.subtitle =
      'Olá, bem vindo! <br/> Desculpe, não foi possível encontrar o vendedor. <br/> Você deseja continuar e conversar com outro especialista da FAST?'
    this.showContactInput = false
  }
}
