import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Step } from '@app/stepper/steps/step'
import {
  StepsEnum,
  businessHoursSteps,
} from '@app/stepper/steps/business-hours.steps'
import { of } from 'rxjs'
import {
  BusinessHours,
  DailyBusinessHours,
  DayOfWeek,
} from './business-hours.model'
import { FormGroup } from '@angular/forms'
import { BusinessHoursService } from './business-hours.service'
import { InfoBarService } from '@app/info-bar/info-bar.service'

type FormTypes = 'Name' | 'TimeTable'

@Injectable()
export class BusinessHoursStore {
  private businessHoursSteps: Step[]
  private businessHoursList: any[] = []

  private formValues: {
    [form: string]: any
  }

  get steps$() {
    return of(this.businessHoursSteps)
  }

  constructor(
    private router: Router,
    private businessHoursService: BusinessHoursService,
    private infoBarService: InfoBarService,
  ) {
    this.businessHoursSteps = [...businessHoursSteps]
    this.formValues = {}
  }

  nextStep = () => {
    const nextStep = this.completeCurrentStep()

    if (nextStep) {
      this.router.navigateByUrl(nextStep.route)
    }
  }

  getCurrentStep = (steps: Step[]) => steps.find(step => step.active)

  completeCurrentStep = () => {
    const currentStep = this.getCurrentStep(this.businessHoursSteps)

    currentStep.completed = true

    return this.businessHoursSteps[currentStep.index + 1]
  }

  setStep = (name: StepsEnum) => {
    const currentStep = this.getCurrentStep(this.businessHoursSteps)

    if (currentStep) {
      currentStep.active = false
    }

    const step = this.businessHoursSteps[name]

    if (step) {
      step.active = true
      step.enabled = true
    }
  }

  save = () => {
    const dailyBusinessHours = this.formatDailyBusinessHours(
      this.formValues['TimeTable'],
    )

    const businessHours = {
      name: this.formValues['Name'].name,
      queuesIds: this.formValues['Name'].queues,
      dailyBusinessHours,
    } as BusinessHours

    this.businessHoursService.save(businessHours).subscribe(
      () => {
        this.infoBarService.show(
          'Grade de horário cadastrada com sucesso',
          'success',
        )
        this.router.navigateByUrl('cadastros/grade-horario')
      },
      ({ error }) => this.infoBarService.show(error, 'danger'),
    )
  }

  formatDailyBusinessHours = (formValue: any): DailyBusinessHours[] => {
    for (const key in formValue) {
      if (!formValue.hasOwnProperty(key)) continue

      const obj = formValue[key]
      this.businessHoursList.push(obj)
    }

    return this.businessHoursList
      .map((weekday, index) => {
        if (weekday[Object.keys(weekday)[0]]) {
          return {
            dayOfWeek: DayOfWeek[Object.keys(weekday)[0]],
            openTime: this.formatTime(weekday[Object.keys(weekday)[1]]),
            closeTime: this.formatTime(weekday[Object.keys(weekday)[2]]),
          }
        }

        return null
      })
      .filter(dayHour => dayHour !== null)
  }

  formatTime = (time: string) => `${time.substr(0, 2)}:${time.substr(2)}`

  getFormData = (formType: FormTypes) => this.formValues[formType]

  storeFormData = (values: any, formType: FormTypes) =>
    (this.formValues[formType] = values)
}
