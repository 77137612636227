import { Component, OnInit } from '@angular/core'
import { LoggedUser } from '@app/components/authentication/logged-user.model'
import { AccessProfile } from '@app/register/user/user.model'
import { LocalStorageService } from '@app/tools/local-storage.service'

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  loggedUser: LoggedUser
  accessProfile: typeof AccessProfile = AccessProfile

  constructor(public localStorageService: LocalStorageService) {}

  ngOnInit() {
    this.loggedUser = this.localStorageService.getLoggedUser()
  }
}
