<div class="container">
  <div class="row">
    <app-title title="Filas de Chat "></app-title>
  </div>
  <div class="row">
    <button
      routerLink="/cadastros/fila/nova"
      class="btn bla-btn--primary new-queue"
    >
      Criar nova fila
    </button>
  </div>
  <ngx-datatable
    [rows]="rows"
    [columnMode]="ColumnMode.flex"
    headerHeight="44"
    rowHeight="44"
    [messages]="messages"
  >
    <ngx-datatable-column prop="name" name="Filas de Chat" [flexGrow]="1.1">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column prop="active" name="Ativa" [flexGrow]="0.7">
      <ng-template ngx-datatable-cell-template let-row="row">
        <input
          id="{{ row['id'] }}"
          type="checkbox"
          class="checkbox"
          [checked]="row['active']"
          (change)="changeQueueStatus($event, row['id'], !row['active'])"
        />
        <label for="{{ row['id'] }}" class="switch" id="{{ row['id'] }}switch">
          <div class="switch-on">Sim</div>
          <div class="switch-off">Não</div>
        </label>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column prop="teams" name="Time" [flexGrow]="1.4">
      <ng-template ngx-datatable-cell-template let-row="row">
        <div class="team-selector-container">
          <select
            #teamSelect
            id="{{ row['id'] }}"
            [ngModel]="row.attendanceTeamId"
            (ngModelChange)="onOptionSelected(teamSelect, row.attendanceTeamId)"
          >
            <option
              class="select-item"
              *ngFor="let team of teams"
              [value]="team.id"
              [selected]="row.attendanceTeamId === team.id"
            >
              {{ team.name }}
            </option>
          </select>
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [sortable]="false" name="Ações" [flexGrow]="1">
      <ng-template let-value="value" ngx-datatable-cell-template let-row="row">
        <a
          [routerLink]=""
          id="edit-queue-{{ row['id'] }}"
          (click)="editQueue(row['id'])"
          >Editar</a
        >
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>

<app-modal type="diff" class="hide" #confirmationModal>
  <h1 class="bla-modal-title">Deseja alterar o time para esta fila?</h1>
  <div class="bla-modal-separator"></div>
  <div class="bla-modal-text-info">
    Isto fará com que a fila atual passe a ser utilizada pelo time:
    <strong>{{ teamInfo?.name }}</strong>
  </div>
  <div class="modal-actions">
    <div class="bla-btn bla-btn--secondary" (click)="cancelModal()">
      Cancelar
    </div>
    <div class="bla-btn bla-btn--primary" (click)="changeQueueTeam()">
      Ok
    </div>
  </div>
</app-modal>
