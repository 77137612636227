<svg
  width="20"
  height="16"
  viewBox="0 0 24 18"
  class="details"
  [ngClass]="{ 'details--visited': visited }"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M11.9999 0.875C6.58325 0.875 1.95742 4.24417 0.083252 9C1.95742 13.7558 6.58325 17.125 11.9999 17.125C17.4166 17.125 22.0424 13.7558
  23.9166 9C22.0424 4.24417 17.4166 0.875 11.9999 0.875ZM11.9999 14.4167C9.00992 14.4167 6.58325 11.99 6.58325 9C6.58325 6.01 9.00992 3.58333
  11.9999 3.58333C14.9899 3.58333 17.4166 6.01 17.4166 9C17.4166 11.99 14.9899 14.4167 11.9999 14.4167ZM11.9999 5.75C10.2016 5.75 8.74992 7.20167
  8.74992 9C8.74992 10.7983 10.2016 12.25 11.9999 12.25C13.7983 12.25 15.2499 10.7983 15.2499 9C15.2499 7.20167 13.7983 5.75 11.9999 5.75Z"
  />
</svg>
