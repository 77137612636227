import { HeaderDetails } from './header-details/header-details'
import { AttendanceSearchResult } from './../attendance-search-result/attendance-search-result'
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core'
import { map, take } from 'rxjs/operators'
import {
  AttendanceDetails,
  Message,
  TransformedAttendanceDetails,
} from './attendance-details/attendance-details.model'
import { MonitoringAttendanceDetailsService } from './monitoring-attendance-details.service'
import { Sender } from '@app/chat/sender'
import { environment } from '@env/environment'
import { HtmlTools } from '@app/tools/html-tools'
import { getDateOnly, getTimeOnly } from '@app/tools/date-time-tools'

@Component({
  selector: 'app-monitoring-attendance-details',
  templateUrl: './monitoring-attendance-details.component.html',
  styleUrls: ['./monitoring-attendance-details.component.scss'],
})
export class MonitoringAttendanceDetailsComponent
  implements OnChanges, AfterViewInit {
  imageviewModal: HTMLElement
  imageURL: string

  constructor(
    private service: MonitoringAttendanceDetailsService,
    private htmlTools: HtmlTools,
  ) {}

  @Output() eventHideDetail = new EventEmitter()
  @Input() attendance: AttendanceSearchResult

  url = `${environment.urlBlaMessageManagementApi}/messages/media`

  attendanceDetails: TransformedAttendanceDetails
  detailHeader: HeaderDetails
  loadingAttendance = true

  ngAfterViewInit() {
    this.imageviewModal = document.querySelector('.image-modal')
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentAttendance = changes.attendance
      .currentValue as AttendanceSearchResult

    this.fillDetailHeader(currentAttendance)

    this.getAttendanceDetails(currentAttendance.attendanceId)
  }

  getAttendanceDetails = (attendanceId: string) => {
    this.service
      .getAttendanceDetails(attendanceId)
      .pipe(
        take(1),
        map(response => {
          return {
            attendanceRating: response.attendanceRating,
            averageAttendanceTime: response.averageAttendanceTime,
            averageHandlingTime: response.averageHandlingTime,
            averageWaitingTime: response.averageWaitingTime,
            brokerType: response.brokerType,
            messages: response.messageHistory
              .filter(message =>
                this.isVisibleMessageBySenderType(message.senderType),
              )
              .map((message: Message) => {
                return {
                  messageId: message.messageId,
                  customer: message.customer,
                  displayName: this.getDisplayName(
                    response.brokerType,
                    message,
                  ),
                  image: message.image,
                  text: message.text,
                  date: getDateOnly(message.date),
                  hour: getTimeOnly(message.date),
                  type: message.type,
                }
              }),
          }
        }),
      )
      .subscribe(
        (data: TransformedAttendanceDetails) => {
          this.attendanceDetails = data
          this.fillAverageTimes()
          this.loadingAttendance = false
        },
        error => {
          this.loadingAttendance = false
        },
      )
  }

  hideDetail = () => this.eventHideDetail.emit()

  private isVisibleMessageBySenderType = (senderType: Sender) =>
    this.isCustomerSenderType(senderType) ||
    this.isOperationalSenderType(senderType) ||
    this.isAutomaticSenderType(senderType)

  private isCustomerSenderType = (senderType: Sender) =>
    senderType === Sender.Customer

  private isOperationalSenderType = (senderType: Sender) =>
    senderType === Sender.OperationalUser

  private isAutomaticSenderType = (senderType: Sender) =>
    senderType === Sender.Automatic

  private isWhatsappBroker = (brokerType: number) => brokerType === 1

  private fillDetailHeader(attendance: AttendanceSearchResult) {
    this.detailHeader = {
      startDate: attendance.startDate,
      startTime: attendance.startTime,
      endDate: attendance.endDate,
      endTime: attendance.endTime,
      queueName: attendance.queueName,
      closingType: attendance.closingType,
      closingReason: attendance.closingReason,
    }
  }

  private fillAverageTimes() {
    if (!this.detailHeader) {
      this.fillDetailHeader(this.attendance)
    }

    this.detailHeader.averageAttendanceTime = this.attendanceDetails.averageAttendanceTime
    this.detailHeader.averageWaitingTime = this.attendanceDetails.averageWaitingTime
    this.detailHeader.avarageHandlingTime = this.attendanceDetails.averageHandlingTime
  }

  private getDisplayName(brokerType, message): string {
    if (this.isOperationalSenderType(message.senderType))
      return message.operationalUserName
    else if (this.isCustomerSenderType(message.senderType))
      return this.getWhatsappOrAppCustomerName(brokerType, message)
    else if (this.isAutomaticSenderType(message.senderType)) return 'Blá'
    else return ''
  }

  private getWhatsappOrAppCustomerName(brokerType, message) {
    if (this.isWhatsappBroker(brokerType)) return message.customer.phoneNumber
    else return message.customer.name
  }

  showImageModal(url: string) {
    this.imageURL = url
    this.htmlTools.showElement(this.imageviewModal)
  }

  resetImageModal() {
    this.imageURL = undefined
  }
}
