import { Component, OnInit, OnDestroy } from '@angular/core'
import { interval, Subscription } from 'rxjs'
import { MonitoringQueuesService } from './monitoring-queues.service'

@Component({
  selector: 'app-monitoring-queues',
  templateUrl: './monitoring-queues.component.html',
  styleUrls: ['./monitoring-queues.component.scss'],
})
export class MonitoringQueuesComponent implements OnInit, OnDestroy {
  queueInfo: {
    name: string
    lastChatDate: Date
    activeAttendances: number
    availableAttendancesWindows: number
    queuedAttendances: number
  }[]

  subscription: Subscription

  sourceInterval = interval(15000)

  loadingQueues = true

  constructor(private monitoringQueueService: MonitoringQueuesService) {}

  ngOnInit(): void {
    this.retrieveQueuesInfo()
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe()
  }

  retrieveQueuesInfo = () => {
    this.subscription?.unsubscribe()

    this.subscription = this.sourceInterval.subscribe(_ =>
      this.retrieveQueuesInfo(),
    )

    this.monitoringQueueService.getQueuesInfo().subscribe(
      queuesInfo => {
        this.queueInfo = queuesInfo.map(queueInfo => ({
          name: queueInfo.name,
          lastChatDate: queueInfo.oldestEnqueuedChatDate,
          activeAttendances: queueInfo.activeAttendances,
          availableAttendancesWindows: queueInfo.attendanceWindowsAvailable,
          queuedAttendances: queueInfo.queuedAttendances,
        }))

        this.loadingQueues = false
      },
      error => {
        console.error(error)
      },
    )
  }
}
