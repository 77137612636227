<div>
  <div class="print-title">
    <span>Conversa</span>
  </div>
  <div class="right">
    <span class="update" (click)="updateAttendance()" id="update-messages">
      <i class="icon icon--update"></i>atualizar</span
    >
    <span class="print" (click)="printPage()" id="print-messages">
      <i class="icon icon--print"></i>imprimir</span
    >
  </div>
</div>

<ngx-datatable
  #tableDetails
  [columnMode]="ColumnMode.flex"
  [messages]="messages"
  [rowHeight]="'auto'"
  [footerHeight]="hiddenMenu ? 0 : 30"
  [rows]="attendanceMessages"
  [columns]="tableColumns"
  [sortType]="SortType.multi"
  id="attendances-details"
>
  <ngx-datatable-footer>
    <ng-template
      ngx-datatable-footer-template
      let-rowCount="rowCount"
      let-pageSize="pageSize"
      let-selectedCount="selectedCount"
      let-curPage="curPage"
      let-offset="offset"
    >
      <datatable-pager
        [pagerLeftArrowIcon]="'datatable-icon-left'"
        [pagerRightArrowIcon]="'datatable-icon-right'"
        [page]="curPage"
        [size]="pageSize"
        [count]="rowCount"
        [hidden]="!(rowCount / pageSize > 1)"
        (change)="tableDetails.onFooterPage($event)"
      >
      </datatable-pager>
    </ng-template>
  </ngx-datatable-footer>
</ngx-datatable>

<ng-template #messageTemplate let-row="row" let-value="value">
  <ng-container
    *ngTemplateOutlet="
      messageIsOrder(row.type) ? messageOrder : messageText;
      context: {
        message: messageIsOrder(row.type) ? formatOrderMessage(row) : row
      }
    "
  >
  </ng-container>
</ng-template>

<ng-template #messageOrder let-orderMessage="message">
  <section class="summary summary-order">
    <p class="summary-title">
      Resumo do pedido<span *ngIf="orderMessage?.orderId">
        - {{ orderMessage.orderId }}</span
      >
    </p>

    <div *ngFor="let product of orderMessage?.products" class="summary-content">
      <p>* {{ product?.name }}</p>
      <p>Valor: {{ product?.value | currency: 'BRL' }}</p>
    </div>
  </section>

  <section class="summary summary-delivery">
    <p class="summary-title">Dados de entrega</p>

    <div *ngIf="!orderMessage.withoutDelivery" class="summary-content">
      <p>Prazo de entrega:</p>
      <p>{{ orderMessage.deliveryTime }}</p>

      <p>Endereço de entrega:</p>
      <p>{{ orderMessage.deliveryAddress }}</p>
    </div>

    <div *ngIf="orderMessage.withoutDelivery" class="summary-content">
      <p>{{ orderMessage.deliveryAddress }}</p>
    </div>
  </section>

  <section class="summary summary-payment">
    <p class="summary-title">Dados de pagamento</p>

    <div class="summary-content">
      <p *ngIf="orderMessage.amountProduct">
        Valor total dos produtos:
        {{ orderMessage.amountProduct | currency: 'BRL' }}
      </p>
      <p *ngIf="orderMessage.deliveryPrice">
        Despesas de frete:
        {{ orderMessage.deliveryPrice | currency: 'BRL' }}
      </p>
      <p>Valor total do pedido: {{ orderMessage.amount | currency: 'BRL' }}</p>
      <p>Forma de pagamento: {{ orderMessage.paymentMethod }}</p>
    </div>

    <p *ngIf="orderMessage.paymentLink" class="summary-payment-link">
      <a href="{{ orderMessage.paymentLink }}" target="_blank"
        >Link para pagamento</a
      >
    </p>
  </section>
</ng-template>

<ng-template #messageText let-message="message">
  <p *ngIf="!messageIsImage(message.type)">{{ message.text }}</p>

  <div *ngIf="messageIsImage(message.type)" class="image-message-container">
    <app-image-message
      (deleteImage)="openDeleteImageModal($event)"
      (zoom)="openImageModal($event)"
      [deletedMessageId]="deletedMessageId"
      [message]="message"
      [baseURL]="url"
    ></app-image-message>
  </div>
</ng-template>

<app-delete-image-message-modal
  class="delete-image-message-modal hide"
  [imageMessageContext]="imageMessageContext"
  (imageRemoved)="handleImageRemoved($event)"
>
</app-delete-image-message-modal>
