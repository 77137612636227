import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class HtmlTools {
  constructor() {}

  addAttributeToElement = (
    el: HTMLElement,
    attribute: string,
    value: string,
  ) => {
    if (el) el.setAttribute(attribute, value)
  }

  removeAttributeFromElement = (el: HTMLElement, attribute: string) => {
    if (el) el.removeAttribute(attribute)
  }

  showElement = (el: HTMLElement) => {
    if (el) {
      el.classList.add('show')
      el.classList.remove('hide')
    }
  }

  hideElement = (el: HTMLElement) => {
    if (el) {
      el.classList.add('hide')
      el.classList.remove('show')
    }
  }
}
