<div class="input-select">
  <label class="filter-label" [for]="labelForName">{{
    props?.labelTitle
  }}</label>
  <ng-select
    #ngSelectComponent
    class="custom"
    [placeholder]="props?.placeholder"
    [clearable]="true"
    [searchable]="props?.searchable ? props.searchable : false"
    [multiple]="props?.multipleSelect"
    [closeOnSelect]="props?.closeOnSelect ? props.closeOnSelect : false"
    (change)="setValue($event)"
    [id]="ngSelectId"
  >
    <ng-option
      [value]="item.id"
      [disabled]="item.disabled"
      *ngFor="let item of selectItems"
    >
      {{ item.name }}
    </ng-option>
  </ng-select>
</div>
