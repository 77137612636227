import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@env/environment'
import { Observable } from 'rxjs'
import { AttendanceDetails } from './attendance-details/attendance-details.model'

@Injectable({
  providedIn: 'root',
})
export class MonitoringAttendanceDetailsService {
  private url: string

  constructor(private http: HttpClient) {
    this.url = `${environment.urlBlaMessageManagementApi}/attendance`
  }

  public getAttendanceDetails = (attendanceId: string): Observable<any> =>
    this.http.get<AttendanceDetails>(`${this.url}/Monitoring/${attendanceId}`)
}
