<ul class="chat-cards-list" *ngIf="attendances">
  <li
    *ngFor="let attendance of attendances; let i = index"
    class="chat-card"
    (click)="handleClick(attendance.attendanceId, attendance)"
    id="chat-{{ attendance.customer.id }}"
  >
    <div
      class="chat-card__content"
      [ngClass]="{
        'chat-card__content--selected':
          selectedItem && attendance.attendanceId === selectedItem.attendanceId
      }"
    >
      <div class="favorite">
        <app-star
          id="{{ attendance.attendanceId }}"
          [active]="attendance.favorite"
          [attendanceId]="attendance.attendanceId"
          (handleActive)="toggleActive($event)"
        ></app-star>
      </div>
      <div>
        <div
          class="chat-card__notification"
          *ngIf="attendance.unreadMessagesCount"
        >
          {{ attendance.unreadMessagesCount }}
        </div>
        <div class="chat-card__mobile-arrow">
          <i class="icon-down-open icon-down-open--mobile"></i>
        </div>
        <div class="chat-card__phone">
          {{ attendance.customer.phoneNumber }}
        </div>
        <div class="chat-card__queue">{{ attendance.queueName }}</div>
        <div class="chat-card__mobile-icon" *ngIf="!attendance.scheduling">
          <i class="icon icon--arrow-right"></i>
        </div>
        <div class="chat-card__icon" *ngIf="attendance.scheduling">
          <span
            *ngIf="
              attendance.scheduling.scheduledUserId !==
              attendance.operationalUserId
            "
          >
            Vendedor: {{ attendance.scheduling.scheduledUserName }}
          </span>
          <i class="icon icon--calendar"></i>
        </div>
      </div>
    </div>
    <div
      class="chat-card-separator"
      *ngIf="
        attendances.length > 1 &&
        attendances[attendances.length - 1].attendanceId !==
          attendance.attendanceId
      "
    ></div>
  </li>
</ul>
