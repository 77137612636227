<div class="app-search">
  <div class="app-search__title">{{ props.title }}</div>
  <div class="app-search__content">
    <div class="bla-row">
      <div class="bla-column--50">
        <input
          type="text"
          class="bla-input"
          placeholder="{{ props.placeholder }}"
          [(ngModel)]="searchCriteria"
          (keyup.enter)="search()"
        />
      </div>
      <div class="bla-column--25">
        <button
          type="button"
          class="bla-btn"
          (click)="search()"
          [ngClass]="searchCriteria ? 'bla-btn--primary' : 'bla-btn--disabled'"
        >
          {{ props.buttonText }}
        </button>
      </div>
    </div>
  </div>
</div>
