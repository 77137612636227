import { Component, OnInit, Input } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { FormControlValidator } from '@app/tools/form-control-validator'

@Component({
  selector: 'app-business-day-checkbox',
  templateUrl: './business-day-checkbox.component.html',
  styleUrls: ['./business-day-checkbox.component.scss'],
})
export class BusinessDayCheckboxComponent {
  @Input() weekDay: string
  @Input() controlName: string
  @Input() parentForm: FormGroup
  @Input() formControlValidator: FormControlValidator
}
