import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class StringTools {
  constructor() {}

  normalizeString = (str: string): string =>
    str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

  trimFirstWord = (str: string): string => str.replace(/ .*/, '')
}
