import { ActiveContact } from './active-contact'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@env/environment'
import { Observable } from 'rxjs'
import {
  Attendance,
  AttendanceClosingReason,
  TransferReason,
  AttendanceTransferInfo,
  AttendanceClosingType,
  AttendanceInactive,
  BatchCloseAttendancesResponse,
  BatchTransferResult,
} from './attendance'
import { RatingReason, RatingRequest } from './rating'
import { DirectContact } from './direct-contact'
import { Sender } from '../sender'
import { InactiveAttendances } from './inactive-attendances'

@Injectable()
export class AttendanceService {
  private url: string

  constructor(private http: HttpClient) {
    this.url = `${environment.urlBlaMessageManagementApi}/attendance`
  }

  public get = (): Observable<Attendance[]> =>
    this.http.get<Attendance[]>(this.url)

  public getByUserId = (operationalUserId: string): Observable<Attendance[]> =>
    this.http.get<Attendance[]>(`${this.url}/${operationalUserId}`)

  public getClosingReasons = (): Observable<AttendanceClosingReason[]> =>
    this.http.get<AttendanceClosingReason[]>(`${this.url}/ClosingReasons`)

  public closeAttendance = (
    id: string,
    operationalUserId: string,
    closingTypeId: number,
    closingReasonId?: string,
    returnDate?: string,
  ): Observable<any> =>
    this.http.patch<any>(`${this.url}/${id}/Close`, {
      operationalUserId,
      closingTypeId,
      closingReasonId,
      returnDate,
    })

  public bachCloseAttendances = (
    attendanceIds: string[],
  ): Observable<BatchCloseAttendancesResponse> =>
    this.http.patch<BatchCloseAttendancesResponse>(`${this.url}/BatchClose`, {
      attendanceIds,
    })

  public batchTransferAttendances = (
    attendanceIds: string[],
    queueId: string,
    userId: string,
  ): Observable<BatchTransferResult> =>
    this.http.patch<BatchTransferResult>(`${this.url}/BatchTransfer`, {
      attendanceIds,
      queueId,
      userId,
    })

  public getTransferReasons = (): Observable<TransferReason> =>
    this.http.get<TransferReason>(`${this.url}/TransferReasons`)

  public getClosingTypes = (): Observable<AttendanceClosingType[]> =>
    this.http.get<AttendanceClosingType[]>(`${this.url}/ClosingTypes`)

  public transferAttendance = (
    id: string,
    transferInfo: AttendanceTransferInfo,
  ): Observable<any> =>
    this.http.post<any>(`${this.url}/${id}/Transfer`, transferInfo)

  public getRatingReasons = (): Observable<RatingReason[]> =>
    this.http.get<RatingReason[]>(`${this.url}/RatingReason`)

  public saveAttendanceRating = (request: RatingRequest): Observable<any> =>
    this.http.post(`${this.url}/Rating`, request)

  public validateAttendanceRating = (
    attendanceId: string,
  ): Observable<string> =>
    this.http.get<string>(
      `${this.url}/ValidateAttendanceRating/${attendanceId}`,
    )

  public createActiveContact = (
    activeContact: ActiveContact,
  ): Observable<any> =>
    this.http.post(`${this.url}/CreateActiveContact`, activeContact)

  public favoriteAttendance = (
    attendanceId: string,
    favorite: boolean,
  ): Observable<any> =>
    this.http.patch(`${this.url}/${attendanceId}/Favorite`, {
      favorite,
    })

  public createDirectedAttendance = (
    directContact: DirectContact,
  ): Observable<any> =>
    this.http.post(`${this.url}/CreateDirectedAttendance`, directContact)

  public getInactiveAttendances(
    filter: InactiveAttendances,
  ): Observable<any[]> {
    return this.http.get<AttendanceInactive[]>(
      `${this.url}/FilterInactiveAttendances`,
      {
        params: {
          queueId: filter.queueId,
          senderType: filter.sender,
        },
      },
    )
  }
}
