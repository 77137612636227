import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-details-icon',
  templateUrl: './details-icon.component.html',
  styleUrls: ['./details-icon.component.scss'],
})
export class DetailsIconComponent {
  @Input() visited = false
}
