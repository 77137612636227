import { EventEmitter } from '@angular/core'
import { AttendantStatus } from '@app/register/user/user.model'

export class AttendantStatusService {
  statusEmitter = new EventEmitter()
  attendancesEmitter = new EventEmitter()

  updateAttendantStatus = (): EventEmitter<any> => this.statusEmitter

  updateNoAttendanceStatus = (): EventEmitter<any> => {
    return this.attendancesEmitter
  }
}
