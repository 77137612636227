import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { DropDownItem } from '@app/components/drop-down/drop-down-item'
import { EditQueueConfirmationModalComponent } from '@app/components/edit-queue-confirmation-modal/edit-queue-confirmation-modal.component'
import { InfoBarService } from '@app/info-bar/info-bar.service'
import { QueueService } from '@app/register/queue/queue.service'
import { QueueStore } from '@app/register/queue/queue.store'
import { StepsEnum } from '@app/stepper/steps/queue.steps'
import { FormControlValidator } from '@app/tools/form-control-validator'
import { HtmlTools } from '@app/tools/html-tools'

@Component({
  selector: 'app-queue-name',
  templateUrl: './queue-name.component.html',
  styleUrls: ['./queue-name.component.scss'],
})
export class QueueNameComponent implements OnInit, AfterViewInit {
  queueForm: FormGroup
  nextButton: HTMLButtonElement
  lastStep: boolean
  attendanceTeamsDropDownItems: DropDownItem[]
  @ViewChild(EditQueueConfirmationModalComponent)
  confirmationModal: EditQueueConfirmationModalComponent

  StepsEnum = StepsEnum

  constructor(
    private queueService: QueueService,
    private formBuilder: FormBuilder,
    private infoBarService: InfoBarService,
    private htmlTools: HtmlTools,
    public formControlValidator: FormControlValidator,
    public store: QueueStore,
  ) {}

  ngOnInit() {
    this.loadAttendanceTeams()
    this.store.setStep(StepsEnum.QueueName)
    this.initializeForm()
  }

  ngAfterViewInit() {
    this.nextButton = document.querySelector('.next-button')
  }

  loadAttendanceTeams() {
    this.queueService.getAttendanceTeamsInfo().subscribe(
      infos =>
        (this.attendanceTeamsDropDownItems = infos.map(teamInfo => ({
          text: teamInfo.name,
          value: teamInfo.id,
        }))),
      () => {
        this.infoBarService.show(
          'Falha ao carregar os times. Consulte o suporte se o problema persistir',
          'danger',
        )
      },
    )
  }

  submit = () => {
    if (this.queueForm.valid) {
      if (this.store.isUpdateMode()) {
        this.store.storeFormData(this.queueForm.value, 'Name')
        this.confirmationModal.openModal()
      } else {
        this.htmlTools.addAttributeToElement(this.nextButton, 'disabled', '')
        this.queueService
          .checkIfQueueNameExists(this.queueForm.value.name)
          .subscribe(
            response => {
              if (!response.exists) {
                this.storeFormDataAndGoToNextStep()
              } else {
                this.infoBarService.show(
                  'Já existe uma fila com este nome',
                  'danger',
                )
                this.htmlTools.removeAttributeFromElement(
                  this.nextButton,
                  'disabled',
                )
              }
            },
            ({ error }) => this.infoBarService.show(error, 'danger'),
          )
      }
    }
  }

  private storeFormDataAndGoToNextStep = () => {
    this.store.storeFormData(this.queueForm.value, 'Name')

    this.store.nextStep()
    this.htmlTools.removeAttributeFromElement(this.nextButton, 'disabled')
  }

  initializeForm = () => {
    this.queueForm = this.formBuilder.group({
      name: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(35),
      ]),
      attendanceTeamId: ['', Validators.required],
      priority: ['', [Validators.min(1), Validators.max(99)]],
      availableRankingDistribution: new FormControl(false),
      availableScoreDistribution: new FormControl(false),
      receiveTransfers: new FormControl(false),
      makeTransfers: new FormControl(false),
      preAttendance: new FormControl(false),
      checkCustomerPreferenceForActiveMessaging: new FormControl(false)
    })

    const formData = this.store.getFormData('Name')

    if (formData) {
      this.queueForm.setValue(formData)
    }
  }

  cancel = () => this.store.clear()

  isUpdateMode = () => this.store.isUpdateMode()
}
