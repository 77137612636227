import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core'
import { Attendance } from '@app/chat/attendance/attendance'
import { QueueService } from '@app/register/queue/queue.service'
import { QueueInfo } from '@app/register/queue/queue.model'
import * as moment from 'moment'
import { Subscription, interval } from 'rxjs'
import { CustomerStatus } from './chat-panel'

@Component({
  selector: 'app-chat-panel',
  templateUrl: './chat-panel.component.html',
  styleUrls: ['./chat-panel.component.scss'],
})
export class ChatPanelComponent implements OnInit, OnChanges, OnDestroy {
  @Input() currentAttendance: Attendance
  @Output() openEndAttendanceModal = new EventEmitter()
  @Output() openTransferAttendanceModal = new EventEmitter()

  CustomerStatus = CustomerStatus

  queuedAttendances: number
  attendanceStartTime: number
  currentSessionTime: string
  sourceInterval = interval(1000)
  subscription: Subscription
  attendanceInteractionTime: number
  sla: string

  constructor(private queueService: QueueService) {}

  ngOnInit() {
    this.getAttendanceTimesDiff()
    this.getSlaTime()
  }

  getSlaTime = () => {
    const time = this.currentAttendance?.sla?.split(':')

    const hour: number = Number(time[0])
    const minute: number = Number(time[1])
    const seconds: number = Number(time[2])

    this.sla = `${hour <= 0 ? '' : hour + 'h'}${
      minute < 10 ? '0' + minute + 'min' : minute + 'min'
    }${seconds <= 0 ? '' : seconds < 10 ? '0' + seconds + 's' : seconds + 's'}`
  }

  ngOnChanges() {
    this.getQueued()
    this.subscription = this.sourceInterval.subscribe(_ =>
      this.getAttendanceTimesDiff(),
    )
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe()
  }

  getQueued = () =>
    this.queueService
      .getQueued(this.currentAttendance.queueId)
      .subscribe(result => {
        this.queuedAttendances = result
      })

  getAttendanceTimesDiff = () => {
    const attendanceCreationTime = moment(
      this.currentAttendance?.startDate,
    ).valueOf()

    this.attendanceInteractionTime = moment().valueOf()

    const timeDiff = this.attendanceInteractionTime - attendanceCreationTime

    const convertedTime = this.convertDateMillisecondsToTime(timeDiff)

    const { hour, minute, seconds } = convertedTime

    this.currentSessionTime = `${hour <= 0 ? '' : hour + 'h'}${
      minute < 10 ? '0' + minute : minute
    }min${seconds < 10 ? '0' + seconds : seconds}s`
  }

  convertDateMillisecondsToTime = (milliseconds: number) => {
    let hour: number
    let minute: number
    let seconds: number

    seconds = Math.floor(milliseconds / 1000)
    minute = Math.floor(seconds / 60)
    seconds = seconds % 60
    hour = Math.floor(minute / 60)
    minute = minute % 60
    hour = hour % 24

    return {
      hour,
      minute,
      seconds,
    }
  }

  openEndModal = () => this.openEndAttendanceModal.emit('')

  openTransferModal = () => this.openTransferAttendanceModal.emit('')
}
