import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core'
import { DatePickerComponent } from 'ng2-date-picker'
import { MonitoringAttendanceFilterService } from './monitoring-attendances-filter.service'
import { MonitoringAttendancesFilterModel } from './monitoring-attendances-filter.model'
import { take } from 'rxjs/operators'
import { SelectComponent } from '@app/components/select/select.component'
import { InfoBarService } from '@app/info-bar/info-bar.service'

@Component({
  selector: 'app-monitoring-attendances-filter',
  templateUrl: './monitoring-attendances-filter.component.html',
  styleUrls: ['./monitoring-attendances-filter.component.scss'],
})
export class MonitoringAttendancesFilterComponent implements OnInit {
  @Output()
  search: EventEmitter<MonitoringAttendancesFilterModel> = new EventEmitter()
  @Output()
  resetSorts: EventEmitter<void> = new EventEmitter()

  @ViewChild('startDatePicker') startDatePicker: DatePickerComponent
  @ViewChild('endDatePicker') endDatePicker: DatePickerComponent
  @ViewChild('sellersSelect') sellersSelect: SelectComponent

  showFilters = true
  closingTypes: any
  closingReasons: any
  workplaces: any
  sellers: any
  originalSellers: any
  queues: any
  ratings: any[] = [
    { id: 1, name: 1 },
    { id: 2, name: 2 },
    { id: 3, name: 3 },
    { id: 4, name: 4 },
    { id: 5, name: 5 },
  ]
  ratingReasons: any[]

  sellersResult: any = []
  closingReasonsResult: any = []
  closingTypesResult: any = []
  queuesResult: any = []
  workplacesResult: any
  phoneNumberResult: any
  startDateResult: any
  endDateResult: any
  startTimeResult: any
  endTimeResult: any
  ratingsResult: any
  ratingReasonsResult: any

  sellerField = {
    tag: 'vendedor',
    labelTitle: 'Vendedor',
    placeholder: 'Todos',
    multipleSelect: true,
    searchable: true,
  }

  workplaceField = {
    tag: 'local',
    labelTitle: 'Local de atuação',
    placeholder: 'Todos',
    multipleSelect: false,
    closeOnSelect: true,
  }

  queueField = {
    tag: 'fila',
    labelTitle: 'Fila',
    placeholder: 'Selecionar',
    multipleSelect: true,
    searchable: true,
  }

  closingTypeField = {
    tag: 'tipo',
    labelTitle: 'Tipo',
    placeholder: 'Selecionar',
    multipleSelect: true,
    searchable: true,
  }

  closingReasonsField = {
    tag: 'motivo',
    labelTitle: 'Motivo de Encerramento',
    placeholder: 'Selecionar',
    multipleSelect: true,
    searchable: true,
  }

  datePickerConfig = {
    locale: 'pt-br',
    placeholder: 'dd/mm/aaaa',
    format: 'DD/MM/YYYY',
    disableKeypress: false,
    monthFormat: 'MMMM',
    weekDayFormatter: (weekDay: number) => {
      if (weekDay === 0) return 'Do'
      if (weekDay === 1) return 'Se'
      if (weekDay === 2) return 'Te'
      if (weekDay === 3) return 'Qa'
      if (weekDay === 4) return 'Qi'
      if (weekDay === 5) return 'Se'
      if (weekDay === 6) return 'Sa'
    },
  }

  ratingField = {
    tag: 'nota',
    labelTitle: 'Nota Recebida',
    placeholder: 'Todas',
    multipleSelect: false,
    closeOnSelect: true,
  }

  ratingReasonField = {
    tag: 'motivo-avaliacao',
    labelTitle: 'Motivo da Avaliação',
    placeholder: 'Todos',
    multipleSelect: false,
    closeOnSelect: true,
  }

  constructor(
    private monitoringAttendanceService: MonitoringAttendanceFilterService,
    private infoBar: InfoBarService,
  ) {}

  ngOnInit(): void {
    this.getWorkplaces()
    this.getQueues()
    this.getClosingReasons()
    this.getClosingTypes()
    this.getSellers()
    this.getRatingReasons()
  }

  getRatingReasons = () => {
    this.monitoringAttendanceService.getRatingReasons().subscribe(reasons => {
      this.ratingReasons = reasons
    })
  }

  getFieldValue = ({ ids, tag }) => {
    if (tag === 'vendedor') this.sellersResult = ids
    if (tag === 'motivo') this.closingReasonsResult = ids
    if (tag === 'tipo') this.closingTypesResult = ids
    if (tag === 'fila') this.queuesResult = ids
    if (tag === 'nota') this.ratingsResult = ids
    if (tag === 'motivo-avaliacao') this.ratingReasonsResult = ids
    if (tag === 'local') {
      this.workplacesResult = ids === '0' ? undefined : ids
      this.getSellersByWorkplaceId(ids)
    }
  }

  searchAttendances = () => {
    const filter = new MonitoringAttendancesFilterModel(
      this.sellersResult,
      this.closingReasonsResult,
      this.closingTypesResult,
      this.queuesResult,
      this.workplacesResult,
      this.getFormattedDateTime(this.startDateResult, this.startTimeResult),
      this.getFormattedDateTime(
        this.endDateResult,
        this.endTimeResult,
        23,
        59,
        59,
      ),
      this.getFormattedPhoneNumber(this.phoneNumberResult),
      this.ratingsResult,
      this.ratingReasonsResult,
    )

    this.search.emit(filter)
    this.resetSorts.emit()
  }

  getWorkplaces = () => {
    this.monitoringAttendanceService
      .getWorkplaces()
      .pipe(take(1))
      .subscribe(data => (this.workplaces = data))
  }

  getQueues = () => {
    this.monitoringAttendanceService
      .getQueues()
      .pipe(take(1))
      .subscribe(data => (this.queues = data))
  }

  getClosingReasons = () => {
    this.monitoringAttendanceService
      .getClosingReasons()
      .pipe(take(1))
      .subscribe(data => (this.closingReasons = data))
  }

  getClosingTypes = () => {
    this.monitoringAttendanceService
      .getClosingTypes()
      .pipe(take(1))
      .subscribe(data => (this.closingTypes = data))
  }

  getSellers() {
    this.monitoringAttendanceService
      .getSellers()
      .pipe(take(1))
      .subscribe(data => {
        this.sellers = data
        this.originalSellers = this.sellers
      })
  }

  getSellersByWorkplaceId(workplaceId) {
    this.sellersSelect.clearSelection()
    if (workplaceId) {
      this.monitoringAttendanceService
        .getSellersByWorkplaceId(workplaceId)
        .pipe(take(1))
        .subscribe(data => {
          this.sellers = data
        })
    } else {
      this.sellers = this.originalSellers
    }
  }

  getFormattedDateTime = (
    momentDate: any,
    time,
    defaultHourValue: number = 0,
    defaultMinuteValue: number = 0,
    defaultSecondValue: number = 0,
  ) => {
    try {
      if (!momentDate) return null

      const date = momentDate.toDate()
      const day = date.getDate()
      const month = date.getMonth()
      const year = date.getUTCFullYear()

      let hour = 0
      let minute = 0
      const second = defaultSecondValue

      if (time) {
        const givenHour = time.substr(0, 2)
        const givenMinute = time.substr(2, 4)

        if (givenHour > 23 || givenMinute > 59) {
          return 'invalid'
        }

        hour = time.substr(0, 2)
        minute = time.substr(2, 4)
      } else {
        hour = defaultHourValue
        minute = defaultMinuteValue
      }

      return new Date(year, month, day, hour, minute, second)
    } catch (error) {
      this.infoBar.show(
        'Formato de data inválido. O formato deve ser dd/mm/aaaa',
        'danger',
      )
      return 'invalid'
    }
  }

  getFormattedPhoneNumber = (phoneNumber: string) => {
    return !phoneNumber ? null : phoneNumber.replace('(', '').replace(')', '')
  }

  openDatePicker(datePicker: DatePickerComponent) {
    datePicker.api.open()
  }

  allFiltersAreNull() {
    return (
      this.sellersResult.length === 0 &&
      this.queuesResult.length === 0 &&
      this.closingReasonsResult.length === 0 &&
      this.closingTypesResult.length === 0 &&
      !this.phoneNumberResult &&
      !this.endDateResult &&
      !this.startDateResult &&
      !this.workplacesResult &&
      !this.ratingsResult &&
      !this.ratingReasonsResult
    )
  }
}
