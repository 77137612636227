import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core'
import { MediaService } from '@app/Media/media.service'
import { ImageStatus } from './image-message'

@Component({
  selector: 'app-image-message',
  templateUrl: './image-message.component.html',
  styleUrls: ['./image-message.component.scss'],
})
export class ImageMessageComponent implements OnInit, OnChanges {
  @Input() message
  @Input() baseURL: string
  @Input() imageAlt = 'Imagem enviada pelo cliente'
  @Input() deletedMessageId: string

  @Output() deleteImage = new EventEmitter()
  @Output() zoom = new EventEmitter()

  url: string
  imageStatus: ImageStatus
  loading = true

  ImageStatus = ImageStatus

  constructor(private mediaService: MediaService) {}

  ngOnInit(): void {
    this.setImageStatus(this.message)
  }

  ngOnChanges() {
    if (
      this.message.messageId &&
      this.message.messageId === this.deletedMessageId
    )
      this.imageStatus = ImageStatus.Removed
  }

  setImageStatus = ({ image }) => {
    if (image) this.buildImageURL(image.id)
    else this.imageStatus = ImageStatus.Removed

    this.loading = false
  }

  buildImageURL = (imageId: string) => {
    this.mediaService.getImageMedia(imageId).subscribe(
      () => {
        this.url = `${this.baseURL}/${imageId}`
        this.imageStatus = ImageStatus.Available
      },
      () => {
        this.imageStatus = ImageStatus.Unavailable
      },
    )
  }

  hideLoader = () => (this.loading = false)

  openDeleteImageModal = (messageId: string) => {
    this.deleteImage.emit(messageId)
  }

  handleImageRemoved = () => {
    this.imageStatus = ImageStatus.Removed
  }

  openImageModal = (url: string) => {
    this.zoom.emit(url)
  }
}
