import { Component, EventEmitter, Output } from '@angular/core'

@Component({
  selector: 'app-new-customer',
  templateUrl: './new-customer.component.html',
  styleUrls: ['./new-customer.component.scss'],
})
export class NewCustomerComponent {
  @Output() showNewCustomerModal = new EventEmitter()

  constructor() {}

  openNewCustomerModal() {
    this.showNewCustomerModal.emit('')
  }
}
