<div class="chat-attendance-details" #chatDetails>
  <div class="chat-details-title" (click)="animateDetails()">
    <span>Ver detalhes do atendimento</span>
    <div class="icon-down-open">
      <img
        src="../../../assets/images/arrowdown.svg"
        alt="Abrir ou fechar detalhes do usuário"
      />
    </div>
  </div>
  <div class="chat-details-operator">
    <div class="chat-panel__infos">
      <div class="chat-panel__infos-combo">
        <div class="info-title">SLA</div>
        <div class="info-value">
          <img
            src="../../../assets/images/watch.svg"
            alt="SLA de atendimento"
          />
          {{ sla === null ? '30min' : sla }}
        </div>
      </div>
      <div class="chat-panel__infos-combo">
        <div class="info-title">Sessão</div>
        <div class="info-value">
          <img
            src="../../../assets/images/watch.svg"
            alt="Tempo atual do atendimento"
          />
          {{ currentSessionTime }}
        </div>
      </div>
      <div class="chat-panel__infos-combo">
        <div class="info-title">Aguardando</div>
        <div class="info-value">
          <img
            src="../../../assets/images/list_arrow_icon.svg"
            alt="Clientes aguardando na fila"
          />
          {{ queuedAttendances }} na fila
        </div>
      </div>
      <div
        class="chat-panel__infos-combo"
        *ngIf="conversation?.customerStatus !== null"
      >
        <div class="info-title">Tipo de cliente</div>
        <div class="info-value">
          <img
            src="../../../assets/images/list_icon.svg"
            alt="Tipo de cliente"
          />
          {{ CustomerStatus[conversation?.customerStatus] }}
        </div>
      </div>
      <div class="chat-panel__infos-combo">
        <div class="info-title">Fila</div>
        <div class="info-value">
          <img src="../../../assets/images/list_icon.svg" alt="Nome da fila" />
          {{ conversation?.queueName }}
        </div>
      </div>
    </div>
  </div>
  <div class="chat-details-client">
    <div class="client-data">
      <div class="client-info">
        <div class="client-name">
          {{ conversation?.customer.name }}
        </div>
        <div class="client-phonenumber">
          {{ conversation?.customer.phoneNumber }}
        </div>
      </div>
      <div class="client-review" (click)="openCustomerReviewModal()">
        <img
          src="../../../assets/images/review_icon.svg"
          alt="customer review"
        />
        <div *ngIf="hasReview" class="review-signal"></div>
      </div>
    </div>
    <div class="operator-client-actions">
      <button
        (click)="openCloseAttendanceModal()"
        class="btn btn-danger"
        [disabled]="buttonsDisabled"
      >
        <img
          src="../../../assets/images/phone.svg"
          alt="encerramento de atendimento"
        />
        Encerrar
      </button>
      <button
        class="bla-btn"
        [ngClass]="buttonsDisabled ? 'bla-btn--disabled' : 'bla-btn--primary'"
        [disabled]="buttonsDisabled"
        (click)="openChatTransferModal()"
      >
        <img
          src="../../../assets/images/arrowright.svg"
          alt="transferência de atendimento"
        />
        Transferir
      </button>
    </div>
  </div>
</div>
