<div class="input-dropdown">
  <label class="filter-label" for="">{{ props?.labelTitle }}</label>
  <ng-select
    class="custom-dropdown"
    [placeholder]="props?.placeholder"
    [clearable]="true"
    [multiple]="props?.multipleSelect != null ? props?.multipleSelect : true"
    [closeOnSelect]="
      props?.closeOnSelect != null ? props?.closeOnSelect : false
    "
    (change)="setValue($event)"
  >
    <ng-option
      [value]="item.id"
      [disabled]="item.disabled"
      *ngFor="let item of dropdownItens"
    >
      {{ item.name }}
    </ng-option>
  </ng-select>
</div>
