<div class="template-message-form">
  <h2>Fila</h2>
  <hr />
  <form [formGroup]="templateMessageForm" (ngSubmit)="submit()">
    <div class="row">
      <div class="form-group form-item form-group-queue-list">
        <app-multiselect-datatable
          [formGroup]="templateMessageForm"
          controlName="queueIds"
          label="Filas"
          [items]="queuesDropDownItems"
          [isRequired]="true"
          class="queues-multiselect"
          [readonly]="store.isViewMode()"
        ></app-multiselect-datatable>
      </div>
    </div>

    <div class="bla-actions">
      <button
        type="button"
        class="bla-btn"
        routerLink="/cadastros/frase"
        (click)="cancel()"
      >
        Cancelar
      </button>
      <button
        [disabled]="templateMessageForm.invalid && store.isCreateMode()"
        type="submit"
        class="bla-btn bla-btn--primary next-button"
        [ngClass]="{
          'bla-btn--disabled':
            templateMessageForm.invalid && store.isCreateMode()
        }"
      >
        Avançar
      </button>
    </div>
  </form>
</div>
