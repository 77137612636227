import { TemplateMessageService } from './template-message.service'
import {
  TemplateMessage,
  TemplateMessageResponse,
} from './template-message.model'
import { Router } from '@angular/router'
import { InfoBarService } from '@app/info-bar/info-bar.service'
import {
  templateMessageSteps,
  StepsEnum,
} from '@app/stepper/steps/template-message.steps'
import { Step } from '@app/stepper/steps/step'
import { of } from 'rxjs'
import { StoreMode } from '../register.model'
import { Injectable } from '@angular/core'

type FormTypes = 'General' | 'Queue' | 'Message'

@Injectable()
export class TemplateMessageStore {
  public templateMessageSteps: Step[]
  public storeMode: StoreMode
  public templateMessageId: string

  private formValues: {
    [form: string]: any
  }

  get steps$() {
    return of(this.templateMessageSteps)
  }

  constructor(
    private router: Router,
    private templateMessageService: TemplateMessageService,
    private infoBarService: InfoBarService,
  ) {
    this.templateMessageSteps = [...templateMessageSteps]
    this.formValues = {}
    this.storeMode = StoreMode.Create
  }

  storeFormData = (values: any, formType: FormTypes) =>
    (this.formValues[formType] = values)

  getFormData = (formType: FormTypes) => this.formValues[formType]

  save = () => {
    const templateMessage = {
      ...this.formValues['General'],
      ...this.formValues['Queue'],
      ...this.formValues['Message'],
    } as TemplateMessage

    this.templateMessageService.save(templateMessage).subscribe(
      () => {
        this.infoBarService.show('Frase cadastrada com sucesso', 'success')
        this.router.navigateByUrl('/cadastros/frase')
        this.clear()
      },
      ({ error }) => this.infoBarService.show(error, 'danger'),
    )
  }

  clear = () => {
    this.templateMessageSteps = [...templateMessageSteps]
    this.formValues = {}
    this.storeMode = StoreMode.Create
  }

  nextStep = () => {
    const nextStep = this.completeCurrentStep()

    if (nextStep) {
      this.router.navigateByUrl(nextStep.route)
    }
  }

  getCurrentStep = (steps: Step[]) => steps.find(step => step.active)

  completeCurrentStep = () => {
    const currentStep = this.getCurrentStep(this.templateMessageSteps)

    currentStep.completed = true

    return this.templateMessageSteps[currentStep.index + 1]
  }

  setStep = (name: StepsEnum) => {
    const currentStep = this.getCurrentStep(this.templateMessageSteps)

    if (currentStep) {
      currentStep.active = false
    }

    const step = this.templateMessageSteps[name]

    if (step) {
      step.active = true
      step.enabled = true
    }
  }

  isViewMode = () => this.storeMode === StoreMode.View

  isCreateMode = () => this.storeMode === StoreMode.Create

  loadTemplateMessage = (templateId: string) => {
    this.templateMessageService.getTemplateMessageById(templateId).subscribe(
      template => {
        this.storeTemplateMessage(template)
        this.router.navigateByUrl('cadastros/frase/nova')
      },
      error => {
        return this.infoBarService.show(
          'Erro ao buscar as informações da frase. Consulte o suporte se o problema persistir',
          'danger',
        )
      },
    )
  }

  private storeTemplateMessage = (templateMessage: TemplateMessageResponse) => {
    this.storeMode = StoreMode.View
    this.templateMessageId = templateMessage.id

    this.storeFormData(
      {
        brokerType: templateMessage.brokerType,
        messageType: templateMessage.messageType,
        status: templateMessage.status,
        checkCustomerPreferenceForActiveMessaging: templateMessage.checkCustomerPreferenceForActiveMessaging
      },
      'General',
    )

    this.storeFormData(
      {
        queueIds: templateMessage.queues?.map(queue => queue.id),
      },
      'Queue',
    )

    this.storeFormData(
      {
        templateId: templateMessage.templateTextMessage.templateId,
        name: templateMessage.templateTextMessage.name,
        messageCategory:
          templateMessage.templateTextMessage?.messageCategory?.id,
        message: templateMessage.templateTextMessage.message,
        parameters: templateMessage.templateTextMessage?.parameters,
      },
      'Message',
    )
  }
}
