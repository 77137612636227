import { Component, EventEmitter, Input, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

@Component({
  selector: 'app-direct-contact-form',
  templateUrl: './direct-contact-form.component.html',
  styleUrls: ['./direct-contact-form.component.scss'],
})
export class DirectContactFormComponent {
  @Input() subtitle: string
  @Input() showContactInput: boolean
  @Output() directContactConfirmed = new EventEmitter()

  phoneNumberForm: FormGroup

  constructor(private formBuilder: FormBuilder) {
    this.phoneNumberForm = this.formBuilder.group({
      phoneNumber: [
        '',
        [Validators.required, Validators.pattern(/^\(\d{2}\) \d{4,5}-\d{4}$/)],
      ],
    })
  }

  handleClick = () => {
    this.phoneNumberForm.valid === true && this.showContactInput
      ? this.directContactConfirmed.emit(this.phoneNumberForm.value.phoneNumber)
      : this.directContactConfirmed.emit('')
  }
}
