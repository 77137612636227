import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms'
import { FormControlValidator } from '@app/tools/form-control-validator'
import { TeamService } from './attendance-team.service'
import { HtmlTools } from '@app/tools/html-tools'
import { InfoBarService } from '@app/info-bar/info-bar.service'

@Component({
  selector: 'app-team',
  templateUrl: './attendance-team.component.html',
  styleUrls: ['./attendance-team.component.scss'],
})
export class TeamComponent implements OnInit {
  teamForm: FormGroup
  newTeamButton: HTMLButtonElement
  closeModalButton: HTMLButtonElement
  submitButton: HTMLButtonElement
  modal: HTMLElement

  constructor(
    public formControlValidator: FormControlValidator,
    private formBuilder: FormBuilder,
    private service: TeamService,
    private infoBarService: InfoBarService,
    private htmlTools: HtmlTools,
  ) {}

  ngOnInit() {
    this.modal = document.getElementById('team-modal')
    this.newTeamButton = document.querySelector('.new-team')
    this.closeModalButton = document.querySelector('.close-modal')
    this.submitButton = document.querySelector('.submit-team')
    this.initializeForm()
  }

  showModal = () => {
    this.modal.classList.remove('hide')
    this.modal.classList.add('show')
  }

  closeModal = () => {
    this.modal.classList.remove('show')
    this.modal.classList.add('hide')
  }

  initializeForm = () => {
    this.teamForm = this.formBuilder.group({
      name: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(35),
      ]),
      receiveTransfers: new FormControl(false),
      active: new FormControl(false),
    })
  }

  submit = () => {
    if (this.teamForm.valid) {
      this.htmlTools.addAttributeToElement(this.submitButton, 'disabled', '')
      this.service.save(this.teamForm.value).subscribe(
        () => {
          this.closeModal(),
            this.infoBarService.show('Time cadastrado com sucesso', 'success'),
            this.htmlTools.removeAttributeFromElement(
              this.submitButton,
              'disabled',
            ),
            this.teamForm.reset()
        },
        ({ error }) => {
          const formControl = this.teamForm.get('name')
          formControl.setErrors({
            serverError: error,
          })
          this.htmlTools.removeAttributeFromElement(
            this.submitButton,
            'disabled',
          )
        },
      )
    }
  }
}
