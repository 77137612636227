<div class="loader loader--table" id="loader-table">
  <div class="loader--table-body">
    <div *ngFor="let item of [1, 2, 3, 4]" class="row-wrapper">
      <div>
        <div class="cell loader queue-field">
          <div class="animation-reflex"></div>
        </div>

        <div class="cell loader queue-name-field">
          <div class="animation-reflex"></div>
        </div>
      </div>

      <div class="cell loader info-field">
        <div class="animation-reflex"></div>
      </div>

      <div class="cell loader info-field">
        <div class="animation-reflex"></div>
      </div>

      <div class="cell loader info-field">
        <div class="animation-reflex"></div>
      </div>

      <div class="cell loader info-field">
        <div class="animation-reflex"></div>
      </div>
    </div>
  </div>
</div>
