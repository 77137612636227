import { ParameterType } from './../../../parameter-type'
import { InfoBarService } from './../../../../../info-bar/info-bar.service'
import { DropDownItem } from './../../../../../components/drop-down/drop-down-item'
import { Component, Input, OnInit } from '@angular/core'
import {
  AbstractControl,
  ControlContainer,
  FormArray,
  FormBuilder,
  Validators,
} from '@angular/forms'

@Component({
  selector: 'app-template-message-variables',
  templateUrl: './template-message-variables.component.html',
  styleUrls: ['./template-message-variables.component.scss'],
})
export class TemplateMessageVariablesComponent implements OnInit {
  @Input() isCreateMode: boolean

  parameterTypeOptions: DropDownItem[]
  parametersControls: AbstractControl[]

  constructor(
    private formBuilder: FormBuilder,
    private infoBarService: InfoBarService,
    public controlContainer: ControlContainer,
  ) {}

  ngOnInit(): void {
    this.initializeParametersControls()
    this.initializeDropdownItems()
  }

  initializeDropdownItems() {
    this.parameterTypeOptions = []

    if (this.isCreateMode) {
      this.parameterTypeOptions = [
        { text: 'Nome do Cliente', value: ParameterType.CustomerName },
        { text: 'Nome do Vendedor', value: ParameterType.SellerName },
      ] as DropDownItem[]
    } else {
      this.controlContainer.value?.forEach(value => {
        this.parameterTypeOptions.push({
          text: this.getParameterTypeName(value.parameterType),
          value: value.parameterType,
        })
      })
    }
  }

  getParameterTypeName(parameter: ParameterType) {
    return parameter === 0 ? 'Nome do Cliente' : 'Nome do Vendedor'
  }

  initializeParametersControls() {
    this.parametersControls = (this.controlContainer
      .control as FormArray)?.controls
  }

  addNewMessageVariable() {
    const limitVariable = 2
    const newVariable = {
      fixed: [false, Validators.required],
      parameterType: [null, Validators.required],
    }

    if (this.parametersControls.length < limitVariable) {
      this.parametersControls.push(this.formBuilder.group(newVariable))

      this.patchValueControl()
    } else
      this.infoBarService.show(
        `Somente duas variáveis podem ser adicionadas.`,
        'danger',
      )
  }

  removeMessageVariable(index: number) {
    this.parametersControls.splice(index, 1)

    this.patchValueControl()
  }

  patchValueControl() {
    this.controlContainer.control.patchValue(this.parametersControls)
  }
}
