import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { InfoBarService } from '@app/info-bar/info-bar.service'
import { StoreMode } from '../register.model'
import { Broker } from './broker.model'
import { BrokerService } from './broker.service'

type FormTypes = 'BrokerDetails'

@Injectable()
export class BrokerStore {
  storeMode: StoreMode
  public brokerId: string
  public brokerName: string

  private formValues: {
    [form: string]: any
  }

  constructor(
    private router: Router,
    private brokerService: BrokerService,
    private infoBarService: InfoBarService,
  ) {
    this.formValues = {}
    this.storeMode = StoreMode.Create
  }

  public loadBroker = (brokerId: string) => {
    this.brokerService.getById(brokerId).subscribe(
      broker => {
        const brokerToStore = {
          ...broker,
          id: brokerId,
        }

        this.brokerId = brokerId
        this.storeBroker(brokerToStore)
        this.storeMode = StoreMode.Update
        this.router.navigateByUrl('cadastros/broker/novo')
      },
      () =>
        this.infoBarService.show(
          'Erro ao buscar as informações do broker. Consulte o suporte se o problema persistir',
          'danger',
        ),
    )
  }

  getFormData = (formType: FormTypes) => this.formValues[formType]

  storeFormData = (values: any, formType: FormTypes) => {
    this.formValues[formType] = values
  }

  private storeBroker = (broker: Broker) => {
    this.storeFormData(
      {
        name: broker.name,
        endpoint: broker.endpoint,
        integrationToken: broker.integrationToken,
        appId: broker.appId,
        appSecret: broker.appSecret,
        phoneNumberId: broker.phoneNumberId,
        userToken: broker.userToken,
        type: broker.type,
        active: broker.active,
        queueId: broker.queueId,
      },
      'BrokerDetails',
    )
    this.brokerName = broker.name
  }

  isUpdateMode = () => {
    if (this.storeMode === StoreMode.Update) {
      return true
    }

    return false
  }

  update = () => {
    const broker = {
      ...this.formValues['BrokerDetails'],
    } as Broker

    if (this.storeMode === StoreMode.Update) {
      this.brokerService.update(this.brokerId, broker).subscribe(
        () => {
          this.infoBarService.show('Broker atualizado com sucesso', 'success')
          this.router.navigateByUrl('/cadastros/broker/novo')
        },
        ({ error }) => this.infoBarService.show(error, 'danger'),
      )
    }
  }

  clear = () => {
    this.formValues = {}
    this.storeMode = StoreMode.Create
  }
}
