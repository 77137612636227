import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '@env/environment'
import { Observable } from 'rxjs'
import { Workplace, WorkplaceRequest } from './workplace'

@Injectable({
  providedIn: 'root',
})
export class WorkplaceService {
  private url: string

  constructor(private http: HttpClient) {
    this.url = `${environment.urlBlaMessageManagementApi}/Workplace`
  }

  public get = (): Observable<Workplace[]> =>
    this.http.get<Workplace[]>(`${this.url}`)

  public post = (workplace: WorkplaceRequest): Observable<any> =>
    this.http.post<WorkplaceRequest>(`${this.url}`, workplace)
}
