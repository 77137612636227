import { HttpClient } from '@angular/common/http'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core'

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss'],
})
export class ImageModalComponent implements OnChanges, AfterViewInit {
  @ViewChild('image') private image: ElementRef<HTMLImageElement>

  @Input() url?: string
  @Output() closeImageModal = new EventEmitter()

  orientation: string
  imageReady = false
  blobURL: SafeUrl

  constructor(
    private http: HttpClient,
    private element: ElementRef,
    private sanitizer: DomSanitizer,
  ) {}

  ngAfterViewInit() {
    this.element.nativeElement.addEventListener(
      'click',
      this.clickOutside,
      true,
    )
  }

  loadImage = (url: string) => {
    this.http.get(url, { responseType: 'blob' }).subscribe(async imageBlob => {
      this.imageReady = true

      const allowedFileTypes = [
        'image/png',
        'image/jpeg',
        'image/jpg',
        'image/jpe',
        'image/gif',
        'image/bmp',
      ]

      const imageBase64 = await this.blobToB64(imageBlob)

      if (allowedFileTypes.indexOf(imageBlob.type) > -1) {
        this.blobURL = this.sanitizer.bypassSecurityTrustUrl(
          `data:${imageBlob.type};base64,${imageBase64}`,
        )
      }
    })
  }

  setImageOrientation = () => {
    this.orientation =
      this.image.nativeElement.width < this.image.nativeElement.height
        ? 'portrait'
        : 'landscape'

    this.image.nativeElement.classList.remove('image')
    this.image.nativeElement.classList.add(this.orientation)
  }

  ngOnChanges() {
    if (this.url) this.loadImage(this.url)
  }

  clickOutside = (event: any) => {
    const isOutside = event.target.classList.contains('bla-modal__background')
    if (isOutside) this.close()
  }

  close = () => {
    this.imageReady = false
    this.image.nativeElement.src = ''
    this.closeImageModal.emit('')
    this.image.nativeElement.classList.add('image')
    this.element.nativeElement.classList.remove('show')
    this.element.nativeElement.classList.add('hide')
  }

  blobToB64 = blob => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    return new Promise(resolve => {
      reader.onloadend = () => {
        const b64 = reader.result.toString().replace(/^data:.+;base64,/, '')
        resolve(b64)
      }
    })
  }
}
