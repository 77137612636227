import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core'
import { Attendance } from '@app/chat/attendance/attendance'
import { ChatUserInfo } from './chat-user.info.model'

@Component({
  selector: 'app-chat-user-info',
  templateUrl: './chat-user-info.component.html',
  styleUrls: ['./chat-user-info.component.scss'],
})
export class ChatUserInfoComponent {
  @ViewChild('clientName') name: ElementRef
  @Input() currentConversationInfo: Attendance
  @Input() hasReview: boolean
  @Output() openCustomerReviewModal = new EventEmitter()

  newName = ''
  selectedChat: ChatUserInfo
  previousClient: Attendance
  currentClient: Attendance

  constructor() {}

  openModal = () => this.openCustomerReviewModal.emit('')
}
