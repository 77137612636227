<div class="bla-modal new-customer">
  <div class="bla-modal__body">
    <h1 class="bla-modal-title">Adicionar novo cliente</h1>
    <div class="bla-modal-separator"></div>
    <form
      [formGroup]="newCustomerForm"
      (ngSubmit)="submit()"
      class="new-customer-form"
    >
      <div class="bla-row">
        <div class="bla-column">
          <div class="bla-modal-label no-margin-bottom">
            Nome
          </div>
          <input
            formControlName="customerName"
            minlength="3"
            maxlength="35"
            class="bla-input"
            type="text"
            id="customer-name"
          />
        </div>
      </div>

      <div class="bla-row customer-phone">
        <div class="bla-column bla-column--25">
          <div class="bla-modal-label no-margin-bottom">
            Telefone
          </div>
          <input
            appPhoneNumber
            formControlName="customerPhoneNumber"
            minlength="14"
            maxlength="15"
            class="bla-input"
            placeholder="(11) 11111-1111"
            type="tel"
            id="customer-phone"
          />
        </div>
      </div>

      <div class="bla-modal-actions">
        <button
          id="cancel-new-customer-button"
          class="bla-btn bla-btn--secondary"
          (click)="close()"
        >
          Cancelar
        </button>
        <button
          type="submit"
          class="bla-btn"
          [ngClass]="
            newCustomerForm.invalid ? 'bla-btn--disabled' : 'bla-btn--primary'
          "
          [disabled]="newCustomerForm.invalid"
          id="save-new-customer-button"
        >
          Confirmar
        </button>
      </div>
    </form>
  </div>
</div>
<div class="bla-modal__background"></div>
