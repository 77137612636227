<div class="col-9 container business-hour">
  <app-title
    title="Novo grade de horário"
    link="/cadastros/grade-horario"
  ></app-title>
  <div class="business-hours-content">
    <app-stepper [steps]="store.steps$ | async"></app-stepper>
    <div class="business-hours-form">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
