<div
  class="tag"
  [ngClass]="{
    'tag--success': color === 'success',
    'tag--danger': color === 'danger',
    'tag--warning': color === 'warning'
  }"
>
  <div class="tag__value">{{ value }}</div>
</div>
