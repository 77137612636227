<div class="queue-form">
  <h2>Configurações Gerais</h2>
  <hr />
  <form [formGroup]="queueForm" (ngSubmit)="submit()">
    <div class="row">
      <div class="form-group form-item col-lg-12">
        <label for="name">Nome da fila</label>
        <input #queueName formControlName="name" autofocus [ngClass]="{
            'input--invalid': formControlValidator.isInvalid(queueForm, 'name')
          }" class="form-control" [readonly]="isUpdateMode()" id="name" />
        <app-character-count class="counter" [charactersLimit]="35" [characters]="queueName.value">
        </app-character-count>

        <div class="input-validation__info">
          <ul class="input-validation__errors" *ngIf="formControlValidator.isInvalid(queueForm, 'name')">
            <li class="input-error" *ngIf="
                formControlValidator.hasError(queueForm, 'name', 'required')
              ">
              A fila precisa de um nome.
            </li>
            <li class="input-error" *ngIf="
                formControlValidator.hasAnyError(
                  queueForm,
                  'name',
                  'maxlength',
                  'minlength'
                )
              ">
              O nome da fila deve conter entre 3 e 35 letras.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group form-item col-lg-12">
        <app-drop-down [formGroup]="queueForm" controlName="attendanceTeamId" label="Time"
          [items]="attendanceTeamsDropDownItems" [isRequired]="true"
          requiredMessageError="Um time deve ser selecionado"></app-drop-down>
      </div>
    </div>

    <div class="row">
      <div class="form-group form-item col-xl-2 col-lg-3 col-md-6">
        <label for="priority">Prioridade</label>
        <input type="number" formControlName="priority" autofocus [ngClass]="{
            'input--invalid': formControlValidator.isInvalid(
              queueForm,
              'priority'
            )
          }" class="form-control" id="priority" />
      </div>

      <div class="col-xl-12">
        <div class="input-validation__info">
          <ul class="input-validation__errors" *ngIf="formControlValidator.isInvalid(queueForm, 'priority')">
            <li class="input-error" *ngIf="
                formControlValidator.hasAnyError(
                  queueForm,
                  'priority',
                  'min',
                  'max'
                )
              ">
              A prioridade deve ser um número entre 1 e 99.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="checkbox-container form-item form-group col-lg-12">
        <label class="form-check-label" for="AvailableRankingDistribution">Priorizar atendimento por especialidade<small
            class="info">(opcional)</small></label>
        <input formControlName="availableRankingDistribution" type="checkbox" id="availableRankingDistribution"
          class="checkbox" />
        <label for="availableRankingDistribution" class="switch" id="availableRankingDistributionSwitch">
          <div class="switch-on">Sim</div>
          <div class="switch-off">Não</div>
        </label>
      </div>
    </div>

    <div class="row">
      <div class="checkbox-container form-item form-group col-lg-12">
        <label class="form-check-label" for="availableScoreDistribution">Priorizar atendimento por produtividade<small
            class="info">(opcional)</small></label>
        <input formControlName="availableScoreDistribution" type="checkbox" id="availableScoreDistribution"
          class="checkbox" />
        <label for="availableScoreDistribution" class="switch" id="availableScoreDistributionSwitch">
          <div class="switch-on">Sim</div>
          <div class="switch-off">Não</div>
        </label>
      </div>
    </div>

    <div class="row">
      <div class="checkbox-container form-item form-group col-lg-6">
        <label class="form-check-label" for="receiveTransfers">Receber transferência<small
            class="info">(opcional)</small></label>
        <input formControlName="receiveTransfers" type="checkbox" id="receiveTransfers" class="checkbox" />
        <label for="receiveTransfers" class="switch" id="receiveTransfersSwitch">
          <div class="switch-on">Sim</div>
          <div class="switch-off">Não</div>
        </label>
      </div>
    </div>

    <div class="row">
      <div class="checkbox-container form-item form-group col-lg-6">
        <label class="form-check-label" for="makeTransfers">Permitir transferência<small
            class="info">(opcional)</small></label>
        <input formControlName="makeTransfers" type="checkbox" id="makeTransfers" class="checkbox" />
        <label for="makeTransfers" class="switch" id="makeTransfersSwitch">
          <div class="switch-on">Sim</div>
          <div class="switch-off">Não</div>
        </label>
      </div>
    </div>

    <div class="row">
      <div class="checkbox-container form-item form-group col-lg-12">
        <label class="form-check-label" for="checkCustomerPreferenceForActiveMessaging">Consultar preferências de
          atendimento do cliente<small class="info">(opcional)</small></label>
        <input formControlName="checkCustomerPreferenceForActiveMessaging" type="checkbox"
          id="checkCustomerPreferenceForActiveMessaging" class="checkbox" />
        <label for="checkCustomerPreferenceForActiveMessaging" class="switch"
          id="checkCustomerPreferenceForActiveMessagingSwitch">
          <div class="switch-on">Sim</div>
          <div class="switch-off">Não</div>
        </label>
      </div>
    </div>

    <div class="row">
      <div class="checkbox-container form-item form-group col-lg-12">
        <label class="form-check-label" for="preAttendance">Realizar pré-atendimento com o Bot<small
            class="info">(opcional)</small></label>
        <input formControlName="preAttendance" type="checkbox" id="preAttendance" class="checkbox" />
        <label for="preAttendance" class="switch" id="preAttendanceSwitch">
          <div class="switch-on">Sim</div>
          <div class="switch-off">Não</div>
        </label>
      </div>
    </div>

    <div class="bla-actions">
      <button type="button" class="bla-btn" routerLink="/cadastros/fila" (click)="cancel()">
        Cancelar
      </button>
      <button [disabled]="queueForm.invalid" type="submit" class="bla-btn bla-btn--primary" [ngClass]="{
          'bla-btn--disabled': queueForm.invalid,
          'save-button': store.isUpdateMode(),
          'next-button': !store.isUpdateMode()
        }">
        {{ store.isUpdateMode() ? 'Salvar Alterações' : 'Avançar' }}
      </button>
    </div>
  </form>
</div>

<app-edit-queue-confirmation-modal [id]="'queue-name-edit-modal'" [step]="StepsEnum.QueueName" #confirmationModal>
</app-edit-queue-confirmation-modal>