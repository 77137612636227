import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AccessProfile } from '@app/register/user/user.model'
import { environment } from '@env/environment'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class DeleteImageMessageService {
  private url: string

  constructor(private http: HttpClient) {
    this.url = `${environment.urlBlaMessageManagementApi}/messages/media`
  }

  public delete = (
    messageId: string,
    attendanceId: string,
    accessProfile: AccessProfile,
  ): Observable<any> => {
    const options = {
      headers: new HttpHeaders({ ContentType: 'application/json' }),
      body: { attendanceId, accessProfile },
    }

    return this.http.delete(`${this.url}/${messageId}`, options)
  }
}
