import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { DropDownItem } from '@app/components/drop-down/drop-down-item'
import { InfoBarService } from '@app/info-bar/info-bar.service'
import { FormControlValidator } from '@app/tools/form-control-validator'
import { HtmlTools } from '@app/tools/html-tools'
import { BrokerService } from './broker.service'
import { BrokerStore } from './broker.store'

@Component({
  selector: 'app-broker',
  templateUrl: './broker.component.html',
  styleUrls: ['./broker.component.scss'],
})
export class BrokerComponent implements OnInit {
  brokerForm: FormGroup
  saveButton: HTMLButtonElement
  isBrokerCloud = false
  queuesInfosDropDownItems: DropDownItem[]
  brokerTypesDropDownItems: DropDownItem[]

  constructor(
    public formControlValidator: FormControlValidator,
    private service: BrokerService,
    private formBuilder: FormBuilder,
    private infoBarService: InfoBarService,
    private htmlTools: HtmlTools,
    public store: BrokerStore,
    private router: Router,
  ) {}

  title = 'Novo Broker'

  ngOnInit() {
    if (this.store.isUpdateMode()) {
      this.title = 'Editar Broker'
      const formData = this.store.getFormData('BrokerDetails')
      this.loadFormBrokerType(formData['type'])

      if (formData) {
        this.brokerForm.setValue(formData)
      }
    } else {
      this.loadFormBrokerType(1)
    }
    this.loadQueues()
    this.loadBrokerTypes()

    this.saveButton = document.querySelector('.save-button')
  }

  loadQueues() {
    this.service.getQueueNamesAndIds().subscribe(
      infos =>
        (this.queuesInfosDropDownItems = infos.map(queueInfo => ({
          text: queueInfo.name,
          value: queueInfo.id,
        }))),
      ({ error }) => {
        this.infoBarService.show(
          'Falha ao carregar Filas. Consulte o suporte se o problema persistir',
          'danger',
        )
      },
    )
  }

  loadBrokerTypes() {
    this.service.getTypes().subscribe(
      brokerTypes =>
        (this.brokerTypesDropDownItems = brokerTypes.map(brokerType => ({
          text: brokerType.value,
          value: brokerType.id,
        }))),
      ({ error }) => {
        this.infoBarService.show(
          'Falha ao carregar Tipos de Broker. Consulte o suporte se o problema persistir',
          'danger',
        )
      },
    )
  }

  changedBrokerType() {
    this.loadFormBrokerType(parseInt(this.brokerForm.get('type').value))
  }

  loadFormBrokerType(brokerType: number) {
    this.isBrokerCloud = brokerType === 3

    this.brokerForm = this.formBuilder.group({
      name: new FormControl(
        this.store.isUpdateMode() ? this.store.brokerName : '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(35),
        ],
      ),
      endpoint: new FormControl(
        '',
        !this.isBrokerCloud ? [Validators.required] : null,
      ),
      integrationToken: new FormControl(
        '',
        !this.isBrokerCloud ? [Validators.required] : null,
      ),
      appId: new FormControl(
        '',
        this.isBrokerCloud ? [Validators.required] : null,
      ),
      appSecret: new FormControl(
        '',
        this.isBrokerCloud ? [Validators.required] : null,
      ),
      phoneNumberId: new FormControl(
        '',
        this.isBrokerCloud ? [Validators.required] : null,
      ),
      userToken: new FormControl(
        '',
        this.isBrokerCloud ? [Validators.required] : null,
      ),
      type: new FormControl(brokerType),
      active: new FormControl(false),
      queueId: ['', Validators.required],
    })
  }

  save = () => {
    if (this.store.isUpdateMode()) {
      this.store.storeFormData(this.brokerForm.value, 'BrokerDetails')
      this.store.update()
    } else {
      if (this.brokerForm.valid) {
        this.htmlTools.addAttributeToElement(this.saveButton, 'disabled', '')
        this.service.save(this.brokerForm.value).subscribe(
          () => {
            this.infoBarService.show(
              'Broker cadastrado com sucesso',
              'success',
            ),
              this.htmlTools.removeAttributeFromElement(
                this.saveButton,
                'disabled',
              )
          },
          ({ error }) => {
            this.infoBarService.show(error, 'danger')
            this.htmlTools.removeAttributeFromElement(
              this.saveButton,
              'disabled',
            )
          },
        )
      }
    }
  }

  cancel = () => this.store.clear()

  ngOnDestroy() {
    this.store.clear()
    this.router.navigateByUrl('cadastros/broker')
  }

  isUpdateMode = () => this.store.isUpdateMode()
}
