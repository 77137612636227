import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-direct-contact',
  templateUrl: './direct-contact.component.html',
  styleUrls: ['./direct-contact.component.scss'],
})
export class DirectContactComponent {
  @Input() link: string
  buttonText: string
  isButtonActive: boolean

  constructor() {
    this.isButtonActive = true
    this.buttonText = 'Copiar'
  }

  copyLink() {
    navigator.clipboard.writeText(this.link)
    this.disableCopyButton()
    setTimeout(() => {
      this.activateCopyButton()
    }, 3000)
  }

  private activateCopyButton() {
    this.buttonText = 'Copiar'
    this.isButtonActive = true
  }

  private disableCopyButton() {
    this.buttonText = 'Copiado!'
    this.isButtonActive = false
  }
}
