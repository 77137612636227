import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { QueueService } from '@app/register/queue/queue.service'
import { QueueNameAndId } from '@app/register/queue/queue.model'
import { TeamService } from '@app/register/attendance-team/attendance-team.service'
import { AttendanceTeamInfo } from '@app/register/attendance-team/attendance-team.model'
import { UserService } from '@app/register/user/user.service'

@Injectable({
  providedIn: 'root',
})
export class OnlineOperationalUserService {
  http: any
  constructor(
    private queueService: QueueService,
    private teamService: TeamService,
    private userService: UserService,
  ) {}

  public getWorkPauseReasons = (): Observable<any[]> =>
    this.userService.getWorkPauseReasons()

  public getQueueNamesAndIds = (): Observable<QueueNameAndId[]> =>
    this.queueService.getQueueNamesAndIds()

  public getAttendanceTeamsInfo = (): Observable<AttendanceTeamInfo[]> =>
    this.teamService.getAttendanceTeamsInfo()

  public getOnlineOperationalUsers = (): Observable<any> =>
    this.userService.getOnlineOperationalUser()

  public getOnlineOperationalUsersDetails = (
    onlineOperationaUserId: string,
  ): Observable<string> =>
    this.userService.getOnlineOperationalUserDetails(onlineOperationaUserId)
}
