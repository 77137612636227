import { Component } from '@angular/core'

import { DeviceCheck } from './tools/detect-mobile'

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  providers: [DeviceCheck],
  styleUrls: ['./main.component.scss'],
})
export class MainComponent {
  constructor(public deviceChek: DeviceCheck) {}
}
