import { Component, Input } from '@angular/core'
import { Attendance } from '@app/chat/attendance/attendance'

@Component({
  selector: 'app-chat-guide-messages',
  templateUrl: './chat-guide-messages.component.html',
  styleUrls: ['./chat-guide-messages.component.scss'],
})
export class ChatGuideMessagesComponent {
  @Input() attendances: Attendance[]
  @Input() currentAttendance: Attendance
}
