export class AttendanceInactiveSearchResult {
  public attendanceId: string
  public queueName: string
  public attendantName: string
  public customerName: string
  public sender: string
  public idleTime: string

  constructor(
    attendanceId: string,
    queueName: string,
    attendantName: string,
    customerName: string,
    sender: string,
    idleTime: string,
  ) {
    this.attendanceId = attendanceId
    this.queueName = queueName
    this.attendantName = attendantName
    this.customerName = customerName
    this.sender = sender
    this.idleTime = idleTime
  }
}
