import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { take } from 'rxjs/operators'
import { TemplateMessageStatus } from '../template-message-status'
import { TemplateMessageFilterModel } from './template-message-filter.model'
import { TemplateMessageFilterService } from './template-message-filter.service'

@Component({
  selector: 'app-template-message-filter',
  templateUrl: './template-message-filter.component.html',
  styleUrls: ['./template-message-filter.component.scss'],
})
export class TemplateMessageFilterComponent implements OnInit {
  @Output()
  search: EventEmitter<TemplateMessageFilterModel> = new EventEmitter()

  showFilters = true
  brokerTypes: any
  queues: any
  status: any
  message: string

  brokerTypesResult: any = []
  queuesResult: any = []
  statusResult: any = []
  messageResult: any = []

  brokerField = {
    tag: 'broker',
    labelTitle: 'Canal (Broker)',
    placeholder: 'Selecionar',
    multipleSelect: true,
    searchable: true,
  }

  queueField = {
    tag: 'fila',
    labelTitle: 'Fila',
    placeholder: 'Selecionar',
    multipleSelect: true,
    searchable: true,
  }

  statusField = {
    tag: 'status',
    labelTitle: 'Status',
    placeholder: 'Todas',
    multipleSelect: false,
    closeOnSelect: true,
  }

  constructor(
    private templateMessageFilterService: TemplateMessageFilterService,
  ) {}

  ngOnInit(): void {
    this.getBrokerTypes()
    this.getQueues()
    this.getStatus()
  }

  getBrokerTypes = () => {
    this.brokerTypes = this.templateMessageFilterService.getBrokerTypes()
  }

  getQueues = () => {
    this.templateMessageFilterService
      .getQueues()
      .pipe(take(1))
      .subscribe(data => (this.queues = data))
  }

  getStatus = () => {
    this.status = this.templateMessageFilterService.getStatus()
  }

  getFieldValue = ({ ids, tag }) => {
    if (tag === 'broker') this.brokerTypesResult = ids
    if (tag === 'fila') this.queuesResult = ids
    if (tag === 'status')
      this.statusResult =
        ids === TemplateMessageStatus.Todos ? [] : Boolean(ids)
  }

  searchTemplateMessages = () => {
    const filter = new TemplateMessageFilterModel(
      this.brokerTypesResult,
      this.queuesResult,
      this.statusResult,
      this.messageResult,
    )

    this.search.emit(filter)
  }
}
