import { Component, Input, Output, EventEmitter } from '@angular/core'
import { Attendance } from '@app/chat/attendance/attendance'
import { AttendanceService } from '@app/chat/attendance/attendance.service'
import { InfoBarService } from '@app/info-bar/info-bar.service'

@Component({
  selector: 'app-chat-card',
  templateUrl: './chat-card.component.html',
  styleUrls: ['./chat-card.component.scss'],
})
export class ChatCardComponent {
  @Input() attendances: Attendance[]
  @Output() selectAttendance = new EventEmitter()
  @Output() updateAttendanceStatus = new EventEmitter()
  star: HTMLElement

  constructor(
    private attendanceService: AttendanceService,
    private infoBarService: InfoBarService,
  ) {}

  attendanceId: string
  selectedItem: Attendance

  handleClick = (attendanceId: string, attendance: Attendance) => {
    this.selectedItem = attendance
    this.attendanceId = attendanceId
    this.selectAttendance.emit(attendanceId)
  }

  toggleActive = ({ active, attendanceId }) => {
    this.attendanceService.favoriteAttendance(attendanceId, active).subscribe(
      () => {
        active ? this.activate(attendanceId) : this.deactivate(attendanceId)
      },
      () => {
        this.infoBarService.show(
          'Só é possível ter 3(três) atendimentos como favoritos',
          'danger',
        )

        this.disableStar(attendanceId)
      },
    )
  }

  private activate = (attendanceId: string) =>
    this.updateAttendanceStatus.emit({ attendanceId, active: true })

  private deactivate = (attendanceId: string) =>
    this.updateAttendanceStatus.emit({ attendanceId, active: false })

  disableStar = (attendanceId: string) => {
    const element = document.getElementById(attendanceId)

    if (element) this.star = element.firstChild as HTMLElement

    if (this.star) this.star.classList.remove('star--active')
  }
}
