<div class="status-work-pause-reasons">
  <div class="status-choose">Motivo da ausência:</div>
  <select [(ngModel)]="reasonIdSelected">
    <option value="0" disabled>Selecione o motivo</option>
    <option *ngFor="let reason of reasons" [value]="reason.value">{{
      reason.text
    }}</option>
  </select>
  <button
    type="button"
    (click)="handleChangeStatusWorkPause()"
    class="send-work-pause-reason"
    *ngIf="reasonIdSelected !== '0'"
  >
    Ok
  </button>
</div>
