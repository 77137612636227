import { Component, OnInit } from '@angular/core'
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormArray,
} from '@angular/forms'
import { DropDownItem } from '@app/components/drop-down/drop-down-item'
import { BusinessHoursStore } from '../../business-hours.store'
import { InfoBarService } from '@app/info-bar/info-bar.service'
import { StepsEnum } from '@app/stepper/steps/business-hours.steps'
import { FormControlValidator } from '@app/tools/form-control-validator'
import { BusinessHoursService } from '../../business-hours.service'

@Component({
  selector: 'app-business-hours-name',
  templateUrl: './business-hours-name.component.html',
  styleUrls: ['./business-hours-name.component.scss'],
})
export class BusinessHoursNameComponent implements OnInit {
  businessHoursForm: FormGroup
  queuesDropDownItems: DropDownItem[]

  constructor(
    public formControlValidator: FormControlValidator,
    private store: BusinessHoursStore,
    private formBuilder: FormBuilder,
    private infoBarService: InfoBarService,
    private service: BusinessHoursService,
  ) {}

  ngOnInit(): void {
    this.store.setStep(StepsEnum.Name)
    this.initializeForm()
    this.loadQueues()
  }

  next() {
    if (this.businessHoursForm.valid) {
      this.service
        .checkIfBusinessHoursNameExists(this.businessHoursForm.value.name)
        .subscribe(({ exists }) => {
          if (!exists) {
            this.store.storeFormData(this.businessHoursForm.value, 'Name')
            this.store.nextStep()
          } else {
            this.infoBarService.show(
              'Já existe um calendário com esse nome.',
              'danger',
            )
          }
        })
    }
  }

  initializeForm = () => {
    this.businessHoursForm = this.formBuilder.group({
      name: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(35),
      ]),
      queues: new FormArray([]),
    })

    const formData = this.store.getFormData('Name')

    if (formData) {
      this.businessHoursForm.patchValue(formData)
      this.businessHoursForm.setControl(
        'queues',
        this.formBuilder.array(formData.queues || []),
      )
    }
  }

  loadQueues() {
    this.service.getQueueNamesAndIds().subscribe(
      infos => {
        this.queuesDropDownItems = infos.map(queueInfo => ({
          text: queueInfo.name,
          value: queueInfo.id,
        }))
      },
      ({ error }) => {
        this.infoBarService.show(
          'Falha ao carregar Filas. Consulte o suporte se o problema persistir',
          'danger',
        )
      },
    )
  }
}
