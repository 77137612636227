<div class="business-hours-form">
  <h2>Horários</h2>
  <p class="form-title__label">
    A hora deve ser inserida no formato 24 horas. Exemplo: de 11:40 às 23:59.
  </p>
  <hr />
  <form [formGroup]="timeTableForm" (ngSubmit)="save()">
    <app-business-day-checkbox
      weekDay="Segunda-feira"
      controlName="monday"
      [parentForm]="timeTableForm"
      [formControlValidator]="formControlValidator"
    ></app-business-day-checkbox>
    <app-business-day-checkbox
      weekDay="Terça-feira"
      controlName="tuesday"
      [parentForm]="timeTableForm"
      [formControlValidator]="formControlValidator"
    ></app-business-day-checkbox
    ><app-business-day-checkbox
      weekDay="Quarta-feira"
      controlName="wednesday"
      [parentForm]="timeTableForm"
      [formControlValidator]="formControlValidator"
    ></app-business-day-checkbox
    ><app-business-day-checkbox
      weekDay="Quinta-feira"
      controlName="thursday"
      [parentForm]="timeTableForm"
      [formControlValidator]="formControlValidator"
    ></app-business-day-checkbox
    ><app-business-day-checkbox
      weekDay="Sexta-feira"
      controlName="friday"
      [parentForm]="timeTableForm"
      [formControlValidator]="formControlValidator"
    ></app-business-day-checkbox
    ><app-business-day-checkbox
      weekDay="Sábado"
      controlName="saturday"
      [parentForm]="timeTableForm"
      [formControlValidator]="formControlValidator"
    ></app-business-day-checkbox
    ><app-business-day-checkbox
      weekDay="Domingo"
      controlName="sunday"
      [parentForm]="timeTableForm"
      [formControlValidator]="formControlValidator"
    ></app-business-day-checkbox>
    <div class="bla-actions">
      <button type="button" class="bla-btn" routerLink="">
        Cancelar
      </button>
      <button
        type="submit"
        class="bla-btn submit-button"
        [ngClass]="
          timeTableForm.invalid ? 'bla-btn--disabled' : 'bla-btn--primary'
        "
        [disabled]="timeTableForm.invalid"
      >
        Salvar
      </button>
    </div>
  </form>
</div>
