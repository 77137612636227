import { Injectable } from '@angular/core'
import { AttendanceService } from '@app/chat/attendance/attendance.service'
import { InactiveAttendances } from '@app/chat/attendance/inactive-attendances'
import { Sender } from '@app/chat/sender'
import { QueueService } from '@app/register/queue/queue.service'

@Injectable({
  providedIn: 'root',
})
export class ClosingBatchConversationsService {
  constructor(
    private queueService: QueueService,
    private attendanceService: AttendanceService,
  ) {}

  getQueues = () => {
    return this.queueService.getQueueNamesAndIds()
  }

  getInactiveAttendances = (modal: InactiveAttendances) => {
    return this.attendanceService.getInactiveAttendances(modal)
  }
}
