import { Component, Input } from '@angular/core'
import { DropDownItem } from './drop-down-item'
import { FormGroup } from '@angular/forms'
import { FormControlValidator } from '@app/tools/form-control-validator'

@Component({
  selector: 'app-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss'],
})
export class DropDownComponent {
  @Input() items: DropDownItem[]
  @Input() label: string
  @Input() controlName: string
  @Input() formGroup: FormGroup
  @Input() isRequired: boolean
  @Input() placeholder = 'Selecione'
  @Input() requiredMessageError: string

  constructor(public formControlValidator: FormControlValidator) {}

  get requiredOrOptionalLabel() {
    return this.isRequired ? '' : '(opcional)'
  }
}
