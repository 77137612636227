<div class="container">
  <div class="row">
    <app-title title="Brokers "></app-title>
  </div>
  <div class="row">
    <button
      routerLink="/cadastros/broker/novo"
      class="btn bla-btn--primary new-broker"
    >
      Criar novo broker
    </button>
  </div>
  <ngx-datatable
    [rows]="rows"
    [columnMode]="ColumnMode.flex"
    headerHeight="44"
    rowHeight="44"
  >
    <ngx-datatable-column prop="name" name="Broker" [flexGrow]="1.1">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column prop="type" name="Tipo" [flexGrow]="1.1">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ getBrokerTypeName(value) }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [sortable]="false"
      prop="active"
      name="Ativo"
      [flexGrow]="0.7"
    >
      <ng-template ngx-datatable-cell-template let-row="row">
        <input
          id="{{ row['id'] }}"
          type="checkbox"
          class="checkbox"
          [checked]="row['active']"
          (change)="changeBrokerStatus($event, row['id'], !row['active'])"
        />
        <label for="{{ row['id'] }}" class="switch" id="{{ row['id'] }}switch">
          <div class="switch-on">Sim</div>
          <div class="switch-off">Não</div>
        </label>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [sortable]="false" name="Ações" [flexGrow]="0.5">
      <ng-template let-value="value" ngx-datatable-cell-template let-row="row">
        <a
          [routerLink]=""
          id="edit-broker-{{ row['id'] }}"
          (click)="editBroker(row['id'])"
          >Editar</a
        >
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
