import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@env/environment'
import { Observable } from 'rxjs'
import { filter } from 'rxjs/operators'
import { MonitoringAttendancesFilterModel } from './monitoring-attendances-filter/monitoring-attendances-filter.model'

@Injectable({
  providedIn: 'root',
})
export class MonitoringAttendanceService {
  private url: string

  constructor(private http: HttpClient) {
    this.url = `${environment.urlBlaMessageManagementApi}/attendance`
  }

  public getAttendancesByFilters(
    filters: MonitoringAttendancesFilterModel,
  ): Observable<any> {
    return this.http.get<any[]>(`${this.url}/Monitoring/`, {
      params: {
        startDate: this.queryStringParam(filters.getStartDate()),
        endDate: this.queryStringParam(filters.getEndDate()),
        workplaceId: this.queryStringParam(filters.workplaceId),
        usersIds: filters.sellersIds,
        queuesIds: filters.queuesIds,
        closingTypesIds: filters.closingTypesIds,
        closingReasonIds: filters.closingReasonsIds,
        phoneNumber: this.queryStringParam(filters.phoneNumber),
        rating: filters.rating ? filters.rating : 0,
        ratingReason: this.queryStringParam(filters.ratingReason),
      },
    })
  }

  private queryStringParam(value) {
    let param = ''

    if (value?.length > 0) {
      param += value
    }

    return param
  }
}
