import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core'
import { ColumnMode, SortType } from '@swimlane/ngx-datatable'
import { TemplateMessageSearchResult } from './template-message-search-result'
import { TemplateMessageService } from '../template-message.service'
import { InfoBarService } from '@app/info-bar/info-bar.service'
import { TemplateMessageStore } from '../template-message.store'

@Component({
  selector: 'app-template-message-search-result',
  templateUrl: './template-message-search-result.component.html',
  styleUrls: ['./template-message-search-result.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TemplateMessageSearchResultComponent implements OnInit, OnChanges {
  @ViewChild('details', { static: true }) details: TemplateRef<any>
  @ViewChild('table', { static: true }) table
  @Input() templateMessages: TemplateMessageSearchResult[]
  @Output() eventDetailClicked = new EventEmitter<TemplateMessageSearchResult>()
  @Input() resetTable: boolean
  @Output() deleteSuccess = new EventEmitter()

  modal: HTMLElement
  ColumnMode = ColumnMode
  SortType = SortType
  rows = new Array<TemplateMessageSearchResult>()
  pageSize = 6
  hiddenMenu = false
  messages = { emptyMessage: 'Nenhuma frase foi encontrada' }
  selectedMessageToDelete: string

  constructor(
    public store: TemplateMessageStore,
    private templateMessageService: TemplateMessageService,
    private infoBarService: InfoBarService,
  ) {}

  ngOnInit(): void {
    this.modal = document.getElementById(
      'template-messages-delete-warning-modal',
    )
    this.fillTableWithTemplateMessages()
  }

  ngOnChanges(changes: SimpleChanges) {
    this.templateMessages = changes?.templateMessages?.currentValue as Array<
      TemplateMessageSearchResult
    >

    this.resetSorts()
    this.fillTableWithTemplateMessages()
  }

  fillTableWithTemplateMessages() {
    if (this.templateMessages) this.rows = [...this.templateMessages]

    this.hiddenMenuIfRowsLengthLessThanPageSize()
  }

  hiddenMenuIfRowsLengthLessThanPageSize() {
    this.hiddenMenu = this.rows?.length <= this.pageSize
  }

  onDetailTemplateMessages(templateMessage) {
    if (templateMessage) {
      templateMessage.visited = true
      this.eventDetailClicked.emit(templateMessage)
      this.store.loadTemplateMessage(templateMessage.templateId)
    }
  }

  resetSorts() {
    if (this.resetTable && this.table.sorts) this.table.sorts = []
  }

  deleteTemplateMessage() {
    this.templateMessageService.delete(this.selectedMessageToDelete).subscribe(
      () => {
        this.deleteSuccess.emit(this.selectedMessageToDelete)
        this.templateMessages = this.templateMessages.filter(
          value => value.templateId !== this.selectedMessageToDelete,
        )
        this.fillTableWithTemplateMessages()
        this.infoBarService.show('Mensagem excluída com sucesso!', 'success')
      },
      () => {
        this.infoBarService.show('Ocorreu um erro ao excluir a frase', 'danger')
      },
    )
    this.closeModal()
  }

  showModal = (tempalteId: string) => {
    this.selectedMessageToDelete = tempalteId
    this.modal.classList.remove('hide')
    this.modal.classList.add('show')
  }

  closeModal = () => {
    this.modal.classList.remove('show')
    this.modal.classList.add('hide')
  }

  changeTemplateMessageStatus(event: any, id: string) {
    const newStatus = event.srcElement.checked

    this.templateMessageService
      .patchTemplateMessageStatus(id, newStatus)
      .subscribe(
        () => {
          this.infoBarService.show(
            `Frase ${newStatus ? 'ativada' : 'desativada'} com sucesso`,
            'success',
          )
          this.updateTemplateMessageRowStatus(id, newStatus)
        },
        () => {
          this.infoBarService.show(
            `Não foi possível ${
              newStatus ? 'ativar' : 'desativar'
            } a frase. Consulte o suporte se o problema persistir`,
            'danger',
          )

          event.srcElement.checked = !newStatus
        },
      )
  }

  private updateTemplateMessageRowStatus(id: string, newStatus: boolean) {
    const templateMessage = this.rows.find(elem => elem.id === id)

    if (templateMessage) {
      templateMessage.status = newStatus
    }
  }
}
