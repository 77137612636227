import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core'
import { QueueStore } from '@app/register/queue/queue.store'
import { StepsEnum } from '@app/stepper/steps/queue.steps'
import { HtmlTools } from '@app/tools/html-tools'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-edit-queue-confirmation-modal',
  templateUrl: './edit-queue-confirmation-modal.component.html',
  styleUrls: ['./edit-queue-confirmation-modal.component.scss'],
})
export class EditQueueConfirmationModalComponent
  implements AfterViewInit, OnDestroy {
  confirmationModal: HTMLElement
  @Input() id: string
  @Input() step: StepsEnum
  subscription: Subscription

  constructor(private htmlTools: HtmlTools, private store: QueueStore) {}

  ngAfterViewInit() {
    this.subscription = this.store.getEndUpdateEmitter().subscribe(() => {
      this.closeModal()
    })
    this.confirmationModal = document.getElementById(this.id)
  }

  closeModal = () => this.htmlTools.hideElement(this.confirmationModal)

  openModal = () => this.htmlTools.showElement(this.confirmationModal)

  submit = () => {
    this.store.update(this.step)
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
