import {
  Component,
  OnInit,
  ElementRef,
  Input,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core'
import { QueueTransferInfo, QueueInfo } from '@app/register/queue/queue.model'
import { TransferReason } from '../attendance/attendance'
import { TransferAttendanceService } from './transfer-attendance.service'
import { InfoBarService } from '@app/info-bar/info-bar.service'
import { AttendantStatusService } from '@app/components/attendant-status/attendant-status.service'
import { AccessProfile } from '@app/register/user/user.model'
import { UserService } from '@app/register/user/user.service'
import { LocalStorageService } from '@app/tools/local-storage.service'
import { LoggedUser } from './../../components/authentication/logged-user.model'

@Component({
  selector: 'app-transfer-attendance-modal',
  templateUrl: './transfer-attendance-modal.component.html',
  styleUrls: ['./transfer-attendance-modal.component.scss'],
})
export class TransferAttendanceModalComponent implements OnInit, AfterViewInit {
  @Input() canQueueMakeTransfer: boolean
  @Input() attendanceId: string
  @Output() finishTransfer = new EventEmitter()

  AccessProfile = AccessProfile

  modalBody: HTMLElement

  availableQueues: QueueTransferInfo[]
  transferReasons: any
  availableUsers: []
  availableSupervisors: []
  dropdownStatus = false
  accessProfiles: any

  selectedProfile: AccessProfile

  selectedQueueInfo: {
    id: string
    name: string
  } = {
    id: undefined,
    name: undefined,
  }

  selectedTransferReason: {
    id: string
    name: string
  } = {
    id: undefined,
    name: undefined,
  }

  selectedOperationalUser: {
    id: string
    name: string
  } = {
    id: undefined,
    name: undefined,
  }

  selectedUserAccessProfile: {
    id: string
    name: string
  } = {
    id: undefined,
    name: undefined,
  }

  selectedSupervisor: {
    id: string
    name: string
  } = {
    id: undefined,
    name: undefined,
  }

  selectDisplay: HTMLElement

  constructor(
    private element: ElementRef,
    private transferService: TransferAttendanceService,
    private userService: UserService,
    private infoBarService: InfoBarService,
    private attendantStatusService: AttendantStatusService,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit(): void {
    this.loadQueuesAvailableForTransfer()
    this.loadAttendanceTransferReasons()
    this.loadAccessProfiles()
  }

  ngAfterViewInit() {
    this.element.nativeElement.addEventListener(
      'click',
      this.clickOutside,
      true,
    )
  }

  clickOutside = (event: any) => {
    const isOutside = event.target.classList.contains('bla-modal__background')
    if (isOutside) this.close()
  }

  close = () => {
    this.reset()
    this.element.nativeElement.classList.remove('show')
    this.element.nativeElement.classList.add('hide')
  }

  loadQueuesAvailableForTransfer = () => {
    this.transferService
      .getAvailableQueuesForTransfer()
      .subscribe(result => (this.availableQueues = result))
  }

  loadAttendanceTransferReasons = () => {
    this.transferService
      .getTransferReasons()
      .subscribe(result => (this.transferReasons = result))
  }

  loadAccessProfiles = () => {
    const loggedUser =
      this.localStorageService.getLoggedUser() || new LoggedUser()

    this.accessProfiles =
      loggedUser.userInfo.accessProfile === AccessProfile.Supervisor
        ? [{ id: AccessProfile.Operational, name: 'Vendedor' }]
        : [
            { id: AccessProfile.Operational, name: 'Vendedor' },
            { id: AccessProfile.Supervisor, name: 'Supervisor' },
          ]
  }

  getOperationalUsersForSelectedQueue = (queueId: string) => {
    if (this.selectedQueueInfo.id) {
      this.transferService
        .getOperationalUsersByQueue(queueId)
        .subscribe(result => (this.availableUsers = result))
    } else {
      console.error('Não foram encontrados usuários')
    }
  }

  getSupervisors = () => {
    if (this.selectedProfile === AccessProfile.Supervisor) {
      this.userService.getOnlineSupervisorUser().subscribe(users => {
        this.availableSupervisors = users
      })
    } else {
      console.error('Não foram encontrados usuários')
    }
  }

  selectQueue = (event, { id, name }: QueueInfo) => {
    this.closeOptions(event)

    this.selectedQueueInfo = {
      id,
      name,
    }

    this.getOperationalUsersForSelectedQueue(id)
  }

  selectOperationalUser = (event, { id, name }) => {
    this.closeOptions(event)

    this.selectedOperationalUser = {
      id,
      name,
    }
  }

  selectTransferReason = (event, { id, name }: TransferReason) => {
    this.closeOptions(event)

    this.selectedTransferReason = {
      id,
      name,
    }
  }

  selectUserAccessProfile = (event, { id, name }) => {
    this.closeOptions(event)

    this.selectedUserAccessProfile = {
      id,
      name,
    }

    this.selectedProfile = id
    if (this.selectedProfile === AccessProfile.Supervisor) this.getSupervisors()
  }

  selectSupervisor = (event, { id, name }) => {
    this.closeOptions(event)

    this.selectedSupervisor = {
      id,
      name,
    }
  }

  reset = () => {
    this.selectedQueueInfo = { id: undefined, name: undefined }

    this.selectedTransferReason = {
      id: undefined,
      name: undefined,
    }

    this.selectedOperationalUser = {
      id: undefined,
      name: undefined,
    }

    this.selectedUserAccessProfile = { id: undefined, name: undefined }

    this.selectedSupervisor = { id: undefined, name: undefined }

    this.selectedProfile = AccessProfile.Operational
  }

  openOptions = event => {
    this.dropdownStatus = true
    const dropdownId = event.target.dataset.dropdownId

    const dropdownItens = document.querySelector(
      `.bla-input-itens[data-dropdown-id="${dropdownId}"]`,
    ) as HTMLElement

    dropdownItens.classList.add('bla-input-itens--open')
    event.target.classList.add('bla-input-dropdown--open')
    dropdownItens.style.zIndex = '1000'
  }

  closeOptions = event => {
    this.dropdownStatus = false
    const dropdownId = event.target.parentNode.dataset.dropdownId

    const dropdownItens = document.querySelector(
      `.bla-input-itens[data-dropdown-id="${dropdownId}"]`,
    ) as HTMLElement

    dropdownItens.classList.remove('bla-input-itens--open')
    event.target.classList.remove('bla-input-itens--open')
  }

  noQueueOrTransferReasonSelected = () =>
    this.selectedQueueInfo.id === undefined ||
    this.selectedTransferReason.id === undefined

  noSupervisorOrTransferReasonSelected = () =>
    this.selectedSupervisor.id === undefined ||
    this.selectedTransferReason.id === undefined

  makeTransfer = () => {
    const transferRequest = {
      attendanceId: this.attendanceId,
      queueId: this.selectedQueueInfo.id,
      userId:
        this.selectedProfile === AccessProfile.Supervisor
          ? this.selectedSupervisor?.id
          : this.selectedOperationalUser?.id,
      reasonId: this.selectedTransferReason.id,
      selectedProfile: this.selectedProfile,
    }

    this.transferService
      .transferAttendance(
        transferRequest.attendanceId,
        transferRequest.queueId,
        transferRequest.userId,
        transferRequest.reasonId,
        this.selectedProfile,
      )
      .subscribe(
        result => {
          this.finishTransfer.emit(transferRequest.attendanceId)
          this.attendantStatusService.statusEmitter.emit(
            result.operationalUserStatus,
          )
          this.close()
          this.infoBarService.show(
            `Atendimento transferido com sucesso`,
            'success',
          )
        },
        error => {
          console.error(error)
          this.close()
          this.infoBarService.show(
            `Ocorreu um erro ao transferir o atendimento. Consulte o suporte se o problema persistir.`,
            'danger',
          )
        },
      )
  }
}
