import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-row-detail',
  templateUrl: './row-detail.component.html',
  styleUrls: ['./row-detail.component.scss'],
})
export class RowDetailComponent {
  @Input() details: [
    { title: string; value: string },
    { title: string; value: string },
    { title: string; value: string },
  ]
}
