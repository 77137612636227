import { Component, OnInit, ElementRef, ViewChild } from '@angular/core'
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms'
import { DropDownItem } from '@app/components/drop-down/drop-down-item'
import { InfoBarService } from '@app/info-bar/info-bar.service'
import { TeamService } from '@app/register/attendance-team/attendance-team.service'
import { StepsEnum } from '@app/stepper/steps/user.steps'
import { FormControlValidator } from '@app/tools/form-control-validator'

import { UserService } from '../../user.service'
import { UserStore } from '../../user.store'
import { Router } from '@angular/router'
import { WorkplaceService } from './workplace.service'
import { Workplace } from './workplace'
import { WorkplaceModalComponent } from './workplace-modal/workplace-modal/workplace-modal.component'
import { NgSelectConfig } from '@ng-select/ng-select'

@Component({
  selector: 'app-user-and-workplace',
  templateUrl: './user-and-workplace.component.html',
  styleUrls: ['./user-and-workplace.component.scss'],
})
export class UserAndWorkplaceComponent implements OnInit {
  @ViewChild('workplaceModal') workplaceModal: WorkplaceModalComponent

  userChatsAndTeamsForm: FormGroup
  workplaces: Workplace[]

  adminAndSupervisorsDropDownItems: DropDownItem[]
  attendanceTeamDropDownItems: DropDownItem[]

  constructor(
    public formControlValidator: FormControlValidator,
    private formBuilder: FormBuilder,
    private store: UserStore,
    private infoBarService: InfoBarService,
    private userService: UserService,
    private attendanceTeamService: TeamService,
    private workplaceService: WorkplaceService,
    private router: Router,
    private config: NgSelectConfig,
  ) {
    this.config.notFoundText = 'Nenhum resultado encontrado'
  }

  ngOnInit() {
    this.store.setStep(StepsEnum.ChatsAndTeams)
    this.initializeForm()
    this.loadAdminAndSupervisors()
    this.loadAttendanceTeamInfos()
    this.loadWorkplaces()
  }

  initializeForm() {
    this.userChatsAndTeamsForm = this.formBuilder.group({
      immediateSuperiorUserId: new FormControl('', [Validators.required]),
      maxParallelAttendances: new FormControl(null, [
        Validators.required,
        Validators.min(0),
        Validators.max(99),
      ]),
      workplaceId: new FormControl(null, [Validators.required]),
      externalLogin: new FormControl(null, [Validators.required]),
      sellerCode: new FormControl(null, [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(7),
      ]),
      attendanceTeamsIds: new FormArray([]),
    })

    const formData = this.store.getFormData('ChatsAndTeams')

    if (formData) {
      this.userChatsAndTeamsForm.patchValue(formData)
      this.userChatsAndTeamsForm.setControl(
        'attendanceTeamsIds',
        this.formBuilder.array(formData.attendanceTeamsIds || []),
      )
    }
  }

  loadAdminAndSupervisors() {
    this.userService.getAllAdminAndSupervisors().subscribe(
      infos =>
        (this.adminAndSupervisorsDropDownItems = infos.map(user => ({
          text: user.name,
          value: user.id,
        }))),
      ({ error }) => this.infoBarService.show(error, 'danger'),
    )
  }

  loadAttendanceTeamInfos() {
    this.attendanceTeamService.get().subscribe(
      infos =>
        (this.attendanceTeamDropDownItems = infos.map(attendanceTeamInfo => ({
          text: attendanceTeamInfo.name,
          value: attendanceTeamInfo.id,
        }))),
      ({ error }) => this.infoBarService.show(error, 'danger'),
    )
  }

  loadWorkplaces() {
    this.workplaceService.get().subscribe(
      workplaces =>
        (this.workplaces = workplaces.map(workplace => ({
          id: workplace.id,
          name: workplace.name,
          code: workplace.code,
          label: `${workplace.code} - ${workplace.name}`,
        }))),
      ({ error }) => this.infoBarService.show(error, 'danger'),
    )
  }

  save = () => {
    if (this.userChatsAndTeamsForm.valid) {
      this.store.storeFormData(
        this.userChatsAndTeamsForm.value,
        'ChatsAndTeams',
      )

      this.store.save()
    }
  }

  public cancel = () => {
    this.store.clear()
    this.router.navigateByUrl('cadastros/usuario/lista')
  }

  openCreateWorkplaceModal = () => this.workplaceModal.open()
}
