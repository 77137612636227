import { Component, Output } from '@angular/core'
import { LoggedUser } from '@app/components/authentication/logged-user.model'
import { AccessProfile } from './../register/user/user.model'
import { LocalStorageService } from '@app/tools/local-storage.service'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  @Output() loggedUser: LoggedUser
  AccessProfile = AccessProfile

  constructor(private localStorageService: LocalStorageService) {
    this.loggedUser = this.localStorageService.getLoggedUser()
  }

  isOperationalUser() {
    return this.loggedUser.userInfo.accessProfile === AccessProfile.Operational
  }
}
