import { Injectable } from '@angular/core'
import { QueueService } from '@app/register/queue/queue.service'
import { BrokerType } from '../broker-type'
import { TemplateMessageStatus } from '../template-message-status'

@Injectable({
  providedIn: 'root',
})
export class TemplateMessageFilterService {
  constructor(private queueService: QueueService) {}

  getBrokerTypes = () => {
    return [
      {
        name: BrokerType[BrokerType.WhatsappOnPremise],
        id: BrokerType.WhatsappOnPremise,
      },
      {
        name: BrokerType[BrokerType.WhatsappCloud],
        id: BrokerType.WhatsappCloud,
      },
    ]
  }

  getQueues = () => {
    return this.queueService.getQueueNamesAndIds()
  }

  getStatus = () => {
    return [
      {
        name: TemplateMessageStatus[TemplateMessageStatus.Todos],
        id: TemplateMessageStatus.Todos,
      },
      {
        name: TemplateMessageStatus[TemplateMessageStatus.Ativo],
        id: TemplateMessageStatus.Ativo,
      },
      {
        name: TemplateMessageStatus[TemplateMessageStatus.Inativo],
        id: TemplateMessageStatus.Inativo,
      },
    ]
  }
}
