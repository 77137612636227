<div class="template-message-form">
  <h2>Configurações Gerais</h2>
  <hr />
  <form [formGroup]="templateMessageForm" (ngSubmit)="submit()">
    <div class="row">
      <div class="form-group form-item col-lg-12">
        <app-drop-down [formGroup]="templateMessageForm" controlName="brokerType" label="Canal(Broker)"
          [items]="brokersDropDownItems" [isRequired]="true" requiredMessageError="Você deve selecionar um broker.">
        </app-drop-down>
      </div>
    </div>

    <div class="row">
      <div class="form-group form-item col-lg-12">
        <app-drop-down [formGroup]="templateMessageForm" controlName="messageType" label="Tipo de Mensagem"
          [items]="messageTypesDropDownItems" [isRequired]="true"
          requiredMessageError="Você deve selecionar o tipo de mensagem."></app-drop-down>
      </div>
    </div>

    <div class="row">
      <div class="checkbox-container form-item form-group col-lg-6">
        <label class="form-check-label" for="status">Status</label>
        <input formControlName="status" type="checkbox" id="status" class="checkbox" />
        <label for="status" class="switch" id="statusSwitch">
          <div class="switch-on">Ativo</div>
          <div class="switch-off">Inativo</div>
        </label>
      </div>
    </div>

    <div class="row">
      <div class="checkbox-container form-item form-group col-lg-12">
        <label class="form-check-label" for="checkCustomerPreferenceForActiveMessaging">Consultar preferências de
          atendimento do cliente</label>
        <input formControlName="checkCustomerPreferenceForActiveMessaging" type="checkbox"
          id="checkCustomerPreferenceForActiveMessaging" class="checkbox" />
        <label for="checkCustomerPreferenceForActiveMessaging" class="switch"
          id="checkCustomerPreferenceForActiveMessagingSwitch">
          <div class="switch-on">Ativo</div>
          <div class="switch-off">Inativo</div>
        </label>
      </div>
    </div>

    <div class="bla-actions">
      <button type="button" class="bla-btn" routerLink="/cadastros/frase" (click)="cancel()">
        Cancelar
      </button>
      <button [disabled]="templateMessageForm.invalid" type="submit" class="bla-btn bla-btn--primary next-button"
        [ngClass]="{
          'bla-btn--disabled': templateMessageForm.invalid
        }">
        Avançar
      </button>
    </div>
  </form>
</div>