<div class="bla-modal workplace">
  <div class="bla-modal__body">
    <h1 class="bla-modal-title">Cadastrar local de atuação</h1>
    <div class="bla-modal-separator"></div>

    <div class="bla-row">
      <div class="bla-column bla-column--25">
        <div class="bla-modal-label">
          Código da tabela <span>(obrigatório)</span>
        </div>
        <input
          #codeInput
          type="text"
          class="bla-input code-input"
          placeholder="Ex.: A6"
          maxlength="2"
          (blur)="validateCode($event.target.value)"
          (keyup)="validateCode($event.target.value)"
        />
        <div #code class="error"></div>
      </div>
    </div>

    <div class="bla-row">
      <div class="bla-column">
        <div class="bla-modal-label">
          Nome do local de atuação <span>(obrigatório)</span>
        </div>
        <input
          #nameInput
          type="text"
          class="bla-input"
          placeholder="Ex.: Villa Lobos"
          (blur)="validateName($event.target.value)"
          (keyup)="validateName($event.target.value)"
        />
        <div #name class="error"></div>
      </div>
    </div>

    <div class="bla-modal-actions">
      <button class="bla-btn" (click)="close()">
        Cancelar
      </button>
      <button
        type="button"
        class="bla-btn"
        (click)="createWorkplace()"
        [disabled]="!validCode || !validName"
        [ngClass]="
          !validCode || !validName ? 'bla-btn--disabled' : 'bla-btn--primary'
        "
      >
        Salvar
      </button>
    </div>
  </div>
</div>
<div class="bla-modal__background"></div>
