import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  Input,
  SimpleChanges,
  OnChanges,
  TemplateRef,
  ViewEncapsulation,
  Output,
  EventEmitter,
} from '@angular/core'
import { ColumnMode, SortType } from '@swimlane/ngx-datatable'
import * as moment from 'moment'
import { Subscription } from 'rxjs'
import { AttendanceSearchResult } from './attendance-search-result'

@Component({
  selector: 'app-attendance-search-result',
  templateUrl: './attendance-search-result.component.html',
  styleUrls: ['./attendance-search-result.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AttendanceSearchResultComponent
  implements OnInit, OnDestroy, OnChanges {
  @ViewChild('details', { static: true }) details: TemplateRef<any>
  @ViewChild('table', { static: true }) table
  @Input() attendances: AttendanceSearchResult[]
  @Output() eventDetailClicked = new EventEmitter<AttendanceSearchResult>()
  @Input() resetTable: boolean

  subscription: Subscription
  ColumnMode = ColumnMode
  SortType = SortType
  rows = new Array<AttendanceSearchResult>()
  tableColumns = []
  pageSize = 6
  hiddenMenu = false
  messages = { emptyMessage: 'Nenhum atendimento foi encontrado' }

  ngOnInit(): void {
    this.createTableColumns()

    this.fillTableWithAttendances()
  }

  ngOnChanges(changes: SimpleChanges) {
    this.attendances = changes?.attendances?.currentValue as Array<
      AttendanceSearchResult
    >

    this.resetSorts()

    this.fillTableWithAttendances()
  }

  createTableColumns() {
    this.tableColumns = [
      {
        draggable: false,
        resizeable: false,
        canAutoResize: false,
        sortable: false,
        cellTemplate: this.details,
        headerClass: 'd-none',
        width: 38,
      },
      {
        name: 'Dt. Início',
        prop: 'startDate',
        draggable: false,
        resizeable: false,
        canAutoResize: false,
        comparator: this.sortDate,
        width: 120,
      },
      {
        name: 'Hr. Início',
        prop: 'startTime',
        draggable: false,
        resizeable: false,
        canAutoResize: false,
        width: 110,
      },
      {
        name: 'Dt. Término',
        prop: 'endDate',
        draggable: false,
        resizeable: false,
        canAutoResize: false,
        comparator: this.sortDate,
        width: 135,
      },
      {
        name: 'Hr. Término',
        prop: 'endTime',
        draggable: false,
        resizeable: false,
        canAutoResize: false,
        width: 130,
      },
      {
        name: 'Motivo',
        prop: 'closingReason',
        draggable: false,
        resizeable: false,
        canAutoResize: false,
        width: 150,
        cellClass: 'ellipsis',
      },
      {
        name: 'Tipo Encerramento',
        prop: 'closingType',
        draggable: false,
        resizeable: false,
        canAutoResize: false,
        width: 185,
        cellClass: 'ellipsis',
      },
      {
        name: 'Fila',
        prop: 'queueName',
        draggable: false,
        resizeable: false,
        canAutoResize: false,
        width: 122,
        cellClass: 'ellipsis',
      },
      {
        name: 'Vendedor',
        prop: 'operationalUserName',
        draggable: false,
        canAutoResize: false,
        width: 139,
        cellClass: 'ellipsis',
      },
      {
        name: 'Telefone',
        prop: 'phoneNumber',
        draggable: false,
        resizeable: false,
        canAutoResize: false,
        width: 144,
      },
    ]
  }

  fillTableWithAttendances() {
    if (this.attendances) this.rows = [...this.attendances]

    this.hiddenMenuIfRowsLengthLessThanPageSize()
  }

  hiddenMenuIfRowsLengthLessThanPageSize() {
    this.hiddenMenu = this.rows?.length <= this.pageSize
  }

  onDetailAttendance(attendance) {
    if (attendance) {
      attendance.visited = true
      this.eventDetailClicked.emit(attendance)
    }
  }

  sortDate(dateRowA, dateRowB) {
    const dateA = moment(dateRowA, 'DD/MM/yyyy')
    const dateB = moment(dateRowB, 'DD/MM/yyyy')

    if (dateA > dateB) return 1
    else if (dateA < dateB) return -1
    else return 0
  }

  resetSorts() {
    if (this.resetTable && this.table.sorts) this.table.sorts = []
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe()
  }
}
