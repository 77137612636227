<div class="queue-form">
  <h2>Controle de Inatividade</h2>
  <hr />
  <form [formGroup]="idlenessForm" (submit)="submit()">
    <div class="row">
      <div class="checkbox-container form-item form-group col-lg-6">
        <label for="controlIdleness">Controlar inatividade</label>
        <input
          formControlName="controlIdleness"
          type="checkbox"
          id="controlIdleness"
          class="checkbox"
        />
        <label for="controlIdleness" class="switch" id="controlIdlenessSwitch">
          <div class="switch-on">Sim</div>
          <div class="switch-off">Não</div>
        </label>
      </div>
    </div>

    <div *ngIf="controlIdleness">
      <div class="row">
        <div class="input-group form-item col-md-12">
          <label for="idlenessTime"
            >Tempo de inatividade
            <small class="info">({{ requiredOrOptionalLabel }})</small>
          </label>
          <input
            type="number"
            min="0"
            formControlName="idlenessTime"
            class="form-control col-md-2"
            id="idlenessTime"
          />
          <div
            class="input-group-append"
            [ngClass]="{
              'input--invalid': formControlValidator.isInvalid(
                idlenessForm,
                'idlenessTime'
              )
            }"
          >
            <span class="input-group-text" id="idlenessTime-addon"
              >segundos</span
            >
          </div>
          <div class="input-queue__info">
            <ul
              class="input-queue__errors"
              *ngIf="
                formControlValidator.isInvalid(idlenessForm, 'idlenessTime')
              "
            >
              <li
                class="input-error"
                *ngIf="
                  formControlValidator.hasError(
                    idlenessForm,
                    'idlenessTime',
                    'required'
                  )
                "
              >
                O tempo de inatividade precisa ser definido
              </li>
              <li
                class="input-error"
                *ngIf="
                  formControlValidator.hasAnyError(
                    idlenessForm,
                    'idlenessTime',
                    'max',
                    'min'
                  )
                "
              >
                O tempo de inatividade deve ser entre 0 e 999 segundos
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <div class="form-group form-item">
          <div class="row">
            <div class="col-lg-12">
              <label for="attempts">
                Quantidade de tentativas
                <small class="info">({{ requiredOrOptionalLabel }})</small>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-2 col-md-3">
              <input
                type="number"
                min="0"
                formControlName="attempts"
                class="form-control"
                id="attempts"
              />
            </div>
          </div>
          <div class="input-queue__info">
            <ul
              class="input-queue__errors"
              *ngIf="formControlValidator.isInvalid(idlenessForm, 'attempts')"
            >
              <li
                class="input-error"
                *ngIf="
                  formControlValidator.hasError(
                    idlenessForm,
                    'attempts',
                    'required'
                  )
                "
              >
                A quantidade de tentativas precisa ser definida
              </li>
              <li
                class="input-error"
                *ngIf="
                  formControlValidator.hasAnyError(
                    idlenessForm,
                    'attempts',
                    'max',
                    'min'
                  )
                "
              >
                A quantidade de tentativas deve ser entre 0 e 99 vezes
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group form-item col-lg-12">
          <label for="idlenessMessage"
            >Mensagem de inatividade
            <small class="info">(opcional)</small></label
          >
          <label class="example-hint">
            Exemplo: "Por favor, aguarde um instante que já atenderei você"
          </label>
          <textarea
            #idlenessMessage
            formControlName="idlenessMessage"
            class="form-control"
            id="idlenessMessage"
          ></textarea>
          <app-character-count
            class="indleness-counter"
            [charactersLimit]="500"
            [characters]="idlenessMessage.value"
          ></app-character-count>
          <div class="input-queue__info">
            <ul
              class="input-queue__errors"
              *ngIf="
                formControlValidator.isInvalid(idlenessForm, 'idlenessMessage')
              "
            >
              <li
                class="input-error"
                *ngIf="
                  formControlValidator.hasError(
                    idlenessForm,
                    'idlenessMessage',
                    'maxlength'
                  )
                "
              >
                A mensagem de inatividade não pode exceder a 500 caracteres
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="checkbox-container form-item form-group col-lg-6">
          <label for="closeIdleChats">Encerrar chat por inatividade</label>
          <input
            formControlName="closeIdleChats"
            type="checkbox"
            id="closeIdleChats"
            class="checkbox"
          />
          <label for="closeIdleChats" class="switch" id="closeIdleChatsSwitch">
            <div class="switch-on">Sim</div>
            <div class="switch-off">Não</div>
          </label>
        </div>
      </div>

      <div class="row" *ngIf="closeIdleChats">
        <div class="form-group form-item col-lg-12">
          <label for="idleChatsClosingMessage"
            >Mensagem de encerramento
            <small class="info">(opcional)</small></label
          >
          <label class="example-hint">
            Exemplo: "Como não obtive resposta sua, o chat será encerrado"
          </label>
          <textarea
            #idleChatClosingMessage
            formControlName="idleChatsClosingMessage"
            class="form-control"
            id="idleChatsClosingMessage"
          ></textarea>
          <app-character-count
            class="indleness-counter"
            [charactersLimit]="500"
            [characters]="idleChatClosingMessage.value"
          ></app-character-count>
          <div class="input-queue__info">
            <ul
              class="input-queue__errors"
              *ngIf="
                formControlValidator.isInvalid(
                  idlenessForm,
                  'idleChatsClosingMessage'
                )
              "
            >
              <li
                class="input-error"
                *ngIf="
                  formControlValidator.hasError(
                    idlenessForm,
                    'idleChatsClosingMessage',
                    'maxlength'
                  )
                "
              >
                A mensagem de encerramento de chat não pode exceder a 500
                caracteres
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="bla-actions">
      <button
        type="button"
        class="bla-btn"
        routerLink="/cadastros/fila"
        (click)="cancel()"
      >
        Cancelar
      </button>
      <button
        #save
        type="submit"
        class="bla-btn bla-btn--primary"
        [ngClass]="{
          'bla-btn--disabled': idlenessForm.invalid,
          'save-button': store.isUpdateMode(),
          'next-button': !store.isUpdateMode()
        }"
        [disabled]="idlenessForm.invalid"
      >
        Salvar Alterações
      </button>
    </div>
  </form>
</div>
