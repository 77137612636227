<div
  [ngClass]="
    attendances.length > 0 && currentAttendance !== undefined
      ? 'chat'
      : currentAttendance === undefined && attendances.length > 0
      ? 'chat--no-attendance-selected'
      : 'chat--no-attendances'
  "
  (window:resize)="onWindowResize()"
>
  <div
    class="chat-cards-container"
    [class.chat-container-no-attendances]="attendances.length === 0"
    #chatCardsList
  >
    <div class="chat-cards-title--mobile" *ngIf="attendances?.length > 0">
      Janelas de atendimento
    </div>

    <app-new-customer
      (showNewCustomerModal)="openNewCustomerModal()"
    ></app-new-customer>

    <div
      class="favorite-attendances"
      *ngIf="favoritesCustomersList().length > 0"
    >
      <div class="attendance-list-tag">Favoritos</div>
      <div>
        <app-chat-card
          [attendances]="favoritesCustomersList()"
          (selectAttendance)="updateCurrentAttendance($event)"
          (updateAttendanceStatus)="updateFavorites($event)"
          (click)="(scrollChatWindow); animateChat()"
        >
        </app-chat-card>
      </div>
    </div>
    <div
      class="attendance-list-tag"
      *ngIf="favoritesCustomersList().length > 0"
    >
      Clientes da fila
    </div>
    <div *ngIf="queuedCustomersList().length > 0">
      <app-chat-card
        [attendances]="queuedCustomersList()"
        (selectAttendance)="updateCurrentAttendance($event)"
        (updateAttendanceStatus)="updateFavorites($event)"
        (click)="scrollChatWindow(); animateChat()"
      ></app-chat-card>
    </div>
  </div>

  <app-chat-guide-messages
    [attendances]="attendances"
    [currentAttendance]="currentAttendance"
  ></app-chat-guide-messages>

  <div
    class="chat-center"
    #chatMessages
    *ngIf="attendances.length > 0 && currentAttendance !== undefined"
  >
    <div
      class="open-message-list"
      (click)="isDeviceMobile && animateChat()"
      *ngIf="isDeviceMobile"
    >
      <span>&#60; Voltar para janelas de atendimento</span>
    </div>

    <app-chat-user-detail-mobile
      (showParentModal)="showModal()"
      [hasReview]="customerHasReview"
      (showTransferParentModal)="openTransferModal()"
      (showCustomerReviewModal)="openCustomerReviewModal()"
      [buttonsDisabled]="currentAttendance === undefined"
      *ngIf="isDeviceMobile"
      [conversation]="currentAttendance"
    ></app-chat-user-detail-mobile>

    <app-chat-panel
      (openEndAttendanceModal)="showModal()"
      (openTransferAttendanceModal)="openTransferModal()"
      [currentAttendance]="currentAttendance"
    >
    </app-chat-panel>

    <div class="chat-container">
      <app-chat-user-info
        [hasReview]="customerHasReview"
        (openCustomerReviewModal)="openCustomerReviewModal()"
        [currentConversationInfo]="currentAttendance"
      ></app-chat-user-info>

      <div class="chat-window" #chatWindow>
        <div
          [ngClass]="
            message.senderType === Sender.Customer
              ? 'chat-message'
              : message.senderType === Sender.OperationalUser
              ? 'chat-message--attendant'
              : 'chat-message--milestone'
          "
          *ngFor="let message of currentAttendance?.messageHistory || []"
        >
          <app-messsage-bubble
            (openImageModal)="showImageModal($event)"
            [deletedMessageId]="deletedMessageId"
            (openDeleteImageMessageModal)="showDeleteImageMessageModal($event)"
            [message]="message"
          ></app-messsage-bubble>
        </div>
      </div>

      <div class="chat-message-box">
        <div class="chat-message-box__textarea">
          <textarea
            #message
            [(ngModel)]="textValue"
            [value]="textValue"
            type="text"
            placeholder="Escrever mensagem"
          ></textarea>
        </div>
        <div
          class="btn btn-primary send-message-btn"
          (click)="handleMessageDelivery(message.value)"
        >
          ENVIAR
        </div>
      </div>
    </div>
  </div>
</div>

<app-end-attendance-modal
  class="hide"
  id="end-attendance-modal"
  [currentAttendance]="currentAttendance"
  (closeAttendance)="
    removeCurrentAttendance(); animateChat(); updateStatus($event)
  "
  (closeMobileMenu)="closeChatAnimation()"
>
</app-end-attendance-modal>

<app-new-customer-modal
  class="hide"
  id="new-customer-modal"
></app-new-customer-modal>

<app-transfer-attendance-modal
  class="transfer-modal hide"
  [attendanceId]="this.currentAttendance?.attendanceId"
  [canQueueMakeTransfer]="queueAvailableToMakeTransfer"
  (finishTransfer)="removeCurrentAttendance()"
></app-transfer-attendance-modal>

<app-customer-review-modal
  class="customer-review-modal hide"
  [reviewTextContent]="reviewContent"
  (savedReview)="getCustomerReview($event)"
  (openExcludeReview)="openExcludeModal()"
  [customerId]="currentAttendance?.customer.id"
></app-customer-review-modal>

<app-exclude-review-modal
  class="exclude-modal hide"
  (openCustomerReviewModal)="openCustomerReviewModal()"
  (excludeSuccess)="getCustomerReview($event)"
  [customerId]="currentAttendance?.customer.id"
></app-exclude-review-modal>

<app-image-modal
  class="image-modal hide"
  [url]="clientImage"
  (closeImageModal)="resetImageModal()"
></app-image-modal>

<app-delete-image-message-modal
  class="delete-image-message-modal hide"
  [imageMessageContext]="imageMessageContext"
  (imageRemoved)="handleImageRemoved($event)"
></app-delete-image-message-modal>
