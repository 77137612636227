<div
  class="info-bar"
  [ngClass]="!type ? 'alert alert-primary' : 'alert alert-' + type"
  role="alert"
>
  <span class="info-bar__message">{{ message }}</span>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
