<div class="container">
  <div class="row">
    <app-title title="Grade de Horário "></app-title>
  </div>
  <div class="row">
    <button
      routerLink="/cadastros/grade-horario/nova"
      class="bla-btn bla-btn--primary new-business-hours"
    >
      Nova grade de horário
    </button>
  </div>
</div>
