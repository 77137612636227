import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@env/environment'
import { Observable } from 'rxjs'

import { AttendanceTeamInfo, Team } from './attendance-team.model'

@Injectable()
export class TeamService {
  private url: string

  constructor(private http: HttpClient) {
    this.url = `${environment.urlBlaMessageManagementApi}/attendanceteam`
  }

  public save = (team: Team): Observable<Team> =>
    this.http.post<Team>(this.url, this.reinforceTeamFieldsTyping(team))

  public get = (): Observable<AttendanceTeamInfo[]> =>
    this.http.get<AttendanceTeamInfo[]>(`${this.url}/AttendanceTeamInfos`)

  reinforceTeamFieldsTyping = (form: Team): Team => ({
    name: String(form.name),
    receiveTransfers: Boolean(form.receiveTransfers),
    active: Boolean(form.active),
  })

  getAttendanceTeamsInfo = (): Observable<AttendanceTeamInfo[]> => {
    return this.http.get<AttendanceTeamInfo[]>(
      `${this.url}/AttendanceTeamInfos`,
    )
  }
}
