import {
  AfterViewInit,
  Directive,
  Injector,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { AbstractControl, NgControl } from '@angular/forms'
import { log } from 'console'
import { Subscription } from 'rxjs'

@Directive({
  selector: '[appPhoneNumber]',
})
export class PhoneNumberDirective implements AfterViewInit, OnDestroy {
  private phoneControl: AbstractControl

  private sub: Subscription

  constructor(private injector: Injector) {}
  ngAfterViewInit(): void {
    this.phoneControl = this.injector.get(NgControl)?.control

    if (this.phoneControl) this.phoneValidate()
  }

  phoneValidate() {
    this.sub = this.phoneControl.valueChanges.subscribe(newPhone => {
      if (newPhone) {
        newPhone = newPhone.replace(/\D/g, '')
        newPhone = newPhone.replace(/(^\d{2})(\d)/, '($1) $2')
        newPhone = newPhone.replace(/(\d{4,5})(\d{4}$)/, '$1-$2')

        this.phoneControl.setValue(newPhone, { emitEvent: false })
      }
    })
  }

  ngOnDestroy = () => this.sub.unsubscribe()
}
