<div class="col-9 container template-messages">
  <div class="row">
    <app-title [title]="title"></app-title>
  </div>

  <app-template-message-filter
    (search)="searchTemplateMessagesByFilters($event)"
  ></app-template-message-filter>

  <div class="row template-messages-content">
    <button class="btn bla-btn new-template-message" (click)="showModal()">
      Nova Frase
    </button>
  </div>

  <app-template-message-search-result
    [class.hide]="templateMessageDetail"
    [templateMessages]="templateMessages"
    [resetTable]="resetTable"
    (eventDetailClicked)="fillTemplateMessageDetail($event)"
  ></app-template-message-search-result>
</div>

<app-modal type="default" class="hide" id="template-messages-warning-modal">
  <section id="template-messages-warning-modal-content">
    <div id="template-messages-warning-modal-text">
      Para cadastrar uma mensagem, é necessário que ela já tenha sido cadastrada
      e aprovada no Facebook.
    </div>

    <div id="template-messages-warning-modal-buttons">
      <button
        id="agree-button"
        class="btn bla-btn--primary"
        (click)="closeModal()"
        routerLink="/cadastros/frase/nova"
      >
        Entendi
      </button>
    </div>
  </section>
</app-modal>
