<div class="header-mobile">
  <div class="header-mobile__display" #menu (click)="animateMenu()">
    <div class="logo">
      <img
        src="../../../assets/images/white-logo.svg"
        alt="Fast Shop Bla Logo"
      />
    </div>
    <div class="burguer-menu">
      <div class="stripe" [attr.data-stripe]="1"></div>
      <div class="stripe" [attr.data-stripe]="2"></div>
      <div class="stripe" [attr.data-stripe]="3"></div>
    </div>
  </div>
  <div class="menu-content" #wrapper>
    <div class="wrapper" #content>
      <ul class="menu-list">
        <li class="menu-list__item">
          <a routerLink="" (click)="animateMenu()">Home</a>
        </li>
        <div
          *ngIf="
            loggedUser.userInfo.accessProfile ===
              accessProfile.Administrative ||
            loggedUser.userInfo.accessProfile === accessProfile.Supervisor
          "
        >
          <li class="menu-list__item">
            <a routerLink="/cadastros/grade-horario" (click)="animateMenu()"
              >Grade de horário
            </a>
          </li>
          <li class="menu-list__item">
            <a routerLink="/cadastros/time" (click)="animateMenu()">Times</a>
          </li>
          <li class="menu-list__item">
            <a routerLink="/cadastros/usuario" (click)="animateMenu()"
              >Usuários</a
            >
          </li>
          <li class="menu-list__item">
            <a routerLink="/cadastros/fila" (click)="animateMenu()"
              >Filas de chat</a
            >
          </li>
          <li class="menu-list__item">
            <a routerLink="/cadastros/broker" (click)="animateMenu()">Broker</a>
          </li>
        </div>
        <div class="separator"></div>
      </ul>
      <div
        class="chat"
        *ngIf="loggedUser.userInfo.accessProfile === accessProfile.Operational"
      >
        <a routerLink="/chat" (click)="animateMenu()">Janelas de atendimento</a>
        <div class="status">
          <div class="title">Status:</div>
          <app-attendant-status></app-attendant-status>
        </div>
      </div>
      <div class="logout" (click)="logout()">Sair</div>
    </div>
  </div>
</div>
