<div class="no-attendance-message" *ngIf="attendances?.length === 0">
  <div class="no-attendance-message__title">
    Não há atendimentos<br />disponíveis no momento
  </div>
  <img src="../../../assets/images/ilustracao_02.png" alt="" />
  <div class="no-attendance-message__title">
    Aguarde clientes entrarem em contato
  </div>
</div>

<div
  class="attendance-heads-up"
  *ngIf="attendances?.length > 0 && currentAttendance === undefined"
>
  <div class="no-attendance-message__title">
    Selecione um chat para<br />iniciar o atendimento
  </div>
  <img src="../../../assets/images/ilustracao_01.png" alt="" />
</div>
