import { Injectable } from '@angular/core'
import { AbstractControl, FormGroup } from '@angular/forms'

@Injectable()
export class FormControlValidator {
  isInvalid(form: FormGroup, controlName: string): boolean {
    if (!form || !form.contains(controlName)) {
      return false
    }

    return this.isTouchedAndInvalid(form.get(controlName))
  }

  hasError = (
    form: FormGroup,
    controlName: string,
    errorName: string,
  ): boolean => {
    if (!form || !form.contains(controlName)) {
      return false
    }

    return form.get(controlName).hasError(errorName)
  }

  hasAnyError = (
    form: FormGroup,
    controlName: string,
    ...errorNames: string[]
  ): boolean => {
    if (!form || !form.contains(controlName)) {
      return false
    }

    if (!errorNames || !errorNames.length) {
      return false
    }

    const control = form.get(controlName)
    return errorNames.some(error => control.hasError(error))
  }

  isTouchedAndInvalid = (formControl: AbstractControl): boolean => {
    return !!formControl && formControl.touched && formControl.invalid
  }
}
