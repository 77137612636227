<div class="hide-filters" (click)="showFilters = !showFilters">
  <p>{{ showFilters ? 'ocultar filtros' : 'exibir filtros' }}</p>
  <span
    class="arrow"
    [ngClass]="{ 'arrow-top': showFilters, 'arrow-down': !showFilters }"
  ></span>
</div>

<div *ngIf="showFilters" class="template-message-filter">
  <div class="top-row">
    <div class="broker-filter filter">
      <app-select
        [props]="brokerField"
        [selectItems]="brokerTypes"
        (valuesSelected)="getFieldValue($event)"
        id="broker-select"
      ></app-select>
    </div>

    <div class="queue-filter filter">
      <app-select
        [props]="queueField"
        [selectItems]="queues"
        (valuesSelected)="getFieldValue($event)"
        id="queue-select"
      ></app-select>
    </div>

    <div class="status-filter filter">
      <app-input-dropdown
        [props]="statusField"
        [dropdownItens]="status"
        (valuesSelected)="getFieldValue($event)"
        id="status-select"
      ></app-input-dropdown>
    </div>
  </div>

  <div class="bottom-row">
    <div class="message-filter filter">
      <label for="message" class="filter-label">Mensagem</label>
      <input
        name="message"
        autofocus
        type="tel"
        class="form-control"
        id="message"
        [(ngModel)]="messageResult"
        placeholder="Palavra chave"
      />
    </div>
  </div>
</div>

<button
  *ngIf="showFilters"
  class="bla-btn bla-btn--primary btn-search"
  (click)="searchTemplateMessages()"
  id="search-template-messages-button"
>
  Pesquisar
</button>
