import { AfterViewInit, Component, Input, OnChanges } from '@angular/core'

@Component({
  selector: 'app-character-count',
  templateUrl: './character-count.component.html',
  styleUrls: ['./character-count.component.scss'],
})
export class CharacterCountComponent implements OnChanges, AfterViewInit {
  @Input() charactersLimit = 100
  @Input() characters = ''

  counter: HTMLElement

  ngAfterViewInit() {
    this.counter = document.querySelector('.character-counter')
  }

  ngOnChanges() {
    if (!this.characters) this.characters = ''

    this.characters.length > this.charactersLimit
      ? this.counter?.classList.add('limit-warning')
      : this.counter?.classList.remove('limit-warning')
  }
}
