export class TemplateMessageFilterModel {
  brokerTypes: []
  queuesIds: []
  status: []
  message: []

  constructor(brokerTypes?: any, queuesIds?: any, status?: any, message?: any) {
    this.brokerTypes = brokerTypes
    this.queuesIds = queuesIds
    ;(this.status = status), (this.message = message)
  }
}
