<div class="app-direct-contact">
  <div class="bla-container">
    <div class="app-direct-contact__title">
      Compartilhe o link abaixo com cliente para que ele possa conversar direto
      com você
    </div>
    <div class="app-direct-contact__content">
      <div class="direct-contact-row">
        <img src="../../assets/images/link.svg" />
        <span class="bla-direct-contact">{{ link }}</span>
        <button
          id="copy-contact-button"
          type="button"
          [ngClass]="
            isButtonActive
              ? 'bla-btn bla-btn--primary'
              : 'bla-btn bla-btn--disabled'
          "
          (click)="copyLink()"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
  </div>
</div>
