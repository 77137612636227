import { Component, OnInit, Input, Output } from '@angular/core'
import { DropDownItem } from '../drop-down/drop-down-item'
import { UserService } from '@app/register/user/user.service'
import { LoggedUser } from '../authentication/logged-user.model'
import {
  ChangeOperationalUserStatusRequest,
  AttendantStatus,
} from '@app/register/user/user.model'
import { LocalStorageService } from '@app/tools/local-storage.service'
import { EventEmitter } from '@angular/core'
import { AttendantStatusService } from '../attendant-status/attendant-status.service'
import { InfoBarService } from '@app/info-bar/info-bar.service'
import { OperatorConnectorType } from '../authentication/login/login'

@Component({
  selector: 'app-work-pause',
  templateUrl: './work-pause.component.html',
  styleUrls: ['./work-pause.component.scss'],
})
export class WorkPauseComponent implements OnInit {
  @Input() reasons: DropDownItem[]
  @Input() attendancesLength: number
  @Output() updateMenuOpenedStatus = new EventEmitter()

  reasonIdSelected: string
  loggedUser: LoggedUser
  length: number
  status: AttendantStatus

  constructor(
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private attendantStatusService: AttendantStatusService,
    private infoBarService: InfoBarService,
  ) {
    this.attendantStatusService
      .updateAttendantStatus()
      .subscribe((status: AttendantStatus) => (this.status = status))
  }

  ngOnInit(): void {
    this.loggedUser =
      this.localStorageService.getLoggedUser() || new LoggedUser()

    this.reasonIdSelected = '0'
  }

  updateAttendantStatus = (status: AttendantStatus) => {
    this.loggedUser.attendantStatus = status
    this.localStorageService.saveLoggedUser(this.loggedUser)

    this.attendantStatusService.updateAttendantStatus().emit(status)
  }

  handleChangeStatusWorkPause(): void {
    const changeOperationalUserStatusRequest: ChangeOperationalUserStatusRequest = {
      status: AttendantStatus.Away,
      workPauseReasonId: this.reasonIdSelected,
      connectorType: OperatorConnectorType.WebchatConnector,
    }

    this.updateMenuOpenedStatus.emit('close')

    if (this.loggedUser?.userInfo?.id) {
      this.userService
        .changeOperationalUserStatus(
          this.loggedUser.userInfo.id,
          changeOperationalUserStatusRequest,
        )
        .subscribe(
          () => {
            this.attendancesLength > 0
              ? this.updateAttendantStatus(AttendantStatus.Awaiting)
              : this.updateAttendantStatus(AttendantStatus.Away)
          },
          () => {
            this.infoBarService.show(
              'Ocorreu um erro ao mudar de status. Se o problema persistir, contate o administrador.',
              'danger',
            )
          },
        )
    }
  }
}
