export enum Type {
  Text = 1,
  StartAttendance,
  EndAttendance,
  Order,
  Image,
  ActiveContact,
  CustomerRegistered,
  InactiveAttendance,
}
