export interface BusinessHoursExistsResponse {
  exists: boolean
}

export enum DayOfWeek {
  sunday = 0,
  monday = 1,
  tuesday = 2,
  wednesday = 3,
  thursday = 4,
  friday = 5,
  saturday = 6,
}

export interface DailyBusinessHours {
  dayOfWeek: DayOfWeek
  openTime: string
  closeTime: string
}

export interface BusinessHours {
  name: string
  queuesIds: string[]
  dailyBusinessHours: DailyBusinessHours[]
}
