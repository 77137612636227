import { Component, Input } from '@angular/core'
import { Rating } from '../attendance-details/attendance-details.model'
import { HeaderDetails } from './header-details'

@Component({
  selector: 'app-header-details',
  templateUrl: './header-details.component.html',
  styleUrls: ['./header-details.component.scss'],
})
export class HeaderDetailsComponent {
  @Input() detail: HeaderDetails
  @Input() attendanceRating: Rating

  private defaultValue = '-'

  stringIsNotEmpty = value => value && value.length > 0

  nonEmptyStringOrDefaultValue = (value?: string | number) => {
    const result = typeof value === 'number' ? value.toString() : value
    return this.stringIsNotEmpty(result) ? result : this.defaultValue
  }

  formatAverageTime(value?: number) {
    if (value === 0) return value.toString()
    if (value > 0) return `${value}m`
    return this.defaultValue
  }
}
