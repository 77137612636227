import { Component, OnInit } from '@angular/core'
import { AttendanceInactive } from '@app/chat/attendance/attendance'
import { InactiveAttendances } from '@app/chat/attendance/inactive-attendances'
import { Sender } from '@app/chat/sender'
import { InfoBarService } from '@app/info-bar/info-bar.service'
import { isNullOrUndefined } from '@swimlane/ngx-datatable'
import { AttendanceInactiveSearchResult } from '../models/attendance-inactive-search-result.component'
import { ClosingBatchConversationsService } from '../services/closing-batch-conversations.service'

@Component({
  selector: 'app-closing-batch-conversations',
  templateUrl: './closing-batch-conversations.component.html',
  styleUrls: ['./closing-batch-conversations.component.scss'],
})
export class ClosingBatchConversationsComponent implements OnInit {
  private _attendances: AttendanceInactiveSearchResult[]

  constructor(
    private closingBatchConversationsFilterService: ClosingBatchConversationsService,
    private infoBarService: InfoBarService,
  ) {}

  get attendances(): AttendanceInactiveSearchResult[] {
    return this._attendances
  }

  set attendances(newValues: AttendanceInactiveSearchResult[]) {
    this._attendances = newValues
  }

  ngOnInit(): void {}

  searchAttendancesInactives(filters: InactiveAttendances): void {
    this.attendances = []
    this.closingBatchConversationsFilterService
      .getInactiveAttendances(filters)
      .subscribe(this.requestSuccess, this.requestError)
  }

  mappingToAttendanceInactiveSearchResult(
    attendancesInactive: AttendanceInactive[],
  ): AttendanceInactiveSearchResult[] {
    const attendances: AttendanceInactiveSearchResult[] = []

    attendancesInactive.forEach(attendance => {
      attendances.push(
        new AttendanceInactiveSearchResult(
          attendance.attendanceId,
          attendance.queueName,
          attendance.attendantName,
          attendance.customerName,
          this.getSenderDescription(attendance.lastInteraction?.senderType),
          attendance.idleTime,
        ),
      )
    })
    return attendances
  }

  getSenderDescription(senderType: Sender): string {
    switch (senderType) {
      case 1:
        return 'Cliente'
      case 2:
        return 'Vendedor'
      default:
        return ''
    }
  }

  public requestSuccess = (response: AttendanceInactive[]) => {
    if (!isNullOrUndefined(response) && !!response.length) {
      this.attendances = this.mappingToAttendanceInactiveSearchResult(response)
    }
  }

  public requestError = error => {
    this.attendances = []
    this.infoBarService.show(error, 'danger')
  }
}
