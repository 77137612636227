<div class="user-form">
  <h2>Acessos</h2>
  <hr />
  <form [formGroup]="userAccessForm" (ngSubmit)="save()">
    <div class="row">
      <div class="form-group form-item col-lg-12">
        <label for="name">Nome completo <small>(obrigatório)</small></label>
        <input
          appCharactersLimit="50"
          formControlName="name"
          autofocus
          [ngClass]="{
            'input--invalid': formControlValidator.isInvalid(
              userAccessForm,
              'name'
            )
          }"
          class="form-control"
          id="name"
        />
        <div class="input-user__info">
          <ul
            class="input-user__errors"
            *ngIf="formControlValidator.isInvalid(userAccessForm, 'name')"
          >
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasError(
                  userAccessForm,
                  'name',
                  'required'
                )
              "
            >
              O usuário precisa de um nome.
            </li>
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasAnyError(
                  userAccessForm,
                  'name',
                  'maxlength',
                  'minlength'
                )
              "
            >
              O nome do usuário deve ter entre 10 e 50 letras.
            </li>
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasAnyError(
                  userAccessForm,
                  'name',
                  'pattern'
                )
              "
            >
              São permitidas apenas letras no nome completo
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group form-item col-lg-12">
        <label for="username"
          >Username de login <small>(obrigatório)</small></label
        >
        <input
          appCharactersLimit="50"
          formControlName="username"
          [ngClass]="{
            'input--invalid': formControlValidator.isInvalid(
              userAccessForm,
              'username'
            )
          }"
          class="form-control"
          id="username"
        />
        <div class="input-user__info">
          <ul
            class="input-user__errors"
            *ngIf="formControlValidator.isInvalid(userAccessForm, 'username')"
          >
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasError(
                  userAccessForm,
                  'username',
                  'required'
                )
              "
            >
              O usuário precisa de um nome.
            </li>
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasAnyError(
                  userAccessForm,
                  'username',
                  'maxlength',
                  'minlength'
                )
              "
            >
              O nome do usuário deve ter entre 3 e 50 letras.
            </li>
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasAnyError(
                  userAccessForm,
                  'username',
                  'pattern'
                )
              "
            >
              Não são permitidos caracteres especiais no campo username
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group form-item col-lg-6">
        <app-drop-down
          [formGroup]="userAccessForm"
          controlName="accessProfile"
          label="Perfil de acesso"
          [items]="accessProfileDropDownItems"
          [isRequired]="true"
          requiredMessageError="Um Perfil de Acesso deve ser selecionado."
          (change)="setUserSteps()"
        ></app-drop-down>
      </div>
    </div>

    <div class="row">
      <div class="checkbox-container form-item form-group col-lg-12">
        <label for="active">Usuário Ativo</label>
        <input
          formControlName="active"
          type="checkbox"
          id="active"
          class="checkbox"
        />
        <label for="active" class="switch" id="activeSwitch">
          <div class="switch-on">Sim</div>
          <div class="switch-off">Não</div>
        </label>
      </div>
    </div>

    <div class="form-actions">
      <button type="button" class="bla-btn" (click)="cancel()">
        Cancelar
      </button>
      <button
        [disabled]="userAccessForm.invalid"
        type="submit"
        class="bla-btn bla-btn--primary submit-button"
        [ngClass]="{
          'next-button': isOperationalUser(),
          'save-button': !isOperationalUser(),
          'bla-btn--disabled': userAccessForm.invalid
        }"
      >
        {{ isOperationalUser() ? 'Avançar' : 'Salvar' }}
      </button>
    </div>
  </form>
</div>
