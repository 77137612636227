import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { InfoBarService } from '@app/info-bar/info-bar.service'
import { Step } from '@app/stepper/steps/step'
import {
  StepsEnum,
  administrativeUserSteps,
  operationalUserSteps,
} from '@app/stepper/steps/user.steps'
import { of } from 'rxjs'

import { AccessProfile, User, UserResponse } from './user.model'
import { UserService } from './user.service'
import { StoreMode } from '../register.model'

type FormTypes = 'Access' | 'ChatsAndTeams'

@Injectable()
export class UserStore {
  private userSteps: Step[]
  private storeMode: StoreMode
  public userId: string
  private formValues: { [form: string]: any }

  get steps$() {
    return of(this.userSteps)
  }

  get mode() {
    return this.storeMode
  }

  constructor(
    private router: Router,
    private userService: UserService,
    private infoBarService: InfoBarService,
  ) {
    this.userSteps = [...administrativeUserSteps]
    this.formValues = {}
    this.storeMode = StoreMode.Create
  }

  setUserSteps = (accessProfile: AccessProfile) => {
    this.userSteps =
      accessProfile.toString() === AccessProfile.Operational.toString()
        ? [...operationalUserSteps]
        : [...administrativeUserSteps]
  }

  storeFormData = (values: any, formType: FormTypes) =>
    (this.formValues[formType] = values)

  save = () => {
    const user = {
      ...this.formValues['Access'],
      ...this.formValues['ChatsAndTeams'],
    } as User

    if (this.mode === StoreMode.Create)
      this.userService.save(user).subscribe(
        () => {
          this.infoBarService.show('Usuário cadastrado com sucesso', 'success')
          this.router.navigateByUrl('/cadastros/usuario/lista')
          this.clear()
        },
        ({ error }) => this.infoBarService.show(error, 'danger'),
      )
    else if (this.mode === StoreMode.Update)
      this.userService.update(this.userId, user).subscribe(
        () => {
          this.infoBarService.show('Usuário atualizado com sucesso', 'success')
          this.router.navigateByUrl('/cadastros/usuario/lista')
          this.clear()
        },
        ({ error }) => this.infoBarService.show(error, 'danger'),
      )
  }

  nextStep = () => {
    const nextStep = this.completeCurrentStep()

    if (nextStep) {
      this.router.navigateByUrl(nextStep.route)
    }
  }

  getCurrentStep = (steps: Step[]) => steps.find(step => step.active)

  completeCurrentStep = () => {
    const currentStep = this.getCurrentStep(this.userSteps)

    currentStep.completed = true

    return this.userSteps[currentStep.index + 1]
  }

  setStep = (name: StepsEnum) => {
    const currentStep = this.getCurrentStep(this.userSteps)

    if (currentStep) {
      currentStep.active = false
    }

    const step = this.userSteps[name]

    if (step) {
      step.active = true
      step.enabled = true
    }
  }

  getFormData = (formType: FormTypes) => this.formValues[formType]

  public loadUser = async (userId: string) => {
    await this.userService.getUserById(userId).then(
      user => this.storeUser(user),
      () =>
        this.infoBarService.show(
          'Erro ao buscar as informações do usuário. Consulte o suporte se o problema persistir',
          'danger',
        ),
    )
  }

  public clear = () => {
    this.userSteps = [...administrativeUserSteps]
    this.formValues = {}
    this.storeMode = StoreMode.Create
  }

  private storeUser = (user: UserResponse) => {
    this.storeMode = StoreMode.Update
    this.userId = user.id
    this.setUserSteps(user.accessProfile)

    this.storeFormData(
      {
        name: user.name,
        username: user.username,
        accessProfile: user.accessProfile.toString(),
        active: user.active,
      },
      'Access',
    )

    if (user.accessProfile.toString() === AccessProfile.Operational.toString())
      this.storeFormData(
        {
          attendanceTeamsIds: user.operationalProfileInformation?.userAttendanceTeams.map(
            team => team.attendanceTeamInternalId,
          ),
          maxParallelAttendances:
            user.operationalProfileInformation?.maxParallelAttendances,
          workplaceId: user.operationalProfileInformation?.workplace?.id,
          externalLogin: user.operationalProfileInformation?.externalLogin,
          sellerCode: user.operationalProfileInformation?.sellerCode,
          immediateSuperiorUserId:
            user.operationalProfileInformation.immediateSuperiorUserId,
        },
        'ChatsAndTeams',
      )
  }
}
