import { Component } from '@angular/core'
import { InfoBarService } from '@app/info-bar/info-bar.service'
import { BusinessHoursStore } from './business-hours.store'

@Component({
  selector: 'app-business-hours',
  providers: [BusinessHoursStore],
  templateUrl: './business-hours.component.html',
  styleUrls: ['./business-hours.component.scss'],
})
export class BusinessHoursComponent {
  constructor(
    public store: BusinessHoursStore,
    public infoBarService: InfoBarService,
  ) {}
}
