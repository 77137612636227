import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { AttendanceService } from '@app/chat/attendance/attendance.service'
import { AttendanceRatingStatus } from '@app/chat/attendance/rating'

@Component({
  selector: 'app-rating-attendance-page',
  templateUrl: './rating-attendance-page.component.html',
  styleUrls: ['./rating-attendance-page.component.scss'],
})
export class RatingAttendancePageComponent implements OnInit {
  attendanceId: string
  AttendanceRatingStatus = AttendanceRatingStatus

  ratingStatus: string
  title: string
  subtitle: string

  constructor(
    private route: ActivatedRoute,
    private attendanceService: AttendanceService,
  ) {}

  ngOnInit(): void {
    this.getAttendanceId()
    this.validateAttendanceRating()
  }

  validateAttendanceRating = () =>
    this.attendanceService
      .validateAttendanceRating(this.attendanceId)
      .subscribe(status => this.showAlertMessage(status))

  getAttendanceId = () => {
    this.route.params.subscribe(params => {
      this.attendanceId = params.attendanceId
    })
  }

  showAlertMessage = event =>
    event === AttendanceRatingStatus.Answered
      ? this.setAttendanceRatingStatusToAnswered()
      : event === AttendanceRatingStatus.Expired
      ? this.setAttendanceRatingStatusToExpired()
      : (this.ratingStatus = AttendanceRatingStatus.Pending)

  private setAttendanceRatingStatusToAnswered = () => {
    this.ratingStatus = AttendanceRatingStatus.Answered
    this.title = 'Avaliação realizada com sucesso!'
    this.subtitle = 'Obrigado por contribuir para nossa melhora contínua'
  }

  private setAttendanceRatingStatusToExpired = () => {
    this.ratingStatus = AttendanceRatingStatus.Expired
    this.title = 'Avaliação expirada'
    this.subtitle = 'Não é mais possível realizar a avaliação deste atendimento'
  }
}
