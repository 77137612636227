import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Authentication } from '@app/components/authentication/authentication'
import { environment } from '@env/environment'
import { Observable } from 'rxjs'
import { OperatorConnectorType } from '@app/components/authentication/login/login'

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private url: string

  constructor(private http: HttpClient) {
    this.url = `${environment.urlBlaMessageManagementApi}/authentication`
  }

  public loginOrLogout = (
    userId: string,
    loginStatus: Authentication,
  ): Observable<any> => {
    return this.http.post<any>(`${this.url}/LoginOrLogout/${userId}`, {
      loginStatus,
      connectorType: OperatorConnectorType.WebchatConnector,
    })
  }
}
