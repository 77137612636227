import { Pipe, PipeTransform } from '@angular/core'

import * as anchorme from 'anchorme'

@Pipe({
  name: 'anchorme',
})
export class AnchormePipe implements PipeTransform {
  transform(str: string): string {
    return str
      ? anchorme.default({
          input: str,
          options: {
            attributes: {
              target: '_blank',
            },
          },
        })
      : str
  }
}
