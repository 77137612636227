<div class="container" *ngIf="showErrorMessage">
  <div id="logo">
    <img src="../../assets/images/fast-shop-bla.svg" />
  </div>
  <div id="project-description">
    <span>FastShop Bla</span>
  </div>

  <div id="message-container">
    <p>
      Houve um problema no processo de autenticação do seu usuário. Por favor
      contate seu administrador.
    </p>
  </div>
</div>
