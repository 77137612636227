import { Component, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'app-star',
  templateUrl: './star.component.html',
  styleUrls: ['./star.component.scss'],
})
export class StarComponent {
  @Input() disabled = false
  @Output() handleActive = new EventEmitter()

  @Input() active: boolean
  @Input() attendanceId: string

  toggleStar = () => {
    this.active = !this.active
    this.handleActive.emit({
      active: this.active,
      attendanceId: this.attendanceId,
    })
  }
}
