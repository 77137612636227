import { ValidatorFn, AbstractControl } from '@angular/forms'

export const conditionalValidator = (
  predicate: () => boolean,
  validator: ValidatorFn,
) => {
  return (formControl: AbstractControl) => {
    if (!formControl.parent) {
      return null
    }

    return predicate() ? validator(formControl) : null
  }
}
