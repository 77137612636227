<div class="hide-filters" (click)="showFilters = !showFilters">
  <p>{{ showFilters ? 'ocultar filtros' : 'exibir filtros' }}</p>
  <span
    class="arrow"
    [ngClass]="{ 'arrow-top': showFilters, 'arrow-down': !showFilters }"
  ></span>
</div>

<div *ngIf="showFilters" class="monitoring-attendances-filter">
  <div class="top-row">
    <div class="filter">
      <label for="startDate" class="filter-label">Período de</label>
      <dp-date-picker
        #startDatePicker
        theme="dp-material"
        placeholder="dd/mm/aaaa"
        [config]="datePickerConfig"
        [(ngModel)]="startDateResult"
        id="start-date-picker"
      ></dp-date-picker>
      <i
        class="input-icon input-icon--calendar input-icon--with-label"
        (click)="openDatePicker(startDatePicker)"
      ></i>
    </div>

    <div class="time-filter filter">
      <input
        [(ngModel)]="startTimeResult"
        class="form-control"
        id="startTime"
        placeholder="hh:mm"
        mask="00:00"
      />
      <i class="input-icon input-icon--clock"></i>
    </div>

    <div class="split"></div>

    <div class="filter">
      <label for="endDate" class="filter-label">Até</label>
      <dp-date-picker
        #endDatePicker
        [(ngModel)]="endDateResult"
        theme="dp-material"
        placeholder="dd/mm/aaaa"
        [config]="datePickerConfig"
        id="end-date-picker"
      ></dp-date-picker>
      <i
        class="input-icon input-icon--calendar input-icon--with-label"
        (click)="openDatePicker(endDatePicker)"
      ></i>
    </div>

    <div class="time-filter filter">
      <input
        [(ngModel)]="endTimeResult"
        class="form-control"
        id="endTime"
        placeholder="hh:mm"
        mask="00:00"
      />
      <i class="input-icon input-icon--clock"></i>
    </div>

    <div class="split"></div>

    <div class="type-filter filter">
      <app-select
        [props]="closingTypeField"
        [selectItems]="closingTypes"
        (valuesSelected)="getFieldValue($event)"
        id="closing-type-select"
      ></app-select>
    </div>

    <div class="reason-filter filter">
      <app-select
        [props]="closingReasonsField"
        [selectItems]="closingReasons"
        (valuesSelected)="getFieldValue($event)"
        id="closing-reasons-select"
      ></app-select>
    </div>
  </div>

  <div class="bottom-row">
    <div class="queue-filter filter">
      <app-select
        [props]="queueField"
        [selectItems]="queues"
        (valuesSelected)="getFieldValue($event)"
        id="queue-select"
      ></app-select>
    </div>

    <div class="seller-filter filter">
      <app-select
        #sellersSelect
        [props]="sellerField"
        [selectItems]="sellers"
        (valuesSelected)="getFieldValue($event)"
        id="operational-user-select"
      ></app-select>
    </div>

    <div class="workplace-filter filter">
      <app-input-dropdown
        [props]="workplaceField"
        [dropdownItens]="workplaces"
        (valuesSelected)="getFieldValue($event)"
        id="workplace-select"
      ></app-input-dropdown>
    </div>

    <div class="split"></div>

    <div class="phone-number-filter filter">
      <label for="phoneNumber" class="filter-label">Telefone</label>
      <input
        appPhoneNumber
        name="phoneNumber"
        autofocus
        type="tel"
        class="form-control"
        id="phoneNumber"
        [(ngModel)]="phoneNumberResult"
        placeholder="Com DDD"
        maxlength="15"
      />
    </div>
  </div>

  <div class="seller-rating-row">
    <div class="rating-filter filter">
      <app-input-dropdown
        [props]="ratingField"
        [dropdownItens]="ratings"
        (valuesSelected)="getFieldValue($event)"
        id="seller-rating-select"
      ></app-input-dropdown>
    </div>

    <div class="rating-reason-filter filter">
      <app-input-dropdown
        [props]="ratingReasonField"
        [dropdownItens]="ratingReasons"
        (valuesSelected)="getFieldValue($event)"
        id="rating-reason-select"
      ></app-input-dropdown>
    </div>
  </div>
</div>

<button
  *ngIf="showFilters"
  [disabled]="allFiltersAreNull()"
  class="bla-btn"
  [ngClass]="allFiltersAreNull() ? 'bla-btn--disabled' : 'bla-btn--primary'"
  (click)="searchAttendances()"
  id="search-attendances-button"
>
  Pesquisar
</button>
