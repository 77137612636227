import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @Output() searchEvent = new EventEmitter()
  @Input() props: {
    title?: string
    placeholder?: string
    buttonText?: string
  }
  searchCriteria: string

  constructor() {}

  ngOnInit(): void {
    this.initializeInputs()
  }

  public search() {
    if (this.searchCriteria) {
      this.searchEvent.emit(this.searchCriteria)
    }
  }

  private initializeInputs() {
    if (!this.props) this.props = {}

    this.props.title = this.props.title ? this.props.title : 'Buscar'

    this.props.placeholder = this.props.placeholder
      ? this.props.placeholder
      : 'Digite o valor para buscar'

    this.props.buttonText = this.props.buttonText
      ? this.props.buttonText
      : 'Procurar'
  }
}
