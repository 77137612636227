import { Component } from '@angular/core'
import { InfoBarService } from '@app/info-bar/info-bar.service'
import {
  AccessProfile,
  AttendantStatus,
  ChangeOperationalUserStatusRequest,
} from '@app/register/user/user.model'
import { UserService } from '@app/register/user/user.service'
import { LocalStorageService } from '@app/tools/local-storage.service'

import { LoggedUser } from '../authentication/logged-user.model'
import { DropDownItem } from '../drop-down/drop-down-item'
import { AttendantStatusService } from './attendant-status.service'
import { OperatorConnectorType } from '../authentication/login/login'

@Component({
  selector: 'app-attendant-status',
  templateUrl: './attendant-status.component.html',
  styleUrls: ['./attendant-status.component.scss'],
})
export class AttendantStatusComponent {
  AttendantStatus = AttendantStatus
  menuIsOpen = false
  loggedUser: LoggedUser
  workPauseReasons: DropDownItem[]
  status = AttendantStatus.Unavailable
  attendancesLength: number
  firstTimeLoaded = false
  AccessProfile = AccessProfile

  constructor(
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private infoBarService: InfoBarService,
    private attendantStatusService: AttendantStatusService,
  ) {
    this.loggedUser =
      this.localStorageService.getLoggedUser() || new LoggedUser()

    this.attendantStatusService
      .updateNoAttendanceStatus()
      .subscribe((length: number) => (this.attendancesLength = length))

    this.attendantStatusService
      .updateAttendantStatus()
      .subscribe((status: AttendantStatus) => {
        if (this.loggedUser) {
          this.loggedUser.attendantStatus = status
          this.localStorageService.saveLoggedUser(this.loggedUser)
        }
      })
  }

  updateStatus = (status: AttendantStatus) =>
    this.attendantStatusService.updateAttendantStatus().emit(status)

  changeStatus = (status: AttendantStatus) => {
    this.menuIsOpen = false

    this.updateStatus(status)

    if (status === AttendantStatus.Away) {
      this.menuIsOpen = true

      this.attendancesLength > 0
        ? (status = AttendantStatus.Awaiting)
        : (status = AttendantStatus.Away)

      if (!this.workPauseReasons) {
        this.loadWorkPauseReasons()
      }

      if (this.loggedUser) this.loggedUser.attendantStatus = status
      return
    }

    if (this.loggedUser?.userInfo?.id) {
      const changeOperationalUserStatusRequest: ChangeOperationalUserStatusRequest = {
        status,
        connectorType: OperatorConnectorType.WebchatConnector,
      }

      this.userService
        .changeOperationalUserStatus(
          this.loggedUser.userInfo.id,
          changeOperationalUserStatusRequest,
        )
        .subscribe(
          () => {
            this.loggedUser.attendantStatus = status
            this.localStorageService.saveLoggedUser(this.loggedUser)
          },
          () => {
            this.infoBarService.show(
              'Ocorreu um erro ao mudar de status. Se o problema persistir, contate o administrador.',
              'danger',
            )
          },
        )
    }
  }

  updateMenuStatus = $event => {
    this.menuIsOpen = false
  }

  toggleMenu = () => (this.menuIsOpen = !this.menuIsOpen)

  closeMenu = () =>
    this.loggedUser?.attendantStatus?.toString() ===
      AttendantStatus.Away.toString() ||
    this.loggedUser?.attendantStatus?.toString() ===
      AttendantStatus.Awaiting.toString()
      ? false
      : (this.menuIsOpen = false)

  get selectedClasses() {
    return {
      'menu--open': this.menuIsOpen,
      'selected-status--available':
        this.loggedUser?.attendantStatus === AttendantStatus.Available,
      'selected-status--unavailable':
        this.loggedUser?.attendantStatus === AttendantStatus.Unavailable,
      'selected-status--away':
        this.loggedUser?.attendantStatus === AttendantStatus.Away ||
        this.loggedUser?.attendantStatus === AttendantStatus.Awaiting,
    }
  }

  get statusIconClasses() {
    return {
      'icon-check-filled-round':
        this.loggedUser?.attendantStatus === AttendantStatus.Available,
      'icon-cross-filled-round':
        this.loggedUser?.attendantStatus === AttendantStatus.Unavailable,
      'icon-clock-filled-round':
        this.loggedUser?.attendantStatus === AttendantStatus.Away ||
        this.loggedUser?.attendantStatus === AttendantStatus.Awaiting,
    }
  }

  get statusLabel() {
    switch (this.loggedUser?.attendantStatus) {
      case AttendantStatus.Available:
        return 'Disponível'
      case AttendantStatus.Away:
        return 'Ausente'
      case AttendantStatus.Awaiting:
        return 'Aguardando'
      case AttendantStatus.Unavailable:
      default:
        return 'Indisponível'
    }
  }

  private loadWorkPauseReasons = () => {
    this.userService.getWorkPauseReasons().subscribe(
      reasons =>
        (this.workPauseReasons = reasons.map(reason => ({
          text: reason.name,
          value: reason.id,
        }))),
      () => {
        this.infoBarService.show(
          'Falha ao carregar os motivos de encerramento. Consulte o suporte se o problema persistir',
          'danger',
        )
      },
    )
  }
}
