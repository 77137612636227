import { Sender } from './sender'
import { Injectable } from '@angular/core'
import { Message } from '@app/components/messsage-bubble/message'
import { SignalRService } from '@app/tools/signalr.service'
import { environment } from '@env/environment'
import { Subject } from 'rxjs'
import { Attendance } from './attendance/attendance'

@Injectable()
export class ChatService {
  private URL = `${environment.webChatConnector}webchat`

  messages$: Subject<Message> = new Subject<Message>()
  attendances$: Subject<Attendance> = new Subject<Attendance>()

  constructor(private signalRConnection: SignalRService) {}

  public startConnection = async (userId: string) => {
    try {
      await this.signalRConnection.connect(
        `${this.URL}?operationalUserIdentification=${userId}`,
      )

      this.signalRConnection.listen('ReceiveMessageAsync', (message: any) => {
        this.messages$.next({
          ...message,
          senderType: Sender.Customer,
        })
      })

      this.signalRConnection.listen(
        'ReceiveAttendanceAsync',
        (attendance: Attendance) => {
          this.attendances$.next(attendance)
        },
      )
    } catch (error) {
      console.error('SignalR Error: ', error)
    }
  }

  public stopConnection = () => this.signalRConnection.stop()

  public sendMessage = (message: Message) => {
    this.signalRConnection.invoke('ReceiveMessage', message)
  }
}
