import { Component, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { EditQueueConfirmationModalComponent } from '@app/components/edit-queue-confirmation-modal/edit-queue-confirmation-modal.component'
import { conditionalValidator } from '@app/custom-validators/conditional-validator'
import { StepsEnum } from '@app/stepper/steps/queue.steps'
import { FormControlValidator } from '@app/tools/form-control-validator'
import { Observable, of } from 'rxjs'

import { QueueStore } from '../../queue.store'

@Component({
  selector: 'app-queue-attachments',
  templateUrl: './queue-attachments.component.html',
  styleUrls: ['./queue-attachments.component.scss'],
})
export class QueueAttachmentsComponent implements OnInit {
  queueAttachmentForm: FormGroup
  fileTypes: string[] = [
    'txt',
    'jpeg',
    'jpg',
    'png',
    'webp',
    'pdf',
    'xls',
    'csv',
  ]
  @ViewChild(EditQueueConfirmationModalComponent)
  confirmationModal: EditQueueConfirmationModalComponent
  StepsEnum = StepsEnum

  constructor(
    private formBuilder: FormBuilder,
    public formControlValidator: FormControlValidator,
    public store: QueueStore,
  ) {}

  ngOnInit() {
    this.store.setStep(StepsEnum.QueueAttachments)
    this.initializeForm()
  }

  public requestAutocompleteFileTypes = (
    text: string,
  ): Observable<string[]> => {
    return of(this.fileTypes)
  }

  initializeForm = () => {
    this.queueAttachmentForm = this.formBuilder.group({
      allowClient: [false],
      allowAttendant: [false],
      fileExtensions: [
        null,
        [
          conditionalValidator(
            () => this.allowClient || this.allowAttendant,
            Validators.required,
          ),
        ],
      ],
      maximumFileSize: new FormControl(null, [
        Validators.min(1),
        Validators.max(10),
      ]),
    })

    this.subscribeToFieldsChanges()

    const formData = this.store.getFormData('Attachment')

    if (formData) {
      this.queueAttachmentForm.setValue({
        ...formData,
        fileExtensions: formData.fileExtensions,
      })
    }
  }

  get allowClient() {
    return this.queueAttachmentForm.get('allowClient').value
  }

  get allowAttendant() {
    return this.queueAttachmentForm.get('allowAttendant').value
  }

  submit = () => {
    if (this.queueAttachmentForm.valid) {
      this.store.storeFormData(
        { ...this.queueAttachmentForm.value },
        'Attachment',
      )
      if (this.store.isUpdateMode()) this.confirmationModal.openModal()
      else this.store.save()
    }
  }

  subscribeToFieldsChanges = () => {
    this.queueAttachmentForm
      .get('allowClient')
      .valueChanges.subscribe(value => {
        this.queueAttachmentForm.get('fileExtensions').markAsUntouched()
        this.queueAttachmentForm.get('fileExtensions').updateValueAndValidity()
        this.queueAttachmentForm.get('maximumFileSize').markAsUntouched()
        this.queueAttachmentForm.get('maximumFileSize').updateValueAndValidity()
      })

    this.queueAttachmentForm
      .get('allowAttendant')
      .valueChanges.subscribe(value => {
        this.queueAttachmentForm.get('fileExtensions').markAsUntouched()
        this.queueAttachmentForm.get('fileExtensions').updateValueAndValidity()
        this.queueAttachmentForm.get('maximumFileSize').markAsUntouched()
        this.queueAttachmentForm.get('maximumFileSize').updateValueAndValidity()
      })
  }

  getAttachmentsSettingRequest = () => {
    const attachmentsSettings = {
      ...this.queueAttachmentForm.value,
    }
    return { attachmentsSettings }
  }

  cancel = () => this.store.clear()
}
