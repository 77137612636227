import { QueueNameAndId } from './../../../queue/queue.model'
import { InfoBarService } from './../../../../info-bar/info-bar.service'
import { AfterViewInit, Component, OnInit } from '@angular/core'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { DropDownItem } from '@app/components/drop-down/drop-down-item'
import { QueueService } from '@app/register/queue/queue.service'
import { StepsEnum } from '@app/stepper/steps/template-message.steps'
import { HtmlTools } from '@app/tools/html-tools'
import { TemplateMessageStore } from '../../template-message.store'
import { ColumnMode } from '@swimlane/ngx-datatable'

@Component({
  selector: 'app-template-messages-queue',
  templateUrl: './template-messages-queue.component.html',
  styleUrls: ['./template-messages-queue.component.scss'],
})
export class TemplateMessagesQueueComponent implements OnInit, AfterViewInit {
  templateMessageForm: FormGroup
  queuesDropDownItems: DropDownItem[]
  selectedOption: DropDownItem
  nextButton: HTMLButtonElement

  queuesSelecteds: QueueNameAndId[] = []
  ColumnMode = ColumnMode

  constructor(
    public store: TemplateMessageStore,
    private formBuilder: FormBuilder,
    private htmlTools: HtmlTools,
    private queueService: QueueService,
    private infoBarService: InfoBarService,
  ) {}

  ngAfterViewInit(): void {
    this.nextButton = document.querySelector('.next-button')
  }

  ngOnInit(): void {
    this.initializeDropDownItems()
    this.store.setStep(StepsEnum.TemplateMessageQueue)
    this.initializeForm()
  }

  submit = () => {
    if (this.store.isCreateMode() && this.templateMessageForm.valid) {
      this.htmlTools.addAttributeToElement(this.nextButton, 'disabled', '')

      this.storeFormDataAndGoToNextStep()
    } else if (this.store.isViewMode()) {
      this.store.nextStep()
    }
  }

  initializeForm = () => {
    this.templateMessageForm = this.formBuilder.group({
      queueIds: new FormArray([], Validators.required),
    })

    const formData = this.store.getFormData('Queue')

    if (formData && formData.queueIds) {
      this.templateMessageForm.patchValue(formData)
      this.templateMessageForm.setControl(
        'queueIds',
        this.formBuilder.array(formData?.queueIds || []),
      )
    }
  }

  cancel = () => this.store.clear()

  storeFormDataAndGoToNextStep = () => {
    this.store.storeFormData(this.templateMessageForm.value, 'Queue')

    this.store.nextStep()
    this.htmlTools.removeAttributeFromElement(this.nextButton, 'disabled')
  }

  initializeDropDownItems() {
    this.queueService.getQueueNamesAndIds().subscribe(
      infos => {
        this.queuesDropDownItems = infos.map(queueInfo => ({
          text: queueInfo.name,
          value: queueInfo.id,
        }))
      },
      () => {
        this.infoBarService.show(
          'Falha ao carregar Filas. Consulte o suporte se o problema persistir',
          'danger',
        )
      },
    )
  }
}
