import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { conditionalValidator } from '@app/custom-validators/conditional-validator'
import { QueueStore } from '@app/register/queue/queue.store'
import { StepsEnum } from '@app/stepper/steps/queue.steps'
import { FormControlValidator } from '@app/tools/form-control-validator'

@Component({
  selector: 'app-queue-idleness',
  templateUrl: './queue-idleness.component.html',
  styleUrls: ['./queue-idleness.component.scss'],
})
export class QueueIdlenessComponent implements OnInit {
  idlenessForm: FormGroup

  @ViewChild('save', { static: true }) saveButton: ElementRef

  constructor(
    public formControlValidator: FormControlValidator,
    public store: QueueStore,
    private formbuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.initializeForm()
  }

  initializeForm = () => {
    this.idlenessForm = this.formbuilder.group({
      controlIdleness: [false],
      idlenessTime: [
        null,
        [
          Validators.min(0),
          Validators.max(999),
          conditionalValidator(() => this.controlIdleness, Validators.required),
        ],
      ],
      attempts: [
        null,
        [
          Validators.min(0),
          Validators.max(99),
          conditionalValidator(() => this.controlIdleness, Validators.required),
        ],
      ],
      closeIdleChats: [null],
      idlenessMessage: [null, Validators.maxLength(500)],
      idleChatsClosingMessage: [null, Validators.maxLength(500)],
    })

    this.idlenessForm.get('controlIdleness').valueChanges.subscribe(value => {
      this.idlenessForm.get('idlenessTime').markAsUntouched()
      this.idlenessForm.get('idlenessTime').updateValueAndValidity()
      this.idlenessForm.get('attempts').markAsUntouched()
      this.idlenessForm.get('attempts').updateValueAndValidity()

      if (!value) {
        this.idlenessForm.get('idlenessTime').reset()
        this.idlenessForm.get('attempts').reset()
        this.idlenessForm.get('closeIdleChats').reset()
        this.idlenessForm.get('idlenessMessage').reset()
        this.idlenessForm.get('idleChatsClosingMessage').reset()
      }
    })

    this.idlenessForm.get('closeIdleChats').valueChanges.subscribe(value => {
      if (!value) {
        this.idlenessForm.get('idleChatsClosingMessage').reset()
      }
    })

    const formData = this.store.getFormData('Idleness')

    if (formData) {
      this.idlenessForm.setValue(formData)
    }
  }

  submit = () => {
    if (this.idlenessForm.valid) {
      this.store.storeFormData(this.idlenessForm.value, 'Idleness')
      this.store.nextStep()
    }
  }

  get requiredOrOptionalLabel() {
    return this.controlIdleness ? 'obrigatório' : 'opcional'
  }

  get controlIdleness() {
    return this.idlenessForm.get('controlIdleness').value
  }

  get closeIdleChats() {
    return this.idlenessForm.get('closeIdleChats').value
  }

  cancel = () => this.store.clear()
}
