import { Subscription } from 'rxjs'
import { AttendanceService } from '@app/chat/attendance/attendance.service'
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { InfoBarService } from '@app/info-bar/info-bar.service'
import { HtmlTools } from '@app/tools/html-tools'
import { ActiveContact } from '../attendance/active-contact'
import { LocalStorageService } from '@app/tools/local-storage.service'

@Component({
  selector: 'app-new-customer-modal',
  templateUrl: './new-customer-modal.component.html',
  styleUrls: ['./new-customer-modal.component.scss'],
})
export class NewCustomerModalComponent
  implements OnInit, AfterViewInit, OnDestroy {
  newCustomerForm: FormGroup
  saveNewCustomerButton: HTMLElement
  sub: Subscription

  constructor(
    public element: ElementRef,
    private formBuilder: FormBuilder,
    private htmlTools: HtmlTools,
    private infoBarService: InfoBarService,
    private attendanceService: AttendanceService,
    private localStorageService: LocalStorageService,
  ) {
    this.newCustomerForm = this.formBuilder.group({
      customerName: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(35),
        ],
      ],
      customerPhoneNumber: [
        '',
        [Validators.required, Validators.pattern(/^\(\d{2}\) \d{4,5}-\d{4}$/)],
      ],
    })
  }

  ngOnInit(): void {
    this.saveNewCustomerButton = document.getElementById(
      'save-new-customer-button',
    )
  }

  ngOnDestroy = (): void => this.sub.unsubscribe()

  ngAfterViewInit() {
    this.element.nativeElement.addEventListener(
      'click',
      this.clickOutside,
      true,
    )
  }

  submit = () => {
    if (this.newCustomerForm.valid) {
      this.htmlTools.addAttributeToElement(
        this.saveNewCustomerButton,
        'disabled',
        '',
      )

      const activeContact: ActiveContact = this.newCustomerForm.value

      activeContact.operationalUserId = this.getLoggedOperationalUserId()

      this.sub = this.attendanceService
        .createActiveContact(activeContact)
        .subscribe(
          () =>
            this.infoBarService.show(
              'Cliente cadastrado com sucesso!',
              'success',
            ),
          response => {
            this.infoBarService.show(response.error, 'danger')
          },
        )
        .add(() => {
          this.close(),
            this.htmlTools.removeAttributeFromElement(
              this.saveNewCustomerButton,
              'disabled',
            )
        })
    }
  }

  clickOutside = (event: any) => {
    const isOutside = event.target.classList.contains('bla-modal__background')
    if (isOutside) this.close()
  }

  close = () => {
    this.htmlTools.hideElement(this.element.nativeElement)
    this.newCustomerForm.reset()
  }

  getLoggedOperationalUserId = () =>
    this.localStorageService.getLoggedUser()?.userInfo?.id
}
