import { ClosingType } from './closing-type'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { DropDownItem } from '@app/components/drop-down/drop-down-item'
import { conditionalValidator } from '@app/custom-validators/conditional-validator'
import { InfoBarService } from '@app/info-bar/info-bar.service'
import { HtmlTools } from '@app/tools/html-tools'
import { Attendance } from '../attendance/attendance'
import { AttendanceService } from '../attendance/attendance.service'
import { DatePickerComponent } from 'ng2-date-picker'
import * as moment from 'moment'
import { Subscription } from 'rxjs'
import { LocalStorageService } from '@app/tools/local-storage.service'
import { AccessProfile } from '@app/register/user/user.model'

@Component({
  selector: 'app-end-attendance-modal',
  templateUrl: './end-attendance-modal.component.html',
  styleUrls: ['./end-attendance-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EndAttendanceModalComponent implements OnInit, OnDestroy {
  @Input() currentAttendance: Attendance
  @Output() closeAttendance = new EventEmitter()
  @Output() closeMobileMenu = new EventEmitter()

  @ViewChild('returnDatePicker') returnDatePicker: DatePickerComponent

  subscriptionEndAttendanceForm: Subscription
  subscriptionAttendanceClosingTypes: Subscription
  subscriptionAttendanceClosingReasons: Subscription
  subscriptionCloseAttendance: Subscription
  subscriptionAttendanceForm: Subscription

  endAttendanceForm: FormGroup
  closingTypesItems: DropDownItem[]
  closingReasonsItems: DropDownItem[]
  periodItems: DropDownItem[] = [
    { text: 'Manhã', value: 0 },
    { text: 'Tarde', value: 1 },
    { text: 'Noite', value: 2 },
  ]
  hoursPeriodItems: DropDownItem[]

  modal: HTMLElement
  endAttendanceButton: HTMLElement

  returnPeriod = {
    labelTitle: 'Período',
    placeholder: 'Manhã',
    multipleSelect: false,
    searchable: false,
  }

  minReturnDate = moment()
  maxReturnDate = this.minReturnDate.clone().add(3, 'M')

  hoursPerPeriod = [
    ['9:00', '9:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30'],
    [
      '13:00',
      '13:30',
      '14:00',
      '14:30',
      '15:00',
      '15:30',
      '16:00',
      '16:30',
      '17:00',
      '17:30',
    ],
    ['18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00'],
  ]

  datePickerConfig = {
    locale: 'pt-br',
    placeholder: 'dd/mm/aaaa',
    format: 'DD/MM/YYYY',
    disableKeypress: false,
    monthFormat: 'MMMM',
    min: this.minReturnDate.format('DD/MM/YYYY'),
    max: this.maxReturnDate.format('DD/MM/YYYY'),
    weekDayFormatter: (weekDay: number) => {
      if (weekDay === 0) return 'Do'
      if (weekDay === 1) return 'Se'
      if (weekDay === 2) return 'Te'
      if (weekDay === 3) return 'Qa'
      if (weekDay === 4) return 'Qi'
      if (weekDay === 5) return 'Se'
      if (weekDay === 6) return 'Sa'
    },
  }

  constructor(
    private formBuilder: FormBuilder,
    private attendanceService: AttendanceService,
    private infoBarService: InfoBarService,
    private htmlTools: HtmlTools,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit(): void {
    this.modal = document.getElementById('end-attendance-modal')
    this.endAttendanceButton = document.getElementById('end-attendance-button')

    this.endAttendanceForm = this.formBuilder.group({
      endAttendanceTypeId: ['', Validators.required],
      endAttendanceReasonId: [
        '',
        [conditionalValidator(this.isStandardClosingType, Validators.required)],
      ],
      returnDatePicker: [
        '',
        [
          conditionalValidator(
            this.isScheduledClosingType,
            Validators.required,
          ),
        ],
      ],
      returnPeriod: [
        0,
        [
          conditionalValidator(
            this.isScheduledClosingType,
            Validators.required,
          ),
        ],
      ],
      returnHour: [
        '',
        [
          conditionalValidator(
            this.isScheduledClosingType,
            Validators.required,
          ),
        ],
      ],
    })

    this.updateForm()

    this.loadClosingTypes()
    this.loadClosingReasons()
    this.initializePeriodAndSubscribeChanges()
  }

  ngOnDestroy(): void {
    this.subscriptionEndAttendanceForm?.unsubscribe()
    this.subscriptionAttendanceClosingTypes?.unsubscribe()
    this.subscriptionAttendanceClosingReasons?.unsubscribe()
    this.subscriptionCloseAttendance?.unsubscribe()
    this.subscriptionAttendanceForm?.unsubscribe()
  }

  private updateForm = () => {
    this.subscriptionEndAttendanceForm = this.endAttendanceForm
      .get('endAttendanceTypeId')
      .valueChanges.subscribe(value => {
        this.endAttendanceForm.get('endAttendanceReasonId').markAsUntouched()
        this.endAttendanceForm
          .get('endAttendanceReasonId')
          .updateValueAndValidity()

        if (!this.isStandardClosingType)
          this.endAttendanceForm.get('endAttendanceReasonId').reset()
      })
  }

  loadClosingTypes = () => {
    this.subscriptionAttendanceClosingTypes = this.attendanceService
      .getClosingTypes()
      .subscribe(
        types =>
          (this.closingTypesItems = this.getClosingTypesBasedOnUserProfile(
            types,
          )),
        () => {
          this.infoBarService.show(
            'Falha ao carregar os tipos de encerramento. Consulte o suporte se o problema persistir',
            'danger',
          )
        },
      )
  }

  loadClosingReasons = () => {
    this.subscriptionAttendanceClosingReasons = this.attendanceService
      .getClosingReasons()
      .subscribe(
        reasons =>
          (this.closingReasonsItems = reasons.map(reason => ({
            text: reason.name,
            value: reason.id,
          }))),
        () => {
          this.infoBarService.show(
            'Falha ao carregar os motivos de encerramento. Consulte o suporte se o problema persistir',
            'danger',
          )
        },
      )
  }

  isStandardClosingType = () =>
    this.getAttendanceTypeId() === ClosingType.Standard

  isScheduledClosingType = () =>
    this.getAttendanceTypeId() === ClosingType.Scheduled

  closeModal = () => this.htmlTools.hideElement(this.modal)

  submit = () => {
    if (this.endAttendanceForm.valid) {
      this.htmlTools.addAttributeToElement(
        this.endAttendanceButton,
        'disabled',
        '',
      )

      const endAttendanceCallback = (result: any) => {
        this.closeAttendance.emit(result.operationalUserStatus)

        this.closeModal()

        this.infoBarService.show('Atendimento encerrado com sucesso', 'success')

        this.htmlTools.removeAttributeFromElement(
          this.endAttendanceButton,
          'disabled',
        )

        this.endAttendanceForm.reset()
      }

      const handleAttendanceError = () => {
        this.closeMobileMenu.emit('')

        this.closeModal()

        this.infoBarService.show(
          'Falha ao encerrar atendimento. Consulte o suporte se o problema persistir',
          'danger',
        )

        this.htmlTools.removeAttributeFromElement(
          this.endAttendanceButton,
          'disabled',
        )
      }

      this.subscriptionCloseAttendance = this.attendanceService
        .closeAttendance(
          this.currentAttendance.attendanceId,
          this.currentAttendance.operationalUserId,
          this.endAttendanceForm.value.endAttendanceTypeId,
          this.endAttendanceForm.value?.endAttendanceReasonId,
          this.getReturnDateTime(),
        )
        .subscribe(
          result => endAttendanceCallback(result),
          handleAttendanceError,
        )
    }
  }

  openDatePicker(datePicker: DatePickerComponent) {
    datePicker.api.open()
  }

  initializePeriodAndSubscribeChanges() {
    this.filterHourPerPeriod(0)

    this.subscriptionAttendanceForm = this.endAttendanceForm
      .get('returnPeriod')
      .valueChanges.subscribe(period => {
        if (period) this.filterHourPerPeriod(period)
      })
  }

  filterHourPerPeriod = (period: number) => {
    this.hoursPeriodItems = this.hoursPerPeriod[period].map(hour => ({
      text: hour,
      value: hour,
    }))

    this.endAttendanceForm
      .get('returnHour')
      .setValue(this.hoursPeriodItems[0].value)
  }

  getReturnDateTime() {
    const date = this.returnDatePicker?.inputElementValue
    const time = this.endAttendanceForm.value?.returnHour

    if (date && time)
      return moment(`${date} ${time}`, 'DD/MM/YYYY LT').format('YYYY-MM-DD LT')
  }

  getAttendanceTypeId = () =>
    Number(this.endAttendanceForm.get('endAttendanceTypeId')?.value)

  getClosingTypesBasedOnUserProfile = (types: any) => {
    const loggedUser = this.localStorageService.getLoggedUser().userInfo

    if (loggedUser.accessProfile === AccessProfile.Operational)
      return types.map(type => ({ text: type.name, value: type.id }))

    return types
      .filter(type => +type.id === ClosingType.Standard)
      .map(type => ({ text: type.name, value: type.id }))
  }
}
