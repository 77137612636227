import { Component, OnDestroy, OnInit } from '@angular/core'
import { InfoBarService } from '@app/info-bar/info-bar.service'
import { editModeSteps } from '@app/stepper/steps/queue.steps'

import { QueueStore } from './queue.store'

@Component({
  selector: 'app-queue',
  templateUrl: './queue.component.html',
  styleUrls: ['./queue.component.scss'],
})
export class QueueComponent implements OnInit, OnDestroy {
  constructor(
    public store: QueueStore,
    public infoBarService: InfoBarService,
  ) {}

  title = 'Nova fila de chat'
  updateMode: boolean

  ngOnInit(): void {
    if (this.store.isUpdateMode()) {
      this.title = 'Editar fila de chat'
      this.store.queueSteps = editModeSteps()
    }
  }

  ngOnDestroy() {
    this.store.clear()
  }
}
