<div class="hide" [id]="id">
  <div class="bla-modal">
    <div class="bla-modal__body">
      <h1 class="bla-modal-title">Salvar Alterações</h1>
      <div class="bla-modal-separator"></div>

      <div class="bla-row">
        <div class="bla-modal-text-info">
          <p>Tem certeza que deseja salvar as alterções realizadas?</p>
        </div>
      </div>

      <div class="bla-modal-actions">
        <button
          id="cancel-edit-button"
          type="reset"
          (click)="closeModal()"
          class="bla-btn"
        >
          Cancelar
        </button>
        <button
          id="confirmation-edit--button"
          class="bla-btn bla-btn--primary"
          (click)="submit()"
        >
          Confirmar
        </button>
      </div>
    </div>
  </div>
  <div class="bla-modal__background"></div>
</div>
