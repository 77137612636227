import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core'
import { AttendanceService } from '@app/chat/attendance/attendance.service'
import { TransferAttendanceService } from '@app/chat/transfer-attendance-modal/transfer-attendance.service'
import { InfoBarService } from '@app/info-bar/info-bar.service'
import { QueueInfo, QueueTransferInfo } from '@app/register/queue/queue.model'
import { HtmlTools } from '@app/tools/html-tools'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-transfer-inactive-attendance-modal',
  templateUrl: './transfer-inactive-attendance-modal.component.html',
  styleUrls: ['./transfer-inactive-attendance-modal.component.scss'],
})
export class TransferInactiveAttendanceModalComponent
  implements AfterViewInit, OnDestroy, OnInit {
  transferModalElement: HTMLElement
  subscription: Subscription

  endTransferAttendances = new EventEmitter()

  @Input() attendanceIds: string[]

  @Output() transferInactiveAttendanceSuccess = new EventEmitter<string>()

  dropdownStatus = false

  availableQueues: QueueTransferInfo[]
  availableUsers: []

  selectedQueueInfo: {
    id: string
    name: string
  } = {
    id: undefined,
    name: undefined,
  }

  selectedOperationalUser: {
    id: string
    name: string
  } = {
    id: undefined,
    name: undefined,
  }

  constructor(
    private htmlTools: HtmlTools,
    private attendanceService: AttendanceService,
    private infoBarService: InfoBarService,
    private transferService: TransferAttendanceService,
  ) {}

  ngOnInit(): void {
    this.loadQueuesAvailableForTransfer()
  }

  ngAfterViewInit() {
    this.subscription = this.endTransferAttendances.subscribe(() => {
      this.closeModal()
    })

    this.transferModalElement = document.getElementById(
      'transfer-inactive-attendance-modal',
    )
  }

  noQueueSelected = () => this.selectedQueueInfo.id === undefined

  submit = () => {
    this.subscription = this.attendanceService
      .batchTransferAttendances(
        this.attendanceIds,
        this.selectedQueueInfo.id,
        this.selectedOperationalUser.id,
      )
      .subscribe(
        response => {
          if (response.success) {
            this.showSucessMessage()
            this.transferInactiveAttendanceSuccess.emit()
          } else
            response.successfullyTransferedAttendanceIds.length !== 0
              ? this.showPartialSuccessMessage()
              : this.showErrorMessage()
        },
        () => this.showErrorMessage(),
        () => {
          this.endTransferAttendances.emit()
        },
      )
  }

  closeModal = () => this.htmlTools.hideElement(this.transferModalElement)

  openModal = () => this.htmlTools.showElement(this.transferModalElement)

  showSucessMessage = () =>
    this.infoBarService.show(
      'Os atendimentos selecionados foram transferidos com sucesso.',
      'success',
    )

  showPartialSuccessMessage = () =>
    this.infoBarService.show(
      'Alguns atendimentos não foram transferidos com sucesso. Consulte o suporte se o problema persistir',
      'danger',
    )

  showErrorMessage = () =>
    this.infoBarService.show(
      'Falha ao transferir os atendimentos. Consulte o suporte se o problema persistir',
      'danger',
    )

  ngOnDestroy(): void {
    this.subscription?.unsubscribe()
  }

  selectQueue = (event, { id, name }: QueueInfo) => {
    this.closeOptions(event)

    this.selectedQueueInfo = {
      id,
      name,
    }
    this.getOperationalUsersForSelectedQueue(id)
  }

  getOperationalUsersForSelectedQueue = (queueId: string) => {
    if (this.selectedQueueInfo.id) {
      this.transferService
        .getOperationalUsersByQueue(queueId)
        .subscribe(result => (this.availableUsers = result))
    } else {
      console.error('Não foram encontrados usuários')
    }
  }

  openOptions = event => {
    this.dropdownStatus = true
    const dropdownId = event.target.dataset.dropdownId

    const dropdownItens = document.querySelector(
      `.bla-input-itens[data-dropdown-id="${dropdownId}"]`,
    ) as HTMLElement

    dropdownItens.classList.add('bla-input-itens--open')
    event.target.classList.add('bla-input-dropdown--open')
    dropdownItens.style.zIndex = '1000'
  }

  closeOptions = event => {
    this.dropdownStatus = false
    const dropdownId = event.target.parentNode.dataset.dropdownId

    const dropdownItens = document.querySelector(
      `.bla-input-itens[data-dropdown-id="${dropdownId}"]`,
    ) as HTMLElement

    dropdownItens.classList.remove('bla-input-itens--open')
    event.target.classList.remove('bla-input-itens--open')
  }

  loadQueuesAvailableForTransfer = () => {
    this.transferService
      .getAvailableQueuesForTransfer()
      .subscribe(result => (this.availableQueues = result))
  }

  selectOperationalUser = (event, { id, name }) => {
    this.closeOptions(event)

    this.selectedOperationalUser = {
      id,
      name,
    }
  }
}
