import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'
import { TweenMax, Expo } from 'gsap'
import { LocalStorageService } from '@app/tools/local-storage.service'
import { LoggedUser } from '../authentication/logged-user.model'
import { AccessProfile } from '@app/register/user/user.model'
import { AuthenticationService } from '@app/chat/authentication.service'
import { Authentication } from '../authentication/authentication'
import { InfoBarService } from '@app/info-bar/info-bar.service'
import { Router } from '@angular/router'

@Component({
  selector: 'app-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.scss'],
})
export class MenuMobileComponent implements OnInit {
  @ViewChild('menu') menu: ElementRef
  @ViewChild('content') content: ElementRef
  @ViewChild('wrapper') wrapper: ElementRef

  isMenuOpen = false
  loggedUser: LoggedUser
  accessProfile: typeof AccessProfile = AccessProfile

  constructor(
    public localStorageService: LocalStorageService,
    private authenticationService: AuthenticationService,
    private infoBarService: InfoBarService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.loggedUser = this.localStorageService.getLoggedUser()
  }

  animateMenu = () => {
    this.isMenuOpen ? this.closeMenu() : this.openMenu()
  }

  openMenu = () => {
    if (this.menu && !this.isMenuOpen) {
      this.isMenuOpen = true

      TweenMax.to(this.wrapper.nativeElement, { position: 'relative' })

      TweenMax.fromTo(
        this.content.nativeElement,
        0.5,
        { x: 400 },
        { x: 0, ease: Expo.easeOut },
      )
    }
  }

  closeMenu = () => {
    if (this.menu && this.isMenuOpen) {
      this.isMenuOpen = false

      TweenMax.to(this.wrapper.nativeElement, { position: 'absolute' })

      TweenMax.fromTo(
        this.content.nativeElement,
        0.5,
        { x: 0 },
        { x: 400, ease: Expo.easeOut },
      )
    }
  }

  redirectToSSOLogoutPage = () => (window.location.href = '/api/logout')

  logout = () => {
    this.authenticationService
      .loginOrLogout(this.loggedUser.userInfo.username, Authentication.Logout)
      .subscribe(
        () => {
          this.localStorageService.clearLocalStorage()
          this.redirectToSSOLogoutPage()
        },
        ({ error }) => {
          this.infoBarService.show(error, 'danger')
        },
      )
  }
}
