import { Workplace } from './forms/user-and-workplace/workplace'
import { OperatorConnectorType } from '@app/components/authentication/login/login'

export interface User {
  name: string
  username: string
  accessProfile: AccessProfile
  active: boolean
  immediateSuperiorUserId: string
  maxParallelAttendances: number
  attendanceTeamsIds: string[]
  externalLogin: string
  sellerCode: string
  workplaceId: string
  requestingUserId?: string
}

export enum AccessProfile {
  Operational = 1,
  Administrative = 2,
  Supervisor = 3,
}

export interface UserInfo {
  id: string
  name: string
  username: string
  accessProfile: AccessProfile
  active?: boolean
  directContactLink?: string
}

export interface CheckUsernameResponse {
  exists: boolean
}

export enum AttendantStatus {
  Available = 1,
  Unavailable = 2,
  Awaiting = 3,
  Away = 4,
}

export interface ChangeOperationalUserStatusRequest {
  status: AttendantStatus
  connectorType: OperatorConnectorType
  workPauseReasonId?: string
}

export interface UserResponse {
  id: string
  name: string
  username: string
  accessProfile: AccessProfile
  active: boolean
  online: boolean
  operationalProfileInformation: OperationalProfileResponse
}

export interface OperationalProfileResponse {
  immediateSuperiorUserId: string
  maxParallelAttendances: number
  workplace: Workplace
  externalLogin: string
  sellerCode: string
  userAttendanceTeams: any[]
}
