import { Component, OnInit } from '@angular/core'
import { AuthenticationService } from '@app/chat/authentication.service'
import { InfoBarService } from '@app/info-bar/info-bar.service'
import { AccessProfile } from '@app/register/user/user.model'
import { LocalStorageService } from '@app/tools/local-storage.service'

import { Authentication } from '../authentication'
import { LoggedUser } from '../logged-user.model'

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  loggedUser: LoggedUser
  accessProfile: typeof AccessProfile = AccessProfile

  constructor(
    private authenticationService: AuthenticationService,
    private infoBarService: InfoBarService,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit(): void {
    this.loggedUser = this.localStorageService.getLoggedUser()
  }

  redirectToSSOLogoutPage = () => (window.location.href = '/api/logout')

  logout = () => {
    this.authenticationService
      .loginOrLogout(this.loggedUser.userInfo.username, Authentication.Logout)
      .subscribe(
        () => {
          this.localStorageService.clearLocalStorage()
          this.redirectToSSOLogoutPage()
        },
        ({ error }) => {
          this.infoBarService.show(error, 'danger')
        },
      )
  }
}
