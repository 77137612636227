<div class="container">
  <div class="row">
    <app-title title="Time"></app-title>
  </div>
  <div class="row">
    <button (click)="showModal()" class="bla-btn bla-btn--primary new-team">
      Novo time
    </button>
  </div>
  <app-modal type="default" class="hide" id="team-modal">
    <div class="team-modal__title">Time</div>
    <hr />
    <form [formGroup]="teamForm" (ngSubmit)="submit()" class="team-form">
      <div class="row">
        <div class="form-group form-item col-lg-12">
          <label for="name"
            >Nome do time <small class="info">(obrigatório)</small></label
          >
          <input
            formControlName="name"
            autofocus
            class="form-control"
            id="name"
            #teamName
          />
          <app-character-count
            class="characters-count"
            [charactersLimit]="35"
            [characters]="teamName.value"
            maxlength="36"
          ></app-character-count>
          <div class="input-info">
            <ul
              class="input-errors"
              *ngIf="formControlValidator.isInvalid(teamForm, 'name')"
            >
              <li
                class="input-error"
                *ngIf="
                  formControlValidator.hasError(teamForm, 'name', 'required')
                "
              >
                O time precisa de um nome.
              </li>
              <li
                class="input-error"
                *ngIf="
                  formControlValidator.hasAnyError(
                    teamForm,
                    'name',
                    'maxlength',
                    'minlength'
                  )
                "
              >
                O nome do time deve ter entre 3 e 35 letras.
              </li>
              <li
                class="input-error"
                *ngIf="teamForm.get('name').errors?.serverError"
              >
                {{ teamForm.get('name').errors?.serverError }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row form-check field-label">Receber Transferencia</div>
      <div class="row">
        <div class="form-check form-item">
          <div class="checkbox-container">
            <input
              formControlName="receiveTransfers"
              type="checkbox"
              id="receiveTransfers"
              class="checkbox"
            />
            <label for="receiveTransfers" class="switch">
              <div class="switch-on">Sim</div>
              <div class="switch-off">Não</div>
            </label>
          </div>
        </div>
      </div>
      <div class="row form-check field-label">Status do time</div>
      <div class="row">
        <div class="form-check form-item">
          <div class="checkbox-container">
            <input
              formControlName="active"
              type="checkbox"
              id="active"
              class="checkbox"
            />
            <label for="active" class="switch">
              <div class="switch-on">Sim</div>
              <div class="switch-off">Não</div>
            </label>
          </div>
        </div>
      </div>
      <div class="bla-actions">
        <button type="reset" (click)="closeModal()" class="bla-btn close-modal">
          Cancelar
        </button>
        <button
          type="submit"
          [disabled]="teamForm.invalid"
          class="bla-btn submit-team"
          [ngClass]="
            teamForm.invalid ? 'bla-btn--disabled' : 'bla-btn--primary'
          "
        >
          Salvar
        </button>
      </div>
    </form>
  </app-modal>
</div>
