import { Component, Input } from '@angular/core'
import { InfoCardType, TitleType } from './info-card'

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
})
export class InfoCardComponent {
  @Input() props: {
    value: string
    title: {
      text: string
      type: TitleType
    }
    color: string
    type: InfoCardType
  }

  TitleType = TitleType
  InfoCardType = InfoCardType
}
