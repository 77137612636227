import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core'
import { AttendanceInactiveSearchResult } from '@app/action/models/attendance-inactive-search-result.component'
import { ColumnMode, SortType, SelectionType } from '@swimlane/ngx-datatable'
import { HtmlTools } from '@app/tools/html-tools'

@Component({
  selector: 'app-closing-batch-conversations-result',
  templateUrl: './closing-batch-conversations-result.component.html',
  styleUrls: ['./closing-batch-conversations-result.component.scss'],
})
export class ClosingBatchConversationsResultComponent
  implements OnInit, OnChanges {
  @ViewChild('selectAll', { static: true }) selectAll: TemplateRef<any>
  @ViewChild('selectItem', { static: true }) selectItem: TemplateRef<any>
  @Input() attendancesInactives: AttendanceInactiveSearchResult[]

  SelectionType = SelectionType
  ColumnMode = ColumnMode
  SortType = SortType
  tableColumns = []
  pageSize = 6
  messages = { emptyMessage: 'Nenhum registro foi encontrado' }
  attendancesSelected = []
  closeInactiveAttendanceModal: HTMLElement
  transferInactiveAttendanceModal: HTMLElement

  constructor(private htmlTools: HtmlTools) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.attendancesSelected = []
  }

  get hasValues(): boolean {
    return this.attendancesInactives?.length > 0
  }

  get hasAttendanceSelected(): boolean {
    return this.attendancesSelected?.length > 0
  }

  get attendanceIds(): string[] {
    return this.attendancesSelected?.map(i => i.attendanceId)
  }

  ngOnInit(): void {
    this.closeInactiveAttendanceModal = document.getElementById(
      'close-inactive-attendance-modal',
    )

    this.transferInactiveAttendanceModal = document.getElementById(
      'transfer-inactive-attendance-modal',
    )
  }

  onSelect({ selected }) {
    this.attendancesSelected.splice(0, this.attendancesSelected.length)
    this.attendancesSelected.push(...selected)
  }

  showCloseInactiveAttendanceModal() {
    this.htmlTools.showElement(this.closeInactiveAttendanceModal)
  }

  showTransferInactiveAttendanceModal() {
    this.htmlTools.showElement(this.transferInactiveAttendanceModal)
  }

  removeAttendancesRemovedOrTransferred(attendanceIds: string[]) {
    for (const attendanceId of attendanceIds) {
      this.removeAttendanceSelectedById(attendanceId)
      this.removeAttendanceInactiveById(attendanceId)
    }
  }

  private removeAttendanceSelectedById(attendanceId: string) {
    const indexSelected = this.attendancesSelected.findIndex(
      attendance => attendance.attendanceId === attendanceId,
    )

    if (indexSelected > -1) this.attendancesSelected.splice(indexSelected, 1)

    this.attendancesSelected = [...this.attendancesSelected]
  }

  private removeAttendanceInactiveById(attendanceId: string) {
    const indexInactive = this.attendancesInactives.findIndex(
      attendance => attendance.attendanceId === attendanceId,
    )

    if (indexInactive > -1) this.attendancesInactives.splice(indexInactive, 1)

    this.attendancesInactives = [...this.attendancesInactives]
  }
}
