<section class="queues-info">
  <h2 class="queues-info__title"><span>Fila</span>{{ queue.name }}</h2>
  <div class="queues-info__cards">
    <app-info-card
      id="info-card-time-{{ id }}"
      [props]="{
        value: timeDiff,
        title: { text: 'Máximo de espera', type: TitleType.small },
        color: 'blue',
        type: InfoCardType.default
      }"
    ></app-info-card>
    <app-info-card
      id="info-card-attendances-{{ id }}"
      [props]="{
        value: queue.activeAttendances.toString(),
        title: { text: 'Atendimentos', type: TitleType.big },
        color: 'gray',
        type: InfoCardType.default
      }"
    ></app-info-card>
    <app-info-card
      id="info-card-windows-{{ id }}"
      [props]="{
        value: queue.availableAttendancesWindows.toString(),
        title: { text: 'Janelas disponíveis', type: TitleType.small },
        color: 'gray',
        type: InfoCardType.default
      }"
    ></app-info-card>
    <app-info-card
      id="info-card-queued-{{ id }}"
      [props]="{
        value: queue.queuedAttendances.toString(),
        title: { text: 'Em fila', type: TitleType.big },
        color: 'gray',
        type: InfoCardType.default
      }"
    ></app-info-card>
  </div>
</section>
