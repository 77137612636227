import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { InfoBarService } from '@app/info-bar/info-bar.service'
import { AccessProfile } from '@app/register/user/user.model'
import { HtmlTools } from '@app/tools/html-tools'
import { DeleteImageMessageContext } from './delete-image-message'
import { DeleteImageMessageService } from './delete-image-message.service'

@Component({
  selector: 'app-delete-image-message-modal',
  templateUrl: './delete-image-message-modal.component.html',
  styleUrls: ['./delete-image-message-modal.component.scss'],
})
export class DeleteImageMessageModalComponent implements AfterViewInit {
  modal: HTMLElement
  @Output() imageRemoved = new EventEmitter<DeleteImageMessageContext>()
  @Input() imageMessageContext: DeleteImageMessageContext

  constructor(
    private htmlTools: HtmlTools,
    private service: DeleteImageMessageService,
    private infoBarService: InfoBarService,
  ) {}

  ngAfterViewInit() {
    this.modal = document.querySelector('.delete-image-message-modal')
  }

  closeModal = () => this.htmlTools.hideElement(this.modal)

  removeImage = () => {
    const { messageId, attendanceId } = this.imageMessageContext

    this.service
      .delete(messageId, attendanceId, this.imageMessageContext.accessProfile)
      .subscribe(
        () => {
          this.imageRemoved.emit(this.imageMessageContext)
          this.infoBarService.show('Imagem removida com sucesso.', 'success')
          this.closeModal()
        },
        () => {
          this.infoBarService.show(
            'Falha ao remover imagem, tente novamente mais tarde ou entre em contato com o suporte.',
            'danger',
          )
          this.closeModal()
        },
      )
  }
}
