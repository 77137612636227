import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-rating-attendance-alert-message',
  templateUrl: './rating-attendance-alert-message.component.html',
  styleUrls: ['./rating-attendance-alert-message.component.scss'],
})
export class RatingAttendanceAlertMessageComponent {
  @Input() title: string
  @Input() subtitle: string
}
