<div class="closing-batch-conversations-result" *ngIf="hasValues">
  <div class="bla-row">
    <div class="bla-column">
      <h4>Resultados</h4>
    </div>
    <div class="bla-column btn-actions" *ngIf="hasAttendanceSelected">
      <button
        class="bla-btn bla-btn--primary btn-transfer"
        (click)="showTransferInactiveAttendanceModal()"
        id="transfer-button"
      >
        Transferir
      </button>

      <button
        class="bla-btn bla-btn--danger"
        (click)="showCloseInactiveAttendanceModal()"
        id="closing-button"
      >
        Encerrar
      </button>
    </div>
  </div>

  <ngx-datatable
    #table
    [rows]="attendancesInactives"
    [columnMode]="ColumnMode.force"
    [footerHeight]="attendancesInactives.length > pageSize ? 30 : 0"
    [rowHeight]="40"
    [limit]="pageSize"
    [selected]="attendancesSelected"
    [selectionType]="SelectionType.checkbox"
    [selectAllRowsOnPage]="true"
    [messages]="messages"
    (select)="onSelect($event)"
    id="attendances-result"
  >
    <ngx-datatable-column
      [width]="50"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
      [headerCheckboxable]="true"
      [headerTemplate]="selectAll"
      [cellTemplate]="selectItem"
    >
    </ngx-datatable-column>
    <ngx-datatable-column
      name="Fila"
      prop="queueName"
      [canAutoResize]="false"
      [width]="200"
    ></ngx-datatable-column>
    <ngx-datatable-column
      name="Atendente"
      prop="attendantName"
      [canAutoResize]="false"
      [width]="250"
    >
    </ngx-datatable-column>
    <ngx-datatable-column
      name="Cliente"
      prop="customerName"
      [canAutoResize]="false"
      [width]="250"
    >
    </ngx-datatable-column>
    <ngx-datatable-column
      name="Última mensagem enviada"
      prop="sender"
      [canAutoResize]="false"
      [width]="350"
    >
    </ngx-datatable-column>
    <ngx-datatable-column
      name="Tempo inativo"
      prop="idleTime"
      [canAutoResize]="false"
      [width]="200"
    >
    </ngx-datatable-column>

    <ngx-datatable-footer>
      <ng-template
        ngx-datatable-footer-template
        let-rowCount="rowCount"
        let-pageSize="pageSize"
        let-selectedCount="selectedCount"
        let-curPage="curPage"
        let-offset="offset"
      >
        <datatable-pager
          [pagerLeftArrowIcon]="'datatable-icon-left'"
          [pagerRightArrowIcon]="'datatable-icon-right'"
          [page]="curPage"
          [size]="pageSize"
          [count]="rowCount"
          [hidden]="!(rowCount / pageSize > 1)"
          (change)="table.onFooterPage($event)"
        >
        </datatable-pager>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>
</div>

<ng-template
  #selectAll
  ngx-datatable-header-template
  let-value="value"
  let-allRowsSelected="allRowsSelected"
  let-selectFn="selectFn"
>
  <app-checkbox
    (change)="selectFn(!allRowsSelected)"
    [ngModel]="allRowsSelected"
  ></app-checkbox>
</ng-template>

<ng-template
  #selectItem
  ngx-datatable-cell-template
  let-value="value"
  let-isSelected="isSelected"
  let-onCheckboxChangeFn="onCheckboxChangeFn"
>
  <app-checkbox
    (change)="onCheckboxChangeFn($event)"
    [ngModel]="isSelected"
  ></app-checkbox>
</ng-template>

<app-close-inactive-attendance-modal
  class="hide"
  id="close-inactive-attendance-modal"
  [attendanceIds]="attendanceIds"
  (closeInactiveAttendanceSuccess)="
    removeAttendancesRemovedOrTransferred(attendanceIds)
  "
>
</app-close-inactive-attendance-modal>

<app-transfer-inactive-attendance-modal
  class="hide"
  id="transfer-inactive-attendance-modal"
  [attendanceIds]="attendanceIds"
  (transferInactiveAttendanceSuccess)="
    removeAttendancesRemovedOrTransferred(attendanceIds)
  "
>
</app-transfer-inactive-attendance-modal>
