import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { QueueNameAndId } from '@app/register/queue/queue.model'
import { QueueService } from '@app/register/queue/queue.service'
import { environment } from '@env/environment'
import { Observable } from 'rxjs'

import { Broker, BrokerType } from './broker.model'

@Injectable()
export class BrokerService {
  private url: string

  constructor(private http: HttpClient, private queueService: QueueService) {
    this.url = `${environment.urlBlaMessageManagementApi}/broker`
  }

  getQueueNamesAndIds(): Observable<QueueNameAndId[]> {
    return this.queueService.getQueueNamesAndIds()
  }

  public save(broker: Broker): Observable<Broker> {
    return this.http.post<Broker>(
      this.url,
      this.reinforceBrokerFieldsTyping(broker),
    )
  }

  public update = (brokerId: string, broker: Broker): Observable<any> => {
    return this.http.put<Broker>(
      `${this.url}/${brokerId}`,
      this.reinforceBrokerFieldsTyping(broker),
    )
  }

  public getTypes(): Observable<BrokerType[]> {
    return this.http.get<BrokerType[]>(`${this.url}/Types`)
  }

  public getBrokers(): Observable<Broker[]> {
    return this.http.get<Broker[]>(`${this.url}/brokers`)
  }

  public changeBrokerStatus(brokerId: string, newStatus: boolean) {
    return this.http.patch(`${this.url}/${brokerId}/status/${newStatus}`, {
      headers: new HttpHeaders().set('Content-Lenght', '0'),
    })
  }

  public getById = (brokerId: string): Observable<any> => {
    return this.http.get<any>(`${this.url}/${brokerId}`)
  }

  reinforceBrokerFieldsTyping(form: Broker): Broker {
    return {
      name: String(form.name),
      endpoint: String(form.endpoint),
      integrationToken: String(form.integrationToken),
      appId: String(form.appId),
      appSecret: String(form.appSecret),
      phoneNumberId: String(form.phoneNumberId),
      userToken: String(form.userToken),
      type: form.type as BrokerType,
      active: Boolean(form.active),
      queueId: String(form.queueId),
    } as Broker
  }
}
