import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@env/environment'
import { Observable } from 'rxjs'
import {
  Queue,
  QueueExistsResponse,
  QueueNameAndId,
  WaitingSettings,
  AttachmentSettings,
  QueueInfo,
  QueueTransferInfo,
  UpdateQueueRequest,
  AttendanceClosingMessageSettings,
  AutomaticMessagesSettings,
  QueueAndTeam,
} from './queue.model'
import { AttendanceTeamInfo } from '../attendance-team/attendance-team.model'
import { TeamService } from '../attendance-team/attendance-team.service'

@Injectable()
export class QueueService {
  private url: string

  constructor(
    private http: HttpClient,
    private attendanceTeamService: TeamService,
  ) {
    this.url = `${environment.urlBlaMessageManagementApi}/queue`
  }

  save = (queue: Queue): Observable<Queue> => {
    return this.http.post<Queue>(
      this.url,
      this.reinforceQueueFieldsTyping(queue),
    )
  }

  patch = (queueId: string, queue: UpdateQueueRequest): Observable<any> => {
    return this.http.patch(
      `${this.url}/${queueId}`,
      this.reinforceQueueUpdateFieldsTyping(queue),
    )
  }

  getQueueNamesAndIds = (): Observable<QueueNameAndId[]> => {
    return this.http.get<QueueNameAndId[]>(`${this.url}/NamesAndIds`)
  }

  checkIfQueueNameExists = (queueName: string) => {
    return this.http.post<QueueExistsResponse>(`${this.url}/checkqueuename`, {
      queueName,
    })
  }

  reinforceQueueUpdateFieldsTyping = (
    form: UpdateQueueRequest,
  ): UpdateQueueRequest => ({
    generalSettings: this.isNullOrUndefined(form.generalSettings)
      ? null
      : form.generalSettings,
    automaticMessagesSettings: this.isNullOrUndefined(
      form.automaticMessagesSettings,
    )
      ? null
      : this.reinforceQueueAutomaticMessagesSettingsFieldsTyping(
          form.automaticMessagesSettings,
        ),
    waitingSettings: this.isNullOrUndefined(form.waitingSettings)
      ? null
      : this.reinforceQueueWaitingFieldsTyping(form.waitingSettings),
    attachmentSettings: this.isNullOrUndefined(form.attachmentSettings)
      ? null
      : this.reinforceQueueAttachmentFieldsTyping(form.attachmentSettings),
  })

  reinforceQueueFieldsTyping = (form: Queue): Queue => {
    const {
      name,
      makeTransfers,
      checkCustomerPreferenceForActiveMessaging,
      receiveTransfers,
      attendanceTeamId,
      id,
      preAttendance,
      availableRankingDistribution,
      availableScoreDistribution,
      priority,
    } = form

    return {
      id,
      name: String(name),
      checkCustomerPreferenceForActiveMessaging: Boolean(
        checkCustomerPreferenceForActiveMessaging,
      ),
      makeTransfers: Boolean(makeTransfers),
      receiveTransfers: Boolean(receiveTransfers),
      attendanceTeamId: String(attendanceTeamId),
      preAttendance: Boolean(preAttendance),
      availableRankingDistribution: Boolean(availableRankingDistribution),
      availableScoreDistribution: Boolean(availableScoreDistribution),
      priority: priority ? Number(priority) : null,
      idlenessSettings: { controlIdleness: false },
      automaticMessagesSettings: this.reinforceQueueAutomaticMessagesSettingsFieldsTyping(
        form.automaticMessagesSettings,
      ),
      waitingSettings: this.reinforceQueueWaitingFieldsTyping(
        form.waitingSettings,
      ),
      attachmentSettings: this.reinforceQueueAttachmentFieldsTyping(
        form.attachmentSettings,
      ),
    }
  }

  reinforceQueueWaitingFieldsTyping = (
    form: WaitingSettings,
  ): WaitingSettings => {
    const { fullQueueMessage, maximumWaitSize } = form

    return {
      maximumWaitSize: Number(maximumWaitSize),
      fullQueueMessage: fullQueueMessage ? String(fullQueueMessage) : null,
    }
  }

  reinforceQueueAttachmentFieldsTyping = (
    form: AttachmentSettings,
  ): AttachmentSettings => {
    const {
      allowClient,
      allowAttendant,
      fileExtensions,
      maximumFileSize,
    } = form

    return {
      allowClient: Boolean(allowClient),
      allowAttendant: Boolean(allowAttendant),
      fileExtensions: allowClient || allowAttendant ? fileExtensions : null,
      maximumFileSize:
        allowClient || allowAttendant
          ? maximumFileSize
            ? Number(maximumFileSize)
            : null
          : null,
    }
  }

  reinforceQueueAutomaticMessagesSettingsFieldsTyping = (
    form: AutomaticMessagesSettings,
  ): AutomaticMessagesSettings => {
    const {
      initialMessage,
      enqueuedAttendanceMessage,
      outOfBusinessHoursMessage,
      attendanceClosingMessageSettings,
    } = form

    return {
      initialMessage: initialMessage ? String(initialMessage) : null,
      enqueuedAttendanceMessage: enqueuedAttendanceMessage
        ? String(enqueuedAttendanceMessage)
        : null,
      outOfBusinessHoursMessage: outOfBusinessHoursMessage
        ? String(outOfBusinessHoursMessage)
        : null,
      attendanceClosingMessageSettings: attendanceClosingMessageSettings as AttendanceClosingMessageSettings,
    }
  }

  getAttendanceTeamsInfo(): Observable<AttendanceTeamInfo[]> {
    return this.attendanceTeamService.getAttendanceTeamsInfo()
  }

  getQueueInfos(): Observable<QueueInfo[]> {
    return this.http.get<QueueInfo[]>(`${this.url}/QueueInfos`)
  }

  getQueued(queueId: string): Observable<number> {
    return this.http.get<number>(`${this.url}/Queued/${queueId}`)
  }

  getQueuesAndTeams(): Observable<QueueAndTeam[]> {
    return this.http.get<QueueAndTeam[]>(`${this.url}/QueuesAndTeams`)
  }

  checkQueueMakeTransferAvailability(queueId: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.url}/MakeTransfer/${queueId}`)
  }

  getAvailableQueuesForTransfer(): Observable<QueueTransferInfo[]> {
    return this.http.get<QueueTransferInfo[]>(`${this.url}/ReceiveTransfer`)
  }

  patchQueueStatus(queueId: string, status: boolean) {
    return this.http.patch(`${this.url}/${queueId}/status/${status}`, {
      headers: new HttpHeaders()
        .set('Content-Lenght', '0')
        .set('Access-Control-Allow-Origin', '*'),
    })
  }

  patchAttendanceTeam = (queueId: string, attendanceTeamId: string) => {
    return this.http.patch(
      `${this.url}/${queueId}/attendanceTeam/${attendanceTeamId}`,
      null,
    )
  }

  public getById = (queueId: string): Observable<any> => {
    return this.http.get<any>(`${this.url}/${queueId}`)
  }

  private isNullOrUndefined = (property: any) =>
    property === null || property === undefined
}
