import { StepsEnum } from '../../../../stepper/steps/template-message.steps'
import { TemplateMessageStore } from '../../template-message.store'
import { AfterViewInit, Component, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { DropDownItem } from '@app/components/drop-down/drop-down-item'
import { HtmlTools } from '@app/tools/html-tools'
import { MessageType } from '../../message-type'
import { BrokerType } from '../../broker-type'

@Component({
  selector: 'app-template-message-general',
  templateUrl: './template-message-general.component.html',
  styleUrls: ['./template-message-general.component.scss'],
})
export class TemplateMessagesGeneralComponent implements OnInit, AfterViewInit {
  templateMessageForm: FormGroup
  brokersDropDownItems: DropDownItem[]
  messageTypesDropDownItems: DropDownItem[]
  nextButton: HTMLButtonElement

  constructor(
    public store: TemplateMessageStore,
    private formBuilder: FormBuilder,
    private htmlTools: HtmlTools,
  ) {}

  ngAfterViewInit(): void {
    this.nextButton = document.querySelector('.next-button')
  }

  ngOnInit(): void {
    this.loadDropDownItems()
    this.store.setStep(StepsEnum.TemplateMessageGeneral)
    this.initializeForm()
  }

  submit = () => {
    if (this.store.isCreateMode() && this.templateMessageForm.valid) {
      this.htmlTools.addAttributeToElement(this.nextButton, 'disabled', '')

      this.storeFormDataAndGoToNextStep()
    } else if (this.store.isViewMode()) {
      this.store.nextStep()
    }
  }

  initializeForm = () => {
    this.templateMessageForm = this.formBuilder.group({
      brokerType: [
        { value: '', disabled: this.store.isViewMode() },
        Validators.required,
      ],
      messageType: [
        { value: '', disabled: this.store.isViewMode() },
        Validators.required,
      ],
      status: new FormControl({
        value: true,
        disabled: this.store.isViewMode(),
      }),
      checkCustomerPreferenceForActiveMessaging: new FormControl({
        value: true,
        disabled: this.store.isViewMode(),
      })
    })

    const formData = this.store.getFormData('General')

    if (formData) {
      this.templateMessageForm.setValue(formData)
    }
  }

  cancel = () => this.store.clear()

  loadDropDownItems() {
    this.brokersDropDownItems = this.enumToDropDownItem(BrokerType)

    this.messageTypesDropDownItems = this.enumToDropDownItem(MessageType)
  }

  enumToDropDownItem(enumme) {
    return Object.keys(enumme)
      .filter(this.stringIsNumber)
      .map(key => ({ text: enumme[key], value: key }))
  }

  stringIsNumber = value => isNaN(Number(value)) === false

  storeFormDataAndGoToNextStep = () => {
    this.store.storeFormData(this.templateMessageForm.value, 'General')

    this.store.nextStep()
    this.htmlTools.removeAttributeFromElement(this.nextButton, 'disabled')
  }
}
