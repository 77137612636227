import { DeleteImageMessageContext } from '../components/delete-image-message-modal/delete-image-message'
import {
  AfterViewChecked,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { LoggedUser } from '@app/components/authentication/logged-user.model'
import { Message } from '@app/components/messsage-bubble/message'
import { InfoBarService } from '@app/info-bar/info-bar.service'
import { DeviceCheck } from '@app/tools/detect-mobile'
import { HtmlTools } from '@app/tools/html-tools'
import { LocalStorageService } from '@app/tools/local-storage.service'
import { Expo, TweenMax } from 'gsap'
import { Subscription } from 'rxjs'

import { Attendance } from './attendance/attendance'
import { AttendanceService } from './attendance/attendance.service'
import { ChatService } from './chat.service'
import { AccessProfile, AttendantStatus } from '@app/register/user/user.model'
import { AttendantStatusService } from '@app/components/attendant-status/attendant-status.service'
import { QueueService } from '@app/register/queue/queue.service'
import { CustomerReviewService } from './customer-review-modal/customer-review.service'
import { CustomerReview } from './customer-review-modal/customer-review'
import { Sender } from './sender'
import { Type } from './type'

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  providers: [DeviceCheck],
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('chatWindow') private chatWindow: ElementRef
  @ViewChild('chatCardsList') private chatCardsList: ElementRef
  @ViewChild('chatMessages') private chatMessages: ElementRef

  Sender = Sender
  Type = Type

  userLoggedIn = false
  isDeviceMobile: boolean
  currentAttendance: Attendance
  attendances: Attendance[] = []
  attendanceSla: string
  textValue = ''
  messageSubscription: Subscription
  attendanceSubscription: Subscription
  loggedUser: LoggedUser
  endModal: HTMLElement
  transferModal: HTMLElement
  customerReviewModal: HTMLElement
  excludeReviewModal: HTMLElement
  imageviewModal: HTMLElement
  deleteImageMessageModal: HTMLElement
  clientImage: string
  review: CustomerReview
  reviewContent = ''
  isChatMessagesClosed = true
  customerHasReview: boolean
  scroll = false
  queueAvailableToMakeTransfer: boolean
  imageMessageContext: DeleteImageMessageContext
  deletedMessageId: string
  newCustomerModal: HTMLElement

  constructor(
    private attendanceService: AttendanceService,
    private chatService: ChatService,
    private queueService: QueueService,
    private infoBarService: InfoBarService,
    private localStorageService: LocalStorageService,
    private htmlTools: HtmlTools,
    private deviceCheck: DeviceCheck,
    private attendantStatusService: AttendantStatusService,
    private customerReviewService: CustomerReviewService,
  ) {}

  ngOnDestroy() {
    this.stopConnection()
  }

  async ngOnInit() {
    this.isDeviceMobile = this.deviceCheck.isMobile()

    this.transferModal = document.querySelector('.transfer-modal')

    this.customerReviewModal = document.querySelector('.customer-review-modal')

    this.excludeReviewModal = document.querySelector('.exclude-modal')

    this.imageviewModal = document.querySelector('.image-modal')

    this.deleteImageMessageModal = document.querySelector(
      '.delete-image-message-modal',
    )

    this.endModal = document.getElementById('end-attendance-modal')

    this.newCustomerModal = document.getElementById('new-customer-modal')

    this.loggedUser = this.localStorageService.getLoggedUser()

    this.loadAttendances()
    await this.startConnection()
  }

  ngAfterViewChecked() {
    if (this.scroll) {
      this.scroll = false
      this.scrollChatWindow()
    }
  }

  onWindowResize = () => (this.isDeviceMobile = this.deviceCheck.isMobile())

  updateCurrentAttendance = (attendanceId: string) => {
    this.currentAttendance = this.getAttendanceById(attendanceId)
    this.currentAttendance.unreadMessagesCount = 0
    this.getCustomerReview(this.currentAttendance.customer.id)
  }

  scrollChatWindow = () => {
    if (this.chatWindow === undefined) return

    this.chatWindow.nativeElement.scrollTop = this.chatWindow.nativeElement.scrollHeight
  }

  animateChat = () => {
    if (this.isDeviceMobile) {
      this.isChatMessagesClosed
        ? this.openChatAnimation()
        : this.closeChatAnimation()
    }
  }

  openChatAnimation = () => {
    this.isChatMessagesClosed = false

    TweenMax.fromTo(
      this.chatCardsList.nativeElement,
      1,
      { x: 0 },
      { x: '-130%', ease: Expo.easeInOut },
    )

    TweenMax.fromTo(
      this.chatMessages.nativeElement,
      1,
      { x: '130%' },
      { x: 0, ease: Expo.easeInOut },
    )
  }

  closeChatAnimation = () => {
    this.isChatMessagesClosed = true

    TweenMax.fromTo(
      this.chatCardsList.nativeElement,
      1,
      { x: '-250%' },
      { x: 0, ease: Expo.easeInOut },
    )

    TweenMax.fromTo(
      this.chatMessages.nativeElement,
      1,
      { x: 0 },
      { x: '130%', ease: Expo.easeInOut },
    )
  }

  removeCurrentAttendance = () => {
    if (!this.currentAttendance) {
      return
    }
    if (this.attendances) {
      this.attendances = this.attendances.filter(
        ({ attendanceId }) =>
          this.currentAttendance.attendanceId !== attendanceId,
      )
    }
    this.currentAttendance = undefined

    this.updateAttendancesLegth()
  }

  handleMessageDelivery = (text: string) => {
    if (text && this.attendances.length && this.currentAttendance) {
      const attendantMessage: Message = {
        ...this.currentAttendance.messageHistory[0],
        operationalUserId: this.loggedUser.userInfo.id,
        senderType: Sender.OperationalUser,
        type: Type.Text,
        date: new Date(),
        text,
      }

      this.currentAttendance.messageHistory = [
        ...this.currentAttendance.messageHistory,
        attendantMessage,
      ]
      this.textValue = ''
      this.chatService.sendMessage(attendantMessage)
      this.scroll = true
    }
  }

  startConnection = async () => {
    await this.chatService.startConnection(this.loggedUser.userInfo.id)

    this.messageSubscription = this.chatService.messages$.subscribe(message => {
      this.handleIncomingMessage(message)
    })

    this.attendanceSubscription = this.chatService.attendances$.subscribe(
      attendance => {
        this.handleIncomingAttendance(attendance)
      },
    )
  }

  private stopConnection = () => {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe()
    }

    if (this.attendanceSubscription) {
      this.attendanceSubscription.unsubscribe()
    }

    this.chatService.stopConnection()
  }

  private getAttendanceById = (attendanceId: string) =>
    this.attendances.find(
      attendance => attendance.attendanceId === attendanceId,
    )

  handleIncomingMessage = (message: Message) => {
    let attendance = this.getAttendanceById(message.attendanceId)

    if (!attendance || attendance === undefined) {
      attendance = {
        attendanceId: message.attendanceId,
        queueId: message.queueId,
        unreadMessagesCount: 0,
        messageHistory: [],
        operationalUserId: this.loggedUser.userInfo.id,
        customer: message.customer,
        startDate: message.date,
        queueName: message.queueName,
      }

      this.attendances = [...this.attendances, attendance]
      this.updateAttendancesLegth()
    }

    this.currentAttendance?.attendanceId !== attendance.attendanceId
      ? attendance.unreadMessagesCount++
      : (this.scroll = true)

    attendance.messageHistory = [...attendance.messageHistory, message]
  }

  handleIncomingAttendance = (attendance: Attendance) => {
    if (!this.getAttendanceById(attendance.attendanceId)) {
      attendance.unreadMessagesCount = 1
      this.attendances = [...this.attendances, attendance]
      this.updateAttendancesLegth()
    }
  }

  private loadAttendances = () => {
    this.attendanceService.getByUserId(this.loggedUser.userInfo.id).subscribe(
      attendances => (this.attendances = attendances),
      () => {
        this.infoBarService.show(
          'Falha ao carregar os atendimentos. Consulte o suporte se o problema persistir',
          'danger',
        )
      },
      () => this.updateAttendancesLegth(),
    )
  }

  showModal = () => {
    this.htmlTools.showElement(this.endModal)
  }

  closeModal = () => this.htmlTools.hideElement(this.endModal)

  openTransferModal = () => {
    this.checkQueueMakeTransferAvailability(this.currentAttendance.queueId)
    this.htmlTools.showElement(this.transferModal)
  }

  openCustomerReviewModal = () => {
    this.checkIfCustomerAlreadyHasAReview()
    this.htmlTools.showElement(this.customerReviewModal)
  }

  openExcludeModal = () => {
    this.htmlTools.showElement(this.excludeReviewModal)
    this.htmlTools.hideElement(this.customerReviewModal)
  }

  showImageModal = (url: string) => {
    this.clientImage = url
    this.htmlTools.showElement(this.imageviewModal)
  }

  showDeleteImageMessageModal = messageId => {
    this.imageMessageContext = {
      messageId,
      attendanceId: this.currentAttendance.attendanceId,
      accessProfile: AccessProfile.Operational,
    }

    this.htmlTools.showElement(this.deleteImageMessageModal)
  }

  openNewCustomerModal = () => {
    this.htmlTools.showElement(this.newCustomerModal)
  }

  resetImageModal = () => {
    this.clientImage = undefined
  }

  updateStatus = (status: AttendantStatus) =>
    this.attendantStatusService.updateAttendantStatus().emit(status)

  private updateAttendancesLegth = () =>
    this.attendantStatusService
      .updateNoAttendanceStatus()
      .emit(this.attendances.length)

  checkQueueMakeTransferAvailability = (queueId: string) => {
    this.queueService.checkQueueMakeTransferAvailability(queueId).subscribe(
      result => (this.queueAvailableToMakeTransfer = result),
      () => {
        this.infoBarService.show(
          `A fila ${this.currentAttendance.queueName} não foi encontrada. Consulte o suporte se o problema persistir`,
          'danger',
        )
      },
    )
  }

  checkIfCustomerAlreadyHasAReview = () => {
    if (this.review) this.reviewContent = this.review.reviewTextContent
  }

  getCustomerReview = (customerId: string) => {
    this.customerReviewService.get(customerId).subscribe(
      result => {
        if (result === null) {
          this.review = null
          this.customerHasReview = false
          this.reviewContent = ''

          return
        }

        this.customerHasReview = true
        this.review = result
      },
      () => {
        this.reviewContent = ''
        this.customerHasReview = false
      },
    )
  }

  handleImageRemoved = (deletedImage: DeleteImageMessageContext) => {
    this.deletedMessageId = deletedImage.messageId

    this.currentAttendance.messageHistory = [
      ...this.currentAttendance.messageHistory.map(message => {
        if (message.messageId === deletedImage.messageId) message.image = null
        return message
      }),
    ]
  }

  updateFavorites = ({ attendanceId, active }) => {
    this.attendances.map(attendance => {
      if (attendanceId === attendance.attendanceId) {
        attendance.favorite = active
      }
    })
  }

  favoritesCustomersList = () => {
    const filtered = this.attendances.filter(attendance => attendance.favorite)
    return filtered
  }

  queuedCustomersList = () =>
    this.attendances.filter(attendance => !attendance.favorite)
}
