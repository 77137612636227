<div class="container">
  <app-title title="Monitoramento de atendentes"></app-title>
  <div class="online-users-filters">
    <div class="user-filter">
      <app-input-dropdown
        [props]="userField"
        [dropdownItens]="users"
        (valuesSelected)="getFieldValue($event)"
      ></app-input-dropdown>
    </div>
    <div class="team-filter">
      <app-input-dropdown
        [props]="teamField"
        [dropdownItens]="teams"
        (valuesSelected)="getFieldValue($event)"
      ></app-input-dropdown>
    </div>
    <div class="queue-filter">
      <app-input-dropdown
        [props]="queueField"
        [dropdownItens]="queues"
        (valuesSelected)="getFieldValue($event)"
      ></app-input-dropdown>
    </div>
    <div class="status-filter">
      <app-select
        [props]="statusField"
        [selectItems]="status"
        (valuesSelected)="getFieldValue($event)"
      ></app-select>
    </div>
    <div class="motive-filter">
      <app-select
        [props]="motiveField"
        [selectItems]="workPauseReasons"
        (valuesSelected)="getFieldValue($event)"
      ></app-select>
    </div>
  </div>
  <button class="bla-btn bla-btn--primary" (click)="searchUsers()">
    Pesquisar
  </button>

  <ngx-datatable
    #table
    [columnMode]="ColumnMode.flex"
    [messages]="messages"
    rowHeight="auto"
    [footerHeight]="hiddenMenu ? 0 : 40"
    [rows]="rows"
  >
    <ngx-datatable-row-detail
      rowHeight="auto"
      #myDetailRow
      (toggle)="onDetailToggle($event)"
    >
      <ng-template
        let-row="row"
        let-expanded="expanded"
        ngx-datatable-row-detail-template
      >
        <div class="row-details-container">
          <app-row-detail
            *ngFor="let item of userDetails"
            [details]="[
              { title: 'Time: ', value: item.attendanceTeamName },
              { title: 'Fila: ', value: item.queueName },
              { title: 'Atendimentos: ', value: item.attendances }
            ]"
          ></app-row-detail>
        </div>
      </ng-template>
    </ngx-datatable-row-detail>

    <ngx-datatable-column
      prop="name"
      name="Usuário"
      [flexGrow]="3"
      [draggable]="false"
    >
      <ng-template
        let-value="value"
        let-rowIndex="rowIndex"
        let-row="row"
        ngx-datatable-cell-template
      >
        {{ value }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      prop="timeSinceLastStatusChange"
      name="Acesso"
      [flexGrow]="1"
      [draggable]="false"
    >
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        {{ value }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      prop="lastStatus"
      name="Status"
      [flexGrow]="1"
      [draggable]="false"
    >
      <ng-template let-value="value" ngx-datatable-cell-template>
        <app-status-tag
          [value]="
            value === 1
              ? 'Disponível'
              : value === 2
              ? 'Indisponível'
              : value === 4
              ? 'Ausente'
              : 'Aguardando'
          "
          [color]="value === 1 ? 'success' : value === 2 ? 'danger' : 'warning'"
        ></app-status-tag>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column prop="workPauseReason" name="Motivo" [flexGrow]="1">
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        {{ value }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      name="Janelas"
      prop="attendances"
      [draggable]="false"
      [flexGrow]="1"
    >
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        {{ value }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      [sortable]="false"
      [draggable]="false"
      [canAutoResize]="true"
      [flexGrow]="1"
    >
      <ng-template
        let-row="row"
        let-expanded="expanded"
        ngx-datatable-cell-template
      >
        <a
          href="javascript:void(0)"
          title="Expandir detalhes"
          (click)="toggleExpandRow(row)"
        >
          <img
            src="../../../../assets/images/arrowdown.svg"
            alt="Expandir detalhes do usuário"
          />
        </a>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-footer>
      <ng-template
        ngx-datatable-footer-template
        let-rowCount="rowCount"
        let-pageSize="pageSize"
        let-selectedCount="selectedCount"
        let-curPage="curPage"
        let-offset="offset"
      >
        <datatable-pager
          [pagerLeftArrowIcon]="'datatable-icon-left'"
          [pagerRightArrowIcon]="'datatable-icon-right'"
          [page]="curPage"
          [size]="pageSize"
          [count]="rowCount"
          [hidden]="!(rowCount / pageSize > 1)"
          (change)="table.onFooterPage($event)"
        >
        </datatable-pager>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>
</div>
