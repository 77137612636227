<div id="transfer-inactive-attendance-modal">
  <div class="bla-modal">
    <div class="bla-modal__body">
      <h1 class="bla-modal-title">Transferir atendimento</h1>
      <div class="bla-modal-separator"></div>

      <div class="bla-modal-text-info">
        <div class="bla-row">
          <div class="bla-column bla-column--25">
            <div class="bla-modal-label">Fila de destino:</div>
          </div>
          <div class="bla-column bla-column--50">
            <div class="modal-dropdown" id="dropdown-queues">
              <ul class="bla-input-dropdown">
                <i class="icon-down"></i>
                <li
                  class="bla-input-dropdown__item"
                  data-dropdown-id="queues"
                  (click)="openOptions($event)"
                >
                  {{
                    selectedQueueInfo?.name
                      ? selectedQueueInfo.name
                      : 'Selecione uma fila para transferência'
                  }}
                </li>
                <ul class="bla-input-itens" data-dropdown-id="queues">
                  <li
                    *ngFor="let queue of availableQueues"
                    class="bla-input-dropdown__item"
                    (click)="
                      selectQueue($event, queue); $event.stopPropagation()
                    "
                  >
                    {{ queue.name }}
                  </li>
                </ul>
              </ul>
            </div>
          </div>
        </div>

        <div class="bla-row" *ngIf="attendanceIds?.length === 1">
          <div class="bla-column bla-column--25">
            <div class="bla-modal-label">Atendente para receber:</div>
          </div>
          <div class="bla-column bla-column--50">
            <div class="modal-dropdown" id="dropdown-users">
              <ul class="bla-input-dropdown">
                <i class="icon-down"></i>
                <li
                  class="bla-input-dropdown__item"
                  data-dropdown-id="user"
                  (click)="openOptions($event)"
                >
                  {{
                    selectedOperationalUser?.name
                      ? selectedOperationalUser.name
                      : 'Selecione um atentende (opcional)'
                  }}
                </li>
                <ul class="bla-input-itens" data-dropdown-id="user">
                  <li
                    *ngFor="let user of availableUsers"
                    class="bla-input-dropdown__item"
                    (click)="selectOperationalUser($event, user)"
                  >
                    {{ user.name }}
                  </li>
                </ul>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="bla-modal-actions">
        <button
          id="cancel-transfer-button"
          type="reset"
          (click)="closeModal()"
          class="bla-btn"
        >
          Cancelar
        </button>
        <button
          id="confirmation-transfer--button"
          class="bla-btn"
          (click)="submit()"
          [ngClass]="
            noQueueSelected() ? 'bla-btn--disabled' : 'bla-btn--primary'
          "
          [disabled]="noQueueSelected()"
        >
          Transferir
        </button>
      </div>
    </div>
  </div>
  <div class="bla-modal__background"></div>
</div>
