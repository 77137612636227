import { EventEmitter, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { InfoBarService } from '@app/info-bar/info-bar.service'
import { queueSteps, StepsEnum } from '@app/stepper/steps/queue.steps'
import { Step } from '@app/stepper/steps/step'
import { of } from 'rxjs'
import { StoreMode } from '../register.model'
import { Queue } from './queue.model'
import { QueueService } from './queue.service'

type FormTypes =
  | 'Name'
  | 'AutomaticMessages'
  | 'Waiting'
  | 'Idleness'
  | 'Attachment'
  | 'AttendanceTeam'

@Injectable()
export class QueueStore {
  public queueSteps: Step[]
  storeMode: StoreMode
  public queueId: string
  endUpdate = new EventEmitter()

  private formValues: {
    [form: string]: any
  }

  get steps$() {
    return of(this.queueSteps)
  }

  constructor(
    private router: Router,
    private queueService: QueueService,
    private infoBarService: InfoBarService,
  ) {
    this.queueSteps = JSON.parse(JSON.stringify(queueSteps))
    this.formValues = {}
  }

  storeFormData = (values: any, formType: FormTypes) =>
    (this.formValues[formType] = values)

  save = () => {
    const queue = {
      ...this.formValues['Name'],
      waitingSettings: this.formValues['Waiting'],
      automaticMessagesSettings: this.formValues['AutomaticMessages'],
      idlenessSettings: this.formValues['Idleness'],
      attachmentSettings: this.buildAttachmentSettings(
        this.formValues['Attachment'],
      ),
    } as Queue

    this.queueService.save(queue).subscribe(
      () => {
        this.infoBarService.show('Fila cadastrada com sucesso', 'success')
        this.router.navigateByUrl('/cadastros/fila')
        this.clear()
      },
      ({ error }) => this.infoBarService.show(error, 'danger'),
    )
  }

  update = (step: StepsEnum) => {
    const queueToUpdate = this.buildUpdateQueueRequest(step)

    this.queueService.patch(this.queueId, queueToUpdate).subscribe(
      () => {
        this.infoBarService.show('Fila atualizada com sucesso', 'success')
        this.endUpdate.emit()
      },
      ({ error }) => {
        this.infoBarService.show(error, 'danger')
        this.endUpdate.emit()
      },
    )
  }

  getEndUpdateEmitter = () => this.endUpdate

  clear = () => {
    this.queueSteps = JSON.parse(JSON.stringify(queueSteps))
    this.formValues = {}
    this.storeMode = StoreMode.Create
  }

  nextStep = () => {
    const nextStep = this.completeCurrentStep()

    if (nextStep) {
      this.router.navigateByUrl(nextStep.route)
    }
  }

  getCurrentStep = (steps: Step[]) => steps.find(step => step.active)

  completeCurrentStep = () => {
    const currentStep = this.getCurrentStep(this.queueSteps)

    currentStep.completed = true

    return this.queueSteps[currentStep.index + 1]
  }

  setStep = (name: StepsEnum) => {
    const currentStep = this.getCurrentStep(this.queueSteps)

    if (currentStep) {
      currentStep.active = false
    }

    const step = this.queueSteps[name]

    if (step) {
      step.active = true
      step.enabled = true
    }
  }

  getFormData = (formType: FormTypes) => this.formValues[formType]

  public loadQueue = (queueId: string) => {
    this.queueService.getById(queueId).subscribe(
      queue => {
        const queueToStore = {
          ...queue,
          id: queueId,
          attendanceTeamId: queue.attendanceTeam.id,
        }

        this.queueId = queueId

        this.storeQueue(queueToStore)
        this.storeMode = StoreMode.Update

        this.router.navigateByUrl('cadastros/fila/nova')
      },
      () =>
        this.infoBarService.show(
          'Erro ao buscar as informações da fila. Consulte o suporte se o problema persistir',
          'danger',
        ),
    )
  }

  private storeQueue = (queue: Queue) => {
    this.setStep(StepsEnum.QueueName)

    this.storeFormData(
      {
        name: queue.name,
        makeTransfers: queue.makeTransfers,
        receiveTransfers: queue.receiveTransfers,
        attendanceTeamId: queue.attendanceTeamId,
        preAttendance: queue.preAttendance,
        availableRankingDistribution: queue.availableRankingDistribution,
        availableScoreDistribution: queue.availableScoreDistribution,
        priority: queue.priority,
        checkCustomerPreferenceForActiveMessaging: queue.checkCustomerPreferenceForActiveMessaging
      },
      'Name',
    )

    this.storeFormData(queue.automaticMessagesSettings, 'AutomaticMessages')
    this.storeFormData(queue.waitingSettings, 'Waiting')
    this.storeFormData(queue.idlenessSettings, 'Idleness')

    queue.attachmentSettings.fileExtensions = this.buildFileExtensions(
      queue.attachmentSettings.fileExtensions,
    )

    this.storeFormData(queue.attachmentSettings, 'Attachment')
  }

  public buildAttachmentSettings = attachmentSettings => {
    if (!attachmentSettings) return null
    const extensions = attachmentSettings?.fileExtensions?.map(
      file => file.value,
    )

    return {
      allowClient: attachmentSettings?.allowClient,
      allowAttendant: attachmentSettings?.allowAttendant,
      fileExtensions: extensions,
      maximumFileSize: attachmentSettings?.maximumFileSize,
    }
  }

  private buildFileExtensions = (fileExtensions: any) =>
    fileExtensions === null
      ? null
      : fileExtensions.map(extension => {
          return { display: extension, value: extension }
        })

  isUpdateMode = () => {
    if (this.storeMode === StoreMode.Update) {
      return true
    }

    return false
  }

  private buildUpdateQueueRequest = (currentStep: StepsEnum): any => {
    switch (currentStep) {
      case StepsEnum.QueueName:
        return {
          generalSettings: { ...this.formValues['Name'] },
        }
      case StepsEnum.AutomaticMessage:
        return {
          automaticMessagesSettings: this.formValues['AutomaticMessages'],
        }
      case StepsEnum.QueueWaiting:
        return {
          waitingSettings: this.formValues['Waiting'],
        }
      case StepsEnum.QueueAttachments:
        return {
          attachmentSettings: this.buildAttachmentSettings(
            this.formValues['Attachment'],
          ),
        }
      default:
        return {}
    }
  }
}
