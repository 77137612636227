export interface ValidateSellerCodeResponse {
  name: string
  active: boolean
}

export enum DirectContactSellerStatus {
  Active,
  Inactive,
  Invalid,
}
