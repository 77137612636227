<div class="template-message-form">
  <h2>Mensagem</h2>
  <hr />
  <form [formGroup]="templateMessageForm" (ngSubmit)="submit()">
    <div class="row">
      <div class="form-group form-item col-lg-4">
        <label for="templateId">Código</label>
        <small class="bla-input-tip"
          >Deve ser o código da mensagem informado no facebook.</small
        >
        <input
          *ngIf="store.isCreateMode()"
          appCharactersLimit="10"
          formControlName="templateId"
          autofocus
          [ngClass]="{
            'input--invalid': formControlValidator.isInvalid(
              templateMessageForm,
              'templateId'
            )
          }"
          class="form-control"
          id="templateId"
        />

        <input
          *ngIf="store.isViewMode()"
          formControlName="templateId"
          autofocus
          [ngClass]="{
            'input--invalid': formControlValidator.isInvalid(
              templateMessageForm,
              'templateId'
            )
          }"
          class="form-control"
          id="templateId"
        />

        <div class="input-validation__info">
          <ul
            class="input-validation__errors"
            *ngIf="formControlValidator.isInvalid(templateMessageForm, 'code')"
          >
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasError(
                  templateMessageForm,
                  'templateId',
                  'required'
                )
              "
            >
              A mensagem precisa de um código.
            </li>
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasAnyError(
                  templateMessageForm,
                  'templateId',
                  'maxlength',
                  'minlength'
                )
              "
            >
              O código deve conter no máximo 10 caracteres.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group form-item col-lg-12">
        <label for="name">Nome</label>
        <input
          *ngIf="store.isCreateMode()"
          appCharactersLimit="20"
          formControlName="name"
          [ngClass]="{
            'input--invalid': formControlValidator.isInvalid(
              templateMessageForm,
              'name'
            )
          }"
          class="form-control"
          id="name"
        />

        <input
          *ngIf="store.isViewMode()"
          formControlName="name"
          [ngClass]="{
            'input--invalid': formControlValidator.isInvalid(
              templateMessageForm,
              'name'
            )
          }"
          class="form-control"
          id="name"
        />
        <div class="input-validation__info">
          <ul
            class="input-validation__errors"
            *ngIf="formControlValidator.isInvalid(templateMessageForm, 'name')"
          >
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasError(
                  templateMessageForm,
                  'name',
                  'required'
                )
              "
            >
              A mensagem precisa de um nome.
            </li>
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasAnyError(
                  templateMessageForm,
                  'name',
                  'maxlength',
                  'minlength'
                )
              "
            >
              O nome da mensagem deve conter no máximo 20 caracteres.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group form-item col-lg-8 form-item-category">
        <app-drop-down
          [formGroup]="templateMessageForm"
          controlName="messageCategory"
          label="Categoria da Mensagem"
          [items]="categoriesDropDownItems"
          [isRequired]="true"
          requiredMessageError="Você deve informar uma categoria para a mensagem."
        ></app-drop-down>
      </div>
    </div>

    <div class="row">
      <div
        class="form-group form-item col-lg-12"
        [ngClass]="{ 'form-item-message': messageTypeIsIndividual() }"
      >
        <div class="row">
          <div class="col-lg-9">
            <label for="message">
              Mensagem para envio ao cliente
              <br />
              <small class="bla-input-tip"
                >Exemplo: "Caro Cliente, estamos lhe desejando feliz
                aniversário."</small
              >
            </label>
          </div>
          <div
            class="col-lg-3"
            *ngIf="messageTypeIsIndividual() && store.isCreateMode()"
          >
            <span
              class="add-message-variable"
              id="add-message-variable"
              (click)="templateMessageVariables.addNewMessageVariable()"
              ><em class="icon icon--plus"></em>Adicionar Variável</span
            >
          </div>
        </div>
        <textarea
          #message
          formControlName="message"
          [ngClass]="{
            'input--invalid': formControlValidator.isInvalid(
              templateMessageForm,
              'message'
            )
          }"
          class="form-control"
          id="message"
        ></textarea>
        <app-character-count
          *ngIf="store.isCreateMode()"
          class="automatic-messages-counter"
          [charactersLimit]="1024"
          [characters]="message?.value"
        ></app-character-count>

        <div class="input-validation__info">
          <ul
            class="input-validation__errors"
            *ngIf="
              formControlValidator.isInvalid(templateMessageForm, 'message')
            "
          >
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasError(
                  templateMessageForm,
                  'message',
                  'maxlength'
                )
              "
              id="initial-queue-message-error"
            >
              A mensagem deve conter no máximo 1024 caracteres
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="messageTypeIsIndividual()">
      <div class="form-group form-item col-lg-12">
        <app-template-message-variables
          #templateMessageVariables
          id="message-variables"
          formGroupName="parameters"
          [isCreateMode]="store.isCreateMode()"
        >
        </app-template-message-variables>
      </div>
    </div>

    <div class="bla-actions" *ngIf="store.isCreateMode()">
      <button
        type="button"
        class="bla-btn"
        routerLink="/cadastros/frase"
        (click)="cancel()"
      >
        Cancelar
      </button>
      <button
        [disabled]="templateMessageForm.invalid"
        type="submit"
        class="bla-btn bla-btn--primary save-button"
        [ngClass]="{
          'bla-btn--disabled': templateMessageForm.invalid
        }"
      >
        Salvar
      </button>
    </div>
  </form>
</div>
