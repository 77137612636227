<div class="collapse navbar-collapse" id="navbar-logged-user-profile">
  <ul class="navbar-nav mr-auto">
    <li class="nav-item dropdown">
      <a
        class="nav-link dropdown-toggle"
        href="#"
        id="navbarDropdownUser"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {{ loggedUser.userInfo.username }}
      </a>
      <div class="dropdown-menu" aria-labelledby="navbarDropdown">
        <ng-container [ngSwitch]="loggedUser.userInfo.accessProfile">
          <label
            class="dropdown-item-label"
            *ngSwitchCase="accessProfile.Operational"
            >Operacional
          </label>

          <label
            class="dropdown-item-label"
            *ngSwitchCase="accessProfile.Administrative"
          >
            Administrativo
          </label>

          <label
            class="dropdown-item-label"
            *ngSwitchCase="accessProfile.Supervisor"
            >Supervisor
          </label>
        </ng-container>
        <a class="dropdown-item">Minha conta</a>
        <a class="dropdown-item">Preferências do sistema</a>
        <a class="dropdown-item logout" (click)="logout()">Sair</a>
      </div>
    </li>
  </ul>
</div>
