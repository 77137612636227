import { AccessProfile } from './../register/user/user.model'
import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router'
import { LocalStorageService } from '@app/tools/local-storage.service'
import { PassportUserService } from '@app/tools/passport-user.service'

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    private passportUserService: PassportUserService,
    private localStorageService: LocalStorageService,
    public router: Router,
  ) {}

  canActivate = (route: ActivatedRouteSnapshot) => {
    const passport = this.passportUserService.get()
    const loggedUser = this.localStorageService.getLoggedUser()

    if (passport && loggedUser) {
      const expectedRole = route?.children[0]?.data?.expectedRole

      if (
        !expectedRole ||
        expectedRole.some(
          (acessProfile: AccessProfile) =>
            acessProfile === loggedUser.userInfo.accessProfile,
        )
      )
        return true

      this.router.navigate(['/'])
      return false
    }

    this.router.navigate(['login'])
    return false
  }
}
