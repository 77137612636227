import { AttendantStatus, UserInfo } from '@app/register/user/user.model'

export class LoggedUser {
  userInfo: UserInfo
  attendantStatus: AttendantStatus
}

export class Passport {
  user?: PassportLoggedUser
}

class PassportLoggedUser {
  userName: string
  email: string
}
