export interface Workplace {
  id: string
  name: string
  code: string
  label: string
}

export interface WorkplaceRequest {
  name: string
  code: string
}

export const errors = {
  codeField: {
    empty: 'O código da tabela deve ser preenchido',
    minLength: 'O código da tabela deve ter 2 caracteres',
  },
  nameField: {
    empty: 'O nome do local de atuação deve ser preenchido',
  },
}
