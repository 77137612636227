<div (clickOutside)="closeMenu()" class="attendant-status">
  <div
    class="selected-status"
    [ngClass]="selectedClasses"
    (click)="toggleMenu()"
  >
    <div class="selected-status__display">
      <i [ngClass]="statusIconClasses"></i>
      <div class="status">{{ statusLabel }}</div>
    </div>
    <i class="icon-down"></i>
  </div>
  <ul *ngIf="menuIsOpen" class="status-options">
    <li
      id="available-link"
      class="status-options__item status-options__item--available"
      (click)="changeStatus(AttendantStatus.Available)"
    >
      <i class="icon-check-filled-round"></i>
      <div class="status">Disponível</div>
    </li>
    <li
      id="unavailable-link"
      class="status-options__item status-options__item--unavailable"
      (click)="changeStatus(AttendantStatus.Unavailable)"
    >
      <i class="icon-cross-filled-round"></i>
      <div class="status">Indisponível</div>
    </li>
    <li
      *ngIf="loggedUser.userInfo.accessProfile === AccessProfile.Operational"
      id="away-link"
      class="status-options__item status-options__item--away"
      (click)="changeStatus(AttendantStatus.Away)"
    >
      <i class="icon-clock-filled-round"></i>
      <div class="status">Ausente</div>
    </li>
    <div
      *ngIf="
        workPauseReasons &&
        (loggedUser.attendantStatus === AttendantStatus.Away ||
          loggedUser.attendantStatus === AttendantStatus.Awaiting)
      "
    >
      <app-work-pause
        (updateMenuOpenedStatus)="updateMenuStatus($event)"
        [reasons]="workPauseReasons"
        [attendancesLength]="attendancesLength"
      ></app-work-pause>
    </div>
  </ul>
</div>
