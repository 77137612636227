import {
  Component,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  OnInit,
} from '@angular/core'
import { NgSelectComponent, NgSelectConfig } from '@ng-select/ng-select'
import { ValuesOutput } from '../input-dropdown/input-dropdown'
import { StringTools } from '@app/tools/normalize-string'

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit {
  @Output() valuesSelected = new EventEmitter<ValuesOutput>()
  @ViewChild('ngSelectComponent') ngSelectComponent: NgSelectComponent

  labelForName: string
  ngSelectId: string

  @Input() props: {
    labelTitle: string
    placeholder: string
    multipleSelect: boolean
    searchable: boolean
    closeOnSelect: boolean
    tag: string
  }

  @Input() selectItems: []

  constructor(
    private config: NgSelectConfig,
    private stringTools: StringTools,
  ) {
    this.config.notFoundText = 'Nenhum resultado encontrado'
  }

  ngOnInit() {
    if (this.props)
      this.ngSelectId = this.labelForName = this.stringTools.normalizeString(
        this.props.labelTitle,
      )
  }

  setValue = (ids: string[]) => {
    const field = this.stringTools
      .trimFirstWord(this.stringTools.normalizeString(this.props.labelTitle))
      .toLocaleLowerCase()

    this.valuesSelected.emit({ field, ids, tag: this.props.tag })
  }

  clearSelection = () => {
    this.ngSelectComponent.clearModel()
  }
}
