<div *ngIf="loadingAttendance">
  <app-loading-attendance-header></app-loading-attendance-header>
  <app-loading-attendance-result></app-loading-attendance-result>
</div>

<div *ngIf="!loadingAttendance">
  <div class="return-page">
    <p (click)="hideDetail()" id="back-monitoring-attendances">
      &#60; voltar para lista de resultados
    </p>
  </div>

  <app-header-details
    [detail]="detailHeader"
    [attendanceRating]="attendanceDetails?.attendanceRating"
  ></app-header-details>

  <app-attendance-details
    (openImageModalEvent)="showImageModal($event)"
    [attendanceMessages]="attendanceDetails?.messages"
    [attendanceId]="attendance.attendanceId"
    (updateAttendanceMessages)="getAttendanceDetails(attendance.attendanceId)"
  ></app-attendance-details>
</div>

<app-image-modal
  class="image-modal hide"
  [url]="imageURL"
  (closeImageModal)="resetImageModal()"
></app-image-modal>
