import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { InfoBarService } from '@app/info-bar/info-bar.service'
import { CustomerReviewService } from '../customer-review.service'

@Component({
  selector: 'app-exclude-review-modal',
  templateUrl: './exclude-review-modal.component.html',
  styleUrls: ['./exclude-review-modal.component.scss'],
})
export class ExcludeReviewModalComponent {
  @Input() customerId: string
  @Output() openCustomerReviewModal = new EventEmitter()
  @Output() excludeSuccess = new EventEmitter()

  exlcusion: boolean

  constructor(
    private element: ElementRef,
    private service: CustomerReviewService,
    private infoBar: InfoBarService,
  ) {}

  close = () => {
    this.hide()
    this.showCustomerReviewModal()
  }

  exclude = () => {
    this.hide()

    this.service.delete(this.customerId).subscribe(
      () => {
        this.excludeSuccess.emit(this.customerId)
        this.infoBar.show('Observação excluída com sucesso', 'success')
      },
      () => {
        this.showCustomerReviewModal()
        this.infoBar.show('Ocorreu um erro ao excluir a observação', 'danger')
      },
    )
  }

  hide = () => {
    this.element.nativeElement.classList.remove('show')
    this.element.nativeElement.classList.add('hide')
  }

  showCustomerReviewModal = () => this.openCustomerReviewModal.emit('')
}
