<section class="direct-contact-page">
  <div class="direct-contact-page__logo">
    <img src="../../../assets/images/fast-shop-logo.png" alt="logo fastshop" />
  </div>
  <section class="form-container">
    <div class="form-container__title">
      Conversar com o vendedor
    </div>
    <div class="form-container__separator"></div>
    <div class="direct-contact-page__content">
      <app-direct-contact-form
        [subtitle]="subtitle"
        [showContactInput]="showContactInput"
        (directContactConfirmed)="sendDirectContact($event)"
      >
      </app-direct-contact-form>
    </div>
  </section>
</section>
