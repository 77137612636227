<div class="queue-form">
  <h2>Permissão de Anexos</h2>
  <hr />
  <form [formGroup]="queueAttachmentForm" (ngSubmit)="submit()">
    <div class="row">
      <div class="checkbox-container form-item form-group col-lg-6">
        <label for="allowClient"
          >Permitir cliente enviar anexos
          <small class="info">(opcional)</small></label
        >
        <input
          formControlName="allowClient"
          type="checkbox"
          id="allowClient"
          class="checkbox"
        />
        <label for="allowClient" class="switch" id="allowClientSwitch">
          <div class="switch-on">Sim</div>
          <div class="switch-off">Não</div>
        </label>
      </div>
    </div>

    <div class="row">
      <div class="checkbox-container form-item form-group col-lg-6">
        <label for="allowAttendant"
          >Permitir atendente enviar anexos
          <small class="info">(opcional)</small></label
        >
        <input
          formControlName="allowAttendant"
          type="checkbox"
          id="allowAttendant"
          class="checkbox"
        />
        <label for="allowAttendant" class="switch" id="allowAttendantSwitch">
          <div class="switch-on">Sim</div>
          <div class="switch-off">Não</div>
        </label>
      </div>
    </div>

    <div *ngIf="allowClient || allowAttendant" class="row">
      <div class="form-group form-item col-lg-12">
        <label for="fileExtensions">Tipo de arquivos permitidos</label>
        <tag-input
          formControlName="fileExtensions"
          [theme]="'queue-attachment-theme'"
          [formGroup]="queueAttachmentForm"
          [placeholder]="'Adicione mais formatos'"
          [secondaryPlaceholder]="'txt, jpeg, jpg, png, webp, pdf, xls, csv'"
        >
          <tag-input-dropdown
            [showDropdownIfEmpty]="false"
            [autocompleteObservable]="requestAutocompleteFileTypes"
          >
          </tag-input-dropdown>
        </tag-input>
        <div class="input-queue__info">
          <ul
            class="input-queue__errors"
            *ngIf="
              formControlValidator.isInvalid(
                queueAttachmentForm,
                'fileExtensions'
              )
            "
          >
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasError(
                  queueAttachmentForm,
                  'fileExtensions',
                  'required'
                )
              "
            >
              Os tipos de arquivos permitidos precisam ser definidos. São
              proibidas extensões exe, zip, rar, 7z, cmd, bat, vbs, wsf, src,
              msi
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div *ngIf="allowClient || allowAttendant" class="row">
      <div class="form-group form-item col-lg-12">
        <label for="maximumFileSize">
          Tamanho máximo permitido dos arquivos
        </label>
        <div class="maximum-size">
          <input
            formControlName="maximumFileSize"
            autofocus
            min="1"
            [ngClass]="{
              'input--invalid': formControlValidator.isInvalid(
                queueAttachmentForm,
                'maximumFileSize'
              )
            }"
            class="form-control col-md-2 input-maximum-file-size"
            id="maximumFileSize"
            type="number"
          />
          <div class="input-group-append">
            <span class="input-group-text">mb</span>
          </div>
        </div>
        <div class="input-queue__info">
          <ul
            class="input-queue__errors"
            *ngIf="
              formControlValidator.isInvalid(
                queueAttachmentForm,
                'maximumFileSize'
              )
            "
          >
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasError(
                  queueAttachmentForm,
                  'maximumFileSize',
                  'required'
                )
              "
            >
              Adicione um valor entre 1 e 10.
            </li>
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasAnyError(
                  queueAttachmentForm,
                  'maximumFileSize',
                  'max',
                  'min'
                )
              "
            >
              O arquivo deve ter entre 1 e 10 mb.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="bla-actions">
      <button
        type="button"
        class="bla-btn"
        routerLink="/cadastros/fila"
        (click)="cancel()"
      >
        Cancelar
      </button>
      <button
        [disabled]="queueAttachmentForm.invalid"
        type="submit"
        class="bla-btn save-button"
        [ngClass]="
          queueAttachmentForm.invalid ? 'bla-btn--disabled' : 'bla-btn--primary'
        "
      >
        {{ store.isUpdateMode() ? 'Salvar Alterações' : 'Salvar' }}
      </button>
    </div>
  </form>
</div>

<app-edit-queue-confirmation-modal
  [id]="'queue-attachments-setting-edit-modal'"
  [step]="StepsEnum.QueueAttachments"
  #confirmationModal
></app-edit-queue-confirmation-modal>
