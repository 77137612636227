import { Component, OnInit } from '@angular/core'
import { AttendanceSearchResult } from './attendance-search-result/attendance-search-result'
import { MonitoringAttendancesFilterModel } from './monitoring-attendances-filter/monitoring-attendances-filter.model'
import { InfoBarService } from '@app/info-bar/info-bar.service'
import { MonitoringAttendanceService } from './monitoring-attendance.service'
import * as moment from 'moment'
moment.locale('pt-br')

@Component({
  selector: 'app-monitoring-attendance',
  templateUrl: './monitoring-attendance.component.html',
  styleUrls: ['./monitoring-attendance.component.scss'],
})
export class MonitoringAttendanceComponent implements OnInit {
  MAX_ATTENDANCES = 10000
  public attendances: []
  limitModal: HTMLElement
  attendanceDetail: AttendanceSearchResult
  resetTable: boolean
  private EMPTY_RESULT = '-'

  constructor(
    private monitoringService: MonitoringAttendanceService,
    private infoBar: InfoBarService,
  ) {}

  ngOnInit(): void {
    this.limitModal = document.getElementById('limit-attendance-modal')
  }

  showModal = () => {
    this.limitModal.classList.remove('hide')
    this.limitModal.classList.add('show')
  }

  closeModal = () => {
    this.limitModal.classList.remove('show')
    this.limitModal.classList.add('hide')
  }

  searchAttendancesByFilters(filters: MonitoringAttendancesFilterModel) {
    if (!this.validateFilters(filters)) return

    this.monitoringService
      .getAttendancesByFilters(filters)
      .subscribe(attendances => {
        if (attendances && attendances.length >= this.MAX_ATTENDANCES)
          this.showModal()

        this.attendances = attendances.map(attendance => ({
          ...attendance,
          startDate: attendance.startDate
            ? moment
                .utc(attendance.startDate)
                .local()
                .format('DD/MM/yyyy')
            : this.EMPTY_RESULT,
          startTime: attendance.startDate
            ? moment
                .utc(attendance.startDate)
                .local()
                .format('HH:mm')
            : this.EMPTY_RESULT,
          endDate: attendance.endDate
            ? moment
                .utc(attendance.endDate)
                .local()
                .format('DD/MM/yyyy')
            : this.EMPTY_RESULT,
          endTime: attendance.endDate
            ? moment
                .utc(attendance.endDate)
                .local()
                .format('HH:mm')
            : this.EMPTY_RESULT,
          closingReason: attendance?.closingReason?.name ?? this.EMPTY_RESULT,
          closingType: attendance?.closingTypes
            ? this.getClosingType(attendance?.closingTypes)
            : this.EMPTY_RESULT,
          phoneNumber: attendance?.customer?.phoneNumber
            ? this.formatPhoneNumber(attendance?.customer?.phoneNumber)
            : this.EMPTY_RESULT,
          operationalUserName:
            attendance?.operationalUserName || this.EMPTY_RESULT,
          customer: undefined,
        }))
      })
  }

  fillAttendanceDetail(attendance) {
    if (attendance && attendance.attendanceId?.length > 0)
      this.attendanceDetail = attendance
    else this.resetAttendanceDetail()
  }

  resetAttendanceDetail = () => (this.attendanceDetail = null)

  resetSorts = event => (this.resetTable = true)

  private validateFilters(filters: MonitoringAttendancesFilterModel) {
    if (
      filters.getStartDate() === 'invalid' ||
      filters.getEndDate() === 'invalid'
    ) {
      this.infoBar.show(
        'Formato de data inválido. O formato deve ser dd/mm/aaaa hh:mm',
        'danger',
      )
      return false
    }

    return true
  }

  private getClosingType(closingType) {
    if (closingType === 1) return 'Padrão'
    else if (closingType === 2) return 'Fidelizado'

    return this.EMPTY_RESULT
  }

  private formatPhoneNumber(phoneNumber: string) {
    return phoneNumber ? phoneNumber.replace('+55', '') : this.EMPTY_RESULT
  }
}
