<div class="loader loader--update">
  <div class="animation-reflex"></div>
</div>

<div class="loader loader--table" id="loader-table">
  <div class="loader--table-header"><div class="animation-reflex"></div></div>

  <div class="loader--table-body">
    <div *ngFor="let item of [1, 2, 3]" class="row-wrapper">
      <div class="cell loader user-field">
        <div class="animation-reflex"></div>
      </div>
      <div class="cell loader date-field">
        <div class="animation-reflex"></div>
      </div>
      <div class="cell loader time-field">
        <div class="animation-reflex"></div>
      </div>
      <div class="cell loader message-content-field">
        <div class="animation-reflex"></div>
      </div>
    </div>
  </div>
</div>
