<section class="app-header-details">
  <div class="row-header">
    <div class="group-header" *ngIf="detail">
      <div class="header-detail">
        <p class="filter-label">Data Início</p>
        <p class="content" id="start-date">
          {{ detail.startDate }}
        </p>
      </div>

      <div class="header-detail">
        <p class="filter-label">Hora Início</p>
        <p class="content" id="start-time">
          {{ detail.startTime }}
        </p>
      </div>
    </div>
    <div class="split"></div>

    <div class="group-header" *ngIf="detail">
      <div class="header-detail">
        <p class="filter-label">Data Término</p>
        <p class="content" id="end-date">
          {{ detail.endDate }}
        </p>
      </div>

      <div class="header-detail">
        <p class="filter-label">Hora Término</p>
        <p class="content" id="end-time">
          {{ detail.endTime }}
        </p>
      </div>
    </div>

    <div class="split"></div>

    <div class="group-header" *ngIf="detail">
      <div class="header-detail">
        <p class="filter-label">TMA</p>
        <p class="content" id="average-attendance-time">
          {{ formatAverageTime(detail.averageAttendanceTime) }}
        </p>
      </div>

      <div class="header-detail">
        <p class="filter-label">TME</p>
        <p class="content" id="average-waiting-time">
          {{ formatAverageTime(detail.averageWaitingTime) }}
        </p>
      </div>

      <div class="header-detail">
        <p class="filter-label">TMI</p>
        <p class="content" id="avarage-handling-time">
          {{ formatAverageTime(detail.avarageHandlingTime) }}
        </p>
      </div>
    </div>
  </div>

  <div class="row-header">
    <div class="group-header" *ngIf="detail">
      <div class="header-detail">
        <p class="filter-label">Fila</p>
        <p class="content" id="queue-name">
          {{ nonEmptyStringOrDefaultValue(detail.queueName) }}
        </p>
      </div>

      <div class="header-detail">
        <p class="filter-label">Tipo</p>
        <p class="content" id="closing-type">
          {{ nonEmptyStringOrDefaultValue(detail.closingType) }}
        </p>
      </div>

      <div class="header-detail">
        <p class="filter-label">Motivo</p>
        <p class="content" id="closing-reason">
          {{ nonEmptyStringOrDefaultValue(detail.closingReason) }}
        </p>
      </div>
    </div>

    <div class="split"></div>

    <div class="group-header">
      <div class="header-detail">
        <p class="filter-label">Nota recebida</p>
        <p class="content" id="attendance-rating">
          {{ nonEmptyStringOrDefaultValue(attendanceRating?.rating) }}
        </p>
      </div>

      <div class="header-detail">
        <p class="filter-label">Motivo da Avaliação</p>
        <p class="content" id="attendance-rating-reason">
          {{ nonEmptyStringOrDefaultValue(attendanceRating?.reason) }}
        </p>
      </div>
    </div>
  </div>
  <div class="row-header">
    <div class="header-detail">
      <p class="filter-label">Comentário do Cliente</p>
      <p class="content" id="attendance-rating-message">
        {{ nonEmptyStringOrDefaultValue(attendanceRating?.message) }}
      </p>
    </div>
  </div>
</section>
