import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core'
import { OnlineOperationalUserService } from './online-operational-user.service'
import { AttendantStatus } from '@app/register/user/user.model'
import { ColumnMode } from '@swimlane/ngx-datatable'
import { UserService } from '@app/register/user/user.service'
import { Subscription, interval } from 'rxjs'
import { InfoBarService } from '@app/info-bar/info-bar.service'

@Component({
  selector: 'app-online-operational-user',
  templateUrl: './online-operational-user.component.html',
  styleUrls: ['./online-operational-user.component.scss'],
})
export class OnlineOperationalUserComponent implements OnInit, OnDestroy {
  @ViewChild('table') table: any

  subscription: Subscription

  AttendantStatus = AttendantStatus

  status: {}[]
  workPauseReasons: any
  teams: any
  users: any
  queues: any

  statusResult: string
  workPauseReasonResult: any
  teamsResult: any
  usersResult: any
  queuesResult: any

  userDetails: any

  rows = []
  ColumnMode = ColumnMode
  messages = {
    emptyMessage: 'Nenhuma opção foi encontrada',
  }
  extraInfosOpened = false
  hiddenMenu = true
  expanded = []

  sourceInterval = interval(15000)

  userField = {
    labelTitle: 'Usuário',
    placeholder: 'Digite o nome do usuário',
  }

  teamField = {
    labelTitle: 'Time',
    placeholder: 'Digite o time',
  }

  queueField = {
    labelTitle: 'Fila de chat',
    placeholder: 'Digite o fila',
  }

  statusField = {
    labelTitle: 'Status',
    placeholder: 'Selecionar',
    multipleSelect: false,
  }

  motiveField = {
    labelTitle: 'Motivo',
    placeholder: 'Selecionar',
    multipleSelect: true,
  }

  constructor(
    private onlineOperationalUserService: OnlineOperationalUserService,
    private infoBarService: InfoBarService,
    private userService: UserService,
  ) {}

  setStatusFieldData = () =>
    (this.status = [
      { id: '1', name: 'Disponível' },
      { id: '2', name: 'Indisponível' },
      { id: '3', name: 'Aguardando' },
      { id: '4', name: 'Ausente' },
    ])

  setPauseReasonsFieldData = () =>
    this.onlineOperationalUserService
      .getWorkPauseReasons()
      .subscribe(result => (this.workPauseReasons = result))

  setQueuesFieldData = () =>
    this.onlineOperationalUserService
      .getQueueNamesAndIds()
      .subscribe(result => (this.queues = result))

  setTeamsFieldData = () =>
    this.onlineOperationalUserService
      .getAttendanceTeamsInfo()
      .subscribe(result => (this.teams = result))

  setUsersFieldData = () => {
    this.onlineOperationalUserService
      .getOnlineOperationalUsers()
      .subscribe(result => (this.users = result))
  }

  ngOnInit(): void {
    this.setStatusFieldData()
    this.setPauseReasonsFieldData()
    this.setQueuesFieldData()
    this.setTeamsFieldData()
    this.setUsersFieldData()
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe()
  }

  filter = (
    usersIds: string[],
    teamsIds: string[],
    queuesIds: string[],
    status: string,
    workPauseReasons: string[],
  ) => {
    this.userService
      .getFilteredOnlineOperationalUsers(
        usersIds,
        teamsIds,
        queuesIds,
        status,
        workPauseReasons,
      )
      .subscribe(
        result => {
          this.rows = [...result]
          this.rows?.length > 10
            ? (this.hiddenMenu = false)
            : (this.hiddenMenu = true)
        },
        () =>
          this.infoBarService.show(
            'Ocorreu um erro ao filtrar usuários. Consulte o suporte se o problema persistir',
            'danger',
          ),
      )
  }

  getOnlineOperationalUserDetails = (id: string) =>
    this.onlineOperationalUserService
      .getOnlineOperationalUsersDetails(id)
      .subscribe(
        result => (this.userDetails = result),
        () =>
          this.infoBarService.show(
            'Ocorreu um erro ao buscar por detalhes deste usuário. Consulte o suporte se o problema persistir',
            'danger',
          ),
      )

  getFieldValue = ({ field, ids }) => {
    if (field === 'usuario') this.usersResult = ids
    if (field === 'time') this.teamsResult = ids
    if (field === 'fila') this.queuesResult = ids
    if (field === 'status') this.statusResult = ids === '0' ? undefined : ids
    if (field === 'motivo') this.workPauseReasonResult = ids
  }

  toggleExpandRow(row) {
    if (row.id !== this.expanded) this.table.rowDetail.collapseAllRows()
    this.table.rowDetail.toggleExpandRow(row)
  }

  onDetailToggle({ value: { id } }) {
    if (id !== undefined) {
      this.expanded = id
      this.getOnlineOperationalUserDetails(id)
    }
  }

  searchUsers = () => {
    this.subscription?.unsubscribe()
    this.subscription = this.sourceInterval.subscribe(_ => this.searchUsers())

    this.filter(
      this.usersResult,
      this.teamsResult,
      this.queuesResult,
      this.statusResult,
      this.workPauseReasonResult,
    )
  }
}
