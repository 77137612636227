<div class="user-form">
  <h2>Local de atuação</h2>
  <hr />
  <form [formGroup]="userChatsAndTeamsForm" (ngSubmit)="save()">
    <div class="row">
      <div class="form-group form-item col-lg-12">
        <app-drop-down
          [formGroup]="userChatsAndTeamsForm"
          controlName="immediateSuperiorUserId"
          label="Superior imediato"
          ImmediateSuperiorUserId
          [items]="adminAndSupervisorsDropDownItems"
          [isRequired]="true"
          requiredMessageError="O usuário precisa de um superior imediato."
        ></app-drop-down>
      </div>
    </div>

    <div class="row">
      <div class="input-group form-item col-md-12">
        <label for="maxParallelAttendances"
          >Quantidade de chats e atendimentos em paralelo
          <small class="observation-text">(obrigatório)</small></label
        >
        <input
          type="number"
          min="0"
          formControlName="maxParallelAttendances"
          class="form-control col-md-2"
          id="maxParallelAttendances"
        />
        <div class="input-user__info">
          <ul
            class="input-user__errors"
            *ngIf="
              formControlValidator.isInvalid(
                userChatsAndTeamsForm,
                'maxParallelAttendances'
              )
            "
          >
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasError(
                  userChatsAndTeamsForm,
                  'maxParallelAttendances',
                  'required'
                )
              "
            >
              Quantidade precisa ser definida
            </li>
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasAnyError(
                  userChatsAndTeamsForm,
                  'maxParallelAttendances',
                  'min',
                  'max'
                )
              "
            >
              Quantidade deve estar entre 0 e 99
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row row--workplace">
      <div class="form-group form-item workplace-dropdown">
        <label for="workplaceId"
          >Local de atuação
          <small class="observation-text">(obrigatório)</small></label
        >
        <div class="input-dropdown">
          <ng-select
            [items]="workplaces"
            bindLabel="label"
            bindId="id"
            bindValue="id"
            class="custom-dropdown"
            placeholder="Escolha um local"
            formControlName="workplaceId"
            [clearable]="true"
            [searchable]="true"
            [multiple]="false"
            [closeOnSelect]="false"
            id="workplace-select"
          >
          </ng-select>
        </div>
        <div class="input-user__info">
          <ul
            class="input-user__errors"
            *ngIf="
              formControlValidator.isInvalid(userChatsAndTeamsForm, 'workplace')
            "
          >
            <li
              class="input-error input-error--absolute"
              *ngIf="
                formControlValidator.hasError(
                  userChatsAndTeamsForm,
                  'workplace',
                  'required'
                )
              "
            >
              O local de atuação deve ser informado
            </li>
          </ul>
        </div>
      </div>
      <div class="add-workplace" (click)="openCreateWorkplaceModal()">
        <div class="add">
          <div class="add-workplace__icon">
            <div class="cross"></div>
          </div>
          <div class="text">Cadastrar local de atuação</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group form-item col-lg-12">
        <label for="externalLogin"
          >Login externo
          <small class="observation-text">(obrigatório)</small></label
        >

        <div class="bla-column--25-relative">
          <input
            formControlName="externalLogin"
            autofocus
            [ngClass]="{
              'input--invalid': formControlValidator.isInvalid(
                userChatsAndTeamsForm,
                'externalLogin'
              )
            }"
            class="form-control"
            id="externalLogin"
          />
        </div>

        <div class="input-user__info">
          <ul
            class="input-user__errors"
            *ngIf="
              formControlValidator.isInvalid(
                userChatsAndTeamsForm,
                'externalLogin'
              )
            "
          >
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasError(
                  userChatsAndTeamsForm,
                  'externalLogin',
                  'required'
                )
              "
            >
              O usuário operacional precisa de um Login externo
            </li>
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasAnyError(
                  userChatsAndTeamsForm,
                  'externalLogin',
                  'maxlength',
                  'minlength'
                )
              "
            >
              O Login externo do usuário operacional deve ter entre 4 e 8
              caracteres
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group form-item col-lg-12">
        <label for="sellerCode"
          >Código do Vendedor
          <small class="observation-text">(obrigatório)</small></label
        >
        <div class="bla-column--25-relative">
          <input
            formControlName="sellerCode"
            autofocus
            [ngClass]="{
              'input--invalid': formControlValidator.isInvalid(
                userChatsAndTeamsForm,
                'sellerCode'
              )
            }"
            class="form-control"
            id="sellerCode"
          />
        </div>
        <div class="input-user__info">
          <ul
            class="input-user__errors"
            *ngIf="
              formControlValidator.isInvalid(
                userChatsAndTeamsForm,
                'sellerCode'
              )
            "
          >
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasError(
                  userChatsAndTeamsForm,
                  'sellerCode',
                  'required'
                )
              "
            >
              O usuário operacional precisa de um código do vendedor
            </li>
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasAnyError(
                  userChatsAndTeamsForm,
                  'sellerCode',
                  'maxlength',
                  'minlength'
                )
              "
            >
              O código do vendedor do usuário operacional deve ter 7 caracteres
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group form-item col-lg-12">
        <app-multiselect-datatable
          [formGroup]="userChatsAndTeamsForm"
          controlName="attendanceTeamsIds"
          label="Times"
          [items]="attendanceTeamDropDownItems"
        ></app-multiselect-datatable>
      </div>
    </div>

    <div class="form-actions">
      <button type="button" class="bla-btn" (click)="cancel()">
        Cancelar
      </button>
      <button
        [disabled]="userChatsAndTeamsForm.invalid"
        type="submit"
        class="bla-btn save-button"
        [ngClass]="
          userChatsAndTeamsForm.invalid
            ? 'bla-btn--disabled'
            : 'bla-btn--primary'
        "
      >
        Salvar
      </button>
    </div>
  </form>
</div>

<app-workplace-modal
  #workplaceModal
  class="hide"
  (workplaceAddedEmiter)="loadWorkplaces()"
></app-workplace-modal>
