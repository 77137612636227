<div class="bla-stepper">
  <ul class="bla-stepper__steps">
    <li class="bla-step" *ngFor="let step of steps">
      <div class="bla-step__state">
        <div
          [ngClass]="
            step.active && !step?.viewMode
              ? 'bla-step__state-icon--active'
              : !step.enabled
              ? 'bla-step__state-icon--disabled'
              : step.completed
              ? 'bla-step__state-icon'
              : !step.completed
              ? 'bla-step__state-icon--enabled'
              : ''
          "
        >
          <img
            *ngIf="
              step.completed && step.enabled && (!step.active || step?.viewMode)
            "
            src="../../assets/images/check.svg"
            alt="passo concluído"
          />
          <img
            *ngIf="step.active && !step?.viewMode"
            src="../../assets/images/pencil.svg"
            alt="passo ativo para edição"
          />
        </div>
        <div
          class="bla-step__state-line"
          [ngClass]="{
            'bla-step__state-line--checked': step.completed
          }"
        ></div>
      </div>
      <a
        [ngClass]="
          !step.completed && !step.enabled
            ? 'bla-step__link--disabled'
            : step.active
            ? 'bla-step__link--active'
            : 'bla-step__link'
        "
        id="v-pills-{{ step.id }}-tab"
        data-toggle="pill"
        href="#v-pills-{{ step.title }}"
        role="tab"
        [routerLink]="step.route"
        >{{ step.title }}
      </a>
    </li>
  </ul>
</div>
