export interface RatingReason {
  id: string
  name: string
}

export interface RatingRequest {
  rating: number
  message?: string
  attendanceId: string
  reasonId?: string
}

export enum AttendanceRatingStatus {
  Pending = 'PENDING',
  Expired = 'EXPIRED',
  Answered = 'ANSWERED',
}
