import { TemplateMessageVariablesComponent } from './template-message-variables/template-message-variables.component'
import { DropDownItem } from '@app/components/drop-down/drop-down-item'
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { InfoBarService } from '@app/info-bar/info-bar.service'
import { StepsEnum } from '@app/stepper/steps/template-message.steps'
import { FormControlValidator } from '@app/tools/form-control-validator'
import { HtmlTools } from '@app/tools/html-tools'
import { TemplateMessageService } from '../../template-message.service'
import { TemplateMessageStore } from '../../template-message.store'
import { MessageType } from '../../message-type'

@Component({
  selector: 'app-template-messages-message',
  templateUrl: './template-messages-message.component.html',
  styleUrls: ['./template-messages-message.component.scss'],
})
export class TemplateMessagesMessageComponent implements OnInit, AfterViewInit {
  templateMessageForm: FormGroup
  categoriesDropDownItems: DropDownItem[]
  nextButton: HTMLButtonElement

  @ViewChild(TemplateMessageVariablesComponent)
  templateMessageVariables: TemplateMessageVariablesComponent

  constructor(
    public formControlValidator: FormControlValidator,
    public store: TemplateMessageStore,
    private templateMessageService: TemplateMessageService,
    private formBuilder: FormBuilder,
    private htmlTools: HtmlTools,
    private infoBarService: InfoBarService,
  ) {}

  ngAfterViewInit(): void {
    this.nextButton = document.querySelector('.save-button')
  }

  ngOnInit(): void {
    this.initializeDropDownItems()
    this.store.setStep(StepsEnum.TemplateMessageMessage)
    this.initializeForm()
  }

  initializeForm = () => {
    this.templateMessageForm = this.formBuilder.group({
      templateId: [
        { value: '', disabled: this.store.isViewMode() },
        [Validators.required, Validators.maxLength(10)],
      ],
      name: [
        { value: '', disabled: this.store.isViewMode() },
        [Validators.required, Validators.maxLength(20)],
      ],
      messageCategory: [
        { value: '', disabled: this.store.isViewMode() },
        Validators.required,
      ],
      message: [
        { value: '', disabled: this.store.isViewMode() },
        [Validators.required, Validators.maxLength(1024)],
      ],
      parameters: this.formBuilder.array([]),
    })

    const formData = this.store.getFormData('Message')

    if (formData) {
      this.templateMessageForm.patchValue(formData)
      this.templateMessageForm.setControl(
        'parameters',
        this.formBuilder.array(formData.parameters || []),
      )
    }
  }

  submit = () => {
    if (this.templateMessageForm.valid) {
      const category = this.categoriesDropDownItems.find(
        x => x.value === this.templateMessageForm.get('messageCategory').value,
      )

      this.templateMessageForm?.get('messageCategory').setValue({
        id: category.value,
        name: category.text,
      })

      this.htmlTools.addAttributeToElement(this.nextButton, 'disabled', '')

      this.storeFormDataAndGoToNextStep()

      this.store.save()
    }
  }

  cancel = () => this.store.clear()

  storeFormDataAndGoToNextStep = () => {
    this.store.storeFormData(this.templateMessageForm.value, 'Message')

    this.store.nextStep()
    this.htmlTools.removeAttributeFromElement(this.nextButton, 'disabled')
  }

  initializeDropDownItems() {
    this.templateMessageService.getMessageCategories().subscribe(
      categories => {
        this.categoriesDropDownItems = categories.map(categorie => ({
          text: categorie.name,
          value: categorie.id,
        }))
      },
      () => {
        this.infoBarService.show(
          'Falha ao carregar as Categorias. Consulte o suporte se o problema persistir',
          'danger',
        )
      },
    )
  }

  messageTypeIsIndividual() {
    const messageTypeSelected = this.store.getFormData('General')?.messageType

    return (
      MessageType.Individual === MessageType[MessageType[messageTypeSelected]]
    )
  }
}
