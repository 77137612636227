import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Component, OnInit, ViewChild } from '@angular/core'
import { QueueStore } from '../../queue.store'
import { FormControlValidator } from '@app/tools/form-control-validator'
import { StepsEnum } from '@app/stepper/steps/queue.steps'
import { conditionalValidator } from '@app/custom-validators/conditional-validator'
import { EditQueueConfirmationModalComponent } from '@app/components/edit-queue-confirmation-modal/edit-queue-confirmation-modal.component'

@Component({
  selector: 'app-queue-automatic-messages',
  templateUrl: './queue-automatic-messages.component.html',
  styleUrls: ['./queue-automatic-messages.component.scss'],
})
export class QueueAutomaticMessagesComponent implements OnInit {
  automaticMessageForm: FormGroup
  lastStep: boolean
  @ViewChild('confirmationModal')
  confirmationModal: EditQueueConfirmationModalComponent
  StepsEnum = StepsEnum

  constructor(
    public formControlValidator: FormControlValidator,
    private formBuilder: FormBuilder,
    public store: QueueStore,
  ) {}

  ngOnInit(): void {
    this.store.setStep(StepsEnum.AutomaticMessage)
    this.initializeForm()
  }

  initializeForm = () => {
    this.automaticMessageForm = this.formBuilder.group({
      initialMessage: new FormControl('', [
        Validators.required,
        Validators.max(600),
      ]),
      enqueuedAttendanceMessage: new FormControl('', [
        Validators.required,
        Validators.maxLength(600),
      ]),
      outOfBusinessHoursMessage: new FormControl('', [
        Validators.required,
        Validators.maxLength(600),
      ]),
      attendanceClosingMessageSettings: this.formBuilder.group({
        enableAttendanceClosingMessage: new FormControl(false, [
          Validators.required,
        ]),
        attendanceClosingMessage: new FormControl('', [
          Validators.maxLength(600),
          conditionalValidator(
            () => this.enableAttendanceClosingMessage.value,
            Validators.required,
          ),
        ]),
      }),
    })

    this.updateValueAndValidityAttendanceClosingMessageSettings()

    const formData = this.store.getFormData('AutomaticMessages')

    if (formData) this.automaticMessageForm.setValue(formData)
  }

  submit = () => {
    if (this.automaticMessageForm.valid) {
      this.store.storeFormData(
        this.automaticMessageForm.value,
        'AutomaticMessages',
      )
      if (this.isUpdateMode()) this.confirmationModal.openModal()
      else this.store.nextStep()
    }
  }

  isUpdateMode = () => this.store.isUpdateMode()

  updateValueAndValidityAttendanceClosingMessageSettings() {
    this.automaticMessageForm?.controls?.attendanceClosingMessageSettings
      ?.get('enableAttendanceClosingMessage')
      .valueChanges.subscribe(value => {
        this.automaticMessageForm?.controls?.attendanceClosingMessageSettings
          ?.get('attendanceClosingMessage')
          ?.markAsUntouched()

        this.automaticMessageForm?.controls?.attendanceClosingMessageSettings
          ?.get('attendanceClosingMessage')
          ?.updateValueAndValidity()
      })
  }

  get enableAttendanceClosingMessage() {
    return this.automaticMessageForm?.controls?.attendanceClosingMessageSettings?.get(
      'enableAttendanceClosingMessage',
    )
  }

  cancel = () => this.store.clear()
}
