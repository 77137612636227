<div class="closing-batch-conversations-filter">
  <div class="bla-row">
    <div class="bla-column--25">
      <app-select
        [props]="queueField"
        [selectItems]="queues"
        (valuesSelected)="getFieldValue($event)"
        id="queue-select"
      ></app-select>
    </div>
    <div class="bla-column--25">
      <app-select
        [props]="senderField"
        [selectItems]="senders"
        (valuesSelected)="getFieldValue($event)"
        id="sender-select"
      ></app-select>
    </div>
    <div class="bla-column--50">
      <button
        class="bla-btn bla-btn--primary btn-search"
        (click)="search()"
        id="search-conversations"
      >
        Pesquisar
      </button>
    </div>
  </div>
</div>
