<div class="col-9 container template-messages">
  <app-title [title]="title"></app-title>

  <div class="template-messages-content">
    <app-stepper [steps]="store.steps$ | async"></app-stepper>

    <div class="template-messages-form">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
