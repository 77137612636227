<div
  class="info-card"
  [ngClass]="'info-card--' + props.color"
  *ngIf="props.type === InfoCardType.default"
>
  <div
    class="info-card__value"
    [ngClass]="'info-card__value--' + props.color"
    id="info-card-value"
  >
    {{ props.value }}
  </div>
  <div
    class="info-card__title"
    [ngClass]="
      props.title.type === TitleType.big
        ? 'info-card__title--big-' + props.color
        : 'info-card__title--small-' + props.color
    "
    id="info-card-title"
  >
    {{ props.title.text }}
  </div>
</div>

<div
  class="info-card info-card--big"
  [ngClass]="'info-card--' + props.color"
  *ngIf="props.type === InfoCardType.big"
  id="info-card-value"
>
  <div
    class="info-card__value info-card__value--big"
    [ngClass]="'info-card__value--' + props.color"
  >
    {{ props.value }}
  </div>
  <div
    class="info-card__title info-card__title--big"
    [ngClass]="'info-card__title--big-' + props.color"
    id="info-card-title"
  >
    {{ props.title.text }}
  </div>
</div>
