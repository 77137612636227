import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { ClosingBatchConversationsService } from '@app/action/services/closing-batch-conversations.service'
import { InactiveAttendances } from '@app/chat/attendance/inactive-attendances'
import { take } from 'rxjs/operators'

@Component({
  selector: 'app-closing-batch-conversations-filter',
  templateUrl: './closing-batch-conversations-filter.component.html',
  styleUrls: ['./closing-batch-conversations-filter.component.scss'],
})
export class ClosingBatchConversationsFilterComponent implements OnInit {
  @Output() filter: EventEmitter<any> = new EventEmitter()

  private _senderFilter = ''
  private _queueFilter = ''
  senderField: any = {}
  queueField: any = {}
  senders: any = []
  queues: any = []

  constructor(
    private closingBatchConversationsFilterService: ClosingBatchConversationsService,
  ) {}

  get senderFilter() {
    return this._senderFilter
  }

  set senderFilter(value: any) {
    this._senderFilter = value
  }

  get queueFilter() {
    return this._queueFilter
  }

  set queueFilter(value: string) {
    this._queueFilter = value
  }

  setProps = () => {
    this.senderField = {
      tag: 'sender',
      labelTitle: 'Última mensagem enviada',
      placeholder: 'Todos',
      multipleSelect: false,
      searchable: true,
    }

    this.queueField = {
      tag: 'queue',
      labelTitle: 'Fila',
      placeholder: 'Selecionar',
      multipleSelect: false,
      searchable: true,
    }
  }

  setSenders = () =>
    (this.senders = [
      { id: '', name: 'Todos' },
      { id: 1, name: 'Cliente' },
      { id: 2, name: 'Vendedor' },
    ])

  getQueues = () => {
    this.closingBatchConversationsFilterService
      .getQueues()
      .pipe(take(1))
      .subscribe(data => (this.queues = data))
  }

  ngOnInit(): void {
    this.setProps()
    this.getQueues()
    this.setSenders()
  }

  getFieldValue = ({ ids, tag }) => {
    if (tag === 'sender') this.senderFilter = ids
    if (tag === 'queue') this.queueFilter = ids
  }

  search(): void {
    const filter: InactiveAttendances = {
      queueId: this.queueFilter,
      sender: this.senderFilter,
    }

    this.filter.emit(filter)
  }
}
