import { HttpClient } from '@angular/common/http'
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
} from '@angular/core'
import { environment } from '@env/environment'
import { Sender } from '@app/chat/sender'
import { Type } from '@app/chat/type'
import { Message } from './message'
import { MediaService } from '@app/Media/media.service'

@Component({
  selector: 'app-messsage-bubble',
  templateUrl: './messsage-bubble.component.html',
  styleUrls: ['./messsage-bubble.component.scss'],
})
export class MesssageBubbleComponent implements OnInit, OnChanges {
  @Input() message: Message
  @Input() deletedMessageId: string

  @Output() openImageModal = new EventEmitter()
  @Output() openDeleteImageMessageModal = new EventEmitter()

  Sender = Sender
  Type = Type

  url = `${environment.urlBlaMessageManagementApi}/messages/media`

  imageURL: string
  loading = true

  milestoneIcon = {
    start: '../../../assets/images/beginAttendance.svg',
    end: '../../../assets/images/endAttendance.svg',
  }
  errorMessage: string
  messageId: string

  constructor(private http: HttpClient, private mediaService: MediaService) {}

  ngOnInit(): void {
    if (this.message.type === Type.Image) this.buildImage()
  }

  ngOnChanges() {
    this.messageId = this.deletedMessageId
  }

  openCustomerImageMessageModal = (url: string): string | any => {
    if (url) this.openImageModal.emit(url)
  }

  openCustomerDeleteImageMessageModal = (messageId: string): string | any => {
    this.openDeleteImageMessageModal.emit(messageId)
  }

  buildImageURL = (imageId: string) => {
    this.mediaService.getImageMedia(imageId).subscribe(
      () => {
        this.imageURL = `${this.url}/${imageId}`
        this.loading = false
      },
      () => {
        this.errorMessage = 'Imagem indisponível'
        this.loading = false
      },
    )
  }

  hideLoader = () => (this.loading = false)

  buildImage() {
    if (this.message.image) this.buildImageURL(this.message.image.id)
    else this.buildImageRemoved()
  }

  buildImageRemoved() {
    this.errorMessage = 'Imagem removida'
    this.hideLoader()
  }

  messageError() {
    return this.message.image ? this.errorMessage : 'Imagem removida'
  }
}
