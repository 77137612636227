import { Component, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { EditQueueConfirmationModalComponent } from '@app/components/edit-queue-confirmation-modal/edit-queue-confirmation-modal.component'
import { StepsEnum } from '@app/stepper/steps/queue.steps'
import { FormControlValidator } from '@app/tools/form-control-validator'

import { QueueStore } from '../../queue.store'
@Component({
  selector: 'app-queue-waiting',
  templateUrl: './queue-waiting.component.html',
  styleUrls: ['./queue-waiting.component.scss'],
})
export class QueueWaitingComponent implements OnInit {
  queueForm: FormGroup
  lastStep: boolean

  @ViewChild(EditQueueConfirmationModalComponent)
  confirmationModal: EditQueueConfirmationModalComponent

  StepsEnum = StepsEnum

  constructor(
    private formBuilder: FormBuilder,
    public formControlValidator: FormControlValidator,
    public store: QueueStore,
  ) {}

  ngOnInit() {
    this.store.setStep(StepsEnum.QueueWaiting)
    this.initializeForm()
  }

  initializeForm = () => {
    this.queueForm = this.formBuilder.group({
      maximumWaitSize: new FormControl('', [
        Validators.required,
        Validators.min(0),
        Validators.max(99),
      ]),
      fullQueueMessage: new FormControl('', [Validators.maxLength(500)]),
    })

    const formData = this.store.getFormData('Waiting')

    if (formData) {
      this.queueForm.setValue(formData)
    }
  }

  submit = () => {
    if (this.queueForm.valid) {
      this.store.storeFormData(this.queueForm.value, 'Waiting')

      if (this.store.isUpdateMode()) {
        this.confirmationModal.openModal()
      } else {
        this.store.nextStep()
      }
    }
  }

  cancel = () => this.store.clear()
}
