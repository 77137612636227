<div class="queue-form">
  <h2>Atendimento em Espera</h2>
  <hr />
  <form [formGroup]="queueForm" (ngSubmit)="submit()">
    <div class="row">
      <div class="form-group form-item col-lg-12">
        <label for="maximumWaitSize"
          >Quantidade máxima de posições de espera</label
        >
        <div class="row">
          <div class="col-xl-2 col-lg-3 col-md-3">
            <input
              type="number"
              formControlName="maximumWaitSize"
              autofocus
              [ngClass]="{
                'input--invalid': formControlValidator.isInvalid(
                  queueForm,
                  'maximumWaitSize'
                )
              }"
              class="form-control"
              id="maximumWaitSize"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="input-validation__info">
              <ul
                class="input-validation__errors"
                *ngIf="
                  formControlValidator.isInvalid(queueForm, 'maximumWaitSize')
                "
              >
                <li
                  class="input-error"
                  *ngIf="
                    formControlValidator.hasError(
                      queueForm,
                      'maximumWaitSize',
                      'required'
                    )
                  "
                  id="max-positions-required-error"
                >
                  Uma quantidade máxima precisa ser definida
                </li>
                <li
                  class="input-error"
                  *ngIf="
                    formControlValidator.hasAnyError(
                      queueForm,
                      'maximumWaitSize',
                      'min',
                      'max'
                    )
                  "
                  id="max-positions-length-error"
                >
                  A quantidade máxima deve ser entre 0 e 99 lugares
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group form-item col-lg-12">
            <label for="fullQueueMessage"
              >Mensagem para fila cheia
              <small>(opcional)</small>
              <br />
              <small class="input-form__tip"
                >Exemplo: "Por favor, aguarde um instante que já atenderei
                você".</small
              >
            </label>
            <textarea
              #fullQueueMessage
              formControlName="fullQueueMessage"
              [ngClass]="{
                'input--invalid': formControlValidator.isInvalid(
                  queueForm,
                  'fullQueueMessage'
                )
              }"
              class="form-control"
              id="fullQueueMessage"
            ></textarea>
            <app-character-count
              class="full-queue-message"
              [charactersLimit]=""
              [characters]="fullQueueMessage.value"
            ></app-character-count>
            <div class="input-validation__info">
              <ul
                class="input-validation__errors"
                *ngIf="
                  formControlValidator.isInvalid(queueForm, 'fullQueueMessage')
                "
              >
                <li
                  class="input-error"
                  *ngIf="
                    formControlValidator.hasError(
                      queueForm,
                      'fullQueueMessage',
                      'maxlength'
                    )
                  "
                  id="full-queue-message-error"
                >
                  A mensagem deve conter no máximo 500 caracteres
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bla-actions">
      <button
        type="button"
        class="bla-btn"
        routerLink="/cadastros/fila/"
        (click)="cancel()"
      >
        Cancelar
      </button>
      <button
        [disabled]="queueForm.invalid"
        class="bla-btn bla-btn--primary"
        [ngClass]="{
          'bla-btn--disabled': queueForm.invalid,
          'save-button': store.isUpdateMode(),
          'next-button': !store.isUpdateMode()
        }"
      >
        {{ store.isUpdateMode() ? 'Salvar Alterações' : 'Avançar' }}
      </button>
    </div>
  </form>
</div>

<app-edit-queue-confirmation-modal
  [id]="'queue-waiting-setting-edit-modal'"
  [step]="StepsEnum.QueueWaiting"
  #confirmationModal
></app-edit-queue-confirmation-modal>
