import { BusinessHoursNameComponent } from './forms/business-hours-name/business-hours-name.component'
import { BusinessHoursTimetableComponent } from './forms/business-hours-timetable/business-hours-timetable.component'

export const businessHoursRoutes = [
  {
    path: '',
    component: BusinessHoursNameComponent,
  },
  {
    path: 'horarios',
    component: BusinessHoursTimetableComponent,
  },
]
