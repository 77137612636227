import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { DropDownItem } from '@app/components/drop-down/drop-down-item'
import { InfoBarService } from '@app/info-bar/info-bar.service'
import { StepsEnum } from '@app/stepper/steps/user.steps'
import { FormControlValidator } from '@app/tools/form-control-validator'
import { HtmlTools } from '@app/tools/html-tools'
import { AccessProfile } from '../../user.model'
import { UserService } from '../../user.service'
import { UserStore } from '../../user.store'
import { Router } from '@angular/router'
import { StoreMode } from '@app/register/register.model'
import { LocalStorageService } from '@app/tools/local-storage.service'
import { LoggedUser } from '@app/components/authentication/logged-user.model'

@Component({
  selector: 'app-user-access',
  templateUrl: './user-access.component.html',
  styleUrls: ['./user-access.component.scss'],
})
export class UserAccessComponent implements OnInit {
  userAccessForm: FormGroup
  saveButton: HTMLButtonElement
  accessProfileDropDownItems: DropDownItem[]
  loggedUser: LoggedUser

  constructor(
    public formControlValidator: FormControlValidator,
    private formBuilder: FormBuilder,
    private infoBarService: InfoBarService,
    private userService: UserService,
    private store: UserStore,
    private htmlTools: HtmlTools,
    private router: Router,
    private localStorageService: LocalStorageService,
  ) {}

  isOperationalUser(): boolean {
    return (
      this.userAccessForm.get('accessProfile').value ===
      AccessProfile.Operational.toString()
    )
  }

  ngOnInit() {
    this.loggedUser = this.localStorageService.getLoggedUser()
    this.store.setStep(StepsEnum.Access)
    this.initializeForm()
    this.loadAccessProfiles()
    this.saveButton = document.querySelector('.submit-button')
  }

  initializeForm() {
    this.userAccessForm = this.formBuilder.group({
      name: new FormControl('', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(50),
        Validators.pattern(/^[[A-zÀ-ú\s]+$/),
      ]),
      username: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
        Validators.pattern(/^[a-zA-Z0-9]+$/),
      ]),
      accessProfile: new FormControl('', [Validators.required]),
      active: new FormControl(false),
    })

    const formData = this.store.getFormData('Access')

    if (formData) this.userAccessForm.setValue(formData)
  }

  loadAccessProfiles() {
    this.userService.getProfiles(this.loggedUser?.userInfo.id).subscribe(
      infos =>
        (this.accessProfileDropDownItems = infos.map(item => ({
          text: item.value,
          value: item.id,
        }))),
      ({ error }) => {
        this.infoBarService.show(error, 'danger')
      },
    )
  }

  setUserSteps = () => {
    const accessProfile = this.userAccessForm.get('accessProfile')
      .value as AccessProfile

    this.store.setUserSteps(accessProfile)
  }

  save = () => {
    if (this.userAccessForm.valid) {
      this.htmlTools.addAttributeToElement(this.saveButton, 'disabled', '')

      if (this.isOperationalUser()) {
        if (this.store.mode === StoreMode.Create) {
          this.userService
            .checkIfUsernameExists(this.userAccessForm.get('username').value)
            .subscribe(
              response => {
                if (response.exists)
                  this.infoBarService.show(
                    'Já existe um usuário com esse username',
                    'danger',
                  )

                this.htmlTools.removeAttributeFromElement(
                  this.saveButton,
                  'disabled',
                )

                return
              },
              ({ error }) => {
                this.infoBarService.show(error, 'danger')
                this.htmlTools.removeAttributeFromElement(
                  this.saveButton,
                  'disabled',
                )
              },
            )
        }
        this.store.storeFormData(this.userAccessForm.value, 'Access')
        this.store.nextStep()
      } else {
        const successCallBack = (message: string) => {
          this.infoBarService.show(message, 'success')
          this.htmlTools.removeAttributeFromElement(this.saveButton, 'disabled')
          this.router.navigateByUrl('/cadastros/usuario/lista')
        }

        const errorCallBack = (error: any) => {
          this.infoBarService.show(error, 'danger')
          this.htmlTools.removeAttributeFromElement(this.saveButton, 'disabled')
        }

        if (this.store.mode === StoreMode.Create) {
          this.userService.save(this.userAccessForm.value).subscribe(
            () => successCallBack('Usuário cadastrado com sucesso'),
            ({ error }) => errorCallBack(error),
          )
        } else if (this.store.mode === StoreMode.Update) {
          this.userService
            .update(this.store.userId, this.userAccessForm.value)
            .subscribe(
              () => successCallBack('Usuário alterado com sucesso'),
              ({ error }) => errorCallBack(error),
            )
        }
      }
    }
  }

  public cancel = () => {
    this.store.clear()
    this.router.navigateByUrl('cadastros/usuario/lista')
  }
}
