import {
  Component,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core'
import { errors } from '../../workplace'
import { WorkplaceService } from '../../workplace.service'
import { InfoBarService } from '@app/info-bar/info-bar.service'

@Component({
  selector: 'app-workplace-modal',
  templateUrl: './workplace-modal.component.html',
  styleUrls: ['./workplace-modal.component.scss'],
})
export class WorkplaceModalComponent {
  constructor(
    private element: ElementRef,
    private service: WorkplaceService,
    private infoBar: InfoBarService,
  ) {}

  code: string
  name: string
  validCode = false
  validName = false

  @ViewChild('code') codeErrorMessage: ElementRef
  @ViewChild('name') nameErrorMessage: ElementRef
  @ViewChild('codeInput') codeInput: ElementRef
  @ViewChild('nameInput') nameInput: ElementRef

  @Output() workplaceAddedEmiter: EventEmitter<boolean> = new EventEmitter<
    boolean
  >()

  close = () => {
    this.element.nativeElement.classList.remove('show')
    this.element.nativeElement.classList.add('hide')
    this.resetForm()
  }

  open = () => {
    this.element.nativeElement.classList.remove('hide')
    this.element.nativeElement.classList.add('show')
  }

  createWorkplace = () => {
    const workplaceRequest = { code: this.code, name: this.name }

    if (this.validCode && this.validName) {
      this.service.post(workplaceRequest).subscribe(
        result => {
          this.close()
          this.infoBar.show(
            'Local de atuação cadastrado com sucesso',
            'success',
          )
          this.workplaceAddedEmiter.emit(true)
        },
        () => {
          this.close()
          this.infoBar.show(
            'Ocorreu um erro ao cadastrar o local de atuação. Consulte o suporte se o problema persistir',
            'danger',
          )
        },
      )
    }
  }

  validateName = (value: string) => {
    this.nameErrorMessage.nativeElement.innerHTML = ''
    this.validName = true

    this.name = value

    if (this.isFieldEmpty(this.name)) {
      this.nameErrorMessage.nativeElement.innerHTML = errors.nameField.empty
      this.validName = false
    }

    return this.validName
  }

  validateCode = (value: string) => {
    this.codeErrorMessage.nativeElement.innerHTML = ''
    this.validCode = true

    this.code = value

    if (this.isFieldEmpty(this.code)) {
      this.codeErrorMessage.nativeElement.innerHTML = errors.codeField.empty
      this.validCode = false
    } else if (this.code.length !== 2) {
      this.codeErrorMessage.nativeElement.innerHTML = errors.codeField.minLength
      this.validCode = false
    }

    return this.validCode
  }

  isFieldEmpty = (value: string) => value?.length <= 0

  resetForm = () => {
    this.code = ''
    this.name = ''
    this.codeInput.nativeElement.value = ''
    this.nameInput.nativeElement.value = ''
    this.codeErrorMessage.nativeElement.innerHTML = ''
    this.nameErrorMessage.nativeElement.innerHTML = ''
  }
}
