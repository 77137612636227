<div class="business-hours-form">
  <h2>Nome</h2>
  <hr />
  <form [formGroup]="businessHoursForm" (ngSubmit)="next()">
    <div class="row">
      <div class="form-group form-item col-lg-12">
        <label for="name"
          >Nome da grade de horário <small>(obrigatório)</small></label
        >
        <input
          appCharactersLimit="35"
          formControlName="name"
          autofocus
          class="form-control"
          id="name"
        />
        <div class="input-business_hours__info">
          <ul
            class="input-business_hours__errors"
            *ngIf="formControlValidator.isInvalid(businessHoursForm, 'name')"
          >
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasError(
                  businessHoursForm,
                  'name',
                  'required'
                )
              "
            >
              A fila precisa de um nome.
            </li>
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasAnyError(
                  businessHoursForm,
                  'name',
                  'maxlength',
                  'minlength'
                )
              "
            >
              O nome da fila deve conter entre 3 e 35 letras.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group form-item col-lg-12">
        <app-multiselect-datatable
          [items]="queuesDropDownItems"
          label="Fila de Chat"
          [formGroup]="businessHoursForm"
          controlName="queues"
        >
        </app-multiselect-datatable>
      </div>
    </div>

    <div class="bla-actions">
      <div>
        <button type="button" class="bla-btn" routerLink="">
          Cancelar
        </button>
        <button
          type="submit"
          [disabled]="businessHoursForm.invalid"
          class="bla-btn next-button"
          [ngClass]="
            businessHoursForm.invalid ? 'bla-btn--disabled' : 'bla-btn--primary'
          "
        >
          Avançar
        </button>
      </div>
    </div>
  </form>
</div>
