import { Step } from '@app/stepper/steps/step'

export enum StepsEnum {
  QueueName = 0,
  AutomaticMessage = 1,
  QueueWaiting = 2,
  QueueAttachments = 3,
}

export const queueSteps = [
  {
    index: 0,
    active: true,
    title: 'Configurações Gerais',
    id: 'nome',
    route: '/cadastros/fila/nova',
    completed: false,
    name: StepsEnum.QueueName,
    enabled: true,
  },
  {
    index: 1,
    active: false,
    title: 'Mensagens Automáticas',
    id: 'mensagens',
    route: '/cadastros/fila/nova/mensagens',
    completed: false,
    name: StepsEnum.AutomaticMessage,
    enabled: false,
  },
  {
    index: 2,
    active: false,
    title: 'Atendimento em Espera',
    id: 'espera',
    route: '/cadastros/fila/nova/espera',
    completed: false,
    name: StepsEnum.QueueWaiting,
    enabled: false,
  },
  {
    index: 3,
    active: false,
    title: 'Permissão de Anexos',
    id: 'anexos',
    route: '/cadastros/fila/nova/anexos',
    completed: false,
    name: StepsEnum.QueueAttachments,
    enabled: false,
  },
] as Step[]

export const editModeSteps = () =>
  queueSteps.map((step: Step) => ({
    ...step,
    completed: true,
    enabled: true,
  }))
