<h4>Frases</h4>

<ngx-datatable
  #table
  [columnMode]="ColumnMode.flex"
  [messages]="messages"
  [footerHeight]="hiddenMenu ? 0 : 30"
  [rows]="rows"
  rowHeight="auto"
  [limit]="pageSize"
  [sortType]="SortType.multi"
  id="template-message-result"
>
  <ngx-datatable-column
    name=""
    [flexGrow]="0.3"
    [sortable]="false"
    cellClass="column-details"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      <app-details-icon
        [visited]="row.visited"
        (click)="onDetailTemplateMessages(row)"
      ></app-details-icon>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column prop="brokerType" name="Canal" [flexGrow]="1">
    <ng-template let-value="value" ngx-datatable-cell-template>
      {{ value }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    [sortable]="false"
    prop="queues"
    name="Fila"
    [flexGrow]="1"
    cellClass="queue-cell"
  >
    <ng-template let-value="value" ngx-datatable-cell-template>
      {{ value }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    [sortable]="false"
    prop="message"
    name="Mensagem"
    [flexGrow]="4"
    cellClass="ellipsis"
  >
    <ng-template let-value="value" ngx-datatable-cell-template>
      {{ value }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    [sortable]="false"
    prop="status"
    name="Status"
    [flexGrow]="0.8"
  >
    <ng-template ngx-datatable-cell-template let-row="row">
      <input
        id="{{ row['templateId'] }}"
        type="checkbox"
        class="checkbox"
        [checked]="row['status']"
        (change)="changeTemplateMessageStatus($event, row['templateId'])"
      />
      <label
        for="{{ row['templateId'] }}"
        class="switch"
        id="{{ row['templateId'] }}-switch"
      >
        <div class="switch-on"></div>
        <div class="switch-off"></div>
      </label>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    [sortable]="false"
    prop="templateId"
    name="Ações"
    [flexGrow]="0.8"
  >
    <ng-template let-value="value" ngx-datatable-cell-template>
      <a
        (click)="$event.preventDefault(); showModal(value)"
        id="edit-user-{{ value }}"
        href
        >Excluir</a
      >
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-footer>
    <ng-template
      ngx-datatable-footer-template
      let-rowCount="rowCount"
      let-pageSize="pageSize"
      let-selectedCount="selectedCount"
      let-curPage="curPage"
      let-offset="offset"
    >
      <datatable-pager
        [pagerLeftArrowIcon]="'datatable-icon-left'"
        [pagerRightArrowIcon]="'datatable-icon-right'"
        [page]="curPage"
        [size]="pageSize"
        [count]="rowCount"
        [hidden]="!(rowCount / pageSize > 1)"
        (change)="table.onFooterPage($event)"
      >
      </datatable-pager>
    </ng-template>
  </ngx-datatable-footer>
</ngx-datatable>

<app-modal
  type="default"
  class="hide"
  id="template-messages-delete-warning-modal"
>
  <section id="template-messages-delete-warning-modal-content">
    <div id="template-messages-delete-warning-modal-text">
      Tem certeza que deseja excluir este registro?
    </div>

    <div id="template-messages-delete-warning-modal-buttons">
      <button id="cancel-button" class="btn bla-btn" (click)="closeModal()">
        Cancelar
      </button>

      <button
        id="confirm-button"
        class="btn bla-btn--danger"
        (click)="deleteTemplateMessage()"
      >
        Sim
      </button>
    </div>
  </section>
</app-modal>
