import { AccessProfile } from './../../register/user/user.model'
import { Injectable } from '@angular/core'
import { AttendanceService } from '../attendance/attendance.service'
import {
  AttendanceTransferInfo,
  TransferReason,
} from '../attendance/attendance'
import { Observable } from 'rxjs'
import { UserService } from '@app/register/user/user.service'
import { QueueService } from '@app/register/queue/queue.service'

@Injectable({
  providedIn: 'root',
})
export class TransferAttendanceService {
  constructor(
    private attendanceService: AttendanceService,
    private userService: UserService,
    private queueService: QueueService,
  ) {}

  public transferAttendance(
    attendanceId: string,
    destinationQueueId: string,
    destinationAttendantId: string,
    transferReasonId: string,
    accessProfile: AccessProfile,
  ): Observable<any> {
    const transferInfo: AttendanceTransferInfo = {
      destinationQueueId,
      destinationAttendantId: destinationAttendantId
        ? destinationAttendantId
        : null,
      transferReasonId,
      accessProfile,
    }

    return this.attendanceService.transferAttendance(attendanceId, transferInfo)
  }

  public getOperationalUsersByQueue = (queueId: string): Observable<any> =>
    this.userService.getOperationalUsersByQueue(queueId)

  public checkQueueMakeTransferAvailability = (
    queueId: string,
  ): Observable<boolean> =>
    this.queueService.checkQueueMakeTransferAvailability(queueId)

  public getAvailableQueuesForTransfer = (): Observable<any> =>
    this.queueService.getAvailableQueuesForTransfer()

  public getTransferReasons = (): Observable<TransferReason> =>
    this.attendanceService.getTransferReasons()
}
