import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { QueueNameAndId } from '@app/register/queue/queue.model'
import { QueueService } from '@app/register/queue/queue.service'
import { environment } from '@env/environment'
import { Observable } from 'rxjs'
import {
  BusinessHoursExistsResponse,
  BusinessHours,
} from './business-hours.model'

@Injectable()
export class BusinessHoursService {
  private url: string

  constructor(private http: HttpClient, private queueService: QueueService) {
    this.url = `${environment.urlBlaMessageManagementApi}/BusinessHours`
  }

  getQueueNamesAndIds(): Observable<QueueNameAndId[]> {
    return this.queueService.getQueueNamesAndIds()
  }

  checkIfBusinessHoursNameExists = (name: string) => {
    return this.http.post<BusinessHoursExistsResponse>(
      `${this.url}/CheckBusinessHoursName`,
      {
        name,
      },
    )
  }

  public save(businessHours: BusinessHours): Observable<BusinessHours> {
    return this.http.post<BusinessHours>(this.url, businessHours)
  }
}
