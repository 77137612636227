export class MonitoringAttendancesFilterModel {
  sellersIds: []
  closingReasonsIds: []
  closingTypesIds: []
  queuesIds: []
  workplaceId: any
  startDate: any
  endDate: any
  phoneNumber: any
  rating: any
  ratingReason: any

  getStartDate = () =>
    this.startDate === 'invalid'
      ? this.startDate
      : this.startDate?.toISOString() || null

  getEndDate = () =>
    this.endDate === 'invalid'
      ? this.endDate
      : this.endDate?.toISOString() || null

  constructor(
    sellersIds?: any,
    closingReasonsIds?: any,
    closingTypesIds?: any,
    queuesIds?: any,
    workplaceId?: any,
    startDate?: any,
    endDate?: any,
    phoneNumber?: any,
    rating?: any,
    ratingReason?: any,
  ) {
    this.sellersIds = sellersIds
    this.closingReasonsIds = closingReasonsIds
    this.closingTypesIds = closingTypesIds
    this.queuesIds = queuesIds
    this.workplaceId = workplaceId
    this.startDate = startDate
    this.endDate = endDate
    this.phoneNumber = phoneNumber
    this.rating = rating
    this.ratingReason = ratingReason
  }
}
