<div class="container">
  <app-title [title]="title"></app-title>
  <form [formGroup]="brokerForm" (ngSubmit)="save()">
    <div class="row">
      <div class="form-group form-item col-lg-6">
        <app-drop-down
          [formGroup]="brokerForm"
          autofocus
          controlName="type"
          label="Selecione o Tipo de Broker"
          [items]="brokerTypesDropDownItems"
          [isRequired]="true"
          requiredMessageError="Um Tipo de Broker deve ser selecionado."
          (change)="changedBrokerType()"
        ></app-drop-down>
      </div>
    </div>
    <div class="row">
      <div class="form-group form-item col-lg-6">
        <label for="name">Nome do broker <small>(obrigatório)</small></label>
        <input
          #brokerName
          [readonly]="isUpdateMode()"
          appCharactersLimit="35"
          formControlName="name"
          [ngClass]="{
            'input--invalid': formControlValidator.isInvalid(brokerForm, 'name')
          }"
          class="form-control"
          id="name"
        />
        <div class="input-broker__info">
          <ul
            class="input-broker__errors"
            *ngIf="formControlValidator.isInvalid(brokerForm, 'name')"
          >
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasError(brokerForm, 'name', 'required')
              "
            >
              O broker precisa de um nome.
            </li>
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasAnyError(
                  brokerForm,
                  'name',
                  'maxlength',
                  'minlength'
                )
              "
            >
              O nome do broker deve ter entre 3 e 35 letras.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="!isBrokerCloud">
      <div class="form-group form-item col-lg-6">
        <label for="endpoint">Endpoint <small>(obrigatório)</small></label>
        <input
          formControlName="endpoint"
          [ngClass]="{
            'input--invalid': formControlValidator.isInvalid(
              brokerForm,
              'endpoint'
            )
          }"
          class="form-control"
          id="endpoint"
        />
        <div class="input-broker__info">
          <ul
            class="input-broker__errors"
            *ngIf="formControlValidator.isInvalid(brokerForm, 'endpoint')"
          >
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasError(
                  brokerForm,
                  'endpoint',
                  'required'
                )
              "
            >
              O endpoint é obrigatório.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!isBrokerCloud">
      <div class="form-group form-item col-lg-6">
        <label for="integrationToken"
          >Token de integração <small>(obrigatório)</small></label
        >
        <input
          formControlName="integrationToken"
          [ngClass]="{
            'input--invalid': formControlValidator.isInvalid(
              brokerForm,
              'integrationToken'
            )
          }"
          class="form-control"
          id="integrationToken"
        />
        <div class="input-broker__info">
          <ul
            class="input-broker__errors"
            *ngIf="
              formControlValidator.isInvalid(brokerForm, 'integrationToken')
            "
          >
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasError(
                  brokerForm,
                  'integrationToken',
                  'required'
                )
              "
            >
              O token de integração é obrigatório.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="isBrokerCloud">
      <div class="form-group form-item col-lg-6">
        <label for="appId">App Id <small>(obrigatório)</small></label>
        <input
          formControlName="appId"
          autofocus
          [ngClass]="{
            'input--invalid': formControlValidator.isInvalid(
              brokerForm,
              'appId'
            )
          }"
          class="form-control"
          id="appId"
        />
        <div class="input-broker__info">
          <ul
            class="input-broker__errors"
            *ngIf="formControlValidator.isInvalid(brokerForm, 'appId')"
          >
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasError(brokerForm, 'appId', 'required')
              "
            >
              O identificador do aplicativo (App ID) é obrigatório.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="isBrokerCloud">
      <div class="form-group form-item col-lg-6">
        <label for="appSecret">App Secret <small>(obrigatório)</small></label>
        <input
          formControlName="appSecret"
          autofocus
          [ngClass]="{
            'input--invalid': formControlValidator.isInvalid(
              brokerForm,
              'appSecret'
            )
          }"
          class="form-control"
          id="appSecret"
        />
        <div class="input-broker__info">
          <ul
            class="input-broker__errors"
            *ngIf="formControlValidator.isInvalid(brokerForm, 'appSecret')"
          >
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasError(
                  brokerForm,
                  'appSecret',
                  'required'
                )
              "
            >
              A Chave Secreta do Aplicativo (App Secret) é obrigatória.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="isBrokerCloud">
      <div class="form-group form-item col-lg-6">
        <label for="phoneNumberId"
          >Phone Number ID <small>(obrigatório)</small></label
        >
        <input
          formControlName="phoneNumberId"
          autofocus
          [ngClass]="{
            'input--invalid': formControlValidator.isInvalid(
              brokerForm,
              'phoneNumberId'
            )
          }"
          class="form-control"
          id="phoneNumberId"
        />
        <div class="input-broker__info">
          <ul
            class="input-broker__errors"
            *ngIf="formControlValidator.isInvalid(brokerForm, 'phoneNumberID')"
          >
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasError(
                  brokerForm,
                  'phoneNumberID',
                  'required'
                )
              "
            >
              O Phone Number ID é obrigatório.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="isBrokerCloud">
      <div class="form-group form-item col-lg-6">
        <label for="userToken">User Token <small>(obrigatório)</small></label>
        <input
          formControlName="userToken"
          autofocus
          [ngClass]="{
            'input--invalid': formControlValidator.isInvalid(
              brokerForm,
              'userToken'
            )
          }"
          class="form-control"
          id="userToken"
        />
        <div class="input-broker__info">
          <ul
            class="input-broker__errors"
            *ngIf="formControlValidator.isInvalid(brokerForm, 'userToken')"
          >
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasError(
                  brokerForm,
                  'userToken',
                  'required'
                )
              "
            >
              O Token de Usuário é obrigatório.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="checkbox-container form-item form-group col-lg-6">
        <label for="active">Ativo</label>
        <input
          formControlName="active"
          type="checkbox"
          id="active"
          class="checkbox"
        />
        <label for="active" class="switch">
          <div class="switch-on">Sim</div>
          <div class="switch-off">Não</div>
        </label>
      </div>
    </div>

    <div class="row">
      <div class="form-group form-item col-lg-6">
        <app-drop-down
          [formGroup]="brokerForm"
          controlName="queueId"
          label="Selecione a Fila"
          [items]="queuesInfosDropDownItems"
          [isRequired]="true"
          requiredMessageError="Uma fila deve ser selecionada."
        ></app-drop-down>
      </div>
    </div>
    <div class="bla-actions col-lg-6">
      <button
        type="button"
        class="bla-btn"
        routerLink="/cadastros/broker"
        (click)="cancel()"
      >
        Cancelar
      </button>
      <button
        [disabled]="brokerForm.invalid"
        type="submit"
        class="bla-btn save-button"
        [ngClass]="
          brokerForm.invalid ? 'bla-btn--disabled' : 'bla-btn--primary'
        "
      >
        Salvar
      </button>
    </div>
  </form>
</div>
