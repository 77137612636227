import { Step } from '@app/stepper/steps/step'

export enum StepsEnum {
  Access = 0,
  ChatsAndTeams = 1,
}

export const administrativeUserSteps = [
  {
    index: 0,
    active: true,
    title: 'Acessos',
    id: 'acessos',
    route: '/cadastros/usuario',
    completed: false,
    name: StepsEnum.Access,
    enabled: true,
  },
] as Step[]

export const operationalUserSteps = [
  ...administrativeUserSteps,
  {
    index: 1,
    active: false,
    title: 'Local de atuação',
    id: 'chats-e-times',
    route: `/cadastros/usuario/chats-e-times`,
    completed: false,
    name: StepsEnum.ChatsAndTeams,
    enabled: false,
  },
] as Step[]
