<div class="form-container__subtitle--centered" [innerHTML]="subtitle"></div>
<form [formGroup]="phoneNumberForm">
  <div class="phone-number-contact" *ngIf="showContactInput">
    <input
      appPhoneNumber
      formControlName="phoneNumber"
      maxlength="15"
      type="tel"
      class="bla-input"
      id="customer-phone"
      placeholder="(00) 00000-0000"
    />
  </div>

  <div class="form-container__subtitle--centered">
    <p>Confirme abaixo! E mande aquele "Oi" para começarmos</p>
  </div>

  <div class="direct-contact-actions">
    <button
      id="btn-send-phoneNumber"
      class="bla-btn bla-btn--primary"
      (click)="handleClick()"
      [disabled]="phoneNumberForm.invalid && showContactInput"
      [ngClass]="
        phoneNumberForm.invalid && showContactInput
          ? 'bla-btn--disabled'
          : 'bla-btn--primary'
      "
    >
      Confirmar
    </button>
  </div>
</form>
