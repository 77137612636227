import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@env/environment'
import { Observable } from 'rxjs'
import {
  MessageCategorieNameAndId,
  TemplateMessage,
  TemplateMessageResponse,
} from './template-message.model'
import { TemplateMessageFilterModel } from './template-message-filter/template-message-filter.model'

@Injectable({
  providedIn: 'root',
})
export class TemplateMessageService {
  private url: string

  constructor(private http: HttpClient) {
    this.url = `${environment.urlBlaMessageManagementApi}/TemplateMessage`
  }

  getTemplateMessageById = (
    templateId: string,
  ): Observable<TemplateMessageResponse> =>
    this.http.get<TemplateMessageResponse>(`${this.url}/${templateId}`)

  getMessageCategories(): Observable<MessageCategorieNameAndId[]> {
    return this.http.get<MessageCategorieNameAndId[]>(
      `${this.url}/MessageCategory`,
    )
  }

  save = (templateMessage: TemplateMessage): Observable<TemplateMessage> => {
    return this.http.post<TemplateMessage>(this.url, templateMessage)
  }

  getTemplateMessagesByFilters(
    filters: TemplateMessageFilterModel,
  ): Observable<any> {
    return this.http.get<any[]>(this.url, {
      params: {
        BrokerTypes: filters.brokerTypes,
        QueuesIds: filters.queuesIds,
        TemplateMessageStatus: filters.status,
        TextMessage: filters.message,
      },
    })
  }

  delete = (templateId: string): Observable<any> =>
    this.http.delete(`${this.url}/${templateId}`)

  patchTemplateMessageStatus(templateId: string, status: boolean) {
    return this.http.patch(`${this.url}/${templateId}/status/${status}`, null)
  }
}
