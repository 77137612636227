import { Component, OnInit } from '@angular/core'
import { BusinessHoursStore } from '../../business-hours.store'
import { StepsEnum } from '@app/stepper/steps/business-hours.steps'
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms'
import { conditionalValidator } from '@app/custom-validators/conditional-validator'
import { FormControlValidator } from '@app/tools/form-control-validator'

@Component({
  selector: 'app-business-hours-timetable',
  templateUrl: './business-hours-timetable.component.html',
  styleUrls: ['./business-hours-timetable.component.scss'],
})
export class BusinessHoursTimetableComponent implements OnInit {
  timeTableForm: FormGroup

  constructor(
    private store: BusinessHoursStore,
    private formBuilder: FormBuilder,
    public formControlValidator: FormControlValidator,
  ) {}

  ngOnInit(): void {
    this.store.setStep(StepsEnum.TimeTable)
    this.initializeForm()
  }

  initializeForm() {
    this.timeTableForm = this.formBuilder.group({
      mondayGroup: this.formBuilder.group(
        {
          monday: new FormControl(true),
          mondayStartTime: new FormControl(''),
          mondayEndTime: new FormControl(''),
        },
        { validator: this.businessHoursValidator('monday') },
      ),

      tuesdayGroup: this.formBuilder.group(
        {
          tuesday: new FormControl(true),
          tuesdayStartTime: new FormControl(''),
          tuesdayEndTime: new FormControl(''),
        },
        { validator: this.businessHoursValidator('tuesday') },
      ),

      wednesdayGroup: this.formBuilder.group(
        {
          wednesday: new FormControl(true),
          wednesdayStartTime: new FormControl(''),
          wednesdayEndTime: new FormControl(''),
        },
        { validator: this.businessHoursValidator('wednesday') },
      ),

      thursdayGroup: this.formBuilder.group(
        {
          thursday: new FormControl(true),
          thursdayStartTime: new FormControl(''),
          thursdayEndTime: new FormControl(''),
        },
        { validator: this.businessHoursValidator('thursday') },
      ),

      fridayGroup: this.formBuilder.group(
        {
          friday: new FormControl(true),
          fridayStartTime: new FormControl(''),
          fridayEndTime: new FormControl(''),
        },
        { validator: this.businessHoursValidator('friday') },
      ),

      saturdayGroup: this.formBuilder.group(
        {
          saturday: new FormControl(true),
          saturdayStartTime: new FormControl(''),
          saturdayEndTime: new FormControl(''),
        },
        { validator: this.businessHoursValidator('saturday') },
      ),

      sundayGroup: this.formBuilder.group(
        {
          sunday: new FormControl(true),
          sundayStartTime: new FormControl(''),
          sundayEndTime: new FormControl(''),
        },
        { validator: this.businessHoursValidator('sunday') },
      ),
    })
  }

  businessHoursValidator = (day: string): ValidatorFn => {
    return (formGroup: FormControl): { [key: string]: boolean } | null => {
      let errors = {}
      const pattern = /^(0[0-9]|1[0-9]|2[0-3])[0-5][0-9]$/
      const startTimeValue = formGroup.get(day + 'StartTime').value
      const endTimeValue = formGroup.get(day + 'EndTime').value

      if (formGroup.get(day).value) {
        if (startTimeValue === '') {
          errors = { ...errors, [day + 'StartTimeEmpty']: true }
        }

        if (endTimeValue === '') {
          errors = { ...errors, [day + 'EndTimeEmpty']: true }
        }

        if (!startTimeValue.match(pattern)) {
          errors = { ...errors, [day + 'StartTimePattern']: true }
        }

        if (!endTimeValue.match(pattern)) {
          errors = { ...errors, [day + 'EndTimePattern']: true }
        }

        if (Number(startTimeValue) >= Number(endTimeValue)) {
          errors = { ...errors, [day + 'TimeError']: true }
        }

        return Object.keys(errors).length === 0 ? null : errors
      }

      return null
    }
  }

  save = () => {
    if (this.timeTableForm.valid) {
      this.store.storeFormData(this.timeTableForm.value, 'TimeTable')
      this.store.save()
    }
  }
}
