<div class="bla-modal exclude-review-modal">
  <div class="bla-modal__body">
    <h1 class="bla-modal-title">Excluir observação</h1>
    <div class="bla-modal-separator"></div>
    <div class="bla-modal-text-info">
      Deseja excluir esta observação?<br />Esta ação não pode ser desfeita.
    </div>
    <div class="bla-modal-actions">
      <button class="bla-btn bla-btn--secondary" (click)="close()">
        Cancelar
      </button>
      <button
        id="btn-exclude-review"
        class="bla-btn bla-btn--danger"
        (click)="exclude()"
      >
        Excluir
      </button>
    </div>
  </div>
</div>
<div class="bla-modal__background"></div>
