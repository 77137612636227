import { registerLocaleData } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import localeExtraPT from '@angular/common/locales/extra/pt'
import localeBr from '@angular/common/locales/pt'
import { LOCALE_ID, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { NgxDatatableModule } from '@swimlane/ngx-datatable'
import { CookieService } from 'angular2-cookie/services/cookies.service'
import { ClickOutsideModule } from 'ng-click-outside'
import { TagInputModule } from 'ngx-chips'
import { NgxMaskModule } from 'ngx-mask'
import { AppComponent } from './app.component'
import { AuthGuardService } from './auth/auth-guard.service'
import { AttendanceService } from './chat/attendance/attendance.service'
import { ChatComponent } from './chat/chat.component'
import { ChatService } from './chat/chat.service'
import { AttendantStatusComponent } from './components/attendant-status/attendant-status.component'
import { LoginComponent } from './components/authentication/login/login.component'
import { LogoutComponent } from './components/authentication/logout/logout.component'
import { ChatCardComponent } from './components/chat-card/chat-card.component'
import { ChatUserDetailMobileComponent } from './components/chat-user-detail-mobile/chat-user-detail-mobile.component'
import { ChatUserInfoComponent } from './components/chat-user-info/chat-user-info.component'
import { DropDownComponent } from './components/drop-down/drop-down.component'
import { MenuMobileComponent } from './components/menu-mobile/menu-mobile.component'
import { MesssageBubbleComponent } from './components/messsage-bubble/messsage-bubble.component'
import { ModalComponent } from './components/modal/modal.component'
import { MultiselectDatatableComponent } from './components/multiselect-datatable/multiselect-datatable.component'
import { CharacterCounterDirective } from './directives/character-counter/character-counter.directive'
import { MainComponent } from './main.component'
import { InfoBarComponent } from './info-bar/info-bar.component'
import { NavbarComponent } from './navbar/navbar.component'
import { TeamComponent } from './register/attendance-team/attendance-team.component'
import { TeamService } from './register/attendance-team/attendance-team.service'
import { BrokerComponent } from './register/broker/broker.component'
import { BrokerListComponent } from './register/broker/broker-list/broker-list.component'
import { BrokerService } from './register/broker/broker.service'
import { BusinessHoursComponent } from './register/business-hours/business-hours.component'
import { BusinessHoursService } from './register/business-hours/business-hours.service'
import { BusinessHoursNameComponent } from './register/business-hours/forms/business-hours-name/business-hours-name.component'
import { BusinessDayCheckboxComponent } from './register/business-hours/forms/business-hours-timetable/business-day-checkbox/business-day-checkbox.component'
import { BusinessHoursTimetableComponent } from './register/business-hours/forms/business-hours-timetable/business-hours-timetable.component'
import { BusinessHoursListComponent } from './register/business-hours/list/business-hours-list.component'
import { QueueAttachmentsComponent } from './register/queue/forms/queue-attachments/queue-attachments.component'
import { QueueIdlenessComponent } from './register/queue/forms/queue-idleness/queue-idleness.component'
import { QueueNameComponent } from './register/queue/forms/queue-name/queue-name.component'
import { QueueWaitingComponent } from './register/queue/forms/queue-waiting/queue-waiting.component'
import { QueueListComponent } from './register/queue/queue-list/queue-list.component'
import { QueueComponent } from './register/queue/queue.component'
import { QueueService } from './register/queue/queue.service'
import { UserAccessComponent } from './register/user/forms/user-access/user-access.component'
import { UserAndWorkplaceComponent } from './register/user/forms/user-and-workplace/user-and-workplace.component'
import { UserComponent } from './register/user/user.component'
import { UserService } from './register/user/user.service'
import { AppRoutingModule } from './routing/app-routing.module'
import { StepperComponent } from './stepper/stepper.component'
import { TitleComponent } from './title/title.component'
import { FormControlValidator } from './tools/form-control-validator'
import { LocalStorageService } from './tools/local-storage.service'
import { PassportUserService } from './tools/passport-user.service'
import { SignalRService } from './tools/signalr.service'
import { WorkPauseComponent } from './components/work-pause/work-pause.component'
import { AnchormePipe } from './pipes/anchorme/anchorme.pipe'
import { AttendantStatusService } from './components/attendant-status/attendant-status.service'
import { TransferAttendanceModalComponent } from './chat/transfer-attendance-modal/transfer-attendance-modal.component'
import { OnlineOperationalUserComponent } from './monitoring/online-operational-user/online-operational-user/online-operational-user.component'
import { InputDropdownComponent } from './components/input-dropdown/input-dropdown.component'
import { NgSelectModule } from '@ng-select/ng-select'
import { SelectComponent } from './components/select/select.component'
import { UserListComponent } from './register/user/user-list/user-list/user-list.component'
import { SearchComponent } from './components/search/search.component'
import { StatusTagComponent } from './components/status-tag/status-tag.component'
import { UserStore } from './register/user/user.store'
import { RowDetailComponent } from './components/row-detail/row-detail.component'
import { ChatGuideMessagesComponent } from './components/chat-guide-messages/chat-guide-messages.component'
import { ChatPanelComponent } from './components/chat-panel/chat-panel.component'
import { WorkplaceModalComponent } from './register/user/forms/user-and-workplace/workplace-modal/workplace-modal/workplace-modal.component'
import { CustomerReviewModalComponent } from './chat/customer-review-modal/customer-review-modal.component'
import { ExcludeReviewModalComponent } from './chat/customer-review-modal/exclude-review-modal/exclude-review-modal.component'
import { CharacterCountComponent } from './components/character-count/character-count.component'
import { ImageModalComponent } from './components/image-modal/image-modal.component'
import { MonitoringAttendanceComponent } from './monitoring/attendance/monitoring-attendance.component'
import { MonitoringAttendancesFilterComponent } from './monitoring/attendance/monitoring-attendances-filter/monitoring-attendances-filter.component'
import { PhoneNumberDirective } from './directives/phone-number/phone-number.directive'
import { DpDatePickerModule } from 'ng2-date-picker'
import { AttendanceSearchResultComponent } from './monitoring/attendance/attendance-search-result/attendance-search-result.component'
import { DetailsIconComponent } from './components/details-icon/details-icon.component'
import { MonitoringAttendanceDetailsComponent } from './monitoring/attendance/monitoring-attendance-details/monitoring-attendance-details.component'
import { HeaderDetailsComponent } from './monitoring/attendance/monitoring-attendance-details/header-details/header-details.component'
import { AttendanceDetailsComponent } from './monitoring/attendance/monitoring-attendance-details/attendance-details/attendance-details.component'
import { LoadingAttendanceHeaderComponent } from './monitoring/attendance/loading-attendance-header/loading-attendance-header.component'
import { LoadingAttendanceResultComponent } from './monitoring/attendance/loading-attendance-result/loading-attendance-result.component'
import { RatingAttendancePageComponent } from './rating-attendance/rating-attendance-page/rating-attendance-page.component'
import { RatingAttendanceFormComponent } from './rating-attendance/rating-attendance-form/rating-attendance-form.component'
import { RatingAttendanceAlertMessageComponent } from './rating-attendance/rating-attendance-alert-message/rating-attendance-alert-message.component'
import { MonitoringQueuesComponent } from './monitoring/queues/monitoring-queues/monitoring-queues.component'
import { MonitoringQueuesInfoComponent } from './monitoring/queues/monitoring-queues-info/monitoring-queues-info/monitoring-queues-info.component'
import { InfoCardComponent } from './components/info-card/info-card.component'
import { LoadingMonitoringQueuesComponent } from './monitoring/queues/loading-monitoring-queues/loading-monitoring-queues.component'
import { EndAttendanceModalComponent } from './chat/end-attendance-modal/end-attendance-modal.component'
import { DeleteImageMessageModalComponent } from './components/delete-image-message-modal/delete-image-message-modal.component'
import { ImageMessageComponent } from './components/image-message/image-message.component'
import { QueueAutomaticMessagesComponent } from './register/queue/forms/queue-automatic-messages/queue-automatic-messages.component'
import { EditQueueConfirmationModalComponent } from './components/edit-queue-confirmation-modal/edit-queue-confirmation-modal.component'
import { QueueStore } from './register/queue/queue.store'
import { NewCustomerComponent } from './components/new-customer/new-customer.component'
import { NewCustomerModalComponent } from './chat/new-customer-modal/new-customer-modal.component'
import { StarComponent } from './components/star/star.component'
import { DirectContactPageComponent } from './direct-contact/direct-contact-page/direct-contact-page.component'
import { DirectContactFormComponent } from './direct-contact/direct-contact-form/direct-contact-form.component'
import { DirectContactComponent } from './direct-contact/direct-contact.component'
import { HomeComponent } from './home/home.component'
import { ClosingBatchConversationsComponent } from './action/closing-batch-conversations/closing-batch-conversations.component'
import { ClosingBatchConversationsFilterComponent } from './action/closing-batch-conversations/closing-batch-conversations-filter/closing-batch-conversations-filter.component'
import { ClosingBatchConversationsResultComponent } from './action/closing-batch-conversations/closing-batch-conversations-result/closing-batch-conversations-result.component'
import { CloseInactiveAttendanceModalComponent } from './components/close-inactive-attendance-modal/close-inactive-attendance-modal.component'
import { CheckboxComponent } from './components/checkbox/checkbox.component'
import { TransferInactiveAttendanceModalComponent } from './components/transfer-inactive-attendance-modal/transfer-inactive-attendance-modal.component'
import { TemplateMessageComponent } from './register/template-message/template-message.component'
import { TemplateMessagesGeneralComponent } from './register/template-message/forms/template-message-general/template-message-general.component'
import { TemplateMessagesQueueComponent } from './register/template-message/forms/template-messages-queue/template-messages-queue.component'
import { TemplateMessagesMessageComponent } from './register/template-message/forms/template-messages-message/template-messages-message.component'
import { TemplateMessageStore } from './register/template-message/template-message.store'
import { TemplateMessageListComponent } from './register/template-message/template-message-list/template-message-list.component'
import { TemplateMessageVariablesComponent } from './register/template-message/forms/template-messages-message/template-message-variables/template-message-variables.component'
import { TemplateMessageFilterComponent } from './register/template-message/template-message-filter/template-message-filter.component'
import { TemplateMessageSearchResultComponent } from './register/template-message/template-message-search-result/template-message-search-result.component'
import { BrokerStore } from './register/broker/broker.store'

registerLocaleData(localeBr, 'pt', localeExtraPT)

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    QueueComponent,
    NavbarComponent,
    InfoBarComponent,
    CharacterCounterDirective,
    BrokerComponent,
    BrokerListComponent,
    DropDownComponent,
    LoginComponent,
    LogoutComponent,
    MultiselectDatatableComponent,
    StepperComponent,
    TitleComponent,
    QueueNameComponent,
    QueueIdlenessComponent,
    QueueWaitingComponent,
    QueueAttachmentsComponent,
    TeamComponent,
    ModalComponent,
    UserComponent,
    UserAccessComponent,
    UserAndWorkplaceComponent,
    ChatComponent,
    MesssageBubbleComponent,
    ChatCardComponent,
    BusinessHoursListComponent,
    BusinessHoursComponent,
    BusinessHoursNameComponent,
    BusinessHoursTimetableComponent,
    BusinessDayCheckboxComponent,
    AttendantStatusComponent,
    ChatUserInfoComponent,
    ChatUserDetailMobileComponent,
    QueueListComponent,
    MenuMobileComponent,
    WorkPauseComponent,
    AnchormePipe,
    TransferAttendanceModalComponent,
    OnlineOperationalUserComponent,
    InputDropdownComponent,
    SelectComponent,
    UserListComponent,
    SearchComponent,
    StatusTagComponent,
    RowDetailComponent,
    ChatGuideMessagesComponent,
    ChatPanelComponent,
    WorkplaceModalComponent,
    CustomerReviewModalComponent,
    ExcludeReviewModalComponent,
    CharacterCountComponent,
    ImageModalComponent,
    MonitoringAttendanceComponent,
    MonitoringAttendancesFilterComponent,
    PhoneNumberDirective,
    AttendanceSearchResultComponent,
    DetailsIconComponent,
    MonitoringAttendanceDetailsComponent,
    HeaderDetailsComponent,
    AttendanceDetailsComponent,
    LoadingAttendanceHeaderComponent,
    LoadingAttendanceResultComponent,
    RatingAttendancePageComponent,
    RatingAttendanceFormComponent,
    RatingAttendanceAlertMessageComponent,
    MonitoringQueuesComponent,
    MonitoringQueuesInfoComponent,
    InfoCardComponent,
    LoadingMonitoringQueuesComponent,
    EndAttendanceModalComponent,
    DeleteImageMessageModalComponent,
    ImageMessageComponent,
    QueueAutomaticMessagesComponent,
    EditQueueConfirmationModalComponent,
    NewCustomerComponent,
    NewCustomerModalComponent,
    StarComponent,
    DirectContactPageComponent,
    DirectContactFormComponent,
    DirectContactComponent,
    MainComponent,
    HomeComponent,
    ClosingBatchConversationsComponent,
    ClosingBatchConversationsFilterComponent,
    ClosingBatchConversationsResultComponent,
    CloseInactiveAttendanceModalComponent,
    CheckboxComponent,
    TransferInactiveAttendanceModalComponent,
    TemplateMessageComponent,
    TemplateMessagesGeneralComponent,
    TemplateMessagesQueueComponent,
    TemplateMessagesMessageComponent,
    TemplateMessageListComponent,
    TemplateMessageVariablesComponent,
    TemplateMessageFilterComponent,
    TemplateMessageSearchResultComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    TagInputModule,
    BrowserAnimationsModule,
    FormsModule,
    ClickOutsideModule,
    NgxMaskModule.forRoot(),
    NgxDatatableModule,
    NgSelectModule,
    DpDatePickerModule,
  ],
  providers: [
    AuthGuardService,
    QueueService,
    BrokerService,
    FormControlValidator,
    TeamService,
    UserService,
    SignalRService,
    ChatService,
    BusinessHoursService,
    AttendanceService,
    LocalStorageService,
    PassportUserService,
    CookieService,
    AttendantStatusService,
    UserStore,
    QueueStore,
    TemplateMessageStore,
    BrokerStore,
    { provide: LOCALE_ID, useValue: 'pt' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
