<section class="bla-container">
  <app-title title="Monitoramento de filas"></app-title>
  <app-monitoring-queues-info
    id="queues-info-{{ i }}"
    class="infos"
    *ngFor="let queues of queueInfo; let i = index"
    [queue]="queues"
    [id]="i"
  ></app-monitoring-queues-info>

  <app-loading-monitoring-queues
    *ngIf="loadingQueues"
  ></app-loading-monitoring-queues>
</section>
