import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { InfoBarService } from '@app/info-bar/info-bar.service'
import { ColumnMode } from '@swimlane/ngx-datatable'
import { Broker } from '../broker.model'
import { BrokerService } from '../broker.service'
import { BrokerStore } from '../broker.store'

@Component({
  selector: 'app-broker-list',
  templateUrl: './broker-list.component.html',
  styleUrls: ['./broker-list.component.scss'],
})
export class BrokerListComponent implements OnInit {
  constructor(
    private brokerService: BrokerService,
    private infoBarService: InfoBarService,
    private router: Router,
    public store: BrokerStore,
  ) {}

  rows: Broker[] = []
  ColumnMode = ColumnMode

  ngOnInit(): void {
    this.fetchBrokers()
  }

  changeBrokerStatus(event: any, id: string, newStatus: boolean) {
    this.brokerService.changeBrokerStatus(id, newStatus).subscribe(
      () => {
        this.infoBarService.show(
          `Broker ${newStatus ? 'ativado' : 'desativado'} com sucesso`,
          'success',
        )
        this.updateBrokerRowStatus(id, newStatus)
      },
      () => {
        this.infoBarService.show(
          `Não foi possível ${
            newStatus ? 'ativar' : 'desativar'
          } o broker. Consulte o suporte se o problema persistir`,
          'danger',
        )

        event.srcElement.checked = !newStatus
      },
    )
  }

  private updateBrokerRowStatus(id: string, newStatus: boolean) {
    const broker = this.rows.find(elem => elem.id === id)

    if (broker) {
      broker.active = newStatus

      this.rows = [...this.rows]
    }
  }

  public async editBroker(brokerId: string) {
    this.store.loadBroker(brokerId)
  }

  getBrokerTypeName(type: string): string {
    if (type == '1') return 'WhatsApp On Premise'
    if (type == '2') return 'App'
    if (type == '3') return 'WhatsApp Cloud'
  }

  private fetchBrokers() {
    this.brokerService.getBrokers().subscribe(
      brokers => (this.rows = brokers),
      () =>
        this.infoBarService.show(
          'Falha ao carregar as informações de brokers. Consulte o suporte se o problema persistir',
          'danger',
        ),
    )
  }
}
