<div class="bla-modal">
  <div class="bla-modal__body">
    <h1 class="bla-modal-title">Transferir atendimento</h1>
    <div class="bla-modal-separator"></div>
    <div class="bla-modal-text-info" *ngIf="!canQueueMakeTransfer">
      Esta fila não permite fazer transferências
    </div>
    <div class="bla-modal-text-info" *ngIf="canQueueMakeTransfer">
      <div class="bla-row">
        <div class="bla-column bla-column--25">
          <div class="bla-modal-label">Perfil:</div>
        </div>
        <div class="bla-column bla-column--50">
          <div class="modal-dropdown">
            <ul class="bla-input-dropdown" id="dropdown-access">
              <i class="icon-down"></i>
              <li
                class="bla-input-dropdown__item"
                data-dropdown-id="access"
                (click)="openOptions($event)"
              >
                {{
                  selectedUserAccessProfile?.name
                    ? selectedUserAccessProfile.name
                    : 'Selecione um perfil de acesso'
                }}
              </li>
              <ul class="bla-input-itens" data-dropdown-id="access">
                <li
                  *ngFor="let profile of accessProfiles"
                  class="bla-input-dropdown__item"
                  (click)="
                    selectUserAccessProfile($event, profile);
                    $event.stopPropagation()
                  "
                >
                  {{ profile.name }}
                </li>
              </ul>
            </ul>
          </div>
        </div>
      </div>
      <div
        class="bla-row"
        *ngIf="
          selectedProfile === AccessProfile.Operational ||
          !selectedUserAccessProfile.name
        "
      >
        <div class="bla-column bla-column--25">
          <div class="bla-modal-label">Fila de destino:</div>
        </div>
        <div class="bla-column bla-column--50">
          <div class="modal-dropdown" id="dropdown-queues">
            <ul class="bla-input-dropdown">
              <i class="icon-down"></i>
              <li
                class="bla-input-dropdown__item"
                data-dropdown-id="queues"
                (click)="openOptions($event)"
              >
                {{
                  selectedQueueInfo?.name
                    ? selectedQueueInfo.name
                    : 'Selecione uma fila para transferência'
                }}
              </li>
              <ul class="bla-input-itens" data-dropdown-id="queues">
                <li
                  *ngFor="let queue of availableQueues"
                  class="bla-input-dropdown__item"
                  (click)="selectQueue($event, queue); $event.stopPropagation()"
                >
                  {{ queue.name }}
                </li>
              </ul>
            </ul>
          </div>
        </div>
      </div>
      <div class="bla-row" *ngIf="selectedProfile === AccessProfile.Supervisor">
        <div class="bla-column bla-column--25">
          <div class="bla-modal-label">Supervisores para receber:</div>
        </div>
        <div class="bla-column bla-column--50">
          <div class="modal-dropdown" id="dropdown-supervisors">
            <ul class="bla-input-dropdown">
              <i class="icon-down"></i>
              <li
                class="bla-input-dropdown__item"
                data-dropdown-id="supervisor"
                (click)="openOptions($event)"
              >
                {{
                  selectedSupervisor?.name
                    ? selectedSupervisor.name
                    : 'Selecione um supervisor'
                }}
              </li>
              <ul class="bla-input-itens" data-dropdown-id="supervisor">
                <li
                  *ngFor="let user of availableSupervisors"
                  class="bla-input-dropdown__item"
                  (click)="selectSupervisor($event, user)"
                >
                  {{ user.name }}
                </li>
              </ul>
            </ul>
          </div>
        </div>
      </div>
      <div
        class="bla-row"
        *ngIf="
          selectedProfile === AccessProfile.Operational ||
          !selectedUserAccessProfile.name
        "
      >
        <div class="bla-column bla-column--25">
          <div class="bla-modal-label">Atendente para receber:</div>
        </div>
        <div class="bla-column bla-column--50">
          <div class="modal-dropdown" id="dropdown-users">
            <ul class="bla-input-dropdown">
              <i class="icon-down"></i>
              <li
                class="bla-input-dropdown__item"
                data-dropdown-id="user"
                (click)="openOptions($event)"
              >
                {{
                  selectedOperationalUser?.name
                    ? selectedOperationalUser.name
                    : 'Selecione um atentende (opcional)'
                }}
              </li>
              <ul class="bla-input-itens" data-dropdown-id="user">
                <li
                  *ngFor="let user of availableUsers"
                  class="bla-input-dropdown__item"
                  (click)="selectOperationalUser($event, user)"
                >
                  {{ user.name }}
                </li>
              </ul>
            </ul>
          </div>
        </div>
      </div>
      <div class="bla-row">
        <div class="bla-column bla-column--25">
          <div class="bla-modal-label">Motivo da transferência:</div>
        </div>
        <div class="bla-column bla-column--50">
          <div class="modal-dropdown" id="dropdown-reasons">
            <ul class="bla-input-dropdown">
              <i class="icon-down"></i>
              <li
                class="bla-input-dropdown__item"
                data-dropdown-id="reasons"
                (click)="openOptions($event)"
              >
                {{
                  selectedTransferReason?.name
                    ? selectedTransferReason.name
                    : 'Selecione uma razão para transferência'
                }}
              </li>
              <ul class="bla-input-itens" data-dropdown-id="reasons">
                <li
                  *ngFor="let transferReason of transferReasons"
                  class="bla-input-dropdown__item"
                  (click)="selectTransferReason($event, transferReason)"
                >
                  {{ transferReason.name }}
                </li>
              </ul>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="bla-modal-actions">
      <button class="bla-btn bla-btn--secondary" (click)="close()">
        {{ canQueueMakeTransfer ? 'Cancelar' : 'Ok' }}
      </button>
      <button
        type="button"
        class="bla-btn"
        [ngClass]="{
          'bla-btn--disabled':
            selectedProfile === AccessProfile.Operational
              ? noQueueOrTransferReasonSelected()
              : noSupervisorOrTransferReasonSelected(),
          'bla-btn--primary':
            !noQueueOrTransferReasonSelected() ||
            !noSupervisorOrTransferReasonSelected()
        }"
        [disabled]="
          selectedProfile === AccessProfile.Operational
            ? noQueueOrTransferReasonSelected()
            : noSupervisorOrTransferReasonSelected()
        "
        *ngIf="canQueueMakeTransfer"
        (click)="makeTransfer()"
      >
        Transferir
      </button>
    </div>
  </div>
</div>
<div class="bla-modal__background"></div>
