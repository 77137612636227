import { Injectable } from '@angular/core'
import { AttendanceService } from '@app/chat/attendance/attendance.service'
import { QueueService } from '@app/register/queue/queue.service'
import { WorkplaceService } from '@app/register/user/forms/user-and-workplace/workplace.service'
import { UserService } from '@app/register/user/user.service'

@Injectable({
  providedIn: 'root',
})
export class MonitoringAttendanceFilterService {
  constructor(
    private workplaceService: WorkplaceService,
    private queueService: QueueService,
    private attendanceService: AttendanceService,
    private userService: UserService,
  ) {}

  getWorkplaces = () => {
    return this.workplaceService.get()
  }

  getQueues = () => {
    return this.queueService.getQueueNamesAndIds()
  }

  getClosingTypes = () => {
    return this.attendanceService.getClosingTypes()
  }

  getClosingReasons = () => {
    return this.attendanceService.getClosingReasons()
  }

  getSellers = () => {
    return this.userService.getAllOperationalUsers()
  }

  getSellersByWorkplaceId = (workplaceId: string) => {
    return this.userService.getSellersByWorkplaceId(workplaceId)
  }

  getRatingReasons = () => this.attendanceService.getRatingReasons()
}
