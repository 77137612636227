<div class="bla-modal">
  <div class="bla-modal__body">
    <h1 class="bla-modal-title">Encerrar atendimento</h1>
    <div class="bla-modal-separator"></div>
    <form
      [formGroup]="endAttendanceForm"
      (ngSubmit)="submit()"
      class="end-attendance-form"
    >
      <div class="bla-row">
        <div class="form-group form-item group-end-attendance-type col-lg-12">
          <app-drop-down
            [formGroup]="endAttendanceForm"
            controlName="endAttendanceTypeId"
            label="Tipo de encerramento"
            [items]="closingTypesItems"
            [isRequired]="true"
            requiredMessageError="Um tipo deve ser selecionado."
            class="closing-type-drop-down one-item-select"
            id="end-attendance-type"
          ></app-drop-down>
        </div>
      </div>

      <div class="bla-row" *ngIf="isStandardClosingType()">
        <div class="form-group form-item col-lg-12">
          <app-drop-down
            [formGroup]="endAttendanceForm"
            controlName="endAttendanceReasonId"
            label="Motivo do encerramento"
            [items]="closingReasonsItems"
            [isRequired]="true"
            requiredMessageError="Um motivo deve ser selecionado."
            class="one-item-select"
            id="end-attendance-reason"
          ></app-drop-down>
        </div>
      </div>

      <div *ngIf="isScheduledClosingType()">
        <div class="bla-row">
          <div class="form-group form-item col-lg-5">
            <label class="bla-modal-label" for="return-date-picker"
              >Data para retorno</label
            >
            <dp-date-picker
              #returnDatePicker
              theme="dp-material"
              placeholder="dd/mm/aaaa"
              [config]="datePickerConfig"
              formControlName="returnDatePicker"
              class="return-date-picker"
              id="return-date-picker"
            ></dp-date-picker>
            <i
              class="input-icon input-icon--calendar input-icon--with-label"
              (click)="openDatePicker(returnDatePicker)"
            ></i>
          </div>
        </div>

        <div class="row row--hour">
          <div class="form-group form-item col-lg-5 padding-period">
            <app-drop-down
              [formGroup]="endAttendanceForm"
              controlName="returnPeriod"
              label="Período"
              [items]="periodItems"
              [placeholder]="null"
              [isRequired]="true"
              requiredMessageError="Um período deve ser selecionado."
              class="return-period one-item-select"
              id="return-period"
            ></app-drop-down>
          </div>

          <div class="form-group form-item col-lg-4">
            <app-drop-down
              [formGroup]="endAttendanceForm"
              controlName="returnHour"
              label="Horário"
              [items]="hoursPeriodItems"
              [placeholder]="null"
              [isRequired]="true"
              requiredMessageError="Um horário deve ser selecionado."
              class="return-hour one-item-select"
              id="return-hour"
            ></app-drop-down>
          </div>
        </div>
      </div>

      <div class="bla-modal-actions">
        <button
          id="end-attendance-button"
          type="reset"
          (click)="closeModal()"
          class="bla-btn bla-btn--secondary"
        >
          Cancelar
        </button>
        <button
          type="submit"
          [disabled]="endAttendanceForm.invalid"
          class="bla-btn bla-btn--danger"
          [ngClass]="endAttendanceForm.invalid ? 'bla-btn--disabled' : ''"
        >
          Encerrar
        </button>
      </div>
    </form>
  </div>
</div>
<div class="bla-modal__background"></div>
