import { TemplateMessagesMessageComponent } from './forms/template-messages-message/template-messages-message.component'
import { TemplateMessagesQueueComponent } from './forms/template-messages-queue/template-messages-queue.component'
import { TemplateMessagesGeneralComponent } from './forms/template-message-general/template-message-general.component'

export const templateMessagesRoutes = [
  {
    path: '',
    component: TemplateMessagesGeneralComponent,
  },
  {
    path: 'fila',
    component: TemplateMessagesQueueComponent,
  },
  {
    path: 'mensagem',
    component: TemplateMessagesMessageComponent,
  },
]
