<svg
  width="41"
  height="41"
  viewBox="0 0 41 41"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  class="star"
  [ngClass]="active ? 'star--active' : ''"
  (click)="toggleStar(); $event.stopPropagation()"
>
  <path
    d="M40.9615 16.0321C40.9615 14.9771 40.1517 14.1209 39.1516 14.1209C39.0871 14.1209 39.0237 14.1248 38.9613 14.1309L26.4615 13.8093L22.1914 1.64799C21.9761 0.847713 21.2805 0.261719 20.4542 0.261719C19.6269 0.261719 18.9322 0.847713 18.7161 1.64799L14.445 13.8093L1.94585 14.1309C1.88312 14.1241 1.81922 14.1209 1.75561 14.1209C0.75639 14.12 -0.0534668 14.9765 -0.0534668 16.0321C-0.0534668 16.7068 0.27666 17.2973 0.774649 17.6375L10.7306 25.3158L7.17811 37.3615C7.04912 37.6227 6.9755 37.9208 6.9755 38.2363C6.9755 39.2923 7.78536 40.1485 8.78546 40.1485C9.20894 40.1485 9.60003 39.993 9.90807 39.7341L20.4551 32.4294L31.0015 39.7341C31.3104 39.993 31.6997 40.1485 32.1241 40.1485C33.1233 40.1485 33.9331 39.2923 33.9331 38.2363C33.9331 37.9218 33.8595 37.624 33.7305 37.3615L30.1783 25.3158L40.134 17.6375C40.6308 17.2967 40.9615 16.7058 40.9615 16.0321Z"
  />
</svg>
