import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core'
import { AttendanceService } from '@app/chat/attendance/attendance.service'
import {
  AttendanceRatingStatus,
  RatingReason,
  RatingRequest,
} from '@app/chat/attendance/rating'
import { HtmlTools } from '@app/tools/html-tools'
import { RatingAttendanceStar } from './rating-attendance-star'

@Component({
  selector: 'app-rating-attendance-form',
  templateUrl: './rating-attendance-form.component.html',
  styleUrls: ['./rating-attendance-form.component.scss'],
})
export class RatingAttendanceFormComponent implements OnInit {
  @Output() ratingSuccess = new EventEmitter()
  @ViewChild('ratingDescription') private ratingDescription: ElementRef
  @Input() attendanceId: string

  maxStars = 5
  stars: number[]
  selected = false
  ratingReasons: RatingReason[]
  rate: number
  reasonId: string
  message: string

  ratingField = {
    labelTitle: 'Motivos',
    placeholder: 'Selecione uma opção',
    closeOnSelect: true,
  }

  ratingRequest: RatingRequest
  attendanceStar: RatingAttendanceStar = RatingAttendanceStar.Bad

  constructor(
    private htmlTools: HtmlTools,
    private attendanceService: AttendanceService,
  ) {}

  @HostListener('input')
  updateField() {}

  ngOnInit(): void {
    this.stars = this.setRatingRange(this.maxStars)
    this.getRatingReasons()
  }

  setRatingRange = (range: number) =>
    Array.from({ length: range }, (v, k) => k + 1)

  fillStar = (hoverIndex: number) => {
    const stars = document.querySelectorAll('.star')

    this.attendanceStar = this.getAttendanceStarByIndex(hoverIndex)

    stars.forEach((star: HTMLElement, starIndex) => {
      starIndex <= hoverIndex ? this.show(star) : this.hide(star)
    })
  }

  clearStarsFill = () => {
    const stars = document.querySelectorAll('.star')

    stars.forEach((star: HTMLElement) => {
      star.hasAttribute('selected') ? this.show(star) : this.hide(star)
    })
  }

  selectStar = (selectedIndex: number) => {
    const stars = document.querySelectorAll('.star')

    stars.forEach((star: HTMLElement, starIndex) => {
      if (starIndex <= selectedIndex) {
        star.setAttribute('selected', 'selected')
        this.show(star)
      } else {
        star.removeAttribute('selected')
        this.hide(star)
      }
    })

    this.selected = true
    this.rate = selectedIndex + 1
  }

  getRatingReasons = () => {
    this.attendanceService.getRatingReasons().subscribe(reasons => {
      this.ratingReasons = reasons
    })
  }

  private show(star: HTMLElement) {
    this.htmlTools.showElement(star.children[1] as HTMLElement)
    this.htmlTools.hideElement(star.children[0] as HTMLElement)
  }

  private hide(star: HTMLElement) {
    this.htmlTools.hideElement(star.children[1] as HTMLElement)
    this.htmlTools.showElement(star.children[0] as HTMLElement)
  }

  setReason = evt => (this.reasonId = evt.ids)

  setDescription = () =>
    (this.message = this.ratingDescription.nativeElement.value)

  send = () => {
    this.setDescription()

    this.ratingRequest = {
      rating: this.rate,
      message: this.message,
      attendanceId: this.attendanceId,
      reasonId: this.reasonId ?? '',
    }

    this.attendanceService
      .saveAttendanceRating(this.ratingRequest)
      .subscribe(response => {
        this.ratingSuccess.emit(AttendanceRatingStatus.Answered)
      })
  }

  getAttendanceStarByIndex(starIndex: number) {
    const starIndexOk = 3

    if (starIndex < starIndexOk) return RatingAttendanceStar.Bad
    else if (starIndex === starIndexOk) return RatingAttendanceStar.Ok
    else return RatingAttendanceStar.Great
  }
}
