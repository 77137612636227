import { Component, Input, OnInit } from '@angular/core'
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { FormControlValidator } from '@app/tools/form-control-validator'
import { DropDownItem } from '../drop-down/drop-down-item'

@Component({
  selector: 'app-multiselect-datatable',
  templateUrl: './multiselect-datatable.component.html',
  styleUrls: ['./multiselect-datatable.component.scss'],
})
export class MultiselectDatatableComponent implements OnInit {
  @Input() label: string
  @Input() formGroup: FormGroup
  @Input() controlName: string
  @Input() isRequired: boolean
  @Input() readonly = false
  @Input() set items(items: DropDownItem[]) {
    this._items = items ? [...items] : []

    this.dataTableItems = this._items.filter(item =>
      this.formGroup?.get(this.controlName).value.includes(item.value),
    )
  }

  get items() {
    return this._items
  }

  private _items: DropDownItem[]

  dropDownFormGroup: FormGroup
  dataTableItems: DropDownItem[] = []

  constructor(
    public formControlValidator: FormControlValidator,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.initializeDropDownForm()
  }

  initializeDropDownForm() {
    this.dropDownFormGroup = this.formBuilder.group({
      item: new FormControl(''),
    })
  }

  add() {
    const formControl = this.formGroup.get(this.controlName) as FormArray
    const selectedItemValue = this.dropDownFormGroup.get('item').value

    if (!selectedItemValue) return
    if (this.dataTableItems.find(x => x.value === selectedItemValue)) return

    this.dataTableItems.push(
      this._items.find(x => x.value === selectedItemValue),
    )
    formControl.push(new FormControl(selectedItemValue))
  }

  remove(item: DropDownItem) {
    const control = this.formGroup.get(this.controlName) as FormArray
    const index = this.dataTableItems.findIndex(x => x.value === item.value)

    this.dataTableItems.splice(index, 1)
    control.removeAt(index)
  }
}
