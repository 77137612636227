import { Component, OnInit, ElementRef, Input } from '@angular/core'

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input() type = 'default'

  constructor(private element: ElementRef) {}
  modal: HTMLElement

  ngOnInit() {
    this.modal = document.querySelector('.app-modal')

    this.modal.addEventListener('click', (e: MouseEvent) => {
      e.stopPropagation()
      e.stopImmediatePropagation()
      return false
    })

    this.element.nativeElement.addEventListener('click', () => {
      this.close()
    })
  }

  close = () => {
    this.element.nativeElement.classList.remove('show')
    this.element.nativeElement.classList.add('hide')
  }
}
