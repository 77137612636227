import { Step } from '@app/stepper/steps/step'

export enum StepsEnum {
  TemplateMessageGeneral = 0,
  TemplateMessageQueue = 1,
  TemplateMessageMessage = 2,
}

export const templateMessageSteps = [
  {
    index: 0,
    active: true,
    title: 'Configurações Gerais',
    id: 'gerais',
    route: '/cadastros/frase/nova',
    completed: false,
    name: StepsEnum.TemplateMessageGeneral,
    enabled: true,
    viewMode: false,
  },
  {
    index: 1,
    active: false,
    title: 'Fila',
    id: 'fila',
    route: '/cadastros/frase/nova/fila',
    completed: false,
    name: StepsEnum.TemplateMessageQueue,
    enabled: false,
    viewMode: false,
  },
  {
    index: 2,
    active: false,
    title: 'Mensagem',
    id: 'mensagem',
    route: '/cadastros/frase/nova/mensagem',
    completed: false,
    name: StepsEnum.TemplateMessageMessage,
    enabled: false,
    viewMode: false,
  },
] as Step[]

export const viewModeSteps = () =>
  templateMessageSteps.map((step: Step) => ({
    ...step,
    completed: true,
    enabled: true,
    viewMode: true,
  }))
