<div class="queue-form">
  <h2>Mensagens Automáticas</h2>
  <hr />
  <form [formGroup]="automaticMessageForm" (ngSubmit)="submit()">
    <div class="row">
      <div class="form-group form-item col-lg-12">
        <label for="initialMessage">
          Mensagem Inicial
          <br />
          <small class="bla-input-tip"
            >Exemplo: "Prezado (a) Cliente, para sua segurança a Fast Shop não
            solicita envio de fotos e/ou imagens de documentos pessoais (CPF e
            RG) e Cartões de Credito/Débito via Whatsapp/Chat. Por favor, não
            nos envie estes tipos de imagens."</small
          >
        </label>
        <textarea
          #initialMessage
          formControlName="initialMessage"
          [ngClass]="{
            'input--invalid': formControlValidator.isInvalid(
              automaticMessageForm,
              'initialMessage'
            )
          }"
          class="form-control"
          id="initialMessage"
        ></textarea>
        <app-character-count
          class="automatic-messages-counter"
          [charactersLimit]="600"
          [characters]="initialMessage?.value"
        ></app-character-count>

        <div class="input-validation__info">
          <ul
            class="input-validation__errors"
            *ngIf="
              formControlValidator.isInvalid(
                automaticMessageForm,
                'initialMessage'
              )
            "
          >
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasError(
                  automaticMessageForm,
                  'initialMessage',
                  'maxlength'
                )
              "
              id="initial-queue-message-error"
            >
              A mensagem deve conter no máximo 600 caracteres
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group form-item col-lg-12">
        <label for="enqueuedAttendanceMessage">
          Atendimento em Fila
          <br />
          <small class="bla-input-tip">
            Exemplo: "Olá, todos nossos vendedores estão ocupados, por favor
            aguarde que você já será atendido. Se preferir você pode comprar
            também pelo site www.fastshop.com.br, pelo nosso aplicativo no seu
            celular ou pelo número do nosso Televendas 3003-3278.”
          </small>
        </label>
        <textarea
          #enqueuedAttendanceMessage
          formControlName="enqueuedAttendanceMessage"
          [ngClass]="{
            'input--invalid': formControlValidator.isInvalid(
              automaticMessageForm,
              'enqueuedAttendanceMessage'
            )
          }"
          class="form-control"
          id="enqueued-attendance-message"
        ></textarea>
        <app-character-count
          class="automatic-messages-counter"
          [charactersLimit]="600"
          [characters]="enqueuedAttendanceMessage?.value"
        ></app-character-count>
        <div class="input-validation__info">
          <ul
            class="input-validation__errors"
            *ngIf="
              formControlValidator.isInvalid(
                automaticMessageForm,
                'enqueuedAttendanceMessage'
              )
            "
          >
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasError(
                  automaticMessageForm,
                  'enqueuedAttendanceMessage',
                  'maxlength'
                )
              "
              id="enqueued-attendance-message-error"
            >
              A mensagem deve conter no máximo 600 caracteres
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group form-item col-lg-12">
        <label for="outOfBusinessHoursMessage">
          Fora do Horário de Atendimento
          <br />
          <small class="bla-input-tip"
            >Exemplo: "Atendemos de Segunda à Sexta, das 8h às 23h, Sábados das
            10h às 22h, Domingos e Feriados das 14h às 20h. Obrigado pelo
            contato, voltaremos a nos falar no horário de atendimento"</small
          >
        </label>
        <textarea
          #outOfBusinessHoursMessage
          formControlName="outOfBusinessHoursMessage"
          [ngClass]="{
            'input--invalid': formControlValidator.isInvalid(
              automaticMessageForm,
              'outOfBusinessHoursMessage'
            )
          }"
          class="form-control"
          id="outOfBusinessHoursMessage"
        ></textarea>
        <app-character-count
          class="automatic-messages-counter"
          [charactersLimit]="600"
          [characters]="outOfBusinessHoursMessage?.value"
        ></app-character-count>
        <div class="input-validation__info">
          <ul
            class="input-validation__errors"
            *ngIf="
              formControlValidator.isInvalid(
                automaticMessageForm,
                'outOfBusinessHoursMessage'
              )
            "
          >
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasError(
                  automaticMessageForm,
                  'outOfBusinessHoursMessage',
                  'maxlength'
                )
              "
              id="out-of-business-hours-queue-message-error"
            >
              A mensagem deve conter no máximo 500 caracteres
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row" formGroupName="attendanceClosingMessageSettings">
      <div class="form-group form-item col-lg-12">
        <label class="form-check-label" for="attendanceClosingMessage">
          Encerramento de Atendimento / Avaliação do Atendimento<small
            class="info"
            >(opcional)</small
          >
          <input
            #enableAttendanceClosingMessage
            formControlName="enableAttendanceClosingMessage"
            type="checkbox"
            id="enable-attendance-closing-message"
            class="checkbox"
          />
          <label
            for="enable-attendance-closing-message"
            class="switch"
            id="label-enable-attendance-closing-message"
          >
            <div class="switch-on">Sim</div>
            <div class="switch-off">Não</div></label
          >
        </label>

        <small class="bla-input-tip">
          Exemplo: "Obrigado por procurar a Fast Shop! Estamos à sua disposição.
          Com intuito de mantermos nosso padrão de qualidade, gostaríamos de
          saber como foi sua experiência com nosso atendimento."
        </small>

        <div>
          <textarea
            #attendanceClosingMessage
            formControlName="attendanceClosingMessage"
            [ngClass]="{
              'input--invalid': formControlValidator.isInvalid(
                automaticMessageForm,
                'attendanceClosingMessage'
              )
            }"
            [readonly]="!enableAttendanceClosingMessage?.checked"
            class="form-control"
            id="attendanceClosingMessage"
          ></textarea>
          <app-character-count
            class="automatic-messages-counter"
            [charactersLimit]="600"
            [characters]="attendanceClosingMessage?.value"
          ></app-character-count>
          <div class="text-info-rating">
            <p>
              OBS.: O link para a avaliação será acrescentado no final da
              mensagem acima automaticamente
            </p>
          </div>
        </div>
        <div class="input-validation__info">
          <ul
            class="input-validation__errors"
            *ngIf="
              formControlValidator.isInvalid(
                automaticMessageForm,
                'attendanceClosingMessage'
              )
            "
          >
            <li
              class="input-error"
              *ngIf="
                formControlValidator.hasError(
                  automaticMessageForm,
                  'attendanceClosingMessage',
                  'maxlength'
                )
              "
              id="attendance-closing-message-error"
            >
              A mensagem deve conter no máximo 600 caracteres
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="bla-actions">
      <button
        type="button"
        class="bla-btn"
        routerLink="/cadastros/fila"
        (click)="cancel()"
      >
        Cancelar
      </button>
      <button
        [disabled]="automaticMessageForm.invalid"
        type="submit"
        class="bla-btn bla-btn--primary"
        [ngClass]="{
          'bla-btn--disabled': automaticMessageForm.invalid,
          'save-button': store.isUpdateMode(),
          'next-button': !store.isUpdateMode()
        }"
      >
        {{ store.isUpdateMode() ? 'Salvar Alterações' : 'Avançar' }}
      </button>
    </div>
  </form>
</div>

<app-edit-queue-confirmation-modal
  [id]="'automatic-messages-edit-modal'"
  [step]="StepsEnum.AutomaticMessage"
  #confirmationModal
></app-edit-queue-confirmation-modal>
