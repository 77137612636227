<div id="close-inactive-attendance-modal">
  <div class="bla-modal">
    <div class="bla-modal__body">
      <h1 class="bla-modal-title">Encerrar atendimento</h1>
      <div class="bla-modal-separator"></div>

      <div class="bla-row">
        <div class="bla-modal-text-info">
          <p>
            Você tem certeza que deseja encerrar o(s)
            {{ attendanceIds?.length }} atendimento(s) selecionado(s)?
          </p>
        </div>
      </div>

      <div class="bla-modal-actions">
        <button
          id="cancel-close-attendances-button"
          type="reset"
          (click)="closeModal()"
          class="bla-btn"
        >
          Cancelar
        </button>
        <button
          id="confirmation-close-attendances--button"
          class="bla-btn bla-btn--danger"
          (click)="submit()"
        >
          Encerrar
        </button>
      </div>
    </div>
  </div>
  <div class="bla-modal__background"></div>
</div>
