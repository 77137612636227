<label
  class="checkbox-label"
  [ngClass]="{ 'checkbox-label--disabled': disabled }"
>
  <input
    type="checkbox"
    [checked]="checked"
    (change)="onChanged($event)"
    [disabled]="disabled"
  />
  <span
    class="checkbox-custom"
    [ngClass]="{ 'checkbox-custom--disabled': disabled }"
  ></span>
  {{ label }}
</label>
