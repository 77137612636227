import { Component, OnInit, ViewChild } from '@angular/core'
import { QueueService } from '../queue.service'
import { InfoBarService } from '@app/info-bar/info-bar.service'
import { ColumnMode } from '@swimlane/ngx-datatable'
import { TeamService } from '@app/register/attendance-team/attendance-team.service'
import { QueueStore } from '../queue.store'
import { QueueAndTeam } from '../queue.model'
import { AttendanceTeamInfo } from '@app/register/attendance-team/attendance-team.model'

@Component({
  selector: 'app-queue-list',
  templateUrl: './queue-list.component.html',
  styleUrls: ['./queue-list.component.scss'],
})
export class QueueListComponent implements OnInit {
  @ViewChild('confirmationModal') modal: any

  constructor(
    private queueService: QueueService,
    private infoBarService: InfoBarService,
    private teamService: TeamService,
    private store: QueueStore,
  ) {}

  rows: QueueAndTeam[] = []
  ColumnMode = ColumnMode
  messages = {
    emptyMessage: 'Não há nenhuma fila para ser listada',
  }

  teams: AttendanceTeamInfo[] = []
  teamInfo: {
    name: string
    newTeamId: string
    queueId: string
    previousTeamId: string
    teamSelect: HTMLSelectElement
  }

  ngOnInit(): void {
    this.fetchQueuesAndTeams()
    this.fetchAttendanceTeams()
  }

  onOptionSelected(teamSelect: HTMLSelectElement, previousTeamId: string) {
    this.teamInfo = {
      name: this.teams.find(team => team.id === teamSelect.value).name,
      newTeamId: teamSelect.value,
      queueId: teamSelect.id,
      previousTeamId,
      teamSelect,
    }

    this.openModal()
  }

  openModal() {
    this.modal.element.nativeElement.classList.remove('hide')
    this.modal.element.nativeElement.classList.add('show')
  }

  closeModal() {
    if (this.modal?.element?.nativeElement?.classList) {
      this.modal.element.nativeElement.classList.remove('show')
      this.modal.element.nativeElement.classList.add('hide')
    }
  }

  cancelModal() {
    this.updateQueueRowTeamWithPreviousTeamId()

    this.closeModal()
  }

  changeQueueTeam() {
    this.queueService
      .patchAttendanceTeam(this.teamInfo.queueId, this.teamInfo.newTeamId)
      .subscribe(
        () => {
          this.closeModal()

          this.infoBarService.show(
            `Time alterado para ${this.teamInfo.name} com sucesso`,
            'success',
          )
        },
        () => {
          this.cancelModal()

          this.infoBarService.show(
            'Não foi possível atualizar o time para esta fila. Consulte o suporte se o problema persistir',
            'danger',
          )
        },
      )
  }

  changeQueueStatus(event: any, id: string, newStatus: boolean) {
    this.queueService.patchQueueStatus(id, newStatus).subscribe(
      () => {
        this.infoBarService.show(
          `Fila ${newStatus ? 'ativada' : 'desativada'} com sucesso`,
          'success',
        )
        this.updateQueueRowStatus(id, newStatus)
      },
      () => {
        this.infoBarService.show(
          `Não foi possível ${
            newStatus ? 'ativar' : 'desativar'
          } a fila. Consulte o suporte se o problema persistir`,
          'danger',
        )

        event.srcElement.checked = !newStatus
      },
    )
  }

  editQueue = async (queueId: string) => {
    this.store.loadQueue(queueId)
  }

  private fetchAttendanceTeams() {
    this.teamService.getAttendanceTeamsInfo().subscribe(
      teams => (this.teams = teams),
      () => {
        this.infoBarService.show(
          'Falha ao carregar as informações dos times. Consulte o suporte se o problema persistir',
          'danger',
        )
      },
    )
  }

  private fetchQueuesAndTeams() {
    this.queueService.getQueuesAndTeams().subscribe(
      queuesAndTeams => (this.rows = queuesAndTeams),
      () =>
        this.infoBarService.show(
          'Falha ao carregar as informações das filas. Consulte o suporte se o problema persistir',
          'danger',
        ),
    )
  }

  private updateQueueRowStatus(id: string, newStatus: boolean) {
    const queueAndTeam = this.rows.find(elem => elem.id === id)

    if (queueAndTeam) {
      queueAndTeam.active = newStatus

      this.rows = [...this.rows]
    }
  }

  private updateQueueRowTeamWithPreviousTeamId() {
    const queueAndTeam = this.rows.find(
      elem => elem.id === this.teamInfo.queueId,
    )

    if (queueAndTeam) {
      queueAndTeam.attendanceTeamId = this.teamInfo.previousTeamId

      const index = this.teams.findIndex(
        team => team.id === this.teamInfo.previousTeamId,
      )

      this.teamInfo.teamSelect.selectedIndex = index

      this.rows = [...this.rows]
    }
  }
}
