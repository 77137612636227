export const environment = {
  production: false,
  urlBlaMessageManagementApi:
    'https://apigw-qa.fastshop.com.br/chatmessage/v0/api/v1/',
  webChatConnector:
    'https://bla-webchat-connector-qa.devqa.fastshopdigital.com/ws/',
  whatsApp: 'https://api.whatsapp.com/send?phone=551132322925',
  timeMaxForRetryWebChatConnector: 300000,
}

import 'zone.js/dist/zone-error'
