import { Component, OnInit, Input, ElementRef } from '@angular/core'
import { InfoBarService } from './info-bar.service'
import { Subscription, interval } from 'rxjs'

@Component({
  selector: 'app-info-bar',
  templateUrl: './info-bar.component.html',
  styleUrls: ['./info-bar.component.scss'],
})
export class InfoBarComponent implements OnInit {
  type: string
  message: string
  element: any
  sub: Subscription
  private infoBarService: InfoBarService

  constructor(private el: ElementRef, infoBarService: InfoBarService) {
    this.element = el.nativeElement
    this.infoBarService = infoBarService
  }

  ngOnInit() {
    this.infoBarService.setComponent(this)
  }

  open = (message: string, type: string) => {
    this.message = message
    this.type = type
    this.element.style.transform = 'translateY(0px)'
    this.element.style.opacity = '1'

    this.sub = interval(10000).subscribe(() => {
      this.close()
    })
  }

  close = () => {
    this.element.style.transform = 'translateY(-150px)'
    this.element.style.opacity = '0'
    this.unsubscribe()
  }

  unsubscribe = () => {
    this.sub.unsubscribe()
  }
}
