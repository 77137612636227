import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { LocalStorageService } from '@app/tools/local-storage.service'
import { environment } from '@env/environment'
import { Observable } from 'rxjs'
import { ValidateSellerCodeResponse } from './direct-contact'

import {
  AccessProfile,
  CheckUsernameResponse,
  User,
  UserInfo,
  ChangeOperationalUserStatusRequest,
  UserResponse,
} from './user.model'

@Injectable()
export class UserService {
  private url: string

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
  ) {
    this.url = `${environment.urlBlaMessageManagementApi}/user`
  }

  public save = (user: User): Observable<User> => {
    return this.http.post<User>(this.url, this.reinforceUserFieldsTyping(user))
  }

  public update = (userId: string, user: User): Observable<any> => {
    return this.http.put(
      `${this.url}/${userId}`,
      this.reinforceUserFieldsTyping(user),
    )
  }

  public getProfiles = (userId: string): Observable<any[]> => {
    const params = new HttpParams().append('userId', userId)
    return this.http.get<any[]>(`${this.url}/AllowedAccessProfiles`, {
      params,
    })
  }

  public getAllAdminAndSupervisors = (): Observable<UserInfo[]> => {
    return this.http.get<UserInfo[]>(`${this.url}/AllAdminAndSupervisorUsers`)
  }

  public getWorkPauseReasons = (): Observable<any[]> => {
    return this.http.get<any[]>(`${this.url}/WorkPauseReason`)
  }

  public checkIfUsernameExists = (
    username: string,
  ): Observable<CheckUsernameResponse> => {
    return this.http.post<CheckUsernameResponse>(`${this.url}/CheckUsername`, {
      username,
    })
  }

  public changeOperationalUserStatus = (
    userId: string,
    reason: ChangeOperationalUserStatusRequest,
  ): Observable<any> => {
    return this.http.patch<any>(
      `${this.url}/ChangeOperationalUserStatus/${userId}`,
      reason,
    )
  }

  public getOperationalUsersByQueue = (queueId: string): Observable<any> =>
    this.http.get<any>(`${this.url}/OperationalUsersByQueue/${queueId}`)

  public getUserInfoByNameOrUserName = (
    userName: string,
  ): Observable<UserInfo[]> => {
    return this.http.get<UserInfo[]>(this.url, {
      params: new HttpParams().set('searchCriteria', userName),
    })
  }

  public getFilteredOnlineOperationalUsers = (
    usersIds?: string[],
    teamsIds?: string[],
    queuesIds?: string[],
    status?: string,
    workPauseReasons?: string[],
  ): Observable<any> => {
    let params = new HttpParams()

    usersIds?.forEach(user => {
      params = params.append('usersIds', user)
    })

    teamsIds?.forEach(team => {
      params = params.append('teamsIds', team)
    })

    queuesIds?.forEach(queueId => {
      params = params.append('queuesIds', queueId)
    })

    if (status !== undefined) params = params.append('attendanceStatus', status)

    workPauseReasons?.forEach(reason => {
      params = params.append('workPauseReason', reason)
    })

    return this.http.get<any>(`${this.url}/OnlineOperationalUsers`, {
      params,
    })
  }

  public getUserById = async (userId: string): Promise<UserResponse> => {
    return await this.http
      .get<UserResponse>(`${this.url}/${userId}`)
      .toPromise()
  }

  public getOnlineOperationalUser = (): Observable<any> =>
    this.http.get<any>(`${this.url}/OnlineOperationalUser`)

  public getOnlineSupervisorUser = (): Observable<any> =>
    this.http.get<any>(`${this.url}/OnlineSupervisors`)

  public getOnlineOperationalUserDetails = (
    onlineOperationalUserId: string,
  ): Observable<string> =>
    this.http.get<string>(`${this.url}/Details`, {
      params: new HttpParams().set(
        'onlineOperationalUserId',
        onlineOperationalUserId,
      ),
    })

  reinforceUserFieldsTyping = (form: User): User => {
    const loggedUser: UserInfo = this.localStorageService.getLoggedUser()
      .userInfo
    if (
      form.accessProfile !== undefined &&
      form.accessProfile.toString() === AccessProfile.Operational.toString()
    )
      return {
        name: String(form.name),
        username: String(form.username),
        accessProfile: form.accessProfile as AccessProfile,
        active: Boolean(form.active),
        immediateSuperiorUserId: String(form.immediateSuperiorUserId),
        maxParallelAttendances: Number(form.maxParallelAttendances),
        attendanceTeamsIds: form.attendanceTeamsIds as string[],
        externalLogin: String(form.externalLogin),
        sellerCode: String(form.sellerCode),
        workplaceId: String(form.workplaceId),
        requestingUserId: String(loggedUser.id),
      } as User
    else
      return {
        name: String(form.name),
        username: String(form.username),
        accessProfile: form.accessProfile as AccessProfile,
        active: Boolean(form.active),
        requestingUserId: String(loggedUser.id),
      } as User
  }

  public getAllOperationalUsers(): Observable<{ id: string; name: string }[]> {
    return this.http.get<{ id: string; name: string }[]>(
      `${this.url}/AllOperationalUsers`,
    )
  }

  public getSellersByWorkplaceId(
    workplaceId: string,
  ): Observable<{ id: string; name: string }[]> {
    return this.http.get<{ id: string; name: string }[]>(
      `${this.url}/Workplace?id=${workplaceId}`,
    )
  }

  public getOnlineSupervisorUsers(): Observable<UserInfo[]> {
    return this.http.get<UserInfo[]>(`${this.url}/onlineSupervisors`)
  }

  public validateSellerCode = (
    sellerCode: string,
  ): Observable<ValidateSellerCodeResponse> =>
    this.http.get<ValidateSellerCodeResponse>(
      `${this.url}/ValidateUser/${sellerCode}`,
    )
}
