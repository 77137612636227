import { Component, OnDestroy, OnInit } from '@angular/core'
import { TemplateMessageStore } from './template-message.store'
import { viewModeSteps } from '@app/stepper/steps/template-message.steps'
import { InfoBarService } from '@app/info-bar/info-bar.service'

@Component({
  selector: 'app-template-message',
  templateUrl: './template-message.component.html',
  styleUrls: ['./template-message.component.scss'],
})
export class TemplateMessageComponent implements OnInit, OnDestroy {
  title = 'Nova Frase'

  constructor(
    public store: TemplateMessageStore,
    public infoBarService: InfoBarService,
  ) {}

  ngOnInit(): void {
    if (this.store.isViewMode()) {
      this.title = 'Visualizar Frase'
      this.store.templateMessageSteps = viewModeSteps()
    }
  }

  ngOnDestroy() {
    this.store.clear()
  }
}
