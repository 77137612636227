import { BrokerType } from './../broker-type'
import { Component, OnInit } from '@angular/core'
import { TemplateMessageFilterModel } from '../template-message-filter/template-message-filter.model'
import { TemplateMessageSearchResult } from '../template-message-search-result/template-message-search-result'
import { TemplateMessageService } from '../template-message.service'

@Component({
  selector: 'app-template-message-list',
  templateUrl: './template-message-list.component.html',
  styleUrls: ['./template-message-list.component.scss'],
})
export class TemplateMessageListComponent implements OnInit {
  title = 'Cadastrar Frases'
  modal: HTMLElement

  public templateMessages: []
  resetTable: boolean
  templateMessageDetail: TemplateMessageSearchResult

  constructor(private templateMessageService: TemplateMessageService) {}

  ngOnInit(): void {
    this.modal = document.getElementById('template-messages-warning-modal')
  }

  showModal = () => {
    this.modal.classList.remove('hide')
    this.modal.classList.add('show')
  }

  closeModal = () => {
    this.modal.classList.remove('show')
    this.modal.classList.add('hide')
  }

  searchTemplateMessagesByFilters(filters: TemplateMessageFilterModel) {
    this.templateMessageService
      .getTemplateMessagesByFilters(filters)
      .subscribe(templateMessages => {
        this.templateMessages = templateMessages.map(templateMessage => ({
          id: templateMessage.id,
          templateId: templateMessage.templateTextMessage.templateId,
          brokerType: BrokerType[templateMessage.brokerType],
          queues: templateMessage.queues
            .reduce((names, queue) => names + queue.name + '\n', '')
            .slice(0, -1),
          message: templateMessage.templateTextMessage.message,
          status: templateMessage.status,
          messageType: templateMessage.messageType,
        }))
      })
  }

  fillTemplateMessageDetail(templateMessage) {
    if (templateMessage && templateMessage.Id?.length > 0)
      this.templateMessageDetail = templateMessage
    else this.resetTemplateMessageDetail()
  }

  resetTemplateMessageDetail = () => (this.templateMessageDetail = null)

  resetSorts = () => (this.resetTable = true)
}
