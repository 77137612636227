import { Injectable } from '@angular/core'
import * as signalR from '@microsoft/signalr'
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr'
import { environment } from '@env/environment'
@Injectable()
export class SignalRService {
  hubConnection: HubConnection

  public connect = async (url: string) => {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(url, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
          if (
            retryContext.elapsedMilliseconds <
            environment.timeMaxForRetryWebChatConnector
          ) {
            return Math.random() * 10000
          } else {
            return null
          }
        },
      })
      .build()

    await this.hubConnection.start()
  }

  public listen = (eventName: string, callback: (data: any) => void) => {
    this.hubConnection.on(eventName, callback)
  }

  public invoke = (actionName: string, ...args: any[]) => {
    this.hubConnection.invoke(actionName, ...args)
  }

  public stop = () => this.hubConnection.stop()
}
