import { Injectable } from '@angular/core'
import { LoggedUser } from '@app/components/authentication/logged-user.model'
import { ChatUserInfo } from '@app/components/chat-user-info/chat-user.info.model'

const USER_INFO_KEY = 'loggedUser'
const CLIENT_INFO_KEY = 'savedClient'

@Injectable()
export class LocalStorageService {
  public saveLoggedUser(loggedUser: LoggedUser) {
    localStorage.setItem(USER_INFO_KEY, JSON.stringify(loggedUser))
  }

  public getLoggedUser(): LoggedUser {
    const loggedUser = localStorage.getItem(USER_INFO_KEY)
    return JSON.parse(loggedUser)
  }

  public clearLocalStorage() {
    localStorage.clear()
  }

  public saveClient(chatUserInfo: ChatUserInfo) {
    localStorage.setItem(CLIENT_INFO_KEY, JSON.stringify(chatUserInfo))
  }

  public getClient(): ChatUserInfo {
    const client = localStorage.getItem(CLIENT_INFO_KEY)
    return JSON.parse(client)
  }
}
