import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@env/environment'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  url = `${environment.urlBlaMessageManagementApi}/messages/media`

  constructor(private http: HttpClient) {}

  getImageMedia = (imageId: string): Observable<any> =>
    this.http.get(`${this.url}/${imageId}`, {
      responseType: 'blob',
    })
}
