import '@angular/compiler'

import { Injectable } from '@angular/core'
import { Passport } from '@app/components/authentication/logged-user.model'
import { CookieService } from 'angular2-cookie/core'

const PASSPORT_USER_KEY = 'express:sess'

@Injectable()
export class PassportUserService {
  constructor(private cookieService: CookieService) {}

  public get(): Passport {
    const passportCookie = this.cookieService.get(PASSPORT_USER_KEY)

    if (!passportCookie) return

    const decryptedPassportCookie = atob(passportCookie)
    const passportJson = JSON.parse(decryptedPassportCookie)
    const passport = passportJson[Object.keys(passportJson)[0]] as Passport

    if (!passport || !passport.user) return

    return passport
  }
}
