import { Component } from '@angular/core'
import { UserService } from '../../user.service'
import { AccessProfile, UserInfo } from '../../user.model'
import { InfoBarService } from '@app/info-bar/info-bar.service'
import { ColumnMode } from '@swimlane/ngx-datatable'
import { UserStore } from '../../user.store'
import { Router } from '@angular/router'
import { LocalStorageService } from '@app/tools/local-storage.service'

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent {
  searchResult: UserInfo[]

  hiddenFooter = true

  messages = {
    emptyMessage: 'Ainda não há nenhuma busca feita por usuários',
  }

  ColumnMode = ColumnMode

  constructor(
    private userService: UserService,
    private infoBarService: InfoBarService,
    private store: UserStore,
    private router: Router,
    private localStorageService: LocalStorageService,
  ) {}

  public searchUsers(userName: string) {
    this.userService.getUserInfoByNameOrUserName(userName).subscribe(
      result => {
        this.searchResult = result
        this.searchResult?.length > 10
          ? (this.hiddenFooter = false)
          : (this.hiddenFooter = true)
      },
      () =>
        this.infoBarService.show(
          'Falha ao buscar os usuários. Consulte o suporte se o problema persistir',
          'danger',
        ),
    )
  }

  public async editUser(userId: string) {
    await this.store.loadUser(userId)
    this.router.navigateByUrl('cadastros/usuario')
  }

  loggedUserIsSupervisor = (loggedUser: UserInfo) =>
    loggedUser.accessProfile === AccessProfile.Supervisor

  loggedUserIsAdmin = (loggedUser: UserInfo) =>
    loggedUser.accessProfile === AccessProfile.Administrative

  editedUserIsOperationalUser = (user: UserInfo) =>
    user.accessProfile === AccessProfile.Operational

  loggedUserHasSufficientPrivileges = (editedUser: UserInfo) => {
    const loggedUser = this.localStorageService.getLoggedUser().userInfo

    return (
      this.loggedUserIsAdmin(loggedUser) ||
      (this.loggedUserIsSupervisor(loggedUser) &&
        this.editedUserIsOperationalUser(editedUser))
    )
  }
}
