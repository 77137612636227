import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  HostListener,
  Renderer2,
  OnChanges,
  SimpleChanges,
} from '@angular/core'

@Directive({
  selector: 'input[appCharactersLimit],textarea[appCharactersLimit]',
})
export class CharacterCounterDirective implements OnInit, OnChanges {
  @Input() appCharactersLimit = 100
  @Input() content: string

  private countElement: HTMLSpanElement
  private count = 0

  constructor(
    private el: ElementRef<HTMLInputElement>,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.count = this.el.nativeElement.value.length
    this.countElement = this.renderer.createElement('span')
    this.countElement.classList.add('counter-directive')
    this.countElement.id = 'counter-directive'
    this.countElement.textContent = this.countText
    this.el.nativeElement.after(this.countElement)
    this.countElement.style.top = `${this.el.nativeElement.parentElement
      .offsetHeight + 8}px`
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.content?.currentValue === '' && this.countElement)
      this.countElement.textContent = `0 / ${this.appCharactersLimit}`
  }

  @HostListener('input')
  updateCount() {
    this.count = this.el.nativeElement.value.length
    this.countElement.textContent = this.countText

    if (this.count > this.appCharactersLimit) {
      this.countElement.classList.add('invalid-color')
    } else {
      this.countElement.classList.remove('invalid-color')
    }
  }

  get countText() {
    return `${this.count} / ${this.appCharactersLimit}`
  }

  get charactersCount(): number {
    return this.appCharactersLimit
  }
}
