<div [hidden]="!currentConversationInfo">
  <div class="chat-user-info" *ngIf="currentConversationInfo">
    <div class="chat-user-info__phone">
      <span>Número telefone:</span
      >{{ currentConversationInfo.customer.phoneNumber }}
    </div>
    <div class="chat-user-info__name">
      <span>Nome:</span>
      <div>
        <input type="text" [value]="currentConversationInfo.customer.name" />
      </div>
    </div>
    <div class="chat-user-info__review" (click)="openModal()">
      <img src="../../../assets/images/review_icon.svg" alt="" />
      <div *ngIf="hasReview" class="review-signal"></div>
    </div>
  </div>
</div>
