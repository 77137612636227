<div class="chat-panel">
  <div class="chat-panel-content">
    <div class="chat-panel__infos">
      <div class="chat-panel__infos-combo">
        <div class="info-title">SLA</div>
        <div class="info-value">
          <img
            src="../../../assets/images/watch.svg"
            alt="tempo estimado de atendimento"
          />
          {{ sla === null ? '30min' : sla }}
        </div>
      </div>
      <div class="chat-panel__infos-combo">
        <div class="info-title">Sessão</div>
        <div class="info-value">
          <img
            src="../../../assets/images/watch.svg"
            alt="tempo de duração do atendimento"
          />
          {{ currentSessionTime }}
        </div>
      </div>
      <div class="chat-panel__infos-combo">
        <div class="info-title">Fila</div>
        <div class="info-value">
          <img
            src="../../../assets/images/list_icon.svg"
            alt="fila de atendimento"
          />
          {{ currentAttendance?.queueName }}
        </div>
      </div>
      <div class="chat-panel__infos-combo">
        <div class="info-title">Aguardando</div>
        <div class="info-value">
          <img
            src="../../../assets/images/list_arrow_icon.svg"
            alt="clientes em espera para esta fila"
          />
          {{ queuedAttendances }} na fila
        </div>
      </div>
      <div
        class="chat-panel__infos-combo"
        *ngIf="currentAttendance?.customerStatus !== null"
      >
        <div class="info-title">Tipo de cliente</div>
        <div class="info-value">
          <img
            src="../../../assets/images/list_icon.svg"
            alt="tipo do cliente"
          />
          {{ CustomerStatus[currentAttendance?.customerStatus] }}
        </div>
      </div>
      <div class="chat-panel-actions">
        <button
          class="bla-btn chat-panel-content btn-actions-attendance"
          [ngClass]="
            currentAttendance === undefined
              ? 'bla-btn--disabled'
              : 'bla-btn--primary'
          "
          id="btn-transfer-attendance"
          (click)="openTransferModal()"
          [disabled]="currentAttendance === undefined"
        >
          <img
            class="transfer-icon"
            src="../../../assets/images/arrowright.svg"
            alt="transferir atendimento"
          />
          Transferir
        </button>
        <button
          (click)="openEndModal()"
          class="bla-btn chat-panel-content btn-actions-attendance"
          [ngClass]="
            currentAttendance === undefined
              ? 'bla-btn--disabled'
              : 'bla-btn--danger'
          "
          id="btn-close-attendance"
          [disabled]="currentAttendance === undefined"
        >
          <img
            src="../../../assets/images/phone.svg"
            alt="encerrar atendimento"
          />
          Encerrar
        </button>
      </div>
    </div>
  </div>
</div>
