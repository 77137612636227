<div class="form-group">
  <form [formGroup]="formGroup">
    <label [for]="controlName" class="observation-text"
      >{{ label }}
      <small>{{ requiredOrOptionalLabel }}</small>
    </label>
    <select
      [formControlName]="controlName"
      [name]="controlName"
      [id]="controlName"
      class="form-control"
      [ngClass]="{
        'select--invalid':
          isRequired && formControlValidator.isInvalid(formGroup, controlName)
      }"
    >
      <option value="" *ngIf="placeholder">{{ placeholder }}</option>
      <option *ngFor="let item of items" [value]="item.value">
        {{ item.text }}
      </option>
    </select>
    <div *ngIf="isRequired" class="select-broker__info">
      <ul
        class="select-broker__errors"
        *ngIf="formControlValidator.isInvalid(formGroup, controlName)"
      >
        <li
          class="select-error"
          *ngIf="
            formControlValidator.hasError(formGroup, controlName, 'required')
          "
        >
          {{ requiredMessageError }}
        </li>
      </ul>
    </div>
  </form>
</div>
