<section class="rating-page">
  <div class="rating-page__logo">
    <img src="../../../assets/images/fast-shop-logo.png" alt="logo fastshop" />
  </div>
  <div class="rating-page__content">
    <app-rating-attendance-form
      *ngIf="ratingStatus === AttendanceRatingStatus.Pending"
      [attendanceId]="attendanceId"
      (ratingSuccess)="showAlertMessage($event)"
    ></app-rating-attendance-form>

    <app-rating-attendance-alert-message
      *ngIf="
        ratingStatus === AttendanceRatingStatus.Answered ||
        ratingStatus === AttendanceRatingStatus.Expired
      "
      [title]="title"
      [subtitle]="subtitle"
    ></app-rating-attendance-alert-message>
  </div>
</section>
