import { Step } from '@app/stepper/steps/step'

export enum StepsEnum {
  Name = 0,
  TimeTable = 1,
}

export const businessHoursSteps = [
  {
    index: 0,
    active: true,
    title: 'Nome',
    id: 'nome',
    route: '/cadastros/grade-horario/nova',
    completed: false,
    name: StepsEnum.Name,
    enabled: true,
  },
  {
    index: 1,
    active: false,
    title: 'Horários',
    id: 'horarios',
    route: '/cadastros/grade-horario/nova/horarios',
    completed: false,
    name: StepsEnum.TimeTable,
    enabled: false,
  },
] as Step[]
