import { templateMessagesRoutes } from './../register/template-message/template-message.routes'
import { TemplateMessageListComponent } from './../register/template-message/template-message-list/template-message-list.component'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuardService as AuthGuard } from '@app/auth/auth-guard.service'
import { ChatComponent } from '@app/chat/chat.component'
import { LoginComponent } from '@app/components/authentication/login/login.component'
import { MainComponent } from '@app/main.component'
import { TeamComponent } from '@app/register/attendance-team/attendance-team.component'
import { BrokerComponent } from '@app/register/broker/broker.component'
import { BusinessHoursComponent } from '@app/register/business-hours/business-hours.component'
import { businessHoursRoutes } from '@app/register/business-hours/business-hours.routes'
import { BusinessHoursListComponent } from '@app/register/business-hours/list/business-hours-list.component'
import { QueueComponent } from '@app/register/queue/queue.component'
import { queueRoutes } from '@app/register/queue/queue.routes'
import { UserComponent } from '@app/register/user/user.component'
import { AccessProfile } from '@app/register/user/user.model'
import { userRoutes } from '@app/register/user/user.routes'
import { QueueListComponent } from '@app/register/queue/queue-list/queue-list.component'
import { OnlineOperationalUserComponent } from '@app/monitoring/online-operational-user/online-operational-user/online-operational-user.component'
import { MonitoringAttendanceComponent } from '@app/monitoring/attendance/monitoring-attendance.component'
import { UserListComponent } from '@app/register/user/user-list/user-list/user-list.component'
import { RatingAttendancePageComponent } from '@app/rating-attendance/rating-attendance-page/rating-attendance-page.component'
import { MonitoringQueuesComponent } from '@app/monitoring/queues/monitoring-queues/monitoring-queues.component'
import { DirectContactPageComponent } from '@app/direct-contact/direct-contact-page/direct-contact-page.component'
import { HomeComponent } from '@app/home/home.component'
import { ClosingBatchConversationsComponent } from '@app/action/closing-batch-conversations/closing-batch-conversations.component'
import { TemplateMessageComponent } from '@app/register/template-message/template-message.component'
import { BrokerListComponent } from '@app/register/broker/broker-list/broker-list.component'

const { Operational, Administrative, Supervisor } = AccessProfile

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        data: { expectedRole: [Operational, Supervisor, Administrative] },
        component: HomeComponent,
      },
      {
        path: 'cadastros',
        data: { expectedRole: [Administrative, Supervisor] },
        children: [
          {
            path: 'fila',
            component: QueueListComponent,
          },
          {
            path: 'fila/nova',
            component: QueueComponent,
            children: queueRoutes,
          },
          {
            path: 'broker',
            component: BrokerListComponent,
          },
          {
            path: 'broker/novo',
            component: BrokerComponent,
          },
          {
            path: 'time',
            component: TeamComponent,
          },
          {
            path: 'usuario/lista',
            component: UserListComponent,
          },
          {
            path: 'usuario',
            component: UserComponent,
            children: userRoutes,
          },
          {
            path: 'grade-horario',
            component: BusinessHoursListComponent,
          },
          {
            path: 'grade-horario/nova',
            component: BusinessHoursComponent,
            children: businessHoursRoutes,
          },
          {
            path: 'frase',
            component: TemplateMessageListComponent,
          },
          {
            path: 'frase/nova',
            component: TemplateMessageComponent,
            children: templateMessagesRoutes,
          },
        ],
      },
      {
        path: 'chat',
        data: { expectedRole: [Operational, Supervisor] },
        component: ChatComponent,
      },
      {
        path: 'monitoramento',
        data: { expectedRole: [Administrative, Supervisor] },
        children: [
          {
            path: 'atendentes-online',
            component: OnlineOperationalUserComponent,
          },
          {
            path: 'atendimento',
            component: MonitoringAttendanceComponent,
          },
          {
            path: 'filas',
            component: MonitoringQueuesComponent,
          },
        ],
      },
      {
        path: 'acoes',
        data: { expectedRole: [Administrative] },
        children: [
          {
            path: 'encerramento-inativos',
            component: ClosingBatchConversationsComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'pesquisa/:attendanceId',
    component: RatingAttendancePageComponent,
  },
  {
    path: 'contato/:sellerCode',
    component: DirectContactPageComponent,
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
