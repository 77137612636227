<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <nav class="navbar navbar-light bg-light">
      <a class="navbar-brand" routerLink="">
        <img
          src="../../assets/images/fast-shop-bla.svg"
          alt="Fast Shop Bla Logo"
        />
      </a>
    </nav>

    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" routerLink="" id="home-button"
            >Home <span class="sr-only">(current)</span></a
          >
        </li>
        <li
          class="nav-item dropdown"
          *ngIf="
            loggedUser.userInfo.accessProfile == accessProfile.Administrative
          "
        >
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdownRegister"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Cadastros
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <label class="dropdown-item-label">Calendário</label>
            <a
              class="dropdown-item"
              routerLink="/cadastros/grade-horario"
              id="business-hours-button"
              >Grade de Horário</a
            >
            <label class="dropdown-item-label">Equipe</label>
            <a
              class="dropdown-item"
              routerLink="/cadastros/usuario/lista"
              id="user-button"
              >Usuários</a
            >
            <a
              class="dropdown-item"
              routerLink="/cadastros/time"
              id="team-button"
              >Times</a
            >
            <label class="dropdown-item-label">Chat</label>
            <a
              class="dropdown-item"
              routerLink="/cadastros/fila"
              id="queue-button"
              >Filas de Chat</a
            >
            <a
              class="dropdown-item"
              routerLink="/cadastros/broker"
              id="broker-button"
              >Broker</a
            >
            <a
              class="dropdown-item"
              routerLink="/cadastros/frase"
              id="template-messages-button"
              >Frases</a
            >
          </div>
        </li>
        <li
          class="nav-item dropdown"
          *ngIf="loggedUser.userInfo.accessProfile == accessProfile.Supervisor"
        >
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdownRegister"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Cadastros
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <label class="dropdown-item-label">Equipe</label>
            <a
              class="dropdown-item"
              routerLink="/cadastros/usuario/lista"
              id="user-button"
              >Usuários</a
            >
          </div>
        </li>
        <li
          class="nav-item"
          *ngIf="
            loggedUser.userInfo.accessProfile === accessProfile.Operational ||
            loggedUser.userInfo.accessProfile === accessProfile.Supervisor
          "
        >
          <a id="chat-button" class="nav-link" routerLink="/chat">Chat</a>
        </li>
        <li
          class="nav-item dropdown"
          *ngIf="
            loggedUser.userInfo.accessProfile ===
              accessProfile.Administrative ||
            loggedUser.userInfo.accessProfile === accessProfile.Supervisor
          "
        >
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdownMonitoring"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Monitoramento
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a
              class="dropdown-item"
              routerLink="/monitoramento/atendentes-online"
              id="online-operational-users-button"
              >Atendentes Online</a
            >
            <a
              class="dropdown-item"
              routerLink="/monitoramento/atendimento"
              id="attendances-button"
              >Atendimento</a
            >
            <a
              class="dropdown-item"
              routerLink="/monitoramento/filas"
              id="queues-button"
              >Filas</a
            >
          </div>
        </li>
        <li
          class="nav-item dropdown"
          *ngIf="
            loggedUser.userInfo.accessProfile == accessProfile.Administrative
          "
        >
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdownActions"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Ações
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a
              class="dropdown-item"
              routerLink="/acoes/encerramento-inativos"
              id="close-inactive-button"
              >Encerramento de Inativos</a
            >
          </div>
        </li>
      </ul>
    </div>
    <app-attendant-status></app-attendant-status>
    <app-logout></app-logout>
  </div>
</nav>
