import { Component } from '@angular/core'
import { InfoBarService } from '@app/info-bar/info-bar.service'

import { UserStore } from './user.store'

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent {
  constructor(public store: UserStore, public infoBarService: InfoBarService) {}
}
