<div class="bla-container">
  <app-title title="Encerramento de inativos"></app-title>

  <div class="filter-row">
    <app-closing-batch-conversations-filter
      (filter)="searchAttendancesInactives($event)"
    >
    </app-closing-batch-conversations-filter>
  </div>
  <div class="result-row">
    <app-closing-batch-conversations-result
      [attendancesInactives]="attendances"
    >
    </app-closing-batch-conversations-result>
  </div>
</div>
