import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-business-hours-list',
  templateUrl: './business-hours-list.component.html',
  styleUrls: ['./business-hours-list.component.scss'],
})
export class BusinessHoursListComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
