import { Component, Input, Output } from '@angular/core'
import { NgSelectConfig } from '@ng-select/ng-select'
import { EventEmitter } from '@angular/core'
import { ValuesOutput } from './input-dropdown'
import { StringTools } from '@app/tools/normalize-string'

@Component({
  selector: 'app-input-dropdown',
  templateUrl: './input-dropdown.component.html',
  styleUrls: ['./input-dropdown.component.scss'],
})
export class InputDropdownComponent {
  @Output() valuesSelected = new EventEmitter<ValuesOutput>()

  @Input() props: {
    labelTitle: string
    placeholder: string
    multipleSelect: boolean
    closeOnSelect: boolean
    tag: string
  }

  @Input() dropdownItens: []

  constructor(
    private config: NgSelectConfig,
    private stringTools: StringTools,
  ) {
    this.config.notFoundText = 'Nenhum resultado encontrado'
  }

  setValue = (ids: string[]) => {
    const field = this.stringTools
      .trimFirstWord(this.stringTools.normalizeString(this.props.labelTitle))
      .toLocaleLowerCase()
    this.valuesSelected.emit({ field, ids, tag: this.props.tag })
  }
}
