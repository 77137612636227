<div class="form-group">
  <form [formGroup]="formGroup">
    <div class="multiselect-container" *ngIf="!readonly">
      <div class="form-group form-item">
        <app-drop-down
          class="multiselect-dropdown"
          [formGroup]="dropDownFormGroup"
          controlName="item"
          [label]="label"
          [items]="items"
          [isRequired]="isRequired"
        ></app-drop-down>
      </div>

      <div class="form-actions">
        <button
          type="button"
          class="bla-btn bla-btn--primary add-button"
          (click)="add()"
        >
          Adicionar
        </button>
      </div>
    </div>
    <div class="row" *ngIf="dataTableItems.length > 0">
      <table>
        <thead>
          <th>{{ label }}</th>
          <th *ngIf="!readonly">Ações</th>
        </thead>
        <tbody>
          <tr *ngFor="let item of dataTableItems">
            <td>{{ item.text }}</td>
            <td *ngIf="!readonly">
              <button type="button" (click)="remove(item)">
                Remover
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </form>
</div>
