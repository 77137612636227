import {
  Component,
  ViewChild,
  ElementRef,
  HostListener,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core'
import { CustomerReview } from './customer-review'
import { CustomerReviewService } from './customer-review.service'
import { InfoBarService } from '@app/info-bar/info-bar.service'

@Component({
  selector: 'app-customer-review-modal',
  templateUrl: './customer-review-modal.component.html',
  styleUrls: ['./customer-review-modal.component.scss'],
})
export class CustomerReviewModalComponent implements AfterViewInit {
  @ViewChild('reviewContent') private content: ElementRef
  @Input() customerId: string
  @Input() reviewTextContent: string
  @Output() openExcludeReview = new EventEmitter()
  @Output() savedReview = new EventEmitter()

  invalid = true
  inputCharacterLimit = 500
  request: CustomerReview

  constructor(
    private service: CustomerReviewService,
    private infoBar: InfoBarService,
    private element: ElementRef,
  ) {}

  @HostListener('input')
  updateField() {
    const maxCharacterLimit =
      this.content.nativeElement.value.length > this.inputCharacterLimit

    const minCharacterLimit = this.content.nativeElement.value.length === 0

    minCharacterLimit || maxCharacterLimit
      ? (this.invalid = true)
      : (this.invalid = false)
  }

  ngAfterViewInit() {
    this.element.nativeElement.addEventListener(
      'click',
      this.clickOutside,
      true,
    )
  }

  clickOutside = (event: any) => {
    const isOutside = event.target.classList.contains('bla-modal__background')
    if (isOutside) this.close()
  }

  close = () => {
    this.content.nativeElement.value = this.reviewTextContent

    if (this.reviewTextContent.length === 0) this.invalid = true

    this.element.nativeElement.classList.remove('show')
    this.element.nativeElement.classList.add('hide')
  }

  openExcludeReviewModal = () => this.openExcludeReview.emit('')

  save = () => {
    this.request = {
      customerId: this.customerId,
      reviewTextContent: this.content.nativeElement.value,
    }

    this.service.post(this.request).subscribe(
      () => {
        this.close()
        this.reviewTextContent = this.content.nativeElement.value
        this.savedReview.emit(this.request.customerId)
        this.infoBar.show('Observação cadastrada com sucesso', 'success')
        this.request = null
      },
      () => {
        this.close()
        this.infoBar.show('Ocorreu um erro ao cadastrar a observação', 'danger')
      },
    )
  }
}
